import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { Capacitor } from "@capacitor/core";
import { FileOpener } from "@ionic-native/file-opener";
import { App } from "@capacitor/app";
import {
  STORAGE_AFFIX_LIEGENSCHAFT,
  STORAGE_AFFIX_LOCK,
  STORAGE_AFFIX_MEDIA,
  STORAGE_AFFIX_PROJECT,
  STORAGE_AFFIX_USER,
  STORAGE_PATH_DOWNLOADS,
  STORAGE_PATH_PROJECTS,
} from "./constants";

export async function writeDocumentFromBlobOnAndroid(
  blob,
  filename,
  filetype,
  openFileAfter
) {
  const path = STORAGE_PATH_DOWNLOADS + filename;
  console.log("WRITE FILE");
  blobToBase64(blob)
    .then((data) => {
      console.log(data);
      console.log("BASE64");
      Filesystem.writeFile({
        path: path,
        data: data,
        directory: Directory.Documents,
        recursive: true,
      }).then(() => {
        if (openFileAfter) {
          Filesystem.getUri({
            directory: Directory.Documents,
            path: path,
          }).then((getUriResult) => {
            FileOpener.open(getUriResult.uri, filetype);
          });
        }
      });
    })
    .catch(() => {
      console.log("ERROR");
    });
}

export const readFileAsBlob = (
  liegenschaftId,
  projectId,
  mediaId,
  filename
) => {
  return new Promise((resolve, reject) => {
    if (liegenschaftId && projectId && mediaId && filename) {
      const path =
        STORAGE_PATH_PROJECTS + liegenschaftId + "/" + projectId + "/media/";
      Filesystem.readFile({
        path: path + mediaId + "/" + filename,
        directory: Directory.Documents,
      }).then((data) => {
        const blob = b64toBlob(data.data);
        resolve(blob);
      });
    } else {
      reject();
    }
  });
};

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export function openStoredMediaFileOnAndroid(
  liegenschaftId,
  projectId,
  mediaId,
  filename,
  mimetype,
  onError
) {
  if (liegenschaftId && projectId && mediaId && filename) {
    const path =
      STORAGE_PATH_PROJECTS + liegenschaftId + "/" + projectId + "/media/";
    Filesystem.getUri({
      path: path + mediaId + "/" + filename,
      directory: Directory.Documents,
    })
      .then((result) => {
        if (result) {
          FileOpener.open(result.uri, mimetype);
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  }
}

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export function execIfOnWeb(method) {
  if (method && runsOnWeb()) {
    method();
  }
}

export function execIfOnAndroid(method) {
  if (method && Capacitor.getPlatform() === "android") {
    method();
  }
}

export function execIfOnIOS(method) {
  if (method && Capacitor.getPlatform() === "ios") {
    method();
  }
}

export function execIfOnMobile(method) {
  if (method && runsOnMobile()) {
    method();
  }
}

export function addAndroidBackListener(onBackPressed) {
  App.addListener("backButton", () => {
    if (onBackPressed) {
      onBackPressed();
    }
  });
}

export function runsOnWeb() {
  return Capacitor.getPlatform() === "web";
}

export function runsOnMobile() {
  if (Capacitor.getPlatform() === "android") {
    return true;
  } else if (Capacitor.getPlatform() === "ios") {
    return true;
  } else {
    return false;
  }
}

export async function createProjectOnDisk(
  liegenschaftId,
  projectId,
  onSuccess,
  onError
) {
  if (projectId && liegenschaftId) {
    const path =
      STORAGE_PATH_PROJECTS + liegenschaftId + "/" + projectId + "/project.txt";
    Filesystem.writeFile({
      path: path,
      data: "This project was created at " + new Date(),
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
      recursive: true,
    })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  }
}

export async function checkExistenceOfProjectOnDisk(
  liegenschaftId,
  projectId,
  onSuccess,
  onError
) {
  if (projectId && liegenschaftId) {
    const path =
      STORAGE_PATH_PROJECTS + liegenschaftId + "/" + projectId + "/project.txt";
    Filesystem.readFile({
      path: path,
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  }
}

export async function deleteProjectFromDisk(
  liegenschaftId,
  projectId,
  onSuccess,
  onError
) {
  if (projectId && liegenschaftId) {
    const path = STORAGE_PATH_PROJECTS + liegenschaftId + "/" + projectId;
    Filesystem.rmdir({
      path: path,
      directory: Directory.Documents,
      recursive: true,
    })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  }
}

export async function storeLiegenschaftInProjectOnDisk(
  projectId,
  liegenschaft,
  onSuccess,
  onError
) {
  if (projectId && liegenschaft) {
    const path = STORAGE_PATH_PROJECTS + liegenschaft.id + "/" + projectId;
    Filesystem.writeFile({
      path: path + STORAGE_AFFIX_LIEGENSCHAFT,
      data: JSON.stringify(liegenschaft),
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  }
}

export async function storeAuditInProjectOnDisk(
  liegenschaftId,
  project,
  onSuccess,
  onError
) {
  if (liegenschaftId && project) {
    const path = STORAGE_PATH_PROJECTS + liegenschaftId + "/" + project.id;
    Filesystem.writeFile({
      path: path + STORAGE_AFFIX_PROJECT,
      data: JSON.stringify(project),
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  }
}

export const storeAuditProjectSynced = async (liegenschaftId, project) => {
  const path = STORAGE_PATH_PROJECTS + liegenschaftId + "/" + project.id;
  return Filesystem.writeFile({
    path: path + STORAGE_AFFIX_PROJECT,
    data: JSON.stringify(project),
    directory: Directory.Documents,
    encoding: Encoding.UTF8,
  });
};

export async function storeLockInProjectOnDisk(
  liegenschaftId,
  projectId,
  lock,
  onSuccess,
  onError
) {
  if (liegenschaftId && projectId && lock) {
    const path = STORAGE_PATH_PROJECTS + liegenschaftId + "/" + projectId;
    Filesystem.writeFile({
      path: path + STORAGE_AFFIX_LOCK,
      data: JSON.stringify(lock),
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  }
}

export async function storeUserInProjectOnDisk(
  liegenschaftId,
  projectId,
  user,
  onSuccess,
  onError
) {
  if (liegenschaftId && projectId && user) {
    const path = STORAGE_PATH_PROJECTS + liegenschaftId + "/" + projectId;
    Filesystem.writeFile({
      path: path + STORAGE_AFFIX_USER,
      data: JSON.stringify(user),
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  }
}

export const renameFolderOnDisk = async (
  liegenschaftId,
  projectId,
  mediaId,
  newId
) => {
  console.log("renameFolderOnDisk");
  const path = STORAGE_PATH_PROJECTS + liegenschaftId + "/" + projectId;
  return Filesystem.rename({
    from: path + "/media/" + mediaId,
    to: path + "/media/" + newId,
    directory: Directory.Documents,
  });
};

export async function storeMediaInProjectOnDisk(
  liegenschaftId,
  projectId,
  media,
  onSuccess,
  onError
) {
  if (liegenschaftId && projectId) {
    const path = STORAGE_PATH_PROJECTS + liegenschaftId + "/" + projectId;
    Filesystem.writeFile({
      path: path + STORAGE_AFFIX_MEDIA,
      data: media ? JSON.stringify(media) : "",
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  }
}

export async function storeMediaFromBlobOnAndroidInProject(
  liegenschaftId,
  projectId,
  mediaId,
  blob,
  filename,
  onSuccess,
  onError
) {
  const path =
    STORAGE_PATH_PROJECTS + liegenschaftId + "/" + projectId + "/media/";
  blobToBase64(blob)
    .then((data) => {
      Filesystem.writeFile({
        path: path + mediaId + "/" + filename,
        data: data,
        directory: Directory.Documents,
        recursive: true,
      })
        .then((uri) => {
          if (onSuccess) {
            onSuccess(uri.uri);
          }
        })
        .catch(() => {
          if (onError) {
            onError();
          }
        });
    })
    .catch(() => {
      if (onError) {
        onError();
      }
    });
}

export async function readOfflineMediaOnAndroid(path, onSuccess, onError) {
  Filesystem.readFile({
    path: path,
  })
    .then((content) => {
      if (onSuccess) {
        onSuccess(content);
      }
    })
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}

export async function storeOfflineMediaOnAndroidInProject(
  liegenschaftId,
  projectId,
  file,
  filename,
  onSuccess,
  onError
) {
  const path =
    STORAGE_PATH_PROJECTS + liegenschaftId + "/" + projectId + "/media/";
  const mediaId = Date.now();
  blobToBase64(file).then((data) => {
    Filesystem.writeFile({
      path: path + mediaId + "/" + filename,
      data: data,
      directory: Directory.Documents,
      recursive: true,
    })
      .then((uri) => {
        if (onSuccess) {
          onSuccess({
            url: Capacitor.convertFileSrc(uri.uri),
            path: uri.uri,
            id: mediaId,
          });
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  });
}

export async function getStoredProjects(onSuccess, onError) {
  const folderPathsToProjects = [];
  const viewedLiegenschaften = [];
  const path = STORAGE_PATH_PROJECTS;
  Filesystem.readdir({
    path: path,
    directory: Directory.Documents,
  })
    .then((data) => {
      if (data.files && data.files.length > 0) {
        for (let i = 0; i < data.files.length; i++) {
          const liegenschaftId = data.files[i];
          //get all projects for liegenschaft
          Filesystem.readdir({
            path: path + liegenschaftId,
            directory: Directory.Documents,
          })
            .then((files) => {
              if (files.files && files.files.length > 0) {
                for (let j = 0; j < files.files.length; j++) {
                  folderPathsToProjects.push(
                    path + liegenschaftId + "/" + files.files[j]
                  );
                }
              }
              //check break condition
              viewedLiegenschaften.push(liegenschaftId);
              if (data.files.length === viewedLiegenschaften.length) {
                if (onSuccess) {
                  onSuccess(folderPathsToProjects);
                }
              }
            })
            .catch(() => {
              //there are no projects in liegenschaft (empty)
              //check break condition
              viewedLiegenschaften.push(liegenschaftId);
              if (data.files.length === viewedLiegenschaften.length) {
                if (onSuccess) {
                  onSuccess(folderPathsToProjects);
                }
              }
            });
        }
      } else {
        if (onError) {
          onError();
        }
      }
    })
    .catch(() => {
      if (onError) {
        onError();
      }
    });
}

export async function readJsonFileFromDisk(path, onSuccess, onError) {
  if (path) {
    Filesystem.readFile({
      path: path,
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    })
      .then((data) => {
        if (onSuccess) {
          onSuccess(data.data);
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  } else {
    if (onError) {
      onError();
    }
  }
}
