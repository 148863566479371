import React from "react";
import { useId } from "react-id-generator";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  BeeContentHeadline,
  BeeValidatedCurrencyInput,
  BeeValidatedNumberInput,
  BeeIcon,
  BeeContentParagraph,
} from "bee-atomic-components";
import { Checkbox } from "primereact/checkbox";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditSetupLeistungsubersicht.scss";

export function BeeAuditSetupLeistungsubersicht({
  liegenschaftTitle,
  type,
  data,
  readOnly,
  currencyMode,
  currency,
  locale,
  onToggleSelection,
  onAdaptValue,
}) {
  const currentId = useId(1, "bee-audit-setup-leistung-")[0];

  //classNames
  const typeClassNames =
    type === "dark"
      ? "bee-audit-setup-leistung-table-dark"
      : type === "light"
      ? "bee-audit-setup-leistung-table-light"
      : "bee-audit-setup-leistung-table-light";
  const classNames = "bee-audit-setup-leistung ";
  const headerClassNames = "bee-audit-setup-header";
  const tableClassNames = "bee-audit-setup-leistung-table " + typeClassNames;
  const positionColClassNames = "bee-audit-setup-leistung-table-col-stand";
  const selectionColClassNames = "bee-audit-setup-leistung-table-col-selection";
  const titleColClassNames = "bee-audit-setup-leistung-table-col-text";
  const weightColClassNames = "bee-audit-setup-leistung-table-col-weight";

  //labels
  const headlineLabel = "Leistungsübersicht";
  const emptyMessage = "Aktuell keine Auswahl der Kategorien möglich.";
  const positionColLabel = "";
  const selectionColLabel = "Stand";
  const titleColLabel = "Kurztext";
  const weightColLabel = currencyMode ? "GP" : "Gewichtung";

  function positionTemplate(rowData) {
    if (rowData && rowData.position) {
      return <div headername={positionColLabel}>{rowData.position}</div>;
    }
  }

  function selectionTemplate(rowData) {
    const data = rowData;
    if (rowData) {
      if (readOnly) {
        //presentation-mode
        if (rowData.selected) {
          return (
            <div headername="Auswahl">
              <BeeIcon iconClass="pi pi-check" size="large" />
            </div>
          );
        }
        return (
          <div headername="Auswahl">
            <BeeIcon iconClass="pi pi-times" size="large" />
          </div>
        );
      } else {
        //adaption-mode
        return (
          <div headername="Auswahl">
            <Checkbox
              onChange={() => changeSelection(!data.selected, data)}
              checked={data.selected}
              disabled={readOnly}
            />
          </div>
        );
      }
    }
  }

  function titleTemplate(rowData) {
    if (rowData && (rowData.title || rowData.subtitle)) {
      return (
        <div headername={titleColLabel}>
          <p>{rowData.title}</p>
          <BeeContentHeadline
            headline={"h4"}
            type={"primary"}
            label={rowData.subtitle}
          />
        </div>
      );
    }
  }

  function weightTemplate(rowData) {
    const data = rowData;
    if (rowData) {
      const weight = data.weight ? data.weight : 0;
      return currencyMode ? (
        <div headername={weightColLabel}>
          <BeeValidatedCurrencyInput
            value={weight}
            disabled={false}
            currency={currency}
            locale={locale}
            formstate={"neutral"}
            readOnly={readOnly}
            required={false}
            onChange={(e) => changeValue(e.value, data)}
          />
        </div>
      ) : (
        <div headername={weightColLabel}>
          <BeeValidatedNumberInput
            value={weight}
            minFractionDigits={0}
            maxFractionDigits={0}
            grouping={false}
            formstate={"neutral"}
            readOnly={readOnly}
            required={false}
            onChange={(e) => changeValue(e.value, data)}
          />
        </div>
      );
    }
  }

  function changeSelection(newStatus, data) {
    if (onToggleSelection) {
      onToggleSelection(newStatus, data);
    }
  }

  function changeValue(newValue, data) {
    if (onAdaptValue) {
      onAdaptValue(newValue, data);
    }
  }

  return (
    <div id={currentId} className={classNames}>
      <div className={headerClassNames}>
        <BeeContentHeadline
          label={headlineLabel}
          headline={"h2"}
          type={"primary"}
        />
        {liegenschaftTitle ? (
          <BeeContentHeadline
            label={liegenschaftTitle}
            headline={"h3"}
            type={"primary"}
          />
        ) : null}
      </div>
      <BeeContentParagraph
        text={
          "Die Leistungsübersicht dient der Konfiguration des Audits durch die Festlegung des Leistungsumfangs im definierten Zeitraum. Durch einfaches weg- oder hinzunehmen der Haken neben den Leistungen, können einzelne Leistungen dem Audit weggenommen oder hinzugefügt werden. Entsprechend werden im Zuge der Bewertungen Fragen zu diesen Leistungen gestellt. Die zugehörigen Preise dienen lediglich zu Ihrer Orientierung, welche Leistung wie gewichtig in diesem Vertrag ist."
        }
        size={"medium"}
        type={"primary"}
      />
      <div className={tableClassNames}>
        <DataTable
          value={data}
          className="p-datatable-sm"
          emptyMessage={emptyMessage}
        >
          <Column
            className={selectionColClassNames}
            body={selectionTemplate}
            header={selectionColLabel}
          />
          <Column
            className={positionColClassNames}
            body={positionTemplate}
            header={positionColLabel}
          />
          <Column
            className={titleColClassNames}
            body={titleTemplate}
            header={titleColLabel}
          />
          <Column
            className={weightColClassNames}
            body={weightTemplate}
            header={weightColLabel}
          />
        </DataTable>
      </div>
    </div>
  );
}

export default BeeAuditSetupLeistungsubersicht;
