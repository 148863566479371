import React from "react";
import { useId } from "react-id-generator";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BeeIconButton } from "bee-atomic-components";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditBasisTable.scss";
import { GRUNDLAGENTYPEN } from "../../Helper/constants";
import _ from "lodash";

export function BeeAuditBasisTable({
  type,
  data,
  readOnly,
  showEdit,
  showDelete,
  onView,
  onDelete,
}) {
  const currentId = useId(1, "bee-audit-basis-table-")[0];

  //classNames
  const typeClassNames =
    type === "dark"
      ? "bee-audit-basis-table-dark"
      : type === "light"
      ? "bee-audit-basis-table-light"
      : "bee-audit-basis-table-light";
  const classNames = "bee-audit-basis-table " + typeClassNames;
  const tableClassNames = "p-datatable-sm";

  const typeColClassNames = "bee-audit-basis-table-col-type";
  const commentColClassNames = "bee-audit-basis-table-col-comment";
  const dateColClassNames = "bee-audit-basis-table-col-date";
  const actionColClassNames =
    "bee-audit-basis-table-col-action" + calculateActionColWidthClassName();

  //label
  const emptyMessage = "Keine Einträge vorhanden.";

  function calculateActionColWidthClassName() {
    const buttonCount = (showEdit ? 1 : 0) + (showDelete ? 1 : 0);
    const actionColWidthClassNames =
      buttonCount === 1
        ? " bee-audit-basis-table-col-action-oneBtn"
        : buttonCount === 2
        ? " bee-audit-basis-table-col-action-twoBtn"
        : "";
    return actionColWidthClassNames;
  }

  function typeTemplate(rowData) {
    if (rowData) {
      const name = _.filter(GRUNDLAGENTYPEN, function (o) {
        return o.value === rowData.type;
      });
      return <p headername={"Typ"}>{name[0].label}</p>;
    }
  }

  function commentTemplate(rowData) {
    if (rowData) {
      return <p headername={"Anmerkung"}>{rowData.comment}</p>;
    }
  }

  function dateTemplate(rowData) {
    if (rowData) {
      return (
        <p headername={"Datum"}>
          {rowData.date ? rowData.date.toLocaleDateString("de-DE") : null}
        </p>
      );
    }
  }

  function actionTemplate(rowData) {
    const rowDataId = rowData.id;
    if (rowData) {
      return (
        <div headername={""}>
          {showEdit ? (
            <BeeIconButton
              iconClass={"pi pi-pencil"}
              rounded={false}
              disabled={false}
              type={"primary"}
              onClick={() => view(rowDataId)}
            />
          ) : null}
          {showDelete ? (
            <BeeIconButton
              iconClass={"pi pi-trash"}
              rounded={false}
              disabled={readOnly}
              type={"danger"}
              onClick={() => deleteEntry(rowDataId)}
            />
          ) : null}
        </div>
      );
    }
  }

  function view(itemId) {
    if (onView) {
      onView(itemId);
    }
  }

  function deleteEntry(itemId) {
    if (onDelete) {
      onDelete(itemId);
    }
  }

  return (
    <div id={currentId} className={classNames}>
      <DataTable
        value={data}
        className={tableClassNames}
        emptyMessage={emptyMessage}
      >
        <Column
          className={typeColClassNames}
          body={typeTemplate}
          header={"Typ"}
        />
        <Column
          className={commentColClassNames}
          body={commentTemplate}
          header={"Anmerkung"}
        />
        <Column
          className={dateColClassNames}
          body={dateTemplate}
          header={"Datum"}
        />
        {(!readOnly && showEdit) || (!readOnly && showDelete) ? (
          <Column
            className={actionColClassNames}
            body={actionTemplate}
            header={""}
          />
        ) : null}
      </DataTable>
    </div>
  );
}

export default BeeAuditBasisTable;
