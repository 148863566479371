import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import BeeAuditSetup from "../Components/BeeAuditSetup";
import {
  BeeConfirmDialog,
  BeeToast,
  BeeUploadDialog,
} from "bee-atomic-components";
import { logoutUser } from "../../redux/app/appActions";
import {
  extractLeistungsSetup,
  prepareShowingMode,
} from "../../Helper/QuestionaireHelper";
import {
  breadCrumbHome,
  createBreadCrumbAuditSetup,
} from "../../Helper/BreadCrumbHelper";
import {
  callAllAuditTemplatesShort,
  callAuditTemplate,
  convertLVTemplateToJson,
  createAuditProject,
  downloadLvTemplate,
  errorToMsg,
} from "../../Helper/NetworkHelper";
import {
  DURATION_NOTIFICATION_ERROR_LONG,
  DURATION_NOTIFICATION_INFO,
  DURATION_NOTIFICATION_SUCCESS,
  PATH_ONLINE_OVERVIEW_AUDIT,
} from "../../Helper/constants";
import { removeAllOnlinePendingLocksForUserId } from "../../Helper/RemovePendingLocksHelper";
import {
  execIfOnAndroid,
  execIfOnWeb,
  writeDocumentFromBlobOnAndroid,
} from "../../Helper/CapacitorHelper";
import fileDownload from "js-file-download";
import _ from "lodash";
import { convertDataToLv, convertLvToCategoryMap } from "../../Helper/LvHelper";

function OnlineAuditSetup(props) {
  //extract URL Params
  const query = new URLSearchParams(props.location.search);
  const liegenschaftId = query.get("lid");
  //internal states
  const [loadTemplatesPending, setLoadTemplatesPending] = useState(false);
  const [loadTemplatesError, setLoadTemplatesError] = useState(false);
  const [allSelectableTemplates, setAllSelectableTemplates] = useState(null);
  const [loadPayloadPending, setLoadPayloadPending] = useState(false);
  const [loadPayloadError, setLoadPayloadError] = useState(false);
  const [templateWithPayload, setTemplateWithPayload] = useState(null);
  const [templateType, setTemplateType] = useState(null);
  const [leistungsSetup, setLeistungsSetup] = useState(null);
  const [convertedLvJson, setConvertedLvJson] = useState(null);
  const [title, setTitle] = useState("");
  const [toggle, setToggle] = useState(false);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const toast = useRef(null);
  //confirm
  const [v_LogoutDialog, setV_LogoutDialog] = useState(false);
  const [v_FileUploadDialog, setV_FileUploadDialog] = useState(false);
  const [uploadPending, setUploadPending] = useState(false);
  const [uploadError, setUploadError] = useState();

  useEffect(() => {
    //remove all pending locks
    removeAllOnlinePendingLocksForUserId();
    //load all audit-templates without payload on page load if not loaded yet
    if (!allSelectableTemplates) {
      setLoadTemplatesPending(true);
      setLoadTemplatesError(false);
      callAllAuditTemplatesShort()
        .then((data) => {
          if (data) {
            setAllSelectableTemplates(data.data);
          }
          setLoadTemplatesPending(false);
        })
        .catch((error) => {
          setLoadTemplatesPending(false);
          setLoadTemplatesError(true);
          setAllSelectableTemplates(null);
          toast.current.show({
            severity: "error",
            summary:
              "Fragebogen-Templates konnten nicht geladen werden " +
              errorToMsg(error),
            detail:
              "Leider konnten die verfügbaren Fragebogen-Templates nicht vollständig geladen werden. Bitte versuchen Sie es später erneut.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchForLiegenschaftName = (liegenschaftId) => {
    if (props.allLiegenschaftenShort) {
      const liegenschaft = _.find(props.allLiegenschaftenShort, function (l) {
        return l.id === liegenschaftId;
      });
      return liegenschaft ? liegenschaft.name : null;
    }
  };

  function navigateBack() {
    props.history.goBack();
  }

  function selectTemplate(template) {
    if (template && template.id) {
      if (!(templateType && template.id === templateType.id)) {
        setLeistungsSetup(null);
        setTemplateWithPayload(null);
        setLoadPayloadPending(true);
        callAuditTemplate(template.id)
          .then((entry) => {
            setTemplateWithPayload(entry);
            setLoadPayloadPending(false);
          })
          .catch((error) => {
            setLoadPayloadPending(false);
            setLoadPayloadError(true);
            toast.current.show({
              severity: "error",
              summary:
                "Fragebogen konnten nicht geladen werden " + errorToMsg(error),
              detail:
                "Leider konnten der ausgewählte Fragebogen nicht vollständig geladen werden. Bitte versuchen Sie es später erneut.",
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG,
            });
          });
        setTemplateType(template);
      }
    }
  }

  function downloadLVTemplate(event) {
    downloadLvTemplate()
      .then((payload) => {
        execIfOnWeb(() => fileDownload(payload, "template.xlsx"));
        execIfOnAndroid(() =>
          writeDocumentFromBlobOnAndroid(
            payload,
            "template.xlsx",
            payload.type,
            true
          )
        );
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Download fehlgeschlagen " + errorToMsg(error),
          detail:
            "Leider konnte das Leistungsverzeichnis-Template nicht heruntergeladen werden. Bitte versuchen Sie es später erneut.",
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_ERROR_LONG,
        });
      });
  }

  function adaptPreSelectionUsingLVJson(lvJSON) {
    if (lvJSON) {
      const convertedLV = convertDataToLv(lvJSON);
      const lvLookup = convertLvToCategoryMap(convertedLV);
      const setup = _.cloneDeep(leistungsSetup);
      if (setup) {
        setup.forEach((e) => {
          const lvEntry = lvLookup.get(e.position);
          if (lvEntry && lvEntry.data && lvEntry.data.gp) {
            e.selected = true;
            e.weight = lvEntry.data.gp;
          } else {
            // there is no lv-entry (pos) => deactivate cat
            e.selected = false;
            e.weight = null;
          }
        });
      }
      setLeistungsSetup(setup);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Setup fehlgeschlagen",
        detail:
          "Das Automatische Setup der Leistungsübersicht konnte leider nicht durchgeführt werden. Das LV wurde jedoch hinterlegt.",
        sticky: true,
        closable: true,
        life: DURATION_NOTIFICATION_INFO,
      });
    }
  }

  function uploadLVTemplate(data) {
    if (data) {
      setUploadPending(true);
      setUploadError(null);
      convertLVTemplateToJson(data.file)
        .then((response) => {
          //store data
          adaptPreSelectionUsingLVJson(response);
          setConvertedLvJson(response);
          //hide view
          setUploadPending(false);
          setUploadError(null);
          setV_FileUploadDialog(false);
        })
        .catch((error) => {
          const msg =
            "Leider konnte das Leistungsverzeichnis nicht konvertiert werden. " +
            (error && error.data ? error.data.message : "");
          setUploadPending(false);
          setUploadError(msg);
          toast.current.show({
            severity: "error",
            summary: "Leistungsverzeichnis fehlerhaft " + errorToMsg(error),
            detail: msg,
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG,
          });
        });
    }
  }

  function connectToBeestate(event) {
    toast.current.show({
      severity: "info",
      summary: "NOCH NICHT VERFÜGBAR",
      detail:
        "Diese Funktion befindet sich noch in Entwicklung und ist noch nicht freigeschaltet",
      sticky: true,
      closable: true,
      life: DURATION_NOTIFICATION_INFO,
    });
  }

  const prepareLeistungsSetupData = () => {
    if (templateWithPayload && !leistungsSetup) {
      const setup = extractLeistungsSetup(templateWithPayload);
      setLeistungsSetup(setup);
      return setup;
    }
    return leistungsSetup;
  };

  function toggleSelection(newStatus, data) {
    if (leistungsSetup && data) {
      const setup = leistungsSetup;
      for (let i = 0; i < setup.length; i++) {
        if (setup[i].id === data.id) {
          setup[i].selected = newStatus;
          setLeistungsSetup(setup);
          //for calling render automatically after change
          setToggle(!toggle);
          break;
        }
      }
    }
  }

  function adaptValue(newStatus, data) {
    if (leistungsSetup && data) {
      const setup = leistungsSetup;
      for (let i = 0; i < setup.length; i++) {
        if (setup[i].id === data.id) {
          setup[i].weight = newStatus;
          setLeistungsSetup(setup);
          //for calling render automatically after change
          setToggle(!toggle);
          break;
        }
      }
    }
  }

  function createAudit() {
    const newPayload = adaptPayload(
      templateWithPayload.payload,
      convertedLvJson
    );
    let contractAmount = 0;
    if (convertedLvJson) {
      const tmp = convertDataToLv(convertedLvJson);
      if (tmp && tmp.sumGP) {
        contractAmount = tmp.sumGP;
      }
    }
    if (templateWithPayload) {
      createAuditProject(
        templateType.id,
        liegenschaftId,
        title,
        null,
        null,
        newPayload,
        null,
        contractAmount
      )
        .then((data) => {
          //show success
          toast.current.show({
            severity: "success",
            summary: "Erstellen erfolgreich",
            detail: "Das Projekt " + title + " wurde erstellt.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_SUCCESS,
          });
          //redirect
          if (
            props.onlineAccessPossible &&
            sessionStorage.getItem("rT") !== null
          ) {
            props.history.push({
              pathname: PATH_ONLINE_OVERVIEW_AUDIT,
              search: "?lid=" + liegenschaftId,
            });
          }
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Erstellen fehlgeschlagen " + errorToMsg(error),
            detail:
              "Das Projekt konnte leider nicht erstellt werden. Bitte versuchen Sie es später erneut.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG,
          });
        });
    }
  }

  const adaptPayload = (payload, lvJSON) => {
    if (payload && leistungsSetup) {
      //prepare lookup map
      const lookup = new Map();
      leistungsSetup.forEach((entry) => {
        if (entry) {
          lookup.set(entry.id, entry);
        }
      });
      payload.auditName = title;
      payload.auditNummer = null;
      payload.datum = new Date();
      payload.lastedit = new Date();
      payload.beschreibung = null;
      //adapt payload
      if (payload.questionaire && payload.questionaire.kategorien) {
        payload.questionaire.questionaireName = templateType.title;
        payload.questionaire.questionaireID = templateType.id;
        payload.questionaire.questionaireVersion = templateType.version;
        adaptPayloadKategorien(payload.questionaire.kategorien, lookup);
      }
      if (lvJSON) {
        payload.leistungsverzeichnis = lvJSON;
      }
    }
    return payload;
  };

  const testIfNonVirtualChildIsActive = (category, lookup) => {
    if (category && lookup) {
      for (let i = 0; i < category.kategorien.length; i++) {
        //lookup only contains values of selectable non virtual categories
        const entry = lookup.get(category.kategorien[i].kategorieId);
        if (entry && entry.selected) {
          return true;
        } else if (category.kategorien[i].kategorien) {
          const result = testIfNonVirtualChildIsActive(
            category.kategorien[i],
            lookup
          );
          return result;
        }
      }
    }
    return false;
  };

  const adaptPayloadKategorien = (categories, lookup) => {
    if (categories && lookup) {
      for (let i = 0; i < categories.length; i++) {
        const category = categories[i];
        //if this is no virtual category
        if (category && category.istSelektierbar) {
          const entry = lookup.get(category.kategorieId);
          //test if one non-virtual-child is active then set this category active
          if (testIfNonVirtualChildIsActive(category, lookup)) {
            categories[i].istAktiv = true;
            categories[i].gewicht = -1; //FIXME which weight to use?
          } else if (entry) {
            //test if this entry (=has been selectable) is selected itself
            categories[i].istAktiv = entry.selected;
            categories[i].gewicht = entry.weight;
          } else {
            categories[i].istAktiv = false;
            categories[i].gewicht = 0;
          }
          //if checked, then select all of its virtual subcategories
          if (categories[i].istAktiv && categories[i].kategorien) {
            manipulateAllDirectVirtualCategories(categories[i], true);
          } else {
            //deactivate all virtual children
            manipulateAllDirectVirtualCategories(categories[i], false);
          }
          //adapt their unterkategorien
          if (categories[i].kategorien) {
            adaptPayloadKategorien(categories[i].kategorien, lookup);
          }
        }
      }
    }
  };

  function manipulateAllDirectVirtualCategories(category, active) {
    if (category && category.kategorien) {
      for (let i = 0; i < category.kategorien.length; i++) {
        if (!category.kategorien[i].istSelektierbar) {
          //if this is a virtual category
          category.kategorien[i].istAktiv = active;
          category.kategorien[i].gewicht = 0;
        }
      }
    }
  }

  const injectNotificationToast = () => {
    return (
      <BeeToast
        id={"bee-audit-setup-toast"}
        ref={toast}
        position={"top-right"}
      />
    );
  };

  const injectLVFileUpload = () => {
    return (
      <BeeUploadDialog
        type={"primary"}
        visible={v_FileUploadDialog}
        disabled={uploadPending ? true : false}
        locale={"de-DE"}
        header={"Leistungsverzeichnis hochladen"}
        info={null}
        titleLabel={null}
        titleVisible={false}
        copyrightLabel={null}
        copyrightVisible={false}
        dropzoneTitle={"Drag and Drop Upload"}
        dropzoneDescription={
          "Bitte ziehen Sie das LV in diesen Bereich oder Klicken Sie hinzufügen"
        }
        dropzoneAddLabel={"Klicken zum Hinzufügen"}
        dropzoneFormats={
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
        progressVisible={uploadPending}
        progressMessage={"Datei wird hochgeladen"}
        errorVisible={uploadError ? true : false}
        errorHeadline={"Fehler"}
        errorDescription={uploadError}
        onHide={() => {
          setV_FileUploadDialog(false);
          setUploadPending(false);
          setUploadError(null);
        }}
        onUpload={(data) => uploadLVTemplate(data)}
      />
    );
  };

  const injectLogoutConfirmation = () => {
    return (
      <BeeConfirmDialog
        visible={v_LogoutDialog}
        message={
          "Möchten Sie sich wirklich ausloggen? Ungespeicherte Änderungen werden in diesem Fall verworfen!"
        }
        header={"Logout bestätigen"}
        type={"default"}
        onAccept={() => {
          removeAllOnlinePendingLocksForUserId();
          props.logoutUser();
          setV_LogoutDialog(false);
        }}
        onReject={() => setV_LogoutDialog(false)}
        onHide={() => setV_LogoutDialog(false)}
        acceptLabel={"Ausloggen"}
        rejectLabel={"Abbrechen"}
      />
    );
  };

  const injectCreationDialog = () => {
    return (
      <BeeConfirmDialog
        visible={confirmDialogVisible}
        message={
          "Möchten Sie das Audit '" +
          title +
          "' mit den ausgewählten Leistungen erstellen?"
        }
        header={"Neues Audit erstellen"}
        type={"default"}
        acceptLabel={"Erstellen"}
        rejectLabel={"Abbrechen"}
        onAccept={() => {
          createAudit();
          setConfirmDialogVisible(false);
        }}
        onReject={() => setConfirmDialogVisible(false)}
        onHide={() => setConfirmDialogVisible(false)}
      />
    );
  };

  return (
    <div>
      {/* //for calling render automatically after change */}
      {toggle ? null : null}
      <BeeAuditSetup
        templates={allSelectableTemplates}
        selectedTemplate={templateType}
        title={title}
        leistungsSetup={prepareLeistungsSetupData()}
        homeUrl={breadCrumbHome}
        breadcrumbData={createBreadCrumbAuditSetup(liegenschaftId)}
        liegenschaftTitle={searchForLiegenschaftName(liegenschaftId)}
        isCurrencySetup={prepareShowingMode(templateWithPayload)}
        showLoadingTemplates={loadTemplatesPending}
        errorLoadingTemplates={loadTemplatesError}
        showLoadingLeistungsSetup={loadPayloadPending}
        errorLoadingLeistungsSetup={loadPayloadError}
        lvTemplateUploaded={convertedLvJson ? true : false}
        onCancel={() => navigateBack()}
        onCreateAudit={() => setConfirmDialogVisible(true)}
        onLogout={() => setV_LogoutDialog(true)}
        onSelectTemplate={(e) => selectTemplate(e)}
        onChangeTitle={(e) => setTitle(e)}
        onToggleSelection={(newStatus, data) =>
          toggleSelection(newStatus, data)
        }
        onAdaptValue={(newValue, data) => adaptValue(newValue, data)}
        onDownloadTemplate={(e) => downloadLVTemplate(e)}
        onUploadTemplate={(e) => setV_FileUploadDialog(true)}
        onConnectBeestate={(e) => connectToBeestate(e)}
      />
      {injectCreationDialog()}
      {injectNotificationToast()}
      {v_LogoutDialog ? injectLogoutConfirmation() : null}
      {v_FileUploadDialog ? injectLVFileUpload() : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    onlineAccessPossible: state.app.onlineAccessPossible,
    allLiegenschaftenShort: state.app.allLiegenschaftenShort,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlineAuditSetup);
