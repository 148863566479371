import React, { useState } from "react";
import { useId } from "react-id-generator";
import { Dialog } from "primereact/dialog";
import {
  BeeValidatedTextInput,
  BeeValidatedCheckbox,
  BeeButton,
  BeeLinkButton,
  BeeValidatedDropDown,
} from "bee-atomic-components";
import { v4 as uuidv4 } from "uuid";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditDialogStakeholder.scss";
import { KEINE_TN, TEILWEISE_TN, KOMPLETTE_TN } from "../../Helper/constants";

export function BeeAuditDialogStakeholder({
  id,
  type,
  data,
  visible,
  readOnly,
  onSave,
  onCancel,
  onHide,
}) {
  const currentId = useId(1, "bee-audit-dialog-stakeholder")[0];

  //state
  const [sId, setId] = useState(data ? data.id : uuidv4());
  const [sName, setName] = useState(data ? data.name : "");
  const [sRole, setRole] = useState(data ? data.role : "");
  const [sCompany, setCompany] = useState(data ? data.company : "");
  const [sParticipation, setParticipation] = useState(
    data ? data.participation : KEINE_TN.value
  );
  const [sDistribution, setDistribution] = useState(data ? data.shared : null);

  const [adaptedName, setAdaptedName] = useState(false);
  const [adaptedRole, setAdaptedRole] = useState(false);
  const [adaptedCompany, setAdaptedCompany] = useState(false);
  const [adaptedParticipation, setAdaptedParticipation] = useState(false);
  const [adaptedDistribution, setAdaptedDistribution] = useState(false);

  const [errorName, setErrorName] = useState(false);
  const [errorRole, setErrorRole] = useState(false);
  const [errorCompany, setErrorCompany] = useState(false);
  const [errorParticipation, setErrorParticipation] = useState(false);
  const [errorDistribution, setErrorDistribution] = useState(false);

  //classNames
  const typeClassNames =
    type === "primary"
      ? "bee-audit-dialog-stakeholder-primary"
      : type === "secondary"
      ? "bee-audit-dialog-stakeholder-secondary"
      : "bee-audit-dialog-stakeholder-primary";

  const classNames = "bee-audit-dialog-stakeholder " + typeClassNames;
  const btnRowClassNames =
    "grid justify-content-end bee-audit-dialog-stakeholder-btns";

  //label
  const name_label = "Name";
  const role_label = "Rolle";
  const company_label = "Unternehmen";
  const participation_label = "Teilnahme";
  const distribution_label = "Verteiler";
  const headerLabel = "Beteiligte Person";
  const readOnlyAcceptLabel = "Schließen";
  const acceptLabel = "Hinzufügen";
  const rejectLabel = "Abbrechen";
  const name_error_msg = "Bitte einen Namen angeben.";
  const role_error_msg = "Bitte die Rolle angeben.";
  const company_error_msg = "Bitte das Unternehmen angeben.";
  const participation_error_msg = "Bitte die Teilnahme angeben.";
  const distribution_error_msg = "Bitte die Verteilung angeben.";
  const distribution_labelStateTrue = "Ja";
  const distribution_labelStateFalse = "Nein";

  //Teilnahme Dropdown

  const participationArray = [KEINE_TN, TEILWEISE_TN, KOMPLETTE_TN];

  function save() {
    if (validateInputs()) {
      if (onSave) {
        onSave({
          id: sId,
          name: sName,
          role: sRole,
          company: sCompany,
          participation: sParticipation,
          shared: sDistribution,
        });
      }
      clearData();
    }
  }

  function clearData() {
    setId(null);
    setName(null);
    setRole(null);
    setCompany(null);
    setParticipation(null);
    setDistribution(null);
    //reset errors and validations
    setAdaptedName(false);
    setAdaptedRole(false);
    setAdaptedCompany(false);
    setAdaptedParticipation(false);
    setAdaptedDistribution(false);

    setErrorName(false);
    setErrorRole(false);
    setErrorCompany(false);
    setErrorParticipation(false);
    setErrorDistribution(false);
  }

  function cancel(e) {
    clearData();
    if (onCancel) {
      onCancel(e);
    }
  }

  function hide() {
    clearData();
    if (onHide) {
      onHide();
    }
  }
  function onKeyPress(e) {
    if (e.keyCode === 13 && !readOnly && visible) {
      save();
    }
    if (e.keyCode === 27 && !readOnly && visible) {
      cancel(e);
    }
    if (e.keyCode === 27 && readOnly && visible) {
      hide();
    }
  }

  function validateInputs() {
    let allValid = true;
    //validate Name
    if (!sName || sName === "") {
      setErrorName(true);
      allValid = false;
    } else {
      setErrorName(false);
    }
    //validate Role?
    //validate Company?
    //validate Participation?
    //validate Distribution?
    //FIXME other validations?
    return allValid;
  }

  const injectName = () => {
    return (
      <BeeValidatedTextInput
        readOnly={readOnly}
        label={name_label}
        errorLabel={errorName && !adaptedName ? name_error_msg : null}
        neutralLabel={errorName && adaptedName ? name_error_msg : null}
        value={sName ? sName : ""}
        disabled={false}
        formstate={
          errorName && adaptedName ? "neutral" : errorName ? "error" : "none"
        }
        required={true}
        onChange={(e) => {
          setAdaptedName(true);
          setName(e.target.value);
        }}
      />
    );
  };
  const injectRole = () => {
    return (
      <BeeValidatedTextInput
        label={role_label}
        errorLabel={errorRole && !adaptedRole ? role_error_msg : null}
        neutralLabel={errorRole && adaptedRole ? role_error_msg : null}
        value={sRole ? sRole : ""}
        disabled={false}
        formstate={
          errorRole && adaptedRole ? "neutral" : errorRole ? "error" : "none"
        }
        readOnly={readOnly}
        required={false}
        onChange={(e) => {
          setAdaptedRole(true);
          setRole(e.target.value);
        }}
      />
    );
  };
  const injectCompany = () => {
    return (
      <BeeValidatedTextInput
        label={company_label}
        errorLabel={errorCompany && !adaptedCompany ? company_error_msg : null}
        neutralLabel={errorCompany && adaptedCompany ? company_error_msg : null}
        value={sCompany ? sCompany : ""}
        disabled={false}
        formstate={
          errorCompany && adaptedCompany
            ? "neutral"
            : errorCompany
            ? "error"
            : "none"
        }
        readOnly={readOnly}
        required={false}
        onChange={(e) => {
          setAdaptedCompany(true);
          setCompany(e.target.value);
        }}
      />
    );
  };
  const injectParticipation = () => {
    return (
      <>
        <BeeValidatedDropDown
          label={participation_label}
          errorLabel={
            errorParticipation && !adaptedParticipation
              ? participation_error_msg
              : null
          }
          neutralLabel={
            errorParticipation && adaptedParticipation
              ? participation_error_msg
              : null
          }
          value={sParticipation ? sParticipation : "NO_T"}
          options={participationArray}
          optionLabel={"label"}
          disabled={false}
          formstate={
            errorParticipation && adaptedParticipation
              ? "neutral"
              : errorParticipation
              ? "error"
              : "none"
          }
          readOnly={readOnly}
          required={false}
          onChange={(e) => {
            setAdaptedParticipation(true);
            setParticipation(e);
          }}
        />
      </>
    );
  };
  const injectDistribution = () => {
    return (
      <BeeValidatedCheckbox
        label={distribution_label}
        errorLabel={
          errorDistribution && !adaptedDistribution
            ? distribution_error_msg
            : null
        }
        neutralLabel={
          errorDistribution && adaptedDistribution
            ? distribution_error_msg
            : null
        }
        value={sDistribution ? sDistribution : null}
        disabled={false}
        formstate={
          errorDistribution && adaptedDistribution
            ? "neutral"
            : errorDistribution
            ? "error"
            : "none"
        }
        stateTrue={distribution_labelStateTrue}
        stateFalse={distribution_labelStateFalse}
        required={false}
        readOnly={readOnly}
        onChange={(e) => {
          setAdaptedDistribution(true);
          setDistribution(e.checked);
        }}
      />
    );
  };

  const injectButtonRow = () => {
    return (
      <div className={btnRowClassNames}>
        {readOnly ? (
          <BeeButton
            label={readOnlyAcceptLabel}
            disabled={false}
            type={type}
            onClick={() => save()}
          />
        ) : (
          <>
            <BeeLinkButton
              label={rejectLabel}
              raised={false}
              disabled={false}
              type={"primary"}
              onClick={(e) => cancel(e)}
            />
            <BeeButton
              label={acceptLabel}
              disabled={false}
              type={type}
              onClick={() => save()}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div id={currentId} onKeyDown={(e) => onKeyPress(e)} tabIndex={0}>
      <Dialog
        id={currentId}
        header={headerLabel}
        visible={visible}
        className={classNames}
        modal={true}
        onHide={() => hide()}
      >
        <div className="grid">
          <div className="col-12">{injectName()}</div>
          <div className="col-12">{injectRole()}</div>
          <div className="col-12">{injectCompany()}</div>
          <div className="col-12">{injectParticipation()}</div>
          <div className="col-12">{injectDistribution()}</div>
          <div className="col-12">{injectButtonRow()}</div>
        </div>
      </Dialog>
    </div>
  );
}

export default BeeAuditDialogStakeholder;
