import React from "react";
import {
  BeeButton,
  BeeLinkButton,
  BeeErrorLabel,
  BeeInfoLabel,
  BeeContentParagraph,
  BeeLoadingSpinner,
} from "bee-atomic-components";
import { Dialog } from "primereact/dialog";
import { useId } from "react-id-generator";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditDialogSyncProject.scss";

export function BeeAuditDialogSyncProject({
  type,
  usedForDownload,
  visible,
  showProgress,
  errorMsg,
  actionMsg,
  onHide,
  onPerformAction,
}) {
  const currentId = useId(1, "bee-audit-dialog-sync")[0];

  //classNames
  const typeClassNames =
    type === "primary"
      ? "bee-audit-dialog-sync-primary"
      : type === "secondary"
      ? "bee-audit-dialog-sync-secondary"
      : "bee-audit-dialog-sync-primary";
  const classNames = "bee-audit-dialog-sync " + typeClassNames;

  //label
  const uploadHeader = "Projekt einchecken";
  const downloadHeader = "Projekt auschecken";
  const uploadDescription =
    "Möchten Sie das Projekt hochladen? Diese Aktion kann einige Minuten dauern während das Projekt aktualisiert und den anderen Nutzern zur Verfügung gestellt wird. Auf ihrem Gerät gespeicherte Informationen werden im Anschluss an das Hochladen gelöscht und der Speicherplatz freigegeben.";
  const downloadDescription =
    "Möchten Sie das Projekt herunterladen? Diese Aktion kann einige Minuten dauern und benötigt Speicherplatz. Anderen Nutzern wird kenntlich gemacht, dass Sie dieses Projekt für die Offline-Bearbeitung heruntergeladen haben. Bitte denken Sie daran das Projekt nach Ende der Bearbeitung wieder hochzuladen.";
  const uploadBtn = "Projekt hochladen";
  const downloadBtn = "Projekt herunterladen";
  const rejectLabel = "Abbrechen";

  function hide() {
    if (!functionsDisabled()) {
      if (onHide) {
        onHide();
      }
    }
  }

  function accept() {
    if (onPerformAction) {
      onPerformAction();
    }
  }

  function functionsDisabled() {
    if (showProgress) {
      if (errorMsg) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  const renderFooter = () => {
    return (
      <div>
        <BeeLinkButton
          label={rejectLabel}
          raised={false}
          disabled={functionsDisabled()}
          type={"primary"}
          onClick={() => hide()}
        />
        <BeeButton
          label={usedForDownload ? downloadBtn : uploadBtn}
          disabled={functionsDisabled()}
          type={type}
          onClick={() => accept()}
        />
      </div>
    );
  };

  return (
    <Dialog
      id={currentId}
      header={usedForDownload ? downloadHeader : uploadHeader}
      visible={visible}
      modal={true}
      className={classNames}
      footer={renderFooter()}
      onHide={() => hide()}
    >
      <BeeErrorLabel
        size={"large"}
        label={errorMsg}
        visible={errorMsg ? true : false}
      />
      {showProgress ? (
        <div>
          <BeeLoadingSpinner strokeWidth={"3"} type={"mixed"} />
        </div>
      ) : (
        <BeeContentParagraph
          text={usedForDownload ? downloadDescription : uploadDescription}
          size={"medium"}
          type={"primary"}
        />
      )}
      <BeeInfoLabel
        size={"medium"}
        label={actionMsg}
        visible={showProgress ? true : false}
        type={"neutral"}
      />
    </Dialog>
  );
}

export default BeeAuditDialogSyncProject;
