import _ from "lodash";

export const createCategoryRating = (currentAuditProject) => {
  const answer = [];
  if (currentAuditProject && currentAuditProject.payload) {
    const questionaire = currentAuditProject.payload.questionaire;
    if (questionaire) {
      let id = questionaire.questionaireID;
      let aVal = 0;
      let aCount = 0;
      for (let i = 0; i < questionaire.kategorien.length; i++) {
        const cat = questionaire.kategorien[i];
        let calc = calcRatingFromCategory(cat, answer);
        aVal += calc !== null ? calc : null;
        aCount += calc !== null ? 1 : 0;
      }
      let value = aCount === 0 ? null : aVal / aCount;
      answer.push({ id: id, value: value });
    }
  }
  return answer;
};

function calcRatingFromCategory(category, answer) {
  let id = category.kategorieId;
  let aVal = 0;
  let aCount = 0;
  //kategorien
  if (category.kategorien && category.kategorien.length > 0) {
    for (let i = 0; i < category.kategorien.length; i++) {
      const cat = category.kategorien[i];
      let calc = calcRatingFromCategory(cat, answer);
      aVal += calc !== null ? calc : null;
      aCount += calc !== null ? 1 : 0;
    }
  }
  //fragen
  if (category.fragen && category.fragen.length > 0) {
    for (let i = 0; i < category.fragen.length; i++) {
      const que = category.fragen[i];
      if (que.ergebnis && que.ergebnis.antwortWert) {
        if (que.ergebnis.antwortWert !== -1) {
          const pos = que.ergebnis.antwortWert - 1;
          aCount++;
          aVal += que.antwortOptionen[pos].gewicht;
        }
      }
    }
  }
  let value = aCount === 0 ? null : aVal / aCount;
  answer.push({ id: id, value: value });
  return value;
}

//Extract categoryTree from Audit-Template
export const createCategoryTree = (currentAuditProject) => {
  const questionLookup = createMapOfQuestions(currentAuditProject);
  const categoryLookup = createQuestionCategoryLookup(currentAuditProject);
  const tree = [];
  if (currentAuditProject && currentAuditProject.payload) {
    const questionaire = currentAuditProject.payload.questionaire;
    if (questionaire) {
      const data = extractCompletenessUsingTree(
        questionLookup,
        categoryLookup,
        questionaire
      );
      //add root
      tree.push({
        key: questionaire.questionaireID,
        data: {
          title: questionaire.questionaireName,
          subtitle: questionaire.questionaireVersion,
          combinedTitle: questionaire.questionaireName,
          questions: data.max,
          progress: data.current,
          questionIds: data.questionIds,
        },
        children: extractCategories(
          questionaire,
          questionLookup,
          categoryLookup
        ),
        className: "questionaire-root",
      });
    }
  }
  return tree;
};

function extractCategories(questionaire, questionLookup, categoryLookup) {
  const categories = [];
  if (questionaire && questionaire.kategorien) {
    for (let i = 0; i < questionaire.kategorien.length; i++) {
      const category = questionaire.kategorien[i];
      const data = extractCompletenessUsingTree(
        questionLookup,
        categoryLookup,
        category
      );
      categories.push({
        key: category.kategorieId,
        label: "[" + category.kategorieNummer + "] - " + category.kategorieName, //use for treeDropdowns
        categoryNo: category.kategorieNummer, //use for treeDropdowns & matching to lv
        data: {
          title: category.kategorieName,
          subtitle: questionaire.questionaireName,
          combinedTitle:
            questionaire.questionaireName + " ⇾ " + category.kategorieName,
          questions: data.max,
          progress: data.current,
          questionIds: data.questionIds,
        },
        children: extractSubCategories(
          category,
          questionLookup,
          categoryLookup,
          1
        ),
        className: "questionaire-main-category",
      });
    }
  }
  return categories;
}

function extractSubCategories(category, questionLookup, categoryLookup, depth) {
  const subCategories = [];
  if (category && category.kategorien) {
    for (let i = 0; i < category.kategorien.length; i++) {
      const subCategory = category.kategorien[i];
      const data = extractCompletenessUsingTree(
        questionLookup,
        categoryLookup,
        subCategory
      );
      const children = extractSubCategories(
        subCategory,
        questionLookup,
        categoryLookup,
        depth + 1
      );
      subCategories.push({
        key: subCategory.kategorieId,
        label:
          "[" +
          subCategory.kategorieNummer +
          "] - " +
          subCategory.kategorieName, //use for treeDropdowns
        categoryNo: subCategory.kategorieNummer, //use for treeDropdowns & matching to lv
        data: {
          title: subCategory.kategorieName,
          subtitle: category.kategorieName,
          combinedTitle:
            " ↳  ".repeat(depth) +
            category.kategorieName +
            " ⇾ " +
            subCategory.kategorieName,
          questions: data.max,
          progress: data.current,
          questionIds: data.questionIds,
        },
        children: children,
      });
    }
  }
  return subCategories;
}

function extractCompleteness(questionLookup, categoryLookup, categoryId) {
  let amount = 0;
  let amountReady = 0;
  const questionIds = [];
  if (questionLookup && categoryLookup && categoryId) {
    for (let pair of categoryLookup) {
      let [key, value] = pair;
      if (value === categoryId) {
        amount++;
        questionIds.push(key);
        const question = questionLookup.get(key);
        if (
          question &&
          question.frageID &&
          question.ergebnis &&
          question.ergebnis.antwortWert !== null &&
          question.ergebnis.antwortWert !== undefined
        ) {
          amountReady++;
        }
      }
    }
  }
  return { current: amountReady, max: amount, questionIds: questionIds };
}

function extractCompletenessUsingTree(
  questionLookup,
  categoryLookup,
  category
) {
  let amount = 0;
  let amountReady = 0;
  const allQuestionIds = [];
  if (questionLookup && categoryLookup && category) {
    //if category is active, then calculate it´s questions
    if (category.istAktiv) {
      const values = extractCompleteness(
        questionLookup,
        categoryLookup,
        category.kategorieId
      );
      amount += values.max;
      amountReady += values.current;
      if (values.questionIds) {
        for (let i = 0; i < values.questionIds.length; i++) {
          allQuestionIds.push(values.questionIds[i]);
        }
      }
    }
    //loop over category and get all categories
    if (category.kategorien) {
      for (let i = 0; i < category.kategorien.length; i++) {
        const values = extractCompletenessUsingTree(
          questionLookup,
          categoryLookup,
          category.kategorien[i]
        );
        amount += values.max;
        amountReady += values.current;
        if (values.questionIds) {
          for (let i = 0; i < values.questionIds.length; i++) {
            allQuestionIds.push(values.questionIds[i]);
          }
        }
      }
    }
  }
  return {
    current: amountReady,
    max: amount,
    questionIds: allQuestionIds,
  };
}

//Extract LeistungsSetupData for creating new Project
export const extractLeistungsSetup = (currentAuditProject) => {
  if (currentAuditProject) {
    const setup = [];
    const questionaire = currentAuditProject.payload
      ? currentAuditProject.payload.questionaire
      : null;
    if (questionaire && questionaire.kategorien) {
      for (let i = 0; i < questionaire.kategorien.length; i++) {
        const category = questionaire.kategorien[i];
        if (category && category.istSelektierbar) {
          //if category is NO VIRTUAL category
          const entry = {
            id: category.kategorieId,
            position: category.kategorieNummer,
            selected: category.istAktiv,
            title: category.kategorieName,
            subtitle: null,
            weight: category.gewicht,
          };
          if (!testIfCategoryHasRealChildren(category)) {
            setup.push(entry);
          }
          //add their "unterkategorien"
          if (category.kategorien) {
            const sc = extractUnterkategorien(category, category.kategorieName);
            if (sc) {
              for (let i = 0; i < sc.length; i++) {
                setup.push(sc[i]);
              }
            }
          }
        }
      }
    }
    return setup;
  }
  return null;
};

function extractUnterkategorien(entry, parentTitle) {
  const data = [];
  if (entry.kategorien) {
    for (let i = 0; i < entry.kategorien.length; i++) {
      //if category is NO VIRTUAL category
      const category = entry.kategorien[i];
      if (category && category.istSelektierbar) {
        const item = {
          id: category.kategorieId,
          position: category.kategorieNummer,
          selected: category.istAktiv,
          title: parentTitle,
          subtitle: category.kategorieName,
          weight: category.gewicht,
        };
        if (!testIfCategoryHasRealChildren(category)) {
          data.push(item);
        }
        //add their "unterkategorien"
        if (category.kategorien) {
          const sc = extractUnterkategorien(category, category.kategorieName);
          if (sc && sc.length > 0) {
            for (let i = 0; i < sc.length; i++) {
              data.push(sc[i]);
            }
          }
        }
      }
    }
  }
  return data;
}

const testIfCategoryHasRealChildren = (category) => {
  if (category && category.kategorien) {
    //loop over all categories and search for "selektierbare" categories.
    for (let i = 0; i < category.kategorien.length; i++) {
      const cat = category.kategorien[i];
      if (cat.istSelektierbar) {
        return true;
      }
    }
  }
  return false;
};

//Extract Array of QuestionIds
export const collectAllQuestionIds = (currentAuditProject) => {
  const questions = [];
  if (currentAuditProject && currentAuditProject.payload) {
    const questionaire = currentAuditProject.payload.questionaire;
    if (questionaire) {
      if (questionaire.fragen) {
        for (let i = 0; i < questionaire.fragen.length; i++) {
          const question = questionaire.fragen[i];
          if (question && question.frageID) {
            questions.push(question.frageID);
          }
        }
      }
      //für jede kategorie
      if (questionaire && questionaire.kategorien) {
        for (let i = 0; i < questionaire.kategorien.length; i++) {
          const category = questionaire.kategorien[i];
          if (category) {
            //add their questions
            if (category.fragen && category.istAktiv) {
              for (let i = 0; i < category.fragen.length; i++) {
                const question = category.fragen[i];
                if (question && question.frageID) {
                  questions.push(question.frageID);
                }
              }
            }
            //add the unterkategorie questions
            if (category.kategorien) {
              const uFrageIds = extractQuestionIdsOfUnterkategorien(category);
              if (uFrageIds) {
                for (let i = 0; i < uFrageIds.length; i++) {
                  questions.push(uFrageIds[i]);
                }
              }
            }
          }
        }
      }
    }
  }
  return questions;
};

function extractQuestionIdsOfUnterkategorien(entry) {
  const data = [];
  if (entry.kategorien) {
    for (let i = 0; i < entry.kategorien.length; i++) {
      const category = entry.kategorien[i];
      if (category) {
        //add all fragen
        if (category.fragen && category.istAktiv) {
          for (let i = 0; i < category.fragen.length; i++) {
            const question = category.fragen[i];
            if (question && question.frageID) {
              data.push(question.frageID);
            }
          }
        }
        //add their "unterkategorien"
        if (category.kategorien) {
          const uFragen = extractQuestionIdsOfUnterkategorien(category);
          if (uFragen) {
            for (let i = 0; i < uFragen.length; i++) {
              data.push(uFragen[i]);
            }
          }
        }
      }
    }
  }
  return data;
}

//Extract MAP of questionId, question entries
export const createMapOfQuestions = (currentAuditProject) => {
  const map = new Map();
  if (currentAuditProject && currentAuditProject.payload) {
    const questionaire = currentAuditProject.payload.questionaire;
    if (questionaire) {
      const catSequence = [];
      catSequence.push({
        icon: null,
        label: questionaire.questionaireName,
        bold: false,
      });
      if (questionaire.fragen) {
        for (let i = 0; i < questionaire.fragen.length; i++) {
          const question = questionaire.fragen[i];
          if (question && question.frageID) {
            let q = _.cloneDeep(question);
            q.sequence = catSequence;
            map.set(question.frageID, q);
          }
        }
      }
      const frageMap = createMapOfQuestionsOfKategorien(
        questionaire,
        catSequence
      );
      if (frageMap) {
        for (let pair of frageMap) {
          let [key, value] = pair;
          map.set(key, value);
        }
      }
    }
  }
  return map;
};

function createMapOfQuestionsOfKategorien(entry, catSequence) {
  const map = new Map();
  if (entry.kategorien) {
    for (let i = 0; i < entry.kategorien.length; i++) {
      const category = entry.kategorien[i];
      if (category) {
        const catSeq = _.cloneDeep(catSequence);
        catSeq.push({
          icon: null,
          label: category.kategorieName,
          bold: false,
        });
        //add all fragen
        if (category.fragen && category.istAktiv) {
          for (let i = 0; i < category.fragen.length; i++) {
            const question = category.fragen[i];
            if (question && question.frageID) {
              let q = _.cloneDeep(question);
              q.sequence = catSeq;
              map.set(question.frageID, q);
            }
          }
        }
        //add their "unterkategorien"
        if (category.kategorien) {
          const frageMap = createMapOfQuestionsOfKategorien(category, catSeq);
          if (frageMap) {
            for (let pair of frageMap) {
              let [key, value] = pair;
              map.set(key, value);
            }
          }
        }
      }
    }
  }
  return map;
}

//Extract MAP of questionId, categoryId entries
function createQuestionCategoryLookup(currentAuditProject) {
  const categoryLookup = new Map();
  if (currentAuditProject && currentAuditProject.payload) {
    const questionaire = currentAuditProject.payload.questionaire;
    const questId = questionaire.questionaireID;
    if (questionaire) {
      if (questionaire.fragen) {
        for (let i = 0; i < questionaire.fragen.length; i++) {
          const question = questionaire.fragen[i];
          if (question && question.frageID) {
            categoryLookup.set(question.frageID, questId);
          }
        }
      }
      const frageMap = createQuestionCategoryLookupOfKategorien(questionaire);
      if (frageMap) {
        for (let pair of frageMap) {
          let [key, value] = pair;
          categoryLookup.set(key, value);
        }
      }
    }
  }
  return categoryLookup;
}

function createQuestionCategoryLookupOfKategorien(entry) {
  const lookup = new Map();
  if (entry.kategorien) {
    for (let i = 0; i < entry.kategorien.length; i++) {
      const category = entry.kategorien[i];
      const catId = category.kategorieId;
      if (category) {
        //add all fragen
        if (category.fragen && category.istAktiv) {
          for (let i = 0; i < category.fragen.length; i++) {
            const question = category.fragen[i];
            if (question && question.frageID) {
              lookup.set(question.frageID, catId);
            }
          }
        }
        //add their "unterkategorien"
        if (category.kategorien) {
          const frageMap = createQuestionCategoryLookupOfKategorien(category);
          if (frageMap) {
            for (let pair of frageMap) {
              let [key, value] = pair;
              lookup.set(key, value);
            }
          }
        }
      }
    }
  }
  return lookup;
}

//EXTRACT the leistungsubersicht-type
export const prepareShowingMode = (currentAuditProject) => {
  if (
    currentAuditProject &&
    currentAuditProject.payload &&
    currentAuditProject.payload.questionaire
  ) {
    return (
      currentAuditProject.payload.questionaire.kategorieGewichtTyp === "EURO"
    );
  }
};

//CONVERT CategoryTree to categoryList used by AuditUIQuestion
export const convertCategoryTree = (categoryTree) => {
  const values = [];
  if (categoryTree) {
    const data = extractDataFromTree(categoryTree[0]);
    for (let i = 0; i < data.length; i++) {
      values.push(data[i]);
    }
  }
  return values;
};

function extractDataFromTree(entry) {
  const data = [];
  if (entry && entry.data) {
    data.push({
      id: entry.key,
      title: entry.data.title,
      subtitle: entry.data.subtitle,
      combinedTitle: entry.data.combinedTitle,
      questionIds: entry.data.questionIds,
      questions: entry.data.questions,
      progress: entry.data.progress,
    });
    if (entry.children) {
      for (let i = 0; i < entry.children.length; i++) {
        const values = extractDataFromTree(entry.children[i]);
        for (let j = 0; j < values.length; j++) {
          data.push(values[j]);
        }
      }
    }
  }
  return data;
}

export const extractAllMediaIdsForConverting = (pay) => {
  if (pay) {
    const documents = extractAllDocumentIdsForConverting(pay);
    const images = extractAllImageIdsForConverting(pay);
    const mediaIds = [];
    if (documents) {
      mediaIds.push(...documents);
    }
    if (images) {
      mediaIds.push(...images);
    }
    return mediaIds;
  }
};

export const extractAllImageIdsForConverting = (pay) => {
  const data = [];
  //add direct children
  if (pay && pay.bilder) {
    pay.bilder.forEach((bild) => {
      data.push(bild.mediaID);
    });
  }
  //add children from auffalligkeiten
  if (pay && pay.auffaelligkeiten) {
    pay.auffaelligkeiten.forEach((entry) => {
      // entry
      if (entry && entry.bilder) {
        entry.bilder.forEach((bild) => {
          data.push(bild.mediaID);
        });
      }
    });
  }
  //add children from questions
  if (pay && pay.questionaire) {
    const idArray = [];
    extractImgIdsFromKategorien(pay.questionaire, idArray);
    idArray.forEach((entry) => {
      data.push(entry);
    });
  }
  //add children from conditionBuilding
  if (pay && pay.conditionBuilding) {
    if (pay.conditionBuilding.bilder) {
      pay.conditionBuilding.bilder.forEach((bild) => {
        data.push(bild.mediaID);
      });
    }
  }
  return data && data.length > 0 ? data : null;
};

function extractImgIdsFromKategorien(entry, idArray) {
  if (entry) {
    if (entry.fragen) {
      entry.fragen.forEach((frage) => {
        if (frage && frage.ergebnis && frage.ergebnis.bilder) {
          frage.ergebnis.bilder.forEach((bild) => {
            idArray.push(bild.mediaID);
          });
        }
      });
    }
    if (entry.kategorien) {
      entry.kategorien.forEach((kategorie) => {
        extractImgIdsFromKategorien(kategorie, idArray);
      });
    }
  }
  return idArray;
}

export const extractAllDocumentIdsForConverting = (pay) => {
  const data = [];
  //add direct children
  if (pay && pay.dokumente) {
    pay.dokumente.forEach((document) => {
      data.push(document.mediaID);
    });
  }
  //add children from auffalligkeiten
  if (pay && pay.auffaelligkeiten) {
    pay.auffaelligkeiten.forEach((entry) => {
      // entry
      if (entry && entry.dokumente) {
        entry.dokumente.forEach((document) => {
          data.push(document.mediaID);
        });
      }
    });
  }
  //add children from questions
  if (pay && pay.questionaire) {
    const idArray = [];
    extractDocIdsFromKategorien(pay.questionaire, idArray);
    idArray.forEach((entry) => {
      data.push(entry);
    });
  }
  return data && data.length > 0 ? data : null;
};

function extractDocIdsFromKategorien(entry, idArray) {
  if (entry) {
    if (entry.fragen) {
      entry.fragen.forEach((frage) => {
        if (frage && frage.ergebnis && frage.ergebnis.dokumente) {
          frage.ergebnis.dokumente.forEach((document) => {
            idArray.push(document.mediaID);
          });
        }
      });
    }
    if (entry.kategorien) {
      entry.kategorien.forEach((kategorie) => {
        extractDocIdsFromKategorien(kategorie, idArray);
      });
    }
  }
  return idArray;
}

export const extractMaintainerWithPendingLocks = (currentUserId, project) => {
  if (currentUserId && project) {
    const maintainerWithLocks = [];
    if (project.maintainer && project.maintainer.length > 0) {
      for (let j = 0; j < project.maintainer.length; j++) {
        const person = project.maintainer[j];
        //search for active locks from other users
        if (person && currentUserId !== person.userId) {
          if (person.locks && person.locks.length > 0) {
            for (let x = 0; x < person.locks.length; x++) {
              const lock = person.locks[x];
              if (lock && lock.createdAt !== null && lock.releasedAt === null) {
                maintainerWithLocks.push(person);
              }
            }
          }
        } else if (person) {
          if (person.locks && person.locks.length > 0) {
            for (let x = 0; x < person.locks.length; x++) {
              const lock = person.locks[x];
              if (
                lock &&
                !lock.onlineLock &&
                lock.createdAt !== null &&
                lock.releasedAt === null
              ) {
                maintainerWithLocks.push(person);
              }
            }
          }
        }
      }
    }
    return maintainerWithLocks;
  }
  return null;
};

///////////////////////////////////////////////////////////////

export const adaptAnswersInPayload = (payload, questionLookup) => {
  if (payload && questionLookup) {
    const questionaire = payload.questionaire;
    if (questionaire) {
      if (questionaire.fragen) {
        for (let i = 0; i < questionaire.fragen.length; i++) {
          if (questionaire.fragen[i].frageID) {
            //search for question in lookup
            const adapted = questionLookup.get(questionaire.fragen[i].frageID);
            if (adapted) {
              questionaire.fragen[i].ergebnis = adapted.ergebnis
                ? adapted.ergebnis
                : {};
            }
          }
        }
      }
      adaptQuestionsOfKategorien(questionaire, questionLookup);
    }
  }
};

function adaptQuestionsOfKategorien(entry, questionLookup) {
  if (entry.kategorien) {
    for (let i = 0; i < entry.kategorien.length; i++) {
      const category = entry.kategorien[i];
      if (category) {
        if (category.fragen && category.istAktiv) {
          for (let i = 0; i < category.fragen.length; i++) {
            if (category.fragen[i].frageID) {
              //search for question in lookup
              const adapted = questionLookup.get(category.fragen[i].frageID);
              if (adapted) {
                category.fragen[i].ergebnis = adapted.ergebnis
                  ? adapted.ergebnis
                  : {};
              }
            }
          }
        }
        //adapt their "unterkategorien"
        if (category.kategorien) {
          adaptQuestionsOfKategorien(category, questionLookup);
        }
      }
    }
  }
}
