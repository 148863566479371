import React, { useEffect } from "react";
import _ from "lodash";
import "./BeeAuditLvQuantity.scss";
import {
  BeeCurrencyInput,
  BeeCheckbox,
  BeeNumberInput,
} from "bee-atomic-components";
import {
  CURRENCY,
  DELAY_DEBOUNCED,
  DELAY_DEBOUNCED_SHORT,
  LOCALE,
} from "../../Helper/constants";
import BeeDebouncedTextInput from "../Components/BeeDebouncedTextInput";

function BeeAuditLvQuantity({ data, readOnly, onChange }) {
  const readOnlyClassName = readOnly ? " readOnly" : "";
  const deactivatedClassName = data && data.deactivated ? " deactivated" : "";
  const colorClassName =
    isQuantityColoredMenge() &&
    isQuantityColoredEp() &&
    isQuantityColoredEinheit()
      ? " colored coloredGp coloredEp coloredMenge coloredEinheit"
      : isQuantityColoredMenge() && isQuantityColoredEp()
      ? " colored coloredGp coloredEp coloredMenge"
      : isQuantityColoredEp() && isQuantityColoredEinheit()
      ? " colored coloredGp coloredEp coloredEinheit"
      : isQuantityColoredMenge() && isQuantityColoredEinheit()
      ? " colored coloredGp coloredMenge coloredEinheit"
      : isQuantityColoredEinheit()
      ? " colored coloredGp coloredEinheit"
      : isQuantityColoredMenge()
      ? " colored coloredGp coloredMenge"
      : isQuantityColoredEp()
      ? " colored coloredGp coloredEp"
      : "";
  const tableCatClassName =
    "bee-audit-lv-quantity cat" + readOnlyClassName + deactivatedClassName;
  const tablePosClassName =
    "bee-audit-lv-quantity pos" +
    readOnlyClassName +
    deactivatedClassName +
    colorClassName;

  function change(node) {
    if (!readOnly && onChange) {
      onChange(node);
    }
  }

  function activationChange(e) {
    if (!readOnly && onChange) {
      data.adaptedOptionalPositionActivated = e.checked;
      change(data);
    }
  }

  function nachtragChange(e) {
    if (!readOnly && onChange) {
      data.supplementPosition = e.checked;
      change(data);
    }
  }

  function amountChange(amount) {
    if (!readOnly && onChange) {
      data.adaptedAmount = amount;
      change(data);
    }
  }

  function unitChange(unit, pay) {
    if (!readOnly && onChange) {
      pay.adaptedUnit = unit;
      change(pay);
    }
  }

  function epChange(ep) {
    if (!readOnly && onChange) {
      data.adaptedEp = ep;
      change(data);
    }
  }

  function isQuantityColoredMenge() {
    if (data && !data.deactivated) {
      if (data.amount !== data.adaptedAmount) {
        return true;
      }
      return false;
    }
    return false;
  }
  function isQuantityColoredEinheit() {
    if (data && !data.deactivated) {
      if (data.unit !== data.adaptedUnit) {
        return true;
      }
      return false;
    }
    return false;
  }
  function isQuantityColoredEp() {
    if (data && !data.deactivated) {
      if (_.round(data.ep, 3) !== _.round(data.adaptedEp, 3)) {
        return true;
      }
      return false;
    }
    return false;
  }

  return (
    <>
      {data && data.type === "cat" ? (
        <table className={tableCatClassName}>
          <tbody>
            <tr>
              <td className={"platzhalter"}></td>
              <td className={"platzhalter"}></td>
              <td className={"gp-td readOnly"}>
                <BeeCurrencyInput
                  label={"GP"}
                  value={data.quantityAdaptedGp}
                  disabled={false}
                  minFractionDigits={0}
                  maxFractionDigits={3}
                  currency={CURRENCY}
                  locale={LOCALE}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
            </tr>
          </tbody>
        </table>
      ) : data && data.type === "pos" ? (
        <table className={tablePosClassName}>
          <tbody>
            <tr>
              <td className={"activation-td"}>
                {data.optionalPosition === true ? (
                  <BeeCheckbox
                    label={"Aktiviert"}
                    value={data.adaptedOptionalPositionActivated}
                    disabled={data.optionalPosition ? false : true}
                    formstate={"neutral"}
                    stateTrue={"Ja"}
                    stateFalse={"Nein"}
                    readOnly={readOnly || data.deactivated}
                    onChange={(e) => activationChange(e)}
                  />
                ) : null}
              </td>
              <td className={"nachtrag-td"}>
                <BeeCheckbox
                  label={"Nachtrag"}
                  value={data.supplementPosition}
                  disabled={readOnly || data.deactivated}
                  formstate={"neutral"}
                  stateTrue={"Ja"}
                  stateFalse={"Nein"}
                  required={false}
                  readOnly={readOnly || data.deactivated}
                  onChange={(e) => nachtragChange(e)}
                />
              </td>
              <td className={"gp-td readOnly"}>
                <BeeCurrencyInput
                  label={"GP"}
                  value={data.quantityAdaptedGp}
                  disabled={false}
                  minFractionDigits={0}
                  maxFractionDigits={3}
                  currency={CURRENCY}
                  locale={LOCALE}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
            </tr>
            <tr>
              <td className={"menge-td"}>
                <BeeNumberInput
                  label={"Menge"}
                  value={
                    data.adaptedAmount || data.adaptedAmount === 0
                      ? data.adaptedAmount
                      : null
                  }
                  minFractionDigits={0}
                  maxFractionDigits={0}
                  grouping={true}
                  disabled={readOnly || data.deactivated}
                  formstate={"neutral"}
                  readOnly={readOnly || data.deactivated}
                  required={false}
                  delay={DELAY_DEBOUNCED_SHORT}
                  onChange={(e) => amountChange(e.target.value)}
                />
              </td>
              <td className={"einheit-td"}>
                <BeeDebouncedTextInput
                  label={"Einheit"}
                  value={data.adaptedUnit}
                  node={data}
                  disabled={readOnly || data.deactivated}
                  formstate={"neutral"}
                  readOnly={readOnly || data.deactivated}
                  required={false}
                  delay={DELAY_DEBOUNCED}
                  onChange={(value, pay) => {
                    unitChange(value, pay);
                  }}
                />
              </td>
              <td className={"ep-td"}>
                <BeeCurrencyInput
                  label={"EP"}
                  value={data.adaptedEp}
                  disabled={readOnly || data.deactivated}
                  minFractionDigits={0}
                  maxFractionDigits={3}
                  currency={CURRENCY}
                  locale={LOCALE}
                  formstate={"neutral"}
                  readOnly={readOnly || data.deactivated}
                  required={false}
                  onChange={(e) => epChange(e.target.value)}
                />
              </td>
            </tr>
            <tr />
          </tbody>
        </table>
      ) : null}
    </>
  );
}

export default BeeAuditLvQuantity;
