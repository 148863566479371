import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../redux/app/appActions";
import {
  PATH_OFFLINE_OVERVIEW,
  PATH_ONLINE_OVERVIEW_LIEGENSCHAFTEN,
} from "../../Helper/constants";
import {
  addAndroidBackListener,
  getStoredProjects,
  runsOnMobile,
} from "../../Helper/CapacitorHelper";
import BeeAuditLogin from "../Components/BeeAuditLogin";

function AuditLogin(props) {
  const [downloadedProjects, setDownloadedProjects] = useState(false);

  useEffect(() => {
    if (runsOnMobile()) {
      //override back listener on android for complete app
      addAndroidBackListener(function backPressed() {
        window.history.back();
      });
      //check if projects are downloaded
      getStoredProjects(
        function success(data) {
          if (data && data.length > 0) {
            setDownloadedProjects(true);
          } else {
            setDownloadedProjects(false);
          }
        },
        function error() {
          setDownloadedProjects(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onStartOffline() {
    if (runsOnMobile() && props && props.history) {
      props.history.push({
        pathname: PATH_OFFLINE_OVERVIEW,
      });
    }
  }

  const redirectIfLoginSuccess = () => {
    if (props.onlineAccessPossible && sessionStorage.getItem("rT") !== null) {
      props.history.push(PATH_ONLINE_OVERVIEW_LIEGENSCHAFTEN);
    }
  };

  return (
    <>
      {redirectIfLoginSuccess()}
      <BeeAuditLogin
        headline={"Beestate | Audit"}
        showError={props.loginError}
        showProgress={props.loginPending}
        showOfflineMode={downloadedProjects}
        onLogin={(username, password) => props.loginUser(username, password)}
        onStartOffline={() => onStartOffline()}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginError: state.app.loginError,
    loginPending: state.app.loginPending,
    onlineAccessPossible: state.app.onlineAccessPossible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (username, password) => dispatch(loginUser(username, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogin);
