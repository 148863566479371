import React, { useEffect, useState } from "react";
import { useId } from "react-id-generator";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  BeeKnob,
  BeeIcon,
  BeeRatingBar,
  BeeIconButton,
  BeeContentHeadline,
  BeeStarBar,
} from "bee-atomic-components";

//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditOfflineSelectTable.scss";

export function BeeAuditOfflineSelectTable({ type, data, onClick }) {
  const currentId = useId(1, "bee-audit-offline-select-table-")[0];
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  //screen sizes
  const p_sm = 576;
  const p_md = 768;
  const p_lg = 992;

  //classNames
  const typeClassNames =
    type === "dark"
      ? "bee-audit-offline-select-table-dark"
      : type === "light"
      ? "bee-audit-offline-select-table-light"
      : "bee-audit-offline-select-table-light";

  const classNames = "bee-audit-offline-select-table " + typeClassNames;
  const rowGroupHeaderClassNames = "bee-audit-offline-select-rowgroup-header";
  const statusColClassNames = "bee-audit-offline-select-table-col-status";
  const typeColClassNames = "bee-audit-offline-select-table-col-type";
  const adaptingColClassNames = "bee-audit-offline-select-table-col-adapting";
  const titleColClassNames = "bee-audit-offline-select-table-col-name";
  const auditNumberColClassNames = "bee-audit-offline-select-table-col-nr";
  const dienstleisterColClassNames =
    "bee-audit-offline-select-table-col-dienstleister";
  const ratingColClassNames = "bee-audit-offline-select-table-col-rating";
  const actionColClassNames = "bee-audit-offline-select-table-col-action";

  //labels
  const emptyMessage = "Bisher noch keine Audits vorhanden.";
  const statusColLabel = "Stand";
  const typeColLabel = "Typ";
  const adaptingColLabel = "Änderung";
  const titleColLabel = "Name";
  const auditNumberColLabel = "Audit-Nr.";
  const dienstleisterColLabel = "Dienstleister";
  const ratingColLabel = "Bewertung";
  const actionColLabel = "";

  function statusTemplate(rowData) {
    if (rowData) {
      const progress = rowData.progress ? rowData.progress : 0;
      if (progress === 100) {
        return (
          <p headername={statusColLabel}>
            <BeeIcon iconClass={"pi pi-check"} />
          </p>
        );
      } else {
        return (
          <div headername={statusColLabel}>
            <BeeKnob
              value={progress}
              minValue={0}
              maxValue={100}
              step={1}
              size={"xSmall"}
              type={"primary"}
              disabled={false}
              readOnly={true}
            />
          </div>
        );
      }
    }
  }

  function typeTemplate(rowData) {
    if (rowData) {
      return <p headername={typeColLabel}>{rowData.type}</p>;
    }
  }

  function adaptingTemplate(rowData) {
    if (rowData) {
      const date = rowData.lastChange;
      return date ? (
        <p headername={adaptingColLabel}>{date.toLocaleDateString("de-DE")} </p>
      ) : null;
    }
  }

  function titleTemplate(rowData) {
    if (rowData) {
      return <p headername={titleColLabel}>{rowData.title}</p>;
    }
  }

  function auditNumberTemplate(rowData) {
    if (rowData) {
      return <p headername={auditNumberColLabel}>{rowData.nummer}</p>;
    }
  }

  function dienstleisterTemplate(rowData) {
    if (rowData) {
      return <p headername={dienstleisterColLabel}>{rowData.dienstleister}</p>;
    }
  }

  function ratingBodyTemplate(rowData) {
    if (rowData) {
      return (
        <div headername={ratingColLabel}>
          <BeeStarBar
            value={null}
            stars={5}
            disabled={null}
            readOnly={true}
            type={"primary"}
            size={"1.5rem"}
            onChange={null}
          />
        </div>
      );
    }
  }

  function rowGroupHeaderTemplate(data) {
    return (
      <div className={rowGroupHeaderClassNames}>
        <BeeContentHeadline
          label={data.liegenschaftTitle}
          headline={"h3"}
          type={"primary"}
        />
        <BeeContentHeadline
          label={data.liegenschaftNummer}
          headline={"h4"}
          type={"secondary"}
        />
      </div>
    );
  }
  function rowGroupFooterTemplate(data) {
    //FIXME adapt what to show?!
    return <></>;
  }

  function actionTemplate(rowData) {
    const rowDataId = rowData.id;
    if (rowData) {
      return (
        <p headername={actionColLabel}>
          <BeeIconButton
            iconClass={"pi pi-play"}
            rounded={false}
            disabled={false}
            type={"secondary"}
            onClick={() => run(rowDataId)}
          />
        </p>
      );
    }
  }

  function run(itemId) {
    if (onClick) {
      onClick(itemId);
    }
  }

  return (
    <div id={currentId} className={classNames}>
      <DataTable
        value={data}
        removableSort
        rowGroupMode="subheader"
        sortMode="single"
        sortOrder={1}
        className="p-datatable-sm"
        emptyMessage={emptyMessage}
        groupField="liegenschaftId"
        sortField="liegenschaftTitle"
        rowGroupHeaderTemplate={rowGroupHeaderTemplate}
        rowGroupFooterTemplate={rowGroupFooterTemplate}
      >
        <Column
          className={statusColClassNames}
          body={statusTemplate}
          header={statusColLabel}
        />
        <Column
          className={typeColClassNames}
          body={typeTemplate}
          header={typeColLabel}
        />
        <Column
          className={adaptingColClassNames}
          body={adaptingTemplate}
          header={adaptingColLabel}
        />
        <Column
          className={titleColClassNames}
          body={titleTemplate}
          header={titleColLabel}
        />
        {width > p_lg ? (
          <Column
            className={auditNumberColClassNames}
            body={auditNumberTemplate}
            header={auditNumberColLabel}
          />
        ) : null}
        {width > p_md ? (
          <Column
            className={dienstleisterColClassNames}
            body={dienstleisterTemplate}
            header={dienstleisterColLabel}
          />
        ) : null}
        {width > p_sm ? (
          <Column
            className={ratingColClassNames}
            body={ratingBodyTemplate}
            header={ratingColLabel}
          />
        ) : null}
        <Column className={actionColClassNames} body={actionTemplate} />
      </DataTable>
    </div>
  );
}

export default BeeAuditOfflineSelectTable;
