import React, { useEffect, useState } from "react";
import { useId } from "react-id-generator";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  BeeKnob,
  BeeIcon,
  BeeIconButton,
  BeeToolBarButton,
  BeeStarBar,
} from "bee-atomic-components";

//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditSelectTable.scss";
import _ from "lodash";

export function BeeAuditSelectTable({
  type,
  data,
  syncPossible,
  onViewVersion,
  onViewProject,
  onRunProject,
  onDownload,
  onUpload,
}) {
  const currentId = useId(1, "bee-audit-select-table-")[0];
  const [expandedRows, setExpandedRows] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);
  const lockMouseOverClassName = "bee-audit-select-table-mouseover";

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  //screen widths
  const p_sm = 576;
  const p_md = 768;
  const p_lg = 992;

  //classNames
  const typeClassNames =
    type === "dark"
      ? "bee-audit-select-table-dark"
      : type === "light"
      ? "bee-audit-select-table-light"
      : "bee-audit-select-table-light";

  const classNames = "bee-audit-select-table " + typeClassNames;
  const actionColClassNames =
    "bee-audit-select-table-col-action" + calculateActionColClassName();
  const expanderColClassNames = "bee-audit-select-table-expander ";
  const statusColClassNames = "bee-audit-select-table-col-status";
  const typeColClassNames = "bee-audit-select-table-col-type";
  const adaptingColClassNames = "bee-audit-select-table-col-adapting";
  const titleColClassNames = "bee-audit-select-table-col-name";
  const auditorColClassNames = "bee-audit-select-table-col-auditor";
  const dienstleisterColClassNames = "bee-audit-select-table-col-dienstleister";
  const ratingColClassNames = "bee-audit-select-table-col-rating";
  const lockColClassNames = "bee-audit-select-table-col-lock";
  const syncBtnClassNames = "bee-audit-select-table-col-action-sync-btn";
  const innerTableClassNames = "p-datatable-sm bee-audit-select-innertable";
  const innerTitleColClassNames = "bee-audit-select-innertable-col-versions";
  const innerDateColClassNames = "bee-audit-select-innertable-col-date";
  const innerActionColClassNames = syncPossible
    ? "bee-audit-select-innertable-col-action-twoBtn"
    : "bee-audit-select-innertable-col-action-oneBtn";
  const innerSyncBtnClassNames =
    "bee-audit-select-innertable-col-action-placeholder";
  const innerEmptyMsgClassNames = "bee-audit-select-innertable-message";

  //label
  const statusColLabel = "Stand";
  const typeColLabel = "Typ";
  const adaptingColLabel = "Änderung";
  const titleColLabel = "Name";
  const auditorColLabel = "Auditor";
  const dienstleisterColLabel = "DL Kurzname";
  const ratingColLabel = "Bewertung";
  const lockColLabel = "";
  const actionColLabel = "";
  const innerDateColLabel = "Datum";
  const innerTitleColLabel = "Vorhandene Versionen";
  const innerActionColLabel = "";

  const emptyMessage = "Bisher noch keine Audits vorhanden.";
  const innerTableEmptyMessage =
    "Aktuell sind keine Versionen dieses Audits vorhanden.";

  function calculateActionColClassName() {
    const buttonCount = syncPossible ? 3 : 2;

    const actionColWidthClassNames =
      buttonCount === 2
        ? " bee-audit-select-table-col-action-twoBtn"
        : buttonCount === 3
        ? " bee-audit-select-table-col-action-threeBtn"
        : "";
    return actionColWidthClassNames;
  }

  function statusTemplate(rowData) {
    if (rowData) {
      const progress = rowData.progress ? rowData.progress : 0;
      if (progress === 100) {
        return (
          <div headername={statusColLabel}>
            <BeeIcon iconClass={"pi pi-check"} />
          </div>
        );
      } else {
        return (
          <div headername={statusColLabel}>
            <BeeKnob
              value={progress}
              minValue={0}
              maxValue={100}
              step={1}
              size={"xSmall"}
              type={"primary"}
              disabled={false}
              readOnly={true}
            />
          </div>
        );
      }
    }
  }

  function typeTemplate(rowData) {
    if (rowData) {
      return <p headername={typeColLabel}>{rowData.type}</p>;
    }
  }

  function adaptingTemplate(rowData) {
    if (rowData) {
      const date = rowData.lastChange;
      return date ? (
        <p headername={adaptingColLabel}>{date.toLocaleDateString("de-DE")}</p>
      ) : null;
    }
  }

  function titleTemplate(rowData) {
    if (rowData) {
      return <p headername={titleColLabel}>{rowData.title}</p>;
    }
  }

  function auditorTemplate(rowData) {
    if (rowData) {
      return <p>{rowData.auditor}</p>;
    }
  }

  function dienstleisterTemplate(rowData) {
    if (rowData) {
      return <p>{rowData.dienstleister}</p>;
    }
  }

  function ratingBodyTemplate(rowData) {
    if (rowData) {
      let val = rowData.rating;
      val = val !== null ? _.round(_.divide(val, 20), 1) : null;
      return (
        <div>
          <BeeStarBar
            value={val}
            stars={5}
            disabled={rowData.rating === null || rowData.rating === undefined}
            readOnly={true}
            type={"primary"}
            size={"1.25rem"}
            onChange={null}
          />
        </div>
      );
    }
  }

  function lockingTemplate(rowData) {
    if (rowData && rowData.lockedBy) {
      return (
        <div headername={"Lock"}>
          <BeeIcon iconClass={"pi pi-lock"} />
          <span className={lockMouseOverClassName}>{rowData.lockedBy}</span>
        </div>
      );
    }
  }

  function actionTemplate(rowData) {
    const rowDataId = rowData.id;
    if (rowData) {
      return (
        <div headername={actionColLabel}>
          <BeeIconButton
            iconClass={"pi pi-eye"}
            rounded={false}
            disabled={false}
            type={"primary"}
            onClick={() => viewProject(rowDataId)}
          />
          <BeeIconButton
            iconClass={"pi pi-play"}
            rounded={false}
            disabled={rowData.localversion}
            type={"secondary"}
            onClick={() => runProject(rowDataId)}
          />
          {syncPossible ? (
            <div className={syncBtnClassNames}>
              {rowData.localversion ? (
                <BeeIconButton
                  iconClass={"pi pi-upload"}
                  rounded={false}
                  disabled={false}
                  type={"primary"}
                  onClick={() => upload(rowDataId)}
                />
              ) : (
                <BeeIconButton
                  iconClass={"pi pi-download"}
                  rounded={false}
                  disabled={false}
                  type={"primary"}
                  onClick={() => download(rowDataId)}
                />
              )}
            </div>
          ) : null}
        </div>
      );
    }
  }

  function upload(itemId) {
    if (onUpload) {
      onUpload(itemId);
    }
  }

  function download(itemId) {
    if (onDownload) {
      onDownload(itemId);
    }
  }

  function viewVersion(itemId) {
    if (onViewVersion) {
      onViewVersion(itemId);
    }
  }

  function viewProject(itemId) {
    if (onViewProject) {
      onViewProject(itemId);
    }
  }

  function runProject(itemId) {
    if (onRunProject) {
      onRunProject(itemId);
    }
  }

  function innerDateTemplate(rowData) {
    if (rowData) {
      const date = rowData.createdAt;
      return date ? (
        <p headername={innerDateColLabel}>{date.toLocaleDateString("de-DE")}</p>
      ) : null;
    }
  }

  function innerTitleTemplate(rowData) {
    if (rowData) {
      return <p headername={innerTitleColLabel}>{rowData.title}</p>;
    }
  }

  function innerActionTemplate(rowData) {
    const rowDataId = rowData.id;
    if (rowData) {
      return (
        <div headername={innerActionColLabel}>
          <BeeToolBarButton
            iconClass={"pi pi-eye"}
            rounded={false}
            disabled={false}
            type={"primary"}
            onClick={() => viewVersion(rowDataId)}
          />
          {syncPossible ? <div className={innerSyncBtnClassNames}></div> : null}
        </div>
      );
    }
  }

  const injectInnerTable = (versions) => {
    return (
      <DataTable value={versions} className={innerTableClassNames}>
        <Column
          className={innerTitleColClassNames}
          body={innerTitleTemplate}
          header={innerTitleColLabel}
        />
        <Column
          className={innerDateColClassNames}
          body={innerDateTemplate}
          header={innerDateColLabel}
        />
        <Column
          className={innerActionColClassNames}
          body={innerActionTemplate}
          header={innerActionColLabel}
        />
      </DataTable>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <>
        {data.versions ? (
          injectInnerTable(data.versions)
        ) : (
          <div className={innerEmptyMsgClassNames}>
            {innerTableEmptyMessage}
          </div>
        )}
      </>
    );
  };

  const expanderActionTemplate = (rowData) => {
    const itemId = rowData.id;
    const isVisible = rowData.versions && rowData.versions.length > 0;
    const expandedMap = new Map(
      expandedRows ? Object.entries(expandedRows) : null
    );
    let isExpanded = expandedMap.get(itemId);
    const iconClass = isExpanded ? "pi pi-angle-down" : "pi pi-angle-right";
    return (
      <>
        {isVisible ? (
          <BeeIconButton
            iconClass={iconClass}
            rounded={true}
            disabled={false}
            type={"light"}
            onClick={(e) => {
              //adapt in map
              isExpanded
                ? expandedMap.delete(itemId)
                : expandedMap.set(itemId, true);
              //convert to object
              let obj = Array.from(expandedMap).reduce(
                (obj, [key, value]) => Object.assign(obj, { [key]: value }), // Be careful! Maps can have non-String keys; object literals can't.
                {}
              );
              setExpandedRows(obj);
            }}
          />
        ) : null}
      </>
    );
  };

  return (
    <div id={currentId} className={classNames}>
      <DataTable
        value={data}
        className="p-datatable-sm"
        emptyMessage={emptyMessage}
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="id"
      >
        <Column
          className={expanderColClassNames}
          body={expanderActionTemplate}
        />
        <Column
          className={statusColClassNames}
          body={statusTemplate}
          header={statusColLabel}
          field="stand"
        />
        <Column
          className={typeColClassNames}
          body={typeTemplate}
          header={typeColLabel}
          field="typ"
        />
        <Column
          className={adaptingColClassNames}
          body={adaptingTemplate}
          header={adaptingColLabel}
          field="änderung"
        />
        <Column
          className={titleColClassNames}
          body={titleTemplate}
          header={titleColLabel}
          field="name"
        />
        {width > p_lg ? (
          <Column
            className={auditorColClassNames}
            body={auditorTemplate}
            header={auditorColLabel}
            field="auditor"
          />
        ) : null}
        {width > p_md ? (
          <Column
            className={dienstleisterColClassNames}
            body={dienstleisterTemplate}
            header={dienstleisterColLabel}
            field="dienstleister"
          />
        ) : null}
        {width > p_sm ? (
          <Column
            className={ratingColClassNames}
            body={ratingBodyTemplate}
            header={ratingColLabel}
            field="bewertung"
          />
        ) : null}
        <Column
          className={lockColClassNames}
          body={lockingTemplate}
          header={lockColLabel}
          field="lock"
        />
        <Column
          className={actionColClassNames}
          body={actionTemplate}
          header={actionColLabel}
          field="action"
        />
      </DataTable>
    </div>
  );
}

export default BeeAuditSelectTable;
