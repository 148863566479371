import React, { useState } from "react";
import _ from "lodash";
import {
  calculateQualityGP,
  calculateQuantityGP,
  convertDataToLv,
  convertLvToSaveableData,
} from "../../Helper/LvHelper";
import BeeAuditLv from "../Components/BeeAuditLv";

function AuditUILv({
  homeUrl,
  breadcrumbData,
  warningMessage,
  viewOptions,
  selectedView,
  categoryTree,
  readOnly,
  username,
  lvData,
  showLogout,
  showReport,
  onChangeData,
  onLogout,
  onSave,
  onSelectView,
  onGenerateExcelReport,
}) {
  const [lv, setLv] = useState();
  const [sumGP, setSumGP] = useState();
  const [sumGPadapted, setSumGPadapted] = useState();
  const [qualitySumGPadapted, setQualitySumGPadapted] = useState();
  const [quantitySumGPadapted, setQuantitySumGPadapted] = useState();
  const [meanReduction, setMeanReduction] = useState();

  function notifyChange(data) {
    if (!readOnly && onChangeData) {
      onChangeData(data);
    }
  }

  function createExcelReport() {
    if (!readOnly && showReport && onGenerateExcelReport) {
      onGenerateExcelReport();
    }
  }

  function adaptEntry(id, data, creationMode, deletionMode, restoringMode) {
    if (lv) {
      let pay = _.cloneDeep(lv);
      for (let i = 0; i < pay.length; i++) {
        if (creationMode) {
          if (findAndAddEntry(id, pay[i], data)) {
            break;
          }
        } else if (deletionMode) {
          if (findAndDeleteEntry(id, pay[i])) {
            break;
          }
        } else if (restoringMode) {
          if (findAndRestoreEntry(id, pay[i])) {
            break;
          }
        } else {
          if (findAndAdaptEntry(id, pay[i], data, false)) {
            break;
          }
        }
      }
      let tmp = convertLvToSaveableData(pay);
      notifyChange(tmp);
      setLv(null);
    }
  }

  function findAndAddEntry(catId, currentNode, entry) {
    if (catId === currentNode.key && currentNode.data.type === "cat") {
      if (!currentNode.children) {
        currentNode.children = [];
      }
      currentNode.children.push(entry);
      return true;
    } else if (currentNode.children && currentNode.children.length > 0) {
      currentNode.children.forEach((currentChild) => {
        findAndAddEntry(catId, currentChild, entry);
      });
    }
  }

  function findAndAdaptEntry(key, currentNode, values) {
    if (key === currentNode.key) {
      currentNode.data.adaptedAmount = values.adaptedAmount;
      const quantityAdaptedGp = calculateQuantityGP(
        values.adaptedAmount,
        values.adaptedEp
      );
      currentNode.data.quantityAdaptedGp = quantityAdaptedGp;
      currentNode.data.qualityAdaptedGp = calculateQualityGP(
        quantityAdaptedGp,
        values.reductionPercentage
      );
      currentNode.data.adaptedUnit = values.adaptedUnit;
      currentNode.data.adaptedEp = values.adaptedEp;
      currentNode.data.comment = values.comment;
      currentNode.data.reductionPercentage = values.reductionPercentage;
      currentNode.data.fulfillment = values.fulfillment;
      currentNode.data.supplementPosition = values.supplementPosition;
      currentNode.data.optionalPosition = values.optionalPosition;
      currentNode.data.optionalPositionActivated =
        values.optionalPositionActivated;
      currentNode.data.adaptedOptionalPositionActivated =
        values.adaptedOptionalPositionActivated;
      return true;
    } else {
      if (currentNode.children && currentNode.children.length > 0) {
        for (let i = 0; i < currentNode.children.length; i++) {
          if (findAndAdaptEntry(key, currentNode.children[i], values)) {
            return true;
          }
        }
      }
    }
  }

  function findAndRestoreEntry(key, currentNode) {
    if (key === currentNode.key) {
      currentNode.data.deactivated = false;
      return true;
    } else {
      if (currentNode.children && currentNode.children.length > 0) {
        currentNode.children.forEach((currentChild) => {
          findAndRestoreEntry(key, currentChild);
        });
      } else {
        return;
      }
    }
  }

  function findAndDeleteEntry(key, currentNode) {
    if (key === currentNode.key) {
      currentNode.data.deactivated = true;
      return true;
    } else {
      if (currentNode.children && currentNode.children.length > 0) {
        currentNode.children.forEach((currentChild) => {
          findAndDeleteEntry(key, currentChild);
        });
      } else {
        return;
      }
    }
  }

  function processRawLv(lvData) {
    if (!lv && lvData) {
      const data = convertDataToLv(_.cloneDeep(lvData));
      setLv(data.data);
      setSumGP(data.sumGP);
      setSumGPadapted(data.sumGPadapted);
      setQualitySumGPadapted(data.qualitySumGPadapted);
      setQuantitySumGPadapted(data.quantitySumGPadapted);
      setMeanReduction(data.meanReduction);
    }
  }

  return (
    <>
      {processRawLv(lvData)}
      <BeeAuditLv
        homeUrl={homeUrl}
        breadcrumbData={breadcrumbData}
        overallProgress={
          categoryTree && categoryTree.length > 0 && categoryTree[0].data
            ? categoryTree[0].data.progress
            : 0
        }
        maxOverallProgress={
          categoryTree && categoryTree.length > 0 && categoryTree[0].data
            ? categoryTree[0].data.questions
            : 0
        }
        warningMessage={warningMessage}
        viewOptions={viewOptions}
        selectedView={selectedView}
        lvdata={lv}
        meanReduction={meanReduction}
        sumGP={sumGP}
        sumGPadapted={sumGPadapted}
        qualitySumGPadapted={qualitySumGPadapted}
        quantitySumGPadapted={quantitySumGPadapted}
        readOnly={readOnly}
        username={username}
        currency={"EUR"}
        locale={"de-DE"}
        showSave={!readOnly}
        showLogout={showLogout}
        showReport={showReport}
        onSave={() => onSave()}
        onLogout={() => onLogout()}
        onSelectView={(view) => onSelectView(view)}
        onEntryChange={(id, payload) => {
          adaptEntry(id, payload, false, false, false);
        }}
        onEntryAdd={(catIdToCreate, entry) =>
          adaptEntry(catIdToCreate, entry, true, false, false)
        }
        onEntryRemove={(id) => adaptEntry(id, null, false, true, false)}
        onEntryRestore={(id) => adaptEntry(id, null, false, false, true)} //FIXME
        onClickAuffalligkeiten={(id) => console.log("FIXME!!: " + id)}
        onCreateExcelReport={() => createExcelReport()}
      />
    </>
  );
}

export default AuditUILv;
