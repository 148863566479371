// URLs TO APIs
// export const URL_TO_AUTH_API = "https://alphaaudit-api.serthoro.de/auth";
// export const URL_TO_LIEGENSCHAFT_API =
//   "https://alphaaudit-api.serthoro.de/liegenschaften";
// export const URL_TO_MEDIA_API = "https://alphaaudit-api.serthoro.de/media";
// export const URL_TO_MEDIA_SERVER = "https://alphaaudit-media.serthoro.de";
// export const URL_TO_AUDIT_API = "https://alphaaudit-api.serthoro.de/audit";
// export const URL_TO_REFRESH_TOKEN_AT_AUTH_API =
//   "https://alphaaudit-api.serthoro.de/auth/v1/public/token/refresh";

// LIVE_ALPHA_URLS
export const URL_TO_AUTH_API = "https://audit-api.alpha-ic.com/auth";
export const URL_TO_LIEGENSCHAFT_API =
  "https://audit-api.alpha-ic.com/liegenschaften";
export const URL_TO_MEDIA_API = "https://audit-api.alpha-ic.com/media";
export const URL_TO_MEDIA_SERVER = "https://audit-media.alpha-ic.com";
export const URL_TO_AUDIT_API = "https://audit-api.alpha-ic.com/audit";
export const URL_TO_REFRESH_TOKEN_AT_AUTH_API =
  "https://audit-api.alpha-ic.com/auth/v1/public/token/refresh";

//DEFAULT DATA
export const PATH_TO_DEFAULT_PROPERTY_IMAGE =
  "https://beestate.io/wp-content/uploads/2019/05/beestate_bg_digital.jpg";

//URL-PATHS
export const PATH_ROOT = "/";
export const PATH_ERROR = "/error";
export const PATH_LOGIN = "/login";
export const PATH_OFFLINE_OVERVIEW = "/offline/audit";
export const PATH_OFFLINE_AUDIT = "/detail/offline/audit";
export const PATH_ONLINE_OVERVIEW_LIEGENSCHAFTEN = "/overview/liegenschaften";
export const PATH_ONLINE_DETAIL_LIEGENSCHAFT = "/detail/liegenschaft";
export const PATH_ONLINE_OVERVIEW_AUDIT = "/overview/audits";
export const PATH_ONLINE_SETUP_AUDIT = "/setup/audit";
export const PATH_ONLINE_AUDIT = "/detail/audit";

//DEFAULTS
export const DEFAULT_IMAGE_SIZE = "MEDIUM";
export const DEFAULT_IMAGE_DOWNLOAD_SIZE = "MEDIUM";
export const DURATION_NOTIFICATION_INFO = 3000;
export const DURATION_NOTIFICATION_SUCCESS = 2000;
export const DURATION_NOTIFICATION_SUCCESS_LONG = 4000;
export const DURATION_NOTIFICATION_ERROR = 3000;
export const DURATION_NOTIFICATION_ERROR_LONG = 5000;
export const DATE_FORMAT = "dd.mm.yy";

export const IMG_ANDROID_QUALITY = 90;
export const IMG_ANDROID_WIDTH = 1920;
export const IMG_ANDROID_HEIGHT = 1080;
export const IMG_ANDROID_PRESERVE_ASPECT_RATIO = true;

//ACCEPTED FILE FORMATS
export const ACCEPTED_FILE_FORMATS =
  ".pdf, .json, .xlsx, .xlsm, .xlsb, .xltm, .xls, .xlt, .xla, .xlw, .xlr, .doc, .dot, .wbk, .docx, .docm, .dotx, .dotm, .docb, .ppt, .pot, .pps, .pptx, .pptm, .potx, .potm, .ppam, .ppsx, .ppsm, .sldx, .sldm, .csv, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .webp, .svg, .gif, .bmp, .xml, .html, .htm, .txt";
export const ACCEPTED_IMAGE_FORMATS = "image/*";
export const ACCEPTED_EXCEL_LV_UPLOAD_FORMATS =
  ".xlsx, .xlsm, .xlsb, .xltm, .xls";

//QUESTION_TABS
export const AUDIT_VIEW_INFO = "view_data";
export const AUDIT_VIEW_AUFFALLIGKEIT = "view_auffalligkeit";
export const AUDIT_VIEW_CATEGORY = "view_category";
export const AUDIT_VIEW_QUESTION = "view_question";
export const AUDIT_VIEW_LV = "view_lv";
export const VIEWS_WITH_LV = [
  { label: "Grunddaten", value: AUDIT_VIEW_INFO },
  { label: "Auffälligkeiten", value: AUDIT_VIEW_AUFFALLIGKEIT },
  { label: "Kategorien", value: AUDIT_VIEW_CATEGORY },
  { label: "Fragen", value: AUDIT_VIEW_QUESTION },
  { label: "Leistungsverzeichnis", value: AUDIT_VIEW_LV },
];
export const VIEWS_NO_LV = [
  { label: "Grunddaten", value: AUDIT_VIEW_INFO },
  { label: "Auffälligkeiten", value: AUDIT_VIEW_AUFFALLIGKEIT },
  { label: "Kategorien", value: AUDIT_VIEW_CATEGORY },
  { label: "Fragen", value: AUDIT_VIEW_QUESTION },
];

export const LOW_PERCENTAGE_QUESTION = 50;
export const DELAY_DEBOUNCED = 300;
export const DELAY_DEBOUNCED_SHORT = 100;

//FILE PATHS
export const STORAGE_PATH_PROJECTS = "audit/projects/";
export const STORAGE_PATH_DOWNLOADS = "audit/downloads/";
export const STORAGE_AFFIX_PROJECT = "/project.json";
export const STORAGE_AFFIX_LIEGENSCHAFT = "/liegenschaft.json";
export const STORAGE_AFFIX_USER = "/user.json";
export const STORAGE_AFFIX_LOCK = "/lock.json";
export const STORAGE_AFFIX_MEDIA = "/media.json";

//CURRENCY,LOCALE
export const CURRENCY = "EUR";
export const LOCALE = "de-DE";

//AUFFÄLLIGKEITEN
export const STATES_OF_AUFFALLIGKEIT_OPEN = {
  id: "OPN",
  title: "Offen",
};
export const STATES_OF_AUFFALLIGKEIT_PROCESS = {
  id: "PRC",
  title: "In Bearbeitung",
};
export const STATES_OF_AUFFALLIGKEIT_CLOSED = {
  id: "CSD",
  title: "Geschlossen",
};
export const STATES_OF_AUFFALLIGKEIT_DONE = {
  id: "DNE",
  title: "Erledigt",
};
export const STATES_OF_AUFFALLIGKEIT = [
  STATES_OF_AUFFALLIGKEIT_OPEN,
  STATES_OF_AUFFALLIGKEIT_PROCESS,
  STATES_OF_AUFFALLIGKEIT_CLOSED,
  STATES_OF_AUFFALLIGKEIT_DONE,
];

//AUFFÄLLIGKEITEN TYPEN
export const TYPE_MANGEL = {
  label: "Mangel",
  value: "M",
  info: "Mängel liegen im Leistungsumfang und Verantwortung des Dienstleisters und sollten behoben werden.",
};
export const TYPE_DEFIZIT = {
  label: "Defizit",
  value: "DEF",
  info: "Defizit: Schlechtleistung, welche im Rahmen der Betreiberverantwortung des AG behoben werden sollte, allerdings nicht im Leistungsumfang des DL liegt.",
};
export const TYPE_HINWEIS = {
  label: "Hinweis",
  value: "HW",
  info: "Hinweis: Merkmal oder Besonderheit, welches die Leistungsqualität treffend dokumentiert (also auch korrekt erfüllte Leistungen).",
};
export const TYPE_DROPDOWN = [TYPE_MANGEL, TYPE_DEFIZIT, TYPE_HINWEIS];

//AUFFÄLLIGKEITEN CAUSES DROPDOWN

export const CAUSE_REIFE = {
  label: "Reifegrad Organisation",
  value: "RORG",
  info: "Organisatorische Mängel beziehen sich auf die Aufbau-, wie auch auf die Ablauforganisation. Eine etablierte, eingespielte und reife Organisation ist die Voraussetzung für eine gesetzes- und vertragskonforme sowie qualitative hochwertige Vertragserfüllung. Diese Grundlagen werden verstärkt gerade in den ersten Monaten nach Leistungsbeginn geschaffen und im Zuge des Erstaudits hinterfragt. Aber auch durch Wechsel in der Objektleitung und sogar Jahre nach Vertragsbeginn sind organisatorische Defizite als Ursache zu identifizieren. Konkrete Ursachen für Defizite in der Kategorie 'Reifegrad Organisation' können beispielsweise eine zu kurz bemessene Start-up Phase (inkl. Datenqualität, Leistungs-/ Vertragsüberführung, Dokumentationsübergabe, Einweisungen etc.), der Fachkräftemangel, eine unzureichende Qualifikationen der Mitarbeiter, mangelnde Kenntnisse zu Vertrag, Leistungsumfang und Leistungsbeschreibung oder fehlende Prozesse bzw. Prozesskenntnisse sein. ",
};

export const CAUSE_BESTAND = {
  label: "Bestandsdokumentation",
  value: "BADOC",
  info: "Eine vollständige Bestandsdokumentation ist in den seltensten Fällen vorhanden oder an den Dienstleister übergeben worden. Aus dem Start-up muss eine Aufstellung der für den regelkonformen Betrieb zwingend notwendigen Anlagendokumentation (z.B. Abnahmeprotokolle) seitens des AN zusammengestellt werden. Etwaige Lücken gilt es im Zuge des Start-up, mindestens jedoch im laufenden Betrieb zu schließen oder zumindest den AG auf diesen Missstand hinzuweisen und die Behebung einzufordern.",
};
export const CAUSE_ARBEITSVORB = {
  label: "Arbeitsvorbereitung",
  value: "AVOR",
  info: "Die Arbeitsvorbereitung (wie auch die Leistungsplanung) ist eine zentrale Voraussetzung für eine qualitative und vertragskonforme Leistungserbringung. Nicht, verzögert oder unzureichend ausgeführte durchgeführte Instandhaltungsleistungen (Wartungen, Inspektionen und Prüfungen) oder ineffiziente Instandsetzungen haben Ihren Ursprung häufig in der fehlenden oder ungenügenden Arbeitsvorbereitung. Dazu sind bspw. Handlungsanweisungen und Dokumentationsvorlagen zu zählen, genauso wie das gezielte Aufgreifen von Defiziten bzw. Mängeln aus Vorleistungen.",
};
export const CAUSE_BETRIEBSDOK = {
  label: "Betriebsdokumentation",
  value: "BIDOC",
  info: "Die Betriebsdokumentation weißt oftmals Lücken auf, indem nicht alle durchgeführten Leistungen dokumentiert werden oder diese qualitativ ungenügend sind. Qualitative Mängel sind z.B. fehlende oder fehlerhafte Zuordnung der Leistungen zu Flächen oder Anlagen, die fehlende Beschreibung der durchgeführten Leistungen (Bspw. Sachkunde-Prüfung), Lücken in der Anlagendokumentation (Bspw. Betriebsbücher, Wartungssiegel) sowie die fehlende Dokumentation bzw. Zuordnung von Mängeln und deren Auswirkungen auf die Betriebsbereitschaft bzw. Sicherheit der Anlagen. ",
};
export const CAUSE_DLSTEUERUNG = {
  label: "DL-Steuerung",
  value: "DLS",
  info: "Für eine qualitativ ausreichende und insbesondere hochwertige Leistungserbringung ist eine aktive Dienstleistungssteuerung durch a) den Auftragnehmer, aber b) auch dem Auftraggeber unabdingbar. Erfahrungsgemäß beschränken sich die internen Prozesse zur Qualitätssicherung meist auf Leistungen des Eigenpersonals, während QM-Prozesse bzgl. Nachunternehmern sich vorwiegend auf den Einkaufsprozess beschränken, nicht aber die eigentliche Leistungserbringung umschließen. Die Arbeitsvorbereitung, Begleitung, Abnahme und Nachverfolgung der Leistungen durch das Objektteam des AN bilden dabei eine Grundvoraussetzung für eine qualitätssichernde Leistungserbringung und Steuerung von im Objekt tätigen Dienstleistern (bspw. Wartungsunternehmen, Sachverständige, Reinigungsfirmen).  Die Dienstleistungssteuerung zielt dabei auf eine langfristige Optimierung der Leistungen ab und soll demzufolge nicht ausschließlich reaktiv wirken.",
};
export const CAUSE_MANGELNV = {
  label: "Mangelnachverfolgung",
  value: "MNV",
  info: "Oftmals werden Mängel, die bei der Inspektion, Wartung und Prüfung des Gebäudes und der technischen Anlagen festgestellt worden sind, durch den Dienstleister nicht automatisch nachverfolgt und den entsprechenden Prozessen (z.B. Instandsetzung) zugeführt. Ursächlich hierfür kann bereits die unzureichende Klassifizierung und Dokumentation der Mängel, aber auch eine mangelhafte Ergebnisverwertung sein, indem die Prozesse zur Mängelbeseitigung (Mängel in das Betriebsführungssystem aufnehmen, Maßnahmen zur Mängelbeseitigung initiieren sowie Abnahme, Dokumentation und Freimeldung der Mängelbeseitigung) nicht konsequent verfolgt werden. Der AG ist entsprechend den vertraglichen Regelungen und geltenden Freigabeprozessen einzubinden.",
};
export const CAUSE_PLANUNG = {
  label: "Planung",
  value: "PL",
  info: "Die Leistungsplanung (wie auch die Arbeitsvorbereitung) ist eine zentrale Voraussetzung für eine qualitative und vertragskonforme Leistungserbringung. Nicht oder nicht fristgemäß durchgeführte präventive Instandhaltungsleistungen (Wartungen, Inspektionen und Prüfungen) oder nicht effiziente durchgeführte Instandsetzungen haben Ihren Ursprung in der häufig ungenügenden Leistungsplanung. Dazu sind die vertraglich geforderten Leistungen exakt aufzubereiten und entsprechend der definierten Zyklen bzw. Fristen in Abstimmung mit dem Objektteam und Externen (bspw. Wartungsfirmen oder Prüfinstitute) zu planen, zu veranlassen und nachzuverfolgen.",
};
export const CAUSE_STARTUP = {
  label: "Start-up/ Onboarding",
  value: "SO",
  info: "Die Implementierung des FM-Vertrags bildet das Fundament für eine qualitativ hochwertige Leistungserbringung. Oftmals hat eine Schlechtleistung in der operativen Ausführung die Ursache in einem unzureichenden Start-Up-/Onboarding-Prozess vor Beginn des Regelbetriebs. Hierzu sind unter anderem die vertragliche und objektbezogene Einweisung des Objektteams, die Sichtung und Nachforderung der Bestands- und Betriebsdokumentation, die Vorbereitung und Implementierung eines Betriebsführungssystem sowie ein Massenabgleich über das Technische und Infrastrukturelle Gebäudemanagement zu zählen. Aufgrund fehlender Kapazitäten können Nachbesserungen häufig nur schwerlich durch die operative Objektmannschaft eingesteuert werden.",
};
export const CAUSE_TOOL = {
  label: "Tool/ Reporting",
  value: "TR",
  info: "Die durch den Dienstleister eingesetzten Tools unterstützen in Funktionalität und Inhalt (siehe auch Stamm- und Betriebsdaten) nur unzureichend die FM-Prozesse und die Mitarbeiter des Auftragnehmers, bspw. in Form eines Betriebsführungssystems, über welches die Gebäude- und Anlageninformationen einsehbar sind sowie die Betriebsdokumentation (bspw. Handlungsanweisungen oder Wartungsprotokolle) eingesehen sowie vorbereitend zur eigentlichen Leistungserbringung automatisiert generiert werden kann. Auch das Reporting kann Tool-gestützt aufgebaut werden, damit zum einen die vertraglich geforderten Inhalte, zum anderen aber auch historienbasierte Informationen oder Auswertungen (bspw. Entwicklung der Medienverbräuche, Nachverfolgung von Mängeln oder Sonderleistungen) standardisiert abgebildet werden können und damit ausreichend informativ für das Management des AG als auch zur Steuerung der Leistungen gestaltet sind. ",
};
export const CAUSES_DROPDOWN = [
  CAUSE_REIFE,
  CAUSE_BESTAND,
  CAUSE_ARBEITSVORB,
  CAUSE_BETRIEBSDOK,
  CAUSE_DLSTEUERUNG,
  CAUSE_MANGELNV,
  CAUSE_PLANUNG,
  CAUSE_STARTUP,
  CAUSE_TOOL,
];

//AUDIT-GRUNDLAGE
export const GRUNDLAGENTYPEN = [
  { label: "Dokumentenprüfung", value: "DPR" },
  { label: "Gespräch", value: "GSP" },
  { label: "Begehung", value: "BGH" },
  { label: "Sonstiges", value: "STG" },
];

//AUDIT-GRUNDLAGE TEILNAHME DROPDOWN

export const KEINE_TN = { label: "Keine Teilnahme", value: "NO_T" };
export const TEILWEISE_TN = { label: "Teilweise Teilnahme", value: "TEIL_T" };
export const KOMPLETTE_TN = { label: "Komplette Teilnahme", value: "KOMPL_T" };

//LV-DIALOG POSITIONSART DROPDOWN

export const BEDARFSPOS = { label: "Bedarfsposition", value: "BP" };
export const NORMALPOS = { label: "Normalposition", value: "NP" };

export const POS_DROPDOWN = [NORMALPOS, BEDARFSPOS];

export const LIEGENSCHAFT_SORT_CITY = "Stadt";
export const LIEGENSCHAFT_SORT_ADDRESS = "Straße & Hausnummer";
export const LIEGENSCHAFT_SORT_BUILDING_NO = "Gebäudenummer";
export const LIEGENSCHAFT_SORT_COUNT_AUDITS = "Anzahl Audits";
export const LIEGENSCHAFT_SORT_LAST_AUDIT = "Letztes Audit";
export const DROPDOWN_LIEGENSCHAFT_SORT = [
  LIEGENSCHAFT_SORT_CITY,
  LIEGENSCHAFT_SORT_ADDRESS,
  LIEGENSCHAFT_SORT_BUILDING_NO,
  LIEGENSCHAFT_SORT_COUNT_AUDITS,
  LIEGENSCHAFT_SORT_LAST_AUDIT,
];

//erfüllungsgrad Dropdown
export const UNGEPRUEFT = { label: "Ungeprüft", value: "UGP" };
export const ERFUELLT = { label: "Erfüllt", value: "E" };
export const NACHZUERFUELLEN = { label: "Nachzuerfüllen", value: "NZEF" };
export const NACHERFUELLT = { label: "Nacherfüllt", value: "NEF" };
export const MINDERUNG = { label: "Minderung", value: "M" };

export const FULFILLMENTARRAY = [
  UNGEPRUEFT,
  ERFUELLT,
  NACHZUERFUELLEN,
  NACHERFUELLT,
  MINDERUNG,
];
