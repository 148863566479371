import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import BeeAuditDialogSyncProject from "../Dialogs/BeeAuditDialogSyncProject";
import { BeeConfirmDialog, BeeToast } from "bee-atomic-components";
import BeeAuditSelect from "../Components/BeeAuditSelect";
import { logoutUser } from "../../redux/app/appActions";
import {
  DEFAULT_IMAGE_DOWNLOAD_SIZE,
  DURATION_NOTIFICATION_ERROR_LONG,
  PATH_ONLINE_AUDIT,
  STORAGE_AFFIX_LOCK,
  STORAGE_AFFIX_MEDIA,
  STORAGE_AFFIX_PROJECT,
  STORAGE_PATH_PROJECTS,
  URL_TO_MEDIA_SERVER,
} from "../../Helper/constants";
import {
  breadCrumbAuditSelect,
  breadCrumbHome,
} from "../../Helper/BreadCrumbHelper";
import {
  callAllAuditProjectsForLiegenschaft,
  createLockForMaintainer,
  downloadDocumentFromServer,
  ensureUserIsMaintainer,
  errorToMsg,
  releaseLockForMaintainer,
  saveProjectAtServer,
  translateMediaIds,
  uploadDocumentToServer,
  uploadImageToServer,
} from "../../Helper/NetworkHelper";
import { removeAllOnlinePendingLocksForUserId } from "../../Helper/RemovePendingLocksHelper";
import {
  createCategoryRating,
  createCategoryTree,
  extractAllMediaIdsForConverting,
} from "../../Helper/QuestionaireHelper";
import {
  checkExistenceOfProjectOnDisk,
  createProjectOnDisk,
  deleteProjectFromDisk,
  readFileAsBlob,
  readJsonFileFromDisk,
  renameFolderOnDisk,
  runsOnMobile,
  storeAuditInProjectOnDisk,
  storeAuditProjectSynced,
  storeLiegenschaftInProjectOnDisk,
  storeLockInProjectOnDisk,
  storeMediaFromBlobOnAndroidInProject,
  storeMediaInProjectOnDisk,
  storeUserInProjectOnDisk,
} from "../../Helper/CapacitorHelper";
import _ from "lodash";
import { Capacitor } from "@capacitor/core";
import { checkIfValidUUID } from "../../Helper/Validator";
import { convertDataToLv } from "../../Helper/LvHelper";

function OnlineAuditSelect(props) {
  const query = new URLSearchParams(props.location.search);
  const liegenschaftId = query.get("lid");
  const [versionToProjectLookup, setVersionToProjectLookup] = useState();
  const [currentData, setCurrentData] = useState();
  const [lTitle, setLTitle] = useState();
  const [allAudits, setAllAudits] = useState(null);
  const [allAuditsPending, setAllAuditsPending] = useState(false);
  const [allAuditsError, setAllAuditsError] = useState(false);
  //confirm
  const [v_LogoutDialog, setV_LogoutDialog] = useState(false);
  const [v_OfflineDialog, setV_OfflineDialog] = useState(false);
  const [v_SyncDialog, setV_SyncDialog] = useState(false);
  //sync
  const [v_SyncDialogPending, setV_SyncDialogPending] = useState(false);
  const [v_SyncDialogError, setV_SyncDialogError] = useState(false);
  const [v_SyncDialogAction, setV_SyncDialogAction] = useState(false);
  const [fileDownload, setFileDownload] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  //reference
  const toast = useRef(null);

  //initial loading
  useEffect(() => {
    //remove all pending locks
    removeAllOnlinePendingLocksForUserId();
    if (!allAudits && !allAuditsPending) {
      setAllAuditsPending(true);
      setAllAuditsError(false);
      callAllAuditProjectsForLiegenschaft(liegenschaftId)
        .then((data) => {
          setAllAudits(data);
          setAllAuditsPending(false);
          setAllAuditsError(false);
        })
        .catch((error) => {
          setAllAuditsPending(false);
          setAllAuditsError(true);
          toast.current.show({
            severity: "error",
            summary:
              "Projekte konnten nicht geladen werden " + errorToMsg(error),
            detail:
              "Leider konnten die Projekte geladen werden. Bitte versuchen Sie es später erneut.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function upload(itemId) {
    if (runsOnMobile()) {
      setV_SyncDialogPending(true);
      setV_SyncDialogError();
      setV_SyncDialogAction("Daten zur Übermittlung werden ermittelt...");
      //load this project from disk
      const path = STORAGE_PATH_PROJECTS + liegenschaftId + "/" + itemId;
      readJsonFileFromDisk(
        path + STORAGE_AFFIX_LOCK,
        (lockJson) => {
          const lock = JSON.parse(lockJson);
          //success
          readJsonFileFromDisk(
            path + STORAGE_AFFIX_PROJECT,
            (projectJson) => {
              //success
              const project = JSON.parse(projectJson);
              const imgLookup = new Map();
              const docLookup = new Map();
              //read media-data
              setV_SyncDialogAction("Medien zur Übermittlung vorbereiten...");
              readJsonFileFromDisk(
                path + STORAGE_AFFIX_MEDIA,
                (mediaJson) => {
                  //success
                  const mJson = mediaJson ? mediaJson : "[]";
                  if (mJson) {
                    const media = JSON.parse(mJson);
                    if (media && media.length > 0) {
                      for (let i = 0; i < media.length; i++) {
                        if (media[i].image) {
                          imgLookup.set(media[i].image.id, media[i].image);
                        } else if (media[i].document) {
                          docLookup.set(
                            media[i].document.id,
                            media[i].document
                          );
                        }
                      }
                    }
                  }
                  syncAllMedia(project, imgLookup, docLookup)
                    .then(() => {
                      setV_SyncDialogAction("Projekt wird hochgeladen...");
                      // upload these project at server
                      let contractAmount = 0;
                      const lv =
                        project &&
                        project.payload &&
                        project.payload.leistungsverzeichnis
                          ? project.payload.leistungsverzeichnis
                          : null;
                      if (lv) {
                        const tmp = convertDataToLv(lv);
                        if (tmp && tmp.sumGP) {
                          contractAmount = tmp.sumGP;
                        }
                      }
                      const qId =
                        project &&
                        project.payload &&
                        project.payload.questionaire &&
                        project.payload.questionaire.questionaireID
                          ? project.payload.questionaire.questionaireID
                          : null;
                      let rating = null;
                      const catRating = createCategoryRating(project);
                      if (catRating) {
                        let val = _.find(catRating, ["id", qId]);
                        if (val) {
                          rating = val.value;
                        }
                      }
                      saveProjectAtServer(
                        project.id,
                        project.templateId,
                        project.propertyId,
                        lock.id,
                        project.payload.auditName,
                        project.payload.auditNummer,
                        project.payload.beschreibung,
                        JSON.stringify(project.payload),
                        rating,
                        contractAmount
                      )
                        .then(() => {
                          //success => release offline-lock for user
                          setV_SyncDialogAction(
                            "Schreibrechte für andere Nutzer werden freigegeben..."
                          );
                          releaseLockForMaintainer(
                            project.id,
                            lock.maintainerId
                          )
                            .then(() => {
                              //success => delete project on disk
                              setV_SyncDialogAction(
                                "Lokale Daten werden gelöscht..."
                              );
                              deleteProjectFromDisk(
                                liegenschaftId,
                                itemId,
                                () => {
                                  //this is for rerender table
                                  setV_SyncDialog(false);
                                  setCurrentData(null);
                                },
                                () => {
                                  //this is for rerender table
                                  setCurrentData(null);
                                  setV_SyncDialogPending(false);
                                  setV_SyncDialogError(
                                    "Das Löschen der gespeicherten Projektdaten ist fehlgeschlagen."
                                  );
                                }
                              );
                            })
                            .catch(() => {
                              //error
                              setV_SyncDialogPending(false);
                              setV_SyncDialogError(
                                "Der Release des Locks ist fehlgeschlagen."
                              );
                            });
                        })
                        .catch(() => {
                          //error
                          setV_SyncDialogPending(false);
                          setV_SyncDialogError(
                            "Der Upload des Projekts ist fehlgeschlagen."
                          );
                        });
                    })
                    .catch(() => {
                      setV_SyncDialogPending(false);
                      setV_SyncDialogError(
                        "Medien konnten nicht synchronisiert werden werden."
                      );
                    });
                },
                () => {
                  //error
                  setV_SyncDialogPending(false);
                  setV_SyncDialogError(
                    "Notwendige Medien konnten nicht ermittelt werden."
                  );
                }
              );
            },
            () => {
              //error
              setV_SyncDialogPending(false);
              setV_SyncDialogError(
                "Daten konnten nicht ermittelt werden werden."
              );
            }
          );
        },
        () => {
          //error
          setV_SyncDialogPending(false);
          setV_SyncDialogError("Die Lockdatei konnte nicht geladen werden.");
        }
      );
    }
  }

  const syncAllMedia = async (project, imgLookup, docLookup) => {
    const projectId = project.id;
    if (project && project.payload) {
      const pay = project.payload;
      //dokumente
      if (pay.dokumente && pay.dokumente.length > 0) {
        setV_SyncDialogAction("Synchronisiere Dokumente...");
        for (let i = 0; i < pay.dokumente.length; i++) {
          const docId = pay.dokumente[i].mediaID;
          const newId = await uploadDocument(docLookup, docId, projectId);
          //change stored id & save project
          project.payload.dokumente[i].mediaID = newId;
          await storeAuditProjectSynced(liegenschaftId, project);
        }
      }
      //auffalligkeiten
      if (pay.auffaelligkeiten && pay.auffaelligkeiten.length > 0) {
        setV_SyncDialogAction("Synchronisiere Medien aus Auffälligkeiten...");
        for (let a = 0; a < pay.auffaelligkeiten.length; a++) {
          const documents = pay.auffaelligkeiten[a].dokumente;
          const images = pay.auffaelligkeiten[a].bilder;
          //auffalligkeiten > dokumente
          if (documents && documents.length > 0) {
            for (let ad = 0; ad < documents.length; ad++) {
              const docId = documents[ad].mediaID;
              const newId = await uploadDocument(docLookup, docId, projectId);
              //change stored id & save project
              project.payload.auffaelligkeiten[a].dokumente[ad].mediaID = newId;
              await storeAuditProjectSynced(liegenschaftId, project);
            }
          }
          //auffalligkeiten > bilder
          if (images && images.length > 0) {
            for (let ai = 0; ai < images.length; ai++) {
              const imgId = images[ai].mediaID;
              const newId = await uploadImage(imgLookup, imgId, projectId);
              //change stored id & save project
              project.payload.auffaelligkeiten[a].bilder[ai].mediaID = newId;
              await storeAuditProjectSynced(liegenschaftId, project);
            }
          }
        }
      }
      //condition Building
      if (
        pay.conditionBuilding &&
        pay.conditionBuilding.bilder &&
        pay.conditionBuilding.bilder.length > 0
      ) {
        setV_SyncDialogAction("Synchronisiere Medien aus Grunddaten...");
        for (let c = 0; c < pay.conditionBuilding.bilder.length; c++) {
          const img = pay.conditionBuilding.bilder[c];
          const newId = await uploadImage(imgLookup, img.mediaID, projectId);
          //change stored id & save project
          project.payload.conditionBuilding.bilder[c].mediaID = newId;
          await storeAuditProjectSynced(liegenschaftId, project);
        }
      }
      //fragebogen
      if (pay.questionaire) {
        setV_SyncDialogAction("Synchronisiere Medien aus Fragen...");
        //fragen auf root ebene
        if (pay.questionaire.fragen && pay.questionaire.fragen.length > 0) {
          for (let rq = 0; rq < pay.questionaire.fragen.length; rq++) {
            const frage = pay.questionaire.fragen[rq];
            if (frage && frage.ergebnis) {
              const documents = frage.ergebnis.dokumente;
              const images = frage.ergebnis.bilder;
              //auffalligkeiten > dokumente
              if (documents && documents.length > 0) {
                for (let rqd = 0; rqd < documents.length; rqd++) {
                  const docId = documents[rqd].mediaID;
                  const newId = await uploadDocument(
                    docLookup,
                    docId,
                    projectId
                  );
                  //change stored id & save project
                  project.payload.questionaire.fragen[rq].ergebnis.dokumente[
                    rqd
                  ].mediaID = newId;
                  await storeAuditProjectSynced(liegenschaftId, project);
                }
              }
              //auffalligkeiten > bilder
              if (images && images.length > 0) {
                for (let rqi = 0; rqi < images.length; rqi++) {
                  const imgId = images[rqi].mediaID;
                  const newId = await uploadImage(imgLookup, imgId, projectId);
                  //change stored id & save project
                  project.payload.questionaire.fragen[rq].ergebnis.bilder[
                    rqi
                  ].mediaID = newId;
                  await storeAuditProjectSynced(liegenschaftId, project);
                }
              }
            }
          }
        }
        if (
          pay.questionaire.kategorien &&
          pay.questionaire.kategorien.length > 0
        ) {
          //1st level categories
          for (let rc = 0; rc < pay.questionaire.kategorien.length; rc++) {
            const cat = pay.questionaire.kategorien[rc];
            if (cat.fragen && cat.fragen.length > 0) {
              for (let rcq = 0; rcq < cat.fragen.length; rcq++) {
                const frage = cat.fragen[rcq];
                if (frage && frage.ergebnis) {
                  const documents = frage.ergebnis.dokumente;
                  const images = frage.ergebnis.bilder;
                  //dokumente
                  if (documents && documents.length > 0) {
                    for (let rcqd = 0; rcqd < documents.length; rcqd++) {
                      const docId = documents[rcqd].mediaID;
                      const newId = await uploadDocument(
                        docLookup,
                        docId,
                        projectId
                      );
                      //change stored id & save project
                      pay.questionaire.kategorien[rc].fragen[
                        rcq
                      ].ergebnis.dokumente[rcqd].mediaID = newId;
                      await storeAuditProjectSynced(liegenschaftId, project);
                    }
                  }
                  //bilder
                  if (images && images.length > 0) {
                    for (let rcqi = 0; rcqi < images.length; rcqi++) {
                      const imgId = images[rcqi].mediaID;
                      const newId = await uploadImage(
                        imgLookup,
                        imgId,
                        projectId
                      );
                      //change stored id & save project
                      pay.questionaire.kategorien[rc].fragen[
                        rcq
                      ].ergebnis.bilder[rcqi].mediaID = newId;
                      await storeAuditProjectSynced(liegenschaftId, project);
                    }
                  }
                }
              }
            }
            if (cat.kategorien && cat.kategorien.length > 0) {
              //2nd level categories
              for (let rcc = 0; rcc < cat.kategorien.length; rcc++) {
                const cat2 = cat.kategorien[rcc];
                if (cat2.fragen && cat2.fragen.length > 0) {
                  for (let rccq = 0; rccq < cat2.fragen.length; rccq++) {
                    const frage = cat2.fragen[rccq];
                    if (frage && frage.ergebnis) {
                      const documents = frage.ergebnis.dokumente;
                      const images = frage.ergebnis.bilder;
                      //dokumente
                      if (documents && documents.length > 0) {
                        for (let rccqd = 0; rccqd < documents.length; rccqd++) {
                          const docId = documents[rccqd].mediaID;
                          const newId = await uploadDocument(
                            docLookup,
                            docId,
                            projectId
                          );
                          //change stored id & save project
                          pay.questionaire.kategorien[rc].kategorien[
                            rcc
                          ].fragen[rccq].ergebnis.dokumente[rccqd].mediaID =
                            newId;
                          await storeAuditProjectSynced(
                            liegenschaftId,
                            project
                          );
                        }
                      }
                      //bilder
                      if (images && images.length > 0) {
                        for (let rccqi = 0; rccqi < images.length; rccqi++) {
                          const imgId = images[rccqi].mediaID;
                          const newId = await uploadImage(
                            imgLookup,
                            imgId,
                            projectId
                          );
                          //change stored id & save project
                          pay.questionaire.kategorien[rc].kategorien[
                            rcc
                          ].fragen[rccq].ergebnis.bilder[rccqi].mediaID = newId;
                          await storeAuditProjectSynced(
                            liegenschaftId,
                            project
                          );
                        }
                      }
                    }
                  }
                }
                if (cat2.kategorien && cat2.kategorien.length > 0) {
                  //3rd level categories
                  for (let rccc = 0; rccc < cat2.kategorien.length; rccc++) {
                    const cat3 = cat2.kategorien[rccc];
                    if (cat3.fragen && cat3.fragen.length > 0) {
                      for (let rcccq = 0; rcccq < cat3.fragen.length; rcccq++) {
                        const frage = cat3.fragen[rcccq];
                        if (frage && frage.ergebnis) {
                          const documents = frage.ergebnis.dokumente;
                          const images = frage.ergebnis.bilder;
                          //dokumente
                          if (documents && documents.length > 0) {
                            for (
                              let rcccqd = 0;
                              rcccqd < documents.length;
                              rcccqd++
                            ) {
                              const docId = documents[rcccqd].mediaID;
                              const newId = await uploadDocument(
                                docLookup,
                                docId,
                                projectId
                              );
                              //change stored id & save project
                              pay.questionaire.kategorien[rc].kategorien[
                                rcc
                              ].kategorien[rccc].fragen[
                                rcccq
                              ].ergebnis.dokumente[rcccqd].mediaID = newId;
                              await storeAuditProjectSynced(
                                liegenschaftId,
                                project
                              );
                            }
                          }
                          //bilder
                          if (images && images.length > 0) {
                            for (
                              let rcccqi = 0;
                              rcccqi < images.length;
                              rcccqi++
                            ) {
                              const imgId = images[rcccqi].mediaID;
                              const newId = await uploadImage(
                                imgLookup,
                                imgId,
                                projectId
                              );
                              //change stored id & save project
                              pay.questionaire.kategorien[rc].kategorien[
                                rcc
                              ].kategorien[rccc].fragen[rcccq].ergebnis.bilder[
                                rcccqi
                              ].mediaID = newId;
                              await storeAuditProjectSynced(
                                liegenschaftId,
                                project
                              );
                            }
                          }
                        }
                      }
                    }
                    if (cat3.kategorien && cat3.kategorien.length > 0) {
                      //4st level categories
                      for (
                        let rcccc = 0;
                        rcccc < cat3.kategorien.length;
                        rcccc++
                      ) {
                        const cat4 = cat3.kategorien[rcccc];
                        if (cat4.fragen && cat4.fragen.length > 0) {
                          for (
                            let rccccq = 0;
                            rccccq < cat4.fragen.length;
                            rccccq++
                          ) {
                            const frage = cat4.fragen[rccccq];
                            if (frage && frage.ergebnis) {
                              const documents = frage.ergebnis.dokumente;
                              const images = frage.ergebnis.bilder;
                              //dokumente
                              if (documents && documents.length > 0) {
                                for (
                                  let rccccqd = 0;
                                  rccccqd < documents.length;
                                  rccccqd++
                                ) {
                                  const docId = documents[rccccqd].mediaID;
                                  const newId = await uploadDocument(
                                    docLookup,
                                    docId,
                                    projectId
                                  );
                                  //change stored id & save project
                                  pay.questionaire.kategorien[rc].kategorien[
                                    rcc
                                  ].kategorien[rccc].kategorien[rcccc].fragen[
                                    rccccq
                                  ].ergebnis.dokumente[rccccqd].mediaID = newId;
                                  await storeAuditProjectSynced(
                                    liegenschaftId,
                                    project
                                  );
                                }
                              }
                              //bilder
                              if (images && images.length > 0) {
                                for (
                                  let rccccqi = 0;
                                  rccccqi < images.length;
                                  rccccqi++
                                ) {
                                  const imgId = images[rccccqi].mediaID;
                                  const newId = await uploadImage(
                                    imgLookup,
                                    imgId,
                                    projectId
                                  );
                                  //change stored id & save project
                                  pay.questionaire.kategorien[rc].kategorien[
                                    rcc
                                  ].kategorien[rccc].kategorien[rcccc].fragen[
                                    rccccq
                                  ].ergebnis.bilder[rccccqi].mediaID = newId;
                                  await storeAuditProjectSynced(
                                    liegenschaftId,
                                    project
                                  );
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const uploadImage = (imageLookup, imgId, projectId) => {
    //returning the new docId
    return new Promise((resolve, reject) => {
      if (!checkIfValidUUID(imgId)) {
        try {
          //need to upload
          const imgMetaEntry = imageLookup.get(imgId);
          //get the filename from the url
          const url =
            imgMetaEntry &&
            imgMetaEntry.versions &&
            imgMetaEntry.versions[0] &&
            imgMetaEntry.versions[0].url
              ? imgMetaEntry.versions[0].url
              : null;
          const title = imgMetaEntry.title;
          const filename = url ? url.split("/").pop() : "OFFLINE_IMG";
          const oldId = imgMetaEntry.id;
          //get file as blob
          readFileAsBlob(liegenschaftId, projectId, oldId, filename)
            .then((blob) => {
              //upload file to server
              uploadImageToServer(blob, title, filename)
                .then((response) => {
                  renameFolderOnDisk(
                    liegenschaftId,
                    projectId,
                    oldId,
                    response.id
                  )
                    .then(() => {
                      //FIXME add to media-lookup
                      resolve(response.id);
                    })
                    .catch(() => {
                      reject();
                    });
                })
                .catch(() => {
                  reject();
                });
            })
            .catch(() => {
              reject();
            });
        } catch (e) {
          reject();
        }
      } else {
        resolve(imgId);
        //this entry is known yet
      }
    });
  };

  const uploadDocument = (documentLookup, docId, projectId) => {
    //returning the new docId
    return new Promise((resolve, reject) => {
      if (!checkIfValidUUID(docId)) {
        try {
          //need to upload
          const docMetaEntry = documentLookup.get(docId);
          const title = docMetaEntry.title;
          const filename = docMetaEntry.filename;
          const oldId = docMetaEntry.id;
          //get file as blob
          readFileAsBlob(liegenschaftId, projectId, oldId, filename)
            .then((blob) => {
              //upload file to server
              uploadDocumentToServer(blob, title, filename)
                .then((response) => {
                  renameFolderOnDisk(
                    liegenschaftId,
                    projectId,
                    oldId,
                    response.id
                  )
                    .then(() => {
                      //FIXME add to media-lookup
                      resolve(response.id);
                    })
                    .catch(() => {
                      reject();
                    });
                })
                .catch(() => {
                  reject();
                });
            })
            .catch(() => {
              reject();
            });
        } catch (e) {
          reject();
        }
      } else {
        resolve(docId);
        //this entry is known yet
      }
    });
  };

  function download(itemId) {
    if (runsOnMobile()) {
      setV_SyncDialogPending(true);
      setV_SyncDialogError();
      setV_SyncDialogAction("Projekt wird erstellt...");
      createProjectOnDisk(
        liegenschaftId,
        itemId,
        () => {
          //success
          for (let i = 0; i < props.allLiegenschaftenShort.length; i++) {
            const entry = props.allLiegenschaftenShort[i];
            if (entry.id === liegenschaftId) {
              //store liegenschaft in file
              setV_SyncDialogAction("Liegenschaft wird gespeichert...");
              storeLiegenschaftInProjectOnDisk(
                itemId,
                entry,
                () => {
                  //success
                  setV_SyncDialogAction("Nutzerdaten werden gespeichert...");
                  storeUserInProjectOnDisk(
                    liegenschaftId,
                    itemId,
                    props.currentUser,
                    () => {
                      //success => store project in file
                      setV_SyncDialogAction(
                        "Audit-Projekt wird gespeichert..."
                      );
                      //search for project
                      let auditProject = null;
                      for (let i = 0; i < allAudits.length; i++) {
                        if (allAudits[i].id === itemId) {
                          auditProject = allAudits[i];
                          break;
                        }
                      }
                      storeAuditInProjectOnDisk(
                        liegenschaftId,
                        auditProject,
                        () => {
                          //success
                          setV_SyncDialogAction(
                            "Medien werden heruntergeladen..."
                          );
                          //extract all media for download
                          const mediaIds = extractAllMediaIdsForConverting(
                            auditProject.payload
                          );
                          translateMediaIds(mediaIds ? mediaIds : [])
                            .then((data) => {
                              downloadMediaToDevice(
                                data,
                                itemId,
                                function success(mediaInfo) {
                                  setV_SyncDialogAction(
                                    "Medien werden gespeichert..."
                                  );
                                  storeMediaInProjectOnDisk(
                                    liegenschaftId,
                                    itemId,
                                    mediaInfo,
                                    () => {
                                      //success => ensure that user is maintainer
                                      setV_SyncDialogAction(
                                        "Schreibrechte werden beantragt..."
                                      );
                                      ensureUserIsMaintainer(
                                        auditProject.id,
                                        props.currentUser.id,
                                        props.currentUser.userFirstname,
                                        props.currentUser.userLastname,
                                        props.currentUser.username
                                      )
                                        .then((maintainer) => {
                                          //success => get lock for this maintainer
                                          setV_SyncDialogAction(
                                            "Projekt wird für andere Nutzer gesperrt..."
                                          );
                                          createLockForMaintainer(
                                            auditProject.id,
                                            maintainer.id,
                                            false
                                          )
                                            .then((data) => {
                                              //success
                                              setV_SyncDialogAction(
                                                "Daten werden gespeichert..."
                                              );
                                              storeLockInProjectOnDisk(
                                                liegenschaftId,
                                                auditProject.id,
                                                data,
                                                () => {
                                                  //it´s finally done
                                                  setV_SyncDialogPending(false);
                                                  setV_SyncDialogError();
                                                  setV_SyncDialog(false);
                                                  //reload
                                                  setCurrentData(null);
                                                },
                                                () => {
                                                  //error
                                                  setV_SyncDialogPending(false);
                                                  setV_SyncDialogError(
                                                    "Speicherung der Lock-Datei ist leider fehlgeschlagen."
                                                  );
                                                }
                                              );
                                            })
                                            .catch(() => {
                                              setV_SyncDialogPending(false);
                                              setV_SyncDialogError(
                                                "Sperren des Projekts für andere Nutzer ist leider fehlgeschlagen."
                                              );
                                            });
                                        })
                                        .catch(() => {
                                          setV_SyncDialogPending(false);
                                          setV_SyncDialogError(
                                            "Beantragung der Schreibrechte ist leider fehlgeschlagen."
                                          );
                                        });
                                    },
                                    () => {
                                      //error
                                      setV_SyncDialogPending(false);
                                      setV_SyncDialogError(
                                        "Das Speichern verknüpfter Medien ist leider fehlgeschlagen."
                                      );
                                    }
                                  );
                                },
                                () => {
                                  //error
                                  setV_SyncDialogPending(false);
                                  setV_SyncDialogError(
                                    "Das Herunterladen verknüpfter Medien ist leider fehlgeschlagen."
                                  );
                                }
                              );
                            })
                            .catch(() => {
                              setV_SyncDialogPending(false);
                              setV_SyncDialogError(
                                "Das Herunterladen verknüpfter Medien ist leider fehlgeschlagen."
                              );
                            });
                        },
                        () => {
                          //error
                          setV_SyncDialogPending(false);
                          setV_SyncDialogError(
                            "Das Projekt konnte leider nicht gespeichert werden."
                          );
                        }
                      );
                    },
                    () => {
                      //error
                      setV_SyncDialogPending(false);
                      setV_SyncDialogError(
                        "Die Nutzerdaten konnten leider nicht gespeichert werden."
                      );
                    }
                  );
                },
                () => {
                  //error
                  setV_SyncDialogPending(false);
                  setV_SyncDialogError(
                    "Die Liegenschaft konnte leider nicht gespeichert werden."
                  );
                }
              );
              break;
            }
          }
        },
        function error() {
          setV_SyncDialogPending(false);
          setV_SyncDialogError("Projekt konnte leider nicht erstellt werden.");
        }
      );
    }
  }

  function downloadMediaToDevice(data, projectId, onSuccess, onError) {
    if (data && data.length > 0) {
      const mediaInfo = [];
      let sendSuccess = false;
      for (let i = 0; i < data.length; i++) {
        const entry = data[i];
        if (entry.document) {
          const src = URL_TO_MEDIA_SERVER + entry.document.url;
          const secret = entry.document.access;
          downloadDocumentFromServer(src, secret)
            // eslint-disable-next-line no-loop-func
            .then((blob) => {
              //store this entry
              storeMediaFromBlobOnAndroidInProject(
                liegenschaftId,
                projectId,
                entry.document.id,
                blob,
                entry.document.filename,
                function success(uri) {
                  //store adapted media entry
                  const entryClone = _.cloneDeep(entry);
                  entryClone.document.url = Capacitor.convertFileSrc(uri);
                  entryClone.document.access = null;
                  entryClone.document.mimetype = blob.type;
                  mediaInfo.push(entryClone);
                  //if this was the last entry to save => fire success callback
                  if (mediaInfo.length === data.length) {
                    sendSuccess = true;
                    if (onSuccess) {
                      onSuccess(mediaInfo);
                    }
                  }
                },
                function error() {
                  if (onError && !sendSuccess) {
                    onError();
                  }
                }
              );
            })
            .catch((error) => {
              if (onError) {
                onError();
              }
            });
        } else if (entry.image) {
          let version = null;
          for (let i = 0; i < entry.image.versions.length; i++) {
            const v = entry.image.versions[i];
            //default to first version
            if (!version) {
              version = v;
            }
            //search for pic in download-size
            if (v && v.vLabel) {
              if (v.vLabel === DEFAULT_IMAGE_DOWNLOAD_SIZE) {
                version = v;
              }
            }
          }
          if (entry.image && version) {
            const src = URL_TO_MEDIA_SERVER + version.url;
            const secret = version.access;
            downloadDocumentFromServer(src, secret)
              // eslint-disable-next-line no-loop-func
              .then((blob) => {
                //store this entry
                storeMediaFromBlobOnAndroidInProject(
                  liegenschaftId,
                  projectId,
                  entry.image.id,
                  blob,
                  entry.image.filename,
                  function success(uri) {
                    //store adapted media entry
                    const entryClone = _.cloneDeep(entry);
                    const versionClone = _.cloneDeep(version);
                    versionClone.url = Capacitor.convertFileSrc(uri);
                    versionClone.access = null;
                    entryClone.image.mimetype = blob.type;
                    entryClone.image.versions = [];
                    entryClone.image.versions.push(versionClone);
                    mediaInfo.push(entryClone);
                    //if this was the last entry to save => fire success callback
                    if (mediaInfo.length === data.length) {
                      sendSuccess = true;
                      if (onSuccess) {
                        onSuccess(mediaInfo);
                      }
                    }
                  },
                  function error() {
                    if (onError && !sendSuccess) {
                      onError();
                    }
                  }
                );
              })
              .catch((error) => {
                if (onError) {
                  onError();
                }
              });
          }
        }
      }
    } else {
      //there is nothing to download for media
      if (onSuccess) {
        onSuccess();
      }
    }
  }

  function viewProject(itemId) {
    if (itemId && liegenschaftId) {
      if (props.onlineAccessPossible && sessionStorage.getItem("rT") !== null) {
        props.history.push({
          pathname: PATH_ONLINE_AUDIT,
          search: "?aid=" + itemId + "&lid=" + liegenschaftId,
        });
      }
    }
  }

  function runProject(itemId) {
    if (itemId && liegenschaftId) {
      if (props.onlineAccessPossible && sessionStorage.getItem("rT") !== null) {
        props.history.push({
          pathname: PATH_ONLINE_AUDIT,
          search: "?aid=" + itemId + "&lid=" + liegenschaftId + "&adp=" + true,
        });
      }
    }
  }

  function viewVersion(itemId) {
    const auditId = versionToProjectLookup
      ? versionToProjectLookup.get(itemId)
      : null;
    if (auditId && itemId && liegenschaftId) {
      if (props.onlineAccessPossible && sessionStorage.getItem("rT") !== null) {
        props.history.push({
          pathname: PATH_ONLINE_AUDIT,
          search:
            "?aid=" + auditId + "&lid=" + liegenschaftId + "&sid=" + itemId,
        });
      }
    }
  }

  function addEntry() {
    if (props.onlineAccessPossible && sessionStorage.getItem("rT") !== null) {
      props.history.push({
        pathname: "/setup/audit",
        search: "?lid=" + liegenschaftId,
      });
    }
  }

  const prepareDataForPresentation = () => {
    if (allAudits !== null && allAudits !== undefined && !currentData) {
      //stop rendering
      setCurrentData([]);
      //search for liegenschaft-title
      let entry = _.find(props.allLiegenschaftenShort, function (o) {
        return o.id === liegenschaftId;
      });
      setLTitle(entry ? entry.name : null);
      //check if run on mobile or not
      if (runsOnMobile()) {
        const storageLookup = new Map();
        for (let i = 0; i < allAudits.length; i++) {
          checkExistenceOfProjectOnDisk(
            liegenschaftId,
            allAudits[i].id,
            function success() {
              storageLookup.set(allAudits[i].id, true);
              //fire proceeding after last callback
              if (storageLookup.size === allAudits.length) {
                createPayload(storageLookup);
              }
            },
            function error() {
              storageLookup.set(allAudits[i].id, false);
              //fire proceeding after last callback
              if (storageLookup.size === allAudits.length) {
                createPayload(storageLookup);
              }
            }
          );
        }
      } else {
        createPayload(null);
      }
    }
  };

  function createPayload(optionalStorageProjectLookup) {
    let payload = [];
    //add all loaded audits
    const versionLookup = new Map();
    for (let i = 0; i < allAudits.length; i++) {
      const entry = allAudits[i];
      const maintainer = entry ? entry.maintainer : null;
      const auditor = entry.payload ? entry.payload.auditor : "";
      const dlKurz =
        entry.payload && entry.payload.dienstleister
          ? entry.payload.dienstleister.dienstleisterKurzname
          : "";
      const locks = [];
      if (maintainer && maintainer.length > 0) {
        for (let j = 0; j < maintainer.length; j++) {
          const person = maintainer[j];
          //search for active locks from other users
          if (person) {
            if (props.currentUser && props.currentUser.id !== person.userId) {
              const fName = person.userFirstname;
              const lName = person.userLastname;
              if (person.locks && person.locks.length > 0) {
                for (let x = 0; x < person.locks.length; x++) {
                  const lock = person.locks[x];
                  if (
                    lock &&
                    lock.createdAt !== null &&
                    lock.releasedAt === null
                  ) {
                    const online = lock.onlineLock ? "" : "(heruntergeladen)";
                    locks.push(fName + " " + lName + " " + online);
                  }
                }
              }
            } else {
              if (person.locks && person.locks.length > 0) {
                for (let x = 0; x < person.locks.length; x++) {
                  const lock = person.locks[x];
                  if (
                    lock &&
                    lock.createdAt !== null &&
                    lock.releasedAt === null
                  ) {
                    const online = lock.onlineLock ? "" : "(heruntergeladen)";
                    locks.push("Sie " + online);
                  }
                }
              }
            }
          }
        }
      }
      const snapshots = [];
      if (entry.snapshots) {
        for (let x = 0; x < entry.snapshots.length; x++) {
          const snap = entry.snapshots[x];
          if (snap && !snap.deleted) {
            snapshots.push({
              id: snap.id,
              title: snap.versionName,
              createdAt: new Date(snap.createdAt),
            });
            versionLookup.set(snap.id, entry.id);
          }
        }
      }
      setVersionToProjectLookup(versionLookup);
      let data = {
        id: entry.id,
        progress: calculateProgress(entry),
        type:
          entry.payload &&
          entry.payload.questionaire &&
          entry.payload.questionaire.questionaireName
            ? entry.payload.questionaire.questionaireName
            : entry.templateId,
        title: entry.title,
        lastChange: new Date(
          entry.lastUpdate ? entry.lastUpdate : entry.createdAt
        ),
        auditor: auditor,
        dienstleister: dlKurz,
        rating: entry.rating,
        contractAmount: entry.contractAmount,
        lockedBy: locks && locks.length > 0 ? locks : null,
        localversion: optionalStorageProjectLookup
          ? optionalStorageProjectLookup.get(entry.id)
          : false,
        versions: snapshots.length > 0 ? snapshots : null,
      };
      payload.push(data);
    }
    setCurrentData(payload);
  }

  function calculateProgress(project) {
    if (project) {
      const pay = createCategoryTree(project);
      if (pay && pay.length > 0) {
        const data = pay[0].data;
        const progress = data.progress ? data.progress : 0;
        const questions = data.questions ? data.questions : 1;
        const completion = progress / questions;
        return Math.round(completion * 100);
      }
    }
    return 0;
  }

  const injectLogoutConfirmation = () => {
    return (
      <BeeConfirmDialog
        visible={v_LogoutDialog}
        message={"Möchten Sie sich wirklich ausloggen?"}
        header={"Logout bestätigen"}
        type={"default"}
        onAccept={() => {
          removeAllOnlinePendingLocksForUserId();
          props.logoutUser();
          setV_LogoutDialog(false);
        }}
        onReject={() => setV_LogoutDialog(false)}
        onHide={() => setV_LogoutDialog(false)}
        acceptLabel={"Ausloggen"}
        rejectLabel={"Abbrechen"}
      />
    );
  };

  const injectOfflineConfirmation = () => {
    return (
      <BeeConfirmDialog
        visible={v_OfflineDialog}
        message={
          "Um ein Audit offline bearbeiten zu können laden Sie es bitte zunächst herunter. Möchten Sie wirklich in den Offline-Modus wechseln?"
        }
        header={"Offline-Modus starten"}
        type={"default"}
        onAccept={() => {
          removeAllOnlinePendingLocksForUserId();
          props.logoutUser();
          setV_OfflineDialog(false);
        }}
        onReject={() => setV_OfflineDialog(false)}
        onHide={() => setV_OfflineDialog(false)}
        acceptLabel={"Offline Arbeiten"}
        rejectLabel={"Abbrechen"}
      />
    );
  };

  const injectSyncDialog = () => {
    return (
      <BeeAuditDialogSyncProject
        type={"primary"}
        usedForDownload={fileDownload}
        visible={v_SyncDialog}
        showProgress={v_SyncDialogPending}
        errorMsg={v_SyncDialogError}
        actionMsg={v_SyncDialogAction}
        onHide={() => {
          setV_SyncDialog(false);
          //reload page
          setCurrentData(null);
        }}
        onPerformAction={() => {
          if (fileDownload) {
            download(selectedProjectId);
          } else {
            upload(selectedProjectId);
          }
        }}
      />
    );
  };

  const injectNotificationToast = () => {
    return (
      <BeeToast id={"bee-audit-ui-toast"} ref={toast} position={"top-right"} />
    );
  };

  return (
    <div>
      {prepareDataForPresentation()}
      <BeeAuditSelect
        liegenschaftName={lTitle}
        homeUrl={breadCrumbHome}
        breadcrumbData={breadCrumbAuditSelect}
        readOnly={false}
        data={currentData}
        syncPossible={runsOnMobile()}
        showProgress={allAuditsPending}
        showOffline={runsOnMobile()}
        errorMsg={allAuditsError ? "Die Abfrage ist fehlgeschlagen." : null}
        onViewVersion={(itemId) => viewVersion(itemId)}
        onViewProject={(itemId) => viewProject(itemId)}
        onRunProject={(itemId) => runProject(itemId)}
        onDownload={(itemId) => {
          if (runsOnMobile()) {
            setFileDownload(true);
            setSelectedProjectId(itemId);
            setV_SyncDialogError(null);
            setV_SyncDialogPending(false);
            setV_SyncDialog(true);
          }
        }}
        onUpload={(itemId) => {
          if (runsOnMobile()) {
            setFileDownload(false);
            setSelectedProjectId(itemId);
            setV_SyncDialogError(null);
            setV_SyncDialogPending(false);
            setV_SyncDialog(true);
          }
        }}
        onWorkOffline={() => setV_OfflineDialog(true)}
        onLogout={() => setV_LogoutDialog(true)}
        onAddAudit={() => addEntry()}
      />
      {v_LogoutDialog ? injectLogoutConfirmation() : null}
      {v_OfflineDialog ? injectOfflineConfirmation() : null}
      {v_SyncDialog ? injectSyncDialog() : null}
      {injectNotificationToast()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    onlineAccessPossible: state.app.onlineAccessPossible,
    allLiegenschaftenShort: state.app.allLiegenschaftenShort,
    currentUser: state.app.currentUserData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlineAuditSelect);
