import {
  PATH_OFFLINE_OVERVIEW,
  PATH_ONLINE_OVERVIEW_AUDIT,
  PATH_ONLINE_OVERVIEW_LIEGENSCHAFTEN,
} from "./constants";

export const breadCrumbOfflineHome = PATH_OFFLINE_OVERVIEW;
export const breadCrumbHome = PATH_ONLINE_OVERVIEW_LIEGENSCHAFTEN;

export const breadCrumbLiegenschaftOverview = [
  {
    label: "Liegenschaften",
  },
];

export const breadCrumbOfflineOverview = [
  {
    label: "Projekte",
  },
];

export const createBreadCrumbLiegenschaftSetup = (setupMode) => {
  return [
    {
      label: "Liegenschaften",
      url: PATH_ONLINE_OVERVIEW_LIEGENSCHAFTEN,
    },
    {
      label: setupMode ? "Erstellen" : "Anpassen",
    },
  ];
};

export const breadCrumbAuditSelect = [
  {
    label: "Liegenschaften",
    url: PATH_ONLINE_OVERVIEW_LIEGENSCHAFTEN,
  },
  {
    label: "Audits",
  },
];

export const createBreadCrumbAuditSetup = (liegenschaftId) => {
  return [
    {
      label: "Liegenschaften",
      url: PATH_ONLINE_OVERVIEW_LIEGENSCHAFTEN,
    },
    {
      label: "Audits",
      url: PATH_ONLINE_OVERVIEW_AUDIT + "?lid=" + liegenschaftId,
    },
    {
      label: "Erstellen",
    },
  ];
};

export const createBreadCrumbAudit = (liegenschaftId, title, snapshotTitle) => {
  return [
    {
      label: "Liegenschaften",
      url: PATH_ONLINE_OVERVIEW_LIEGENSCHAFTEN,
    },
    {
      label: "Audits",
      url: PATH_ONLINE_OVERVIEW_AUDIT + "?lid=" + liegenschaftId,
    },
    {
      label:
        !snapshotTitle && title
          ? '"' + title + '"'
          : '"' + title + " - " + snapshotTitle + '"',
    },
  ];
};
export const createOfflineBreadCrumbAudit = (liegenschaftTitle, auditTitle) => {
  return [
    {
      label: "Projekte",
      url: PATH_OFFLINE_OVERVIEW,
    },
    {
      label:
        !auditTitle && liegenschaftTitle
          ? '"' + liegenschaftTitle + '"'
          : '"' + liegenschaftTitle + " - " + auditTitle + '"',
    },
  ];
};
