import React, { useState, useEffect, useRef } from "react";
import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import "./BeeAuditLvTreetable.scss";
import BeeAuditLvContract from "../Fragments/BeeAuditLvContract";
import BeeAuditLvQuantity from "../Fragments/BeeAuditLvQuantity";
import BeeAuditLvQuality from "../Fragments/BeeAuditLvQuality";
import BeeAuditLvComment from "../Fragments/BeeAuditLvComment";
import {
  BeeCurrencyInput,
  BeeNumberInput,
  BeeTextInput,
} from "bee-atomic-components";
import { CURRENCY, LOCALE } from "../../Helper/constants";
import _ from "lodash";

function BeeAuditLvTreetable({
  lvdata,
  readOnly,
  username,
  sumMeanReduction,
  sumGP,
  qualitySumGPadapted,
  quantitySumGPadapted,
  onClickAuffalligkeiten,
  onAddPositionToCategory,
  onRemovePositionFromCategory,
  onRestorePosition,
  onEntryChange,
}) {
  //expanded col
  const EXPANDED_QUANTITY = 1;
  const EXPANDED_QUALITY = 2;
  const EXPANDED_COMMENT = 3;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [expandedCol, setExpandedCol] = useState(EXPANDED_QUANTITY);
  const [selectedRow, setSelectedRow] = useState(null);
  let itemsRef = useRef([]);
  // Make it empty at every render cycle as we will get the full list of it at the end of the render cycle
  itemsRef.current = []; // or an {}

  useEffect(() => {
    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  //windowsizes
  const lg = 1200;
  const md = 1100;
  const sm = 550;

  function addPositionToCategory(id) {
    if (!readOnly && onAddPositionToCategory) {
      onAddPositionToCategory(id);
    }
  }

  function removePositionFromCategory(id, entry) {
    if (!readOnly && onRemovePositionFromCategory) {
      onRemovePositionFromCategory(id, entry);
    }
  }

  function restorePosition(id, entry) {
    if (!readOnly && onRestorePosition) {
      onRestorePosition(id, entry);
    }
  }

  function adaptEntry(id, payload) {
    if (!readOnly && onEntryChange) {
      onEntryChange(id, payload);
    }
  }

  function contractHeaderTemplate() {
    return (
      <div>
        <table className={"contract-col-header"}>
          <tbody>
            <tr>
              <td className={"col-name-td"}>
                <BeeTextInput
                  label=""
                  value={"Leistungsverzeichnis Vertrag"}
                  disabled={true}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
              <td className={"contract-col-header-sumGP"}>
                <BeeCurrencyInput
                  label={null}
                  value={sumGP}
                  disabled={false}
                  minFractionDigits={0}
                  maxFractionDigits={3}
                  currency={CURRENCY}
                  locale={LOCALE}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
              <td className={"contract-header-platzhalter"}></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  function quantityHeaderTemplate() {
    return (
      <div onClick={() => setExpandedCol(EXPANDED_QUANTITY)}>
        <table>
          <tbody>
            <tr>
              <td className={"quantity-col-header-text"}>
                <BeeTextInput
                  label=""
                  value={"Leistungsabgleich quantitativ"}
                  disabled={true}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
              <td className={"quantity-col-header-sumGP"}>
                <BeeCurrencyInput
                  label={
                    expandedCol !== EXPANDED_QUANTITY ? "Leistungsabgleich" : ""
                  }
                  value={quantitySumGPadapted}
                  disabled={false}
                  minFractionDigits={0}
                  maxFractionDigits={3}
                  currency={CURRENCY}
                  locale={LOCALE}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  function qualityHeaderTemplate() {
    return (
      <div onClick={() => setExpandedCol(EXPANDED_QUALITY)}>
        <table>
          <tbody>
            <tr>
              <td className={"quality-col-header-text"}>
                <BeeTextInput
                  label=""
                  value={"Leistungserfüllungsgrad"}
                  disabled={true}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
              <td className={"quality-col-header-meanReduction"}>
                <BeeNumberInput
                  label={"%"}
                  value={sumMeanReduction}
                  minFractionDigits={0}
                  maxFractionDigits={0}
                  stepSize={1}
                  disabled={false}
                  grouping={false}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
              <td className={"quality-col-header-sumGP"}>
                <BeeCurrencyInput
                  label={
                    expandedCol !== EXPANDED_QUALITY ? "Leistungserfüllung" : ""
                  }
                  value={qualitySumGPadapted}
                  disabled={false}
                  minFractionDigits={0}
                  maxFractionDigits={3}
                  currency={CURRENCY}
                  locale={LOCALE}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  function commentHeaderTemplate() {
    return (
      <div
        className={"comment-col-header-text"}
        onClick={() => setExpandedCol(EXPANDED_COMMENT)}
      >
        <BeeTextInput
          label=""
          value={"Diskussion"}
          disabled={true}
          formstate={"neutral"}
          readOnly={true}
          required={false}
          onChange={null}
        />
      </div>
    );
  }

  const contractTemplate = (node, column) => {
    const entry = _.cloneDeep(node);
    const id = node.key;
    const replacedId = node.key.split(".").join("-");
    if (entry && entry.data) {
      return (
        <div
          ref={(el) => {
            let refToElement = _.find(itemsRef.current, { id: id });
            if (el && !refToElement) {
              itemsRef.current.push({ id: id, element: el });
            } else if (el) {
              refToElement.element = el;
            }
          }}
          id={replacedId}
        >
          <BeeAuditLvContract
            key={id}
            data={entry.data}
            readOnly={readOnly}
            onDelete={(data) => removePositionFromCategory(id, data)}
            onAdd={() => addPositionToCategory(id)}
            onRestore={(data) => restorePosition(id, data)}
          />
        </div>
      );
    }
  };

  const quantityTemplate = (node, column) => {
    const entry = _.cloneDeep(node);
    const id = node.key;
    if (entry && entry.data) {
      return (
        <div
          key={id + "_quant"}
          onClick={() => {
            setExpandedCol(EXPANDED_QUANTITY);
          }}
        >
          <BeeAuditLvQuantity
            data={entry.data}
            readOnly={readOnly}
            onChange={(val) => adaptEntry(id, val)}
          />
        </div>
      );
    }
  };

  const qualityTemplate = (node, column) => {
    const entry = _.cloneDeep(node);
    const id = node.key;
    if (entry && entry.data) {
      return (
        <div
          key={id + "_qual"}
          onClick={() => {
            setExpandedCol(EXPANDED_QUALITY);
          }}
        >
          <BeeAuditLvQuality
            data={entry.data}
            readOnly={readOnly}
            onChange={(val) => adaptEntry(id, val)}
          />
        </div>
      );
    }
  };

  const commentTemplate = (node, column) => {
    const entry = _.cloneDeep(node);
    const id = node.key;
    if (entry && entry.data) {
      return (
        <div
          key={id + "_com"}
          onClick={() => {
            setExpandedCol(EXPANDED_COMMENT);
          }}
        >
          <BeeAuditLvComment
            data={entry.data}
            username={username}
            collapsed={expandedCol === EXPANDED_COMMENT ? true : false}
            readOnly={readOnly}
            onChange={(val) => adaptEntry(id, val)}
          />
        </div>
      );
    }
  };

  const rowClassName = (node) => {
    return { rowColored: node.key === selectedRow };
  };

  function clickRow(e) {
    const element = _.find(itemsRef.current, { id: e.node.key });

    if (element && element.element) {
      element.element.scrollIntoView({
        block: "center",
        inline: "center",
      });
    }
  }

  return (
    <>
      <TreeTable
        value={lvdata}
        autoLayout
        selectionMode={null}
        rowClassName={rowClassName}
        onRowClick={(e) => {
          setSelectedRow(e.node.key);
          clickRow(e);
        }}
        className={
          windowWidth < md
            ? "bee-audit-lv-treetable sm"
            : windowWidth < lg
            ? "bee-audit-lv-treetable md"
            : "bee-audit-lv-treetable lg"
        }
      >
        <Column
          body={contractTemplate}
          header={contractHeaderTemplate()}
          columnKey="lv-contract-pos"
          className={"contract-col"}
          expander
        />
        <Column
          body={quantityTemplate}
          header={quantityHeaderTemplate()}
          columnKey="lv-headl-pos"
          className={
            expandedCol === EXPANDED_QUANTITY
              ? "quantity-col expanded"
              : "quantity-col collapsed"
          }
          style={
            expandedCol === EXPANDED_QUANTITY
              ? {
                  "--widthQuantity": "40%",
                }
              : {
                  "--widthQuantity": "10em",
                }
          }
        />
        <Column
          body={qualityTemplate}
          header={qualityHeaderTemplate()}
          columnKey="lv-quality-pos"
          className={
            expandedCol === EXPANDED_QUALITY
              ? "quality-col expanded"
              : "quality-col collapsed"
          }
          style={
            expandedCol === EXPANDED_QUALITY
              ? {
                  "--widthQuality": "40%",
                }
              : {
                  "--widthQuality": "17em",
                }
          }
        />
        <Column
          body={commentTemplate}
          header={commentHeaderTemplate()}
          columnKey="lv-comment-pos"
          className={
            expandedCol === EXPANDED_COMMENT
              ? "comment-col expanded"
              : "comment-col collapsed"
          }
          style={
            expandedCol === EXPANDED_COMMENT
              ? {
                  "--widthComment": "40%",
                }
              : {
                  "--widthComment": "8em",
                }
          }
        />
      </TreeTable>
    </>
  );
}

export default BeeAuditLvTreetable;
