import React from "react";
import { useId } from "react-id-generator";
import BeeAuditHeader from "../Fragments/BeeAuditHeader";
import BeeAuditTabs from "../Fragments/BeeAuditTabs";
import BeeAuditInputGrunddaten from "../Fragments/BeeAuditInputGrunddaten";
import BeeAuditStakeholderTable from "../Fragments/BeeAuditStakeholderTable";
import BeeAuditSetupLeistungsubersicht from "../Fragments/BeeAuditSetupLeistungsubersicht";
import {
  BeeOutlinedButton,
  BeeMixButton,
  BeeContentHeadline,
  BeeDocumentTable,
  BeeContentParagraph,
  BeeFlatImageGallery,
  BeeTextArea,
} from "bee-atomic-components";
import "./BeeAuditData.scss";
import BeeAuditBasisTable from "../Fragments/BeeAuditBasisTable";
import { runsOnWeb } from "../../Helper/CapacitorHelper";

export function BeeAuditData({
  readOnly,
  //breadcrumb
  homeUrl,
  breadcrumbData,
  //header
  progress,
  maxProgress,
  viewOptions,
  selectedView,
  warningMessage,
  //grunddaten
  auditTitle,
  auditNumber,
  auditReportDate,
  auditDate,
  auditAuditor,
  auditDescription,
  auditServiceProvider,
  auditServiceProviderShort,
  auditAuthority,
  isAdaptedTitle,
  isAdaptedNumber,
  isAdaptedReportDate,
  isAdaptedAuditDate,
  isAdaptedAuditor,
  isAdaptedDescription,
  isAdaptedServiceProvider,
  isAdaptedServiceProviderShort,
  isAdaptedAuthority,
  isErrorTitle,
  isErrorNumber,
  isErrorReportDate,
  isErrorAuditDate,
  isErrorAuditor,
  isErrorDescription,
  isErrorServiceProvider,
  isErrorServiceProviderShort,
  isErrorAuthority,
  //tabellen
  documents,
  stakeholders,
  basis,
  leistungsSetup,
  isCurrencySetup,
  //eindruck gebäude
  conditionBuildingImgs,
  conditionBuildingDescr,
  //boolean
  showSave,
  showLogout,
  showSnapshot,
  showWordReport,
  showExcelReport,
  onSave,
  onLogout,
  onChangeTitle,
  onChangeNumber,
  onChangeReportDate,
  onChangeAuditDate,
  onChangeAuditor,
  onChangeDescription,
  onChangeServiceProvider,
  onChangeServiceProviderShort,
  onChangeAuthority,
  onAddDocument,
  onViewDocument,
  onDownloadDocument,
  onDeleteDocument,
  onAddStakeholder,
  onViewStakeholder,
  onDeleteStakeholder,
  onAddBasis,
  onViewBasis,
  onDeleteBasis,
  onSelectView,
  onGenerateWordReport,
  onGenerateExcelReport,
  onGenerateSnapshot,
  onChangeConditionBuildingDescr,
  onImageClick,
  onImageRemove,
  onImageAdd,
}) {
  const currentId = useId(1, "bee-audit-data-")[0];

  //classNames
  const classNames = "bee-audit-data";
  const contentClassNames = "m-4 p-2";
  const headlineClassNames = "grid bee-audit-data-headline";
  const gdHeadlineClassNames = "col";
  const HeadlineBtnsClassNames = "col-fixed bee-audit-data-headline-btns";
  const gdContentClassNames = "bee-audit-data-grunddaten-section";
  const documentsClassNames = "bee-audit-data-doc-section";
  const stakeholderClassNames = "bee-audit-data-stakeholder-section";
  const basisClassNames = "bee-audit-data-basis-section";
  const leistungsSetupClassNames = "bee-audit-data-setup-section";

  //labels
  const showReportLabel = "Report erzeugen";
  const showSnapshotLabel = "Als Version speichern";
  const gdHeadline = "Grunddaten";
  const docHeadline = "Dokumente";
  const docAddLabel = "Dokument hinzufügen";
  const stakeholderHeadline = "Beteiligte Personen";
  const stakeholderAddLabel = "Person hinzufügen";

  //icons
  const showSnapshotIcon = "pi pi-flag";
  const showReportIcon = "pi pi-file";

  function selectView(selection) {
    if (onSelectView && selection) {
      onSelectView(selection);
    }
  }

  function performSave(e) {
    if (onSave) {
      onSave(e);
    }
  }

  function performLogout(e) {
    if (onLogout) {
      onLogout(e);
    }
  }

  function changeConditionBuildingDescr(value) {
    if (onChangeConditionBuildingDescr) {
      onChangeConditionBuildingDescr(value);
    }
  }

  function clickImageCallback(imageId) {
    if (onImageClick) {
      onImageClick(imageId);
    }
  }

  function removeImageCallback(imageId) {
    if (onImageRemove) {
      onImageRemove(imageId);
    }
  }

  function addImageCallback(e) {
    if (onImageAdd) {
      onImageAdd(e);
    }
  }

  function generateWordReport(e) {
    if (onGenerateWordReport) {
      onGenerateWordReport(e);
    }
  }

  function generateExcelReport(e) {
    if (onGenerateExcelReport) {
      onGenerateExcelReport(e);
    }
  }

  function generateSnapshot(e) {
    if (onGenerateSnapshot) {
      onGenerateSnapshot(e);
    }
  }

  function changeTitle(value) {
    if (onChangeTitle) {
      onChangeTitle(value);
    }
  }

  function changeNumber(value) {
    if (onChangeNumber) {
      onChangeNumber(value);
    }
  }

  function changeReportDate(value) {
    if (onChangeReportDate) {
      onChangeReportDate(value);
    }
  }

  function changeAuditDate(value) {
    if (onChangeAuditDate) {
      onChangeAuditDate(value);
    }
  }
  function changeAuditor(value) {
    if (onChangeAuditor) {
      onChangeAuditor(value);
    }
  }

  function changeDescription(value) {
    if (onChangeDescription) {
      onChangeDescription(value);
    }
  }

  function changeServiceProvider(value) {
    if (onChangeServiceProvider) {
      onChangeServiceProvider(value);
    }
  }

  function changeAuthority(value) {
    if (onChangeAuthority) {
      onChangeAuthority(value);
    }
  }

  function changeServiceProviderShort(value) {
    if (onChangeServiceProviderShort) {
      onChangeServiceProviderShort(value);
    }
  }

  function addDocument(e) {
    if (onAddDocument) {
      onAddDocument(e);
    }
  }

  function viewDocument(id) {
    if (onViewDocument) {
      onViewDocument(id);
    }
  }

  function downloadDocument(id) {
    if (onDownloadDocument) {
      onDownloadDocument(id);
    }
  }

  function deleteDocument(id) {
    if (onDeleteDocument) {
      onDeleteDocument(id);
    }
  }

  function addStakeholder(e) {
    if (onAddStakeholder) {
      onAddStakeholder(e);
    }
  }

  function viewStakeholder(id) {
    if (onViewStakeholder) {
      onViewStakeholder(id);
    }
  }

  function deleteStakeholder(id) {
    if (onDeleteStakeholder) {
      onDeleteStakeholder(id);
    }
  }

  function addBasis(e) {
    if (onAddBasis) {
      onAddBasis(e);
    }
  }

  function viewBasis(id) {
    if (onViewBasis) {
      onViewBasis(id);
    }
  }

  function deleteBasis(id) {
    if (onDeleteBasis) {
      onDeleteBasis(id);
    }
  }

  const injectHeader = () => {
    return (
      <BeeAuditHeader
        disabled={false}
        homeUrl={homeUrl}
        items={breadcrumbData}
        type={"dark"}
        maxProgress={maxProgress}
        progress={progress}
        warningMessage={warningMessage}
        showSave={showSave}
        showLogout={showLogout}
        showProgress={true}
        showLeftRight={false}
        onSave={(e) => performSave(e)}
        onLogout={(e) => performLogout(e)}
      />
    );
  };
  function injectTabs() {
    return (
      <BeeAuditTabs
        options={viewOptions}
        selectedOption={selectedView}
        disabled={false}
        type={"primary"}
        onSelect={(option) => selectView(option)}
      />
    );
  }

  const injectHeadline = () => {
    return (
      <div className={headlineClassNames}>
        <div className={gdHeadlineClassNames}>
          <BeeContentHeadline
            label={gdHeadline}
            headline={"h1"}
            type={"primary"}
          />
        </div>
        <div className={HeadlineBtnsClassNames}>
          {showWordReport ? (
            <BeeMixButton
              label={showReportLabel}
              disabled={false}
              type={"secondary"}
              iconClass={showReportIcon}
              iconPos={"left"}
              onClick={(e) => generateWordReport(e)}
            />
          ) : null}
          {showExcelReport ? (
            <BeeMixButton
              label={"LV-Report erzeugen"}
              disabled={false}
              type={"secondary"}
              iconClass={showReportIcon}
              iconPos={"left"}
              onClick={(e) => generateExcelReport(e)}
            />
          ) : null}
          {showSnapshot ? (
            <BeeMixButton
              label={showSnapshotLabel}
              disabled={false}
              type={"primary"}
              iconClass={showSnapshotIcon}
              iconPos={"left"}
              onClick={(e) => generateSnapshot(e)}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const injectGrunddaten = () => {
    return (
      <div className={gdContentClassNames}>
        <BeeAuditInputGrunddaten
          title={auditTitle}
          number={auditNumber}
          reportDate={auditReportDate}
          auditDate={auditDate}
          auditor={auditAuditor}
          description={auditDescription}
          serviceProvider={auditServiceProvider}
          serviceProviderShort={auditServiceProviderShort}
          authority={auditAuthority}
          readOnly={readOnly}
          isAdaptedTitle={isAdaptedTitle}
          isAdaptedNumber={isAdaptedNumber}
          isAdaptedReportDate={isAdaptedReportDate}
          isAdaptedAuditDate={isAdaptedAuditDate}
          isAdaptedAuditor={isAdaptedAuditor}
          isAdaptedDescription={isAdaptedDescription}
          isAdaptedServiceProvider={isAdaptedServiceProvider}
          isAdaptedServiceProviderShort={isAdaptedServiceProviderShort}
          isAdaptedAuthority={isAdaptedAuthority}
          isErrorTitle={isErrorTitle}
          isErrorNumber={isErrorNumber}
          isErrorReportDate={isErrorReportDate}
          isErrorAuditDate={isErrorAuditDate}
          isErrorAuditor={isErrorAuditor}
          isErrorDescription={isErrorDescription}
          isErrorServiceProvider={isErrorServiceProvider}
          isErrorServiceProviderShort={isErrorServiceProviderShort}
          isErrorAuthority={isErrorAuthority}
          onChangeTitle={(value) => changeTitle(value)}
          onChangeNumber={(value) => changeNumber(value)}
          onChangeReportDate={(value) => changeReportDate(value)}
          onChangeAuditDate={(value) => changeAuditDate(value)}
          onChangeAuditor={(value) => changeAuditor(value)}
          onChangeDescription={(value) => changeDescription(value)}
          onChangeServiceProvider={(value) => changeServiceProvider(value)}
          onChangeAuthority={(value) => changeAuthority(value)}
          onChangeServiceProviderShort={(value) =>
            changeServiceProviderShort(value)
          }
        />
      </div>
    );
  };

  const injectDocuments = () => {
    return (
      <div className={documentsClassNames}>
        <BeeContentHeadline
          label={docHeadline}
          headline={"h2"}
          type={"primary"}
        />
        <BeeContentParagraph
          text={
            "Hier können Sie Dokumente welche als Grundlage zum Audit dienen hinzufügen. Dies können Auditergebnisse aus dem Vorjahr sein, Gesprächsprotokolle (siehe auch Auditgrundlagen), der Vertrag oder sonstige wichtigen Dokumente. Ziel ist es, dass alle dem Audit zugrunde liegenden Informationen als Ereignis in Auditgrundlagen in Form von Dokumenten lückenlos vorliegen."
          }
          size={"medium"}
          type={"primary"}
        />
        <BeeDocumentTable
          type={"dark"}
          data={documents}
          readOnly={readOnly}
          showView={true}
          showDownload={true}
          showDelete={!readOnly}
          onView={(id) => viewDocument(id)}
          onDownload={(id) => downloadDocument(id)}
          onDelete={(id) => deleteDocument(id)}
        />
        {!readOnly ? (
          <BeeOutlinedButton
            label={docAddLabel}
            disabled={readOnly}
            type={"secondary"}
            onClick={(e) => addDocument(e)}
          />
        ) : null}
      </div>
    );
  };

  const injectStakeholders = () => {
    return (
      <div className={stakeholderClassNames}>
        <BeeContentHeadline
          label={stakeholderHeadline}
          headline={"h2"}
          type={"primary"}
        />
        <BeeContentParagraph
          text={
            "Bitte tragen Sie hier die am Audit direkt oder indirekt beteiligten Personen ein, auch solche, welche über das Auditergebnis nur berichtet wird. Hierzu sollten Sie Namen, Unternehmen, die Rolle (bzw. Funktion, z.B. Objektleiter oder Auditor) hinzufügen. Ebenso bitte ob die Teilnahme vollständig oder nur teilweise war und ob die Person zum Verteiler des Audit-Reports gehört."
          }
          size={"medium"}
          type={"primary"}
        />
        <BeeAuditStakeholderTable
          type={"dark"}
          data={stakeholders}
          readOnly={readOnly}
          showView={true}
          showDelete={!readOnly}
          onView={(id) => viewStakeholder(id)}
          onDelete={(id) => deleteStakeholder(id)}
        />
        {!readOnly ? (
          <BeeOutlinedButton
            label={stakeholderAddLabel}
            disabled={readOnly}
            type={"secondary"}
            onClick={(e) => addStakeholder(e)}
          />
        ) : null}
      </div>
    );
  };

  const injectAuditBasis = () => {
    return (
      <div className={basisClassNames}>
        <BeeContentHeadline
          label={"Auditgrundlage"}
          headline={"h2"}
          type={"primary"}
        />
        <BeeContentParagraph
          text={
            "Das Audit basiert auf einzelnen Bausteinen. Hierzu zählen in erster Linie die Dokumentenprüfung, Gespräche (vor allem mit der Objektleitung des Dienstleisters) und der Begehung des Objekts mit in-Augenscheinnahme der Leistungsqualität durch Sie. Auch können alle sonstigen für die Beurteilung der Leistungsqualität wichtigen Grundlagen hier festgehalten werden, wie z.B. Hinweise auf wichtige Dokumente (Gesprächsprotokolle, Projektdokumentation etc.). Die Dokumente selbst laden Sie bitte bei Grunddaten/ Dokumente hoch."
          }
          size={"medium"}
          type={"primary"}
        />
        <BeeAuditBasisTable
          type={"dark"}
          data={basis}
          readOnly={readOnly}
          showEdit={true}
          showDelete={!readOnly}
          onView={(id) => viewBasis(id)}
          onDelete={(id) => deleteBasis(id)}
        />
        {!readOnly ? (
          <BeeOutlinedButton
            label={"Ereignis hinzufügen"}
            disabled={readOnly}
            type={"secondary"}
            onClick={(e) => addBasis(e)}
          />
        ) : null}
      </div>
    );
  };

  const injectEindruckGebaeude = () => {
    return (
      <div className={basisClassNames}>
        <BeeContentHeadline
          label={"Gesamteindruck Gebäude"}
          headline={"h2"}
          type={"primary"}
        />
        <BeeContentParagraph
          text={
            "Der Gesamteindruck des Gebäudes wird während der Objektbegehung beurteilt. Bitte beschreiben Sie schriftlich Ihren Gesamteindruck des Gebäudes und fügen Sie allgemeine Bilder die den Eindruck belegen hinzu. Der Gesamteindruck gibt Auskunft über die Verfügbarkeit der besichtigten Anlagen sowie der Sauberkeit im gesamten Gebäude."
          }
          size={"medium"}
          type={"primary"}
        />
        <BeeFlatImageGallery
          images={conditionBuildingImgs}
          showAddOption={true}
          activateHover={runsOnWeb()}
          readOnly={readOnly}
          onClick={(imageId) => clickImageCallback(imageId)}
          onRemove={(imageId) => removeImageCallback(imageId)}
          onAdd={(e) => addImageCallback(e)}
        />
        <BeeTextArea
          label={"Kommentar"}
          value={conditionBuildingDescr}
          rows={4}
          disabled={readOnly}
          autoResize={false}
          formstate={"neutral"}
          readOnly={readOnly}
          required={false}
          onChange={(e) => changeConditionBuildingDescr(e.target.value)}
        />
      </div>
    );
  };

  const injectSetup = () => {
    return (
      <div className={leistungsSetupClassNames}>
        <BeeAuditSetupLeistungsubersicht
          type={"dark"}
          liegenschaftTitle={null}
          data={leistungsSetup}
          readOnly={true}
          currencyMode={isCurrencySetup}
          currency={"EUR"}
          locale={"de-DE"}
        />
      </div>
    );
  };

  return (
    <div id={currentId} className={classNames}>
      {injectHeader()}
      {injectTabs()}
      <div className={contentClassNames}>
        {injectHeadline()}
        {injectGrunddaten()}
        {injectDocuments()}
        {injectStakeholders()}
        {injectAuditBasis()}
        {injectEindruckGebaeude()}
        {injectSetup()}
      </div>
    </div>
  );
}

export default BeeAuditData;
