import React, { useEffect, useState } from "react";
import { useId } from "react-id-generator";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BeeIconButton, BeeIcon } from "bee-atomic-components";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditStakeholderTable.scss";
import { KEINE_TN, TEILWEISE_TN, KOMPLETTE_TN } from "../../Helper/constants";

export function BeeAuditStakeholderTable({
  type,
  data,
  readOnly,
  showEdit,
  showDelete,
  onView,
  onDelete,
}) {
  const currentId = useId(1, "bee-audit-stakeholder-table-")[0];

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  //Screen widths
  const p_md = 768;

  //classNames
  const typeClassNames =
    type === "dark"
      ? "bee-audit-stakeholder-table-dark"
      : type === "light"
      ? "bee-audit-stakeholder-table-light"
      : "bee-audit-stakeholder-table-light";
  const classNames = "bee-audit-stakeholder-table " + typeClassNames;
  const tableClassNames = "p-datatable-sm";
  const nameColClassNames = "bee-audit-stakeholder-table-col-name";
  const roleColClassNames = "bee-audit-stakeholder-table-col-role";
  const companyColClassNames = "bee-audit-stakeholder-table-col-company";
  const participationColClassNames =
    "bee-audit-stakeholder-table-col-participation";
  const sharedColClassNames = "bee-audit-stakeholder-table-col-shared";
  const actionColClassNames =
    "bee-audit-stakeholder-table-col-action" +
    calculateActionColWidthClassName();

  //label
  const emptyMessage = "Keine beteiligten Personen vorhanden.";
  const nameColLabel = "Name";
  const roleColLabel = "Rolle";
  const companyColLabel = "Unternehmen";
  const participationColLabel = "Teilnahme";
  const sharedColLabel = "Verteiler";
  const actionColLabel = "";

  //icon
  const sharedTrueIcon = "pi pi-check";
  const sharedFalseIcon = "pi pi-times";
  const editIcon = "pi pi-pencil";
  const deleteIcon = "pi pi-trash";

  function calculateActionColWidthClassName() {
    const buttonCount = (showEdit ? 1 : 0) + (showDelete ? 1 : 0);

    const actionColWidthClassNames =
      buttonCount === 1
        ? " bee-audit-stakeholder-table-col-action-oneBtn"
        : buttonCount === 2
        ? " bee-audit-stakeholder-table-col-action-twoBtn"
        : "";
    return actionColWidthClassNames;
  }

  function nameTemplate(rowData) {
    if (rowData) {
      return <p headername={nameColLabel}>{rowData.name}</p>;
    }
  }
  function roleTemplate(rowData) {
    if (rowData) {
      return <p headername={roleColLabel}>{rowData.role}</p>;
    }
  }
  function companyTemplate(rowData) {
    if (rowData) {
      return <p headername={companyColLabel}>{rowData.company}</p>;
    }
  }

  function participationTemplate(rowData) {
    if (rowData) {
      return (
        <p headername={participationColLabel}>
          {rowData.participation === KEINE_TN.value
            ? KEINE_TN.label
            : rowData.participation === TEILWEISE_TN.value
            ? TEILWEISE_TN.label
            : rowData.participation === KOMPLETTE_TN.value
            ? KOMPLETTE_TN.label
            : ""}
        </p>
      );
    }
  }

  function sharedTemplate(rowData) {
    if (rowData) {
      if (rowData.shared) {
        return (
          <p headername={sharedColLabel}>
            <BeeIcon iconClass={sharedTrueIcon} size="large"></BeeIcon>
          </p>
        );
      }
      return (
        <p headername={sharedColLabel}>
          <BeeIcon iconClass={sharedFalseIcon} size="large"></BeeIcon>
        </p>
      );
    }
  }

  function actionTemplate(rowData) {
    const rowDataId = rowData.id;
    if (rowData) {
      return (
        <div headername={actionColLabel}>
          {showEdit ? (
            <BeeIconButton
              iconClass={editIcon}
              rounded={false}
              disabled={false}
              type={"primary"}
              onClick={() => view(rowDataId)}
            />
          ) : null}
          {showDelete ? (
            <BeeIconButton
              iconClass={deleteIcon}
              rounded={false}
              disabled={readOnly}
              type={"danger"}
              onClick={() => deleteEntry(rowDataId)}
            />
          ) : null}
        </div>
      );
    }
  }

  function view(itemId) {
    if (onView) {
      onView(itemId);
    }
  }

  function deleteEntry(itemId) {
    if (onDelete) {
      onDelete(itemId);
    }
  }

  return (
    <div id={currentId} className={classNames}>
      <DataTable
        value={data}
        className={tableClassNames}
        emptyMessage={emptyMessage}
      >
        <Column
          className={nameColClassNames}
          body={nameTemplate}
          header={nameColLabel}
        />
        {width > p_md ? (
          <Column
            className={roleColClassNames}
            body={roleTemplate}
            header={roleColLabel}
          />
        ) : null}
        <Column
          className={companyColClassNames}
          body={companyTemplate}
          header={companyColLabel}
        />
        <Column
          className={participationColClassNames}
          body={participationTemplate}
          header={participationColLabel}
        />
        <Column
          className={sharedColClassNames}
          body={sharedTemplate}
          header={sharedColLabel}
        />
        {(!readOnly && showEdit) || (!readOnly && showDelete) ? (
          <Column
            className={actionColClassNames}
            body={actionTemplate}
            header={actionColLabel}
          />
        ) : null}
      </DataTable>
    </div>
  );
}

export default BeeAuditStakeholderTable;
