import { authAxios } from "./AuthService";
import {
  URL_TO_AUDIT_API,
  URL_TO_LIEGENSCHAFT_API,
  URL_TO_MEDIA_API,
} from "./constants";

////////////
// HELPER //
////////////
export function errorToMsg(error) {
  return `(Fehler: ${error && error.data ? error.data.status : ""})`;
}

//DOWNLOAD DOCUMENT
export const downloadDocumentFromServer = (src, secretKey) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "get",
      url: src,
      responseType: "blob",
      headers: { "x-bee-secure": secretKey ? secretKey : "" },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//UPLOAD DOCUMENT
export const uploadDocumentToServer = (file, title, originalFileName) => {
  return new Promise((resolve, reject) => {
    const uploadUrl = URL_TO_MEDIA_API + "/v1/doc";
    const fd = new FormData();
    fd.append("file", file);
    fd.append("title", title);
    fd.append("originalFileName", originalFileName);
    fd.append("public", false);
    fd.append("copyright", null);
    fd.append("keywords", null);
    authAxios
      .put(uploadUrl, fd)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//UPLOAD IMAGE
export const uploadImageToServer = (file, title, originalFileName) => {
  return new Promise((resolve, reject) => {
    const uploadUrl = URL_TO_MEDIA_API + "/v1/img";
    const fd = new FormData();
    fd.append("file", file);
    fd.append("title", title);
    fd.append("originalFileName", originalFileName);
    fd.append("public", false);
    fd.append("copyright", null);
    fd.append("keywords", null);
    authAxios
      .put(uploadUrl, fd)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//TRANSLATE MEDIA IDS
export const translateMediaIds = (mediaIds) => {
  return new Promise((resolve, reject) => {
    if (mediaIds && mediaIds.length > 0) {
      authAxios({
        method: "post",
        url: URL_TO_MEDIA_API + "/v1/media",
        data: { ids: mediaIds },
      })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    } else {
      resolve([]);
    }
  });
};

//CALL ALL LIEGENSCHAFTEN-SHORT-VERSION
export const callAllLiegenschaftenShort = () => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "get",
      url: URL_TO_LIEGENSCHAFT_API + "/v1/special/short/liegenschaften",
      data: {},
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//DOWNLOAD AVAILABLE BAUALTERSSTUFE
export const downloadAvailableBaualtersstufen = () => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "get",
      url: URL_TO_LIEGENSCHAFT_API + "/v1/types/baualtersstufe",
      data: {},
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//DOWNLOAD AVAILABLE BAUALTERSSTUFE
export const downloadAvailableAusstattungsgrad = () => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "get",
      url: URL_TO_LIEGENSCHAFT_API + "/v1/types/ausstattungsgrad",
      data: {},
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//CALL LIEGENSCHAFT BY ID
export const callLiegenschaftById = (propertyId) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "get",
      url: URL_TO_LIEGENSCHAFT_API + "/v1/liegenschaften/" + propertyId,
      data: {},
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//CALL GEBÄUDE FOR LIEGENSCHAFT BY ID
export const callAllGebaeudeInLiegenschaft = (propertyId) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "get",
      url:
        URL_TO_LIEGENSCHAFT_API +
        "/v1/liegenschaften/" +
        propertyId +
        "/gebaeude",
      data: {},
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//CREATE LIEGENSCHAFT BY ID
export const createLiegenschaftById = (
  organisationId,
  nummer,
  name,
  beschreibung,
  anmerkung,
  lohnindex,
  eigentuemer,
  verantwortlicher,
  hauptBildId,
  hauptPlanId,
  imageIds,
  documentIds,
  strasse,
  hausnummer,
  postleitzahl,
  stadt,
  region,
  land,
  longitude,
  latitude
) => {
  const images = imageIds ? imageIds : [];
  const documents = documentIds ? documentIds : [];
  const mediaIds = images.concat(documents);
  return new Promise((resolve, reject) => {
    authAxios({
      method: "put",
      url: URL_TO_LIEGENSCHAFT_API + "/v1/liegenschaften/",
      data: {
        organisationId: organisationId,
        nummer: nummer,
        name: name,
        beschreibung: beschreibung,
        anmerkung: anmerkung,
        lohnindex: lohnindex,
        eigentuemer: eigentuemer,
        verantwortlicher: verantwortlicher,
        hauptBildId: hauptBildId,
        hauptPlanId: hauptPlanId,
        mediaIds: mediaIds,
        adresse: {
          strasse: strasse,
          hausnummer: hausnummer,
          postleitzahl: postleitzahl,
          stadt: stadt,
          region: region,
          land: land,
          longitude: longitude,
          latitude: latitude,
        },
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//UPDATE LIEGENSCHAFT BY ID
export const updateLiegenschaftById = (
  propertyId,
  organisationId,
  nummer,
  name,
  beschreibung,
  anmerkung,
  lohnindex,
  eigentuemer,
  verantwortlicher,
  hauptBildId,
  hauptPlanId,
  imageIds,
  documentIds,
  strasse,
  hausnummer,
  postleitzahl,
  stadt,
  region,
  land,
  longitude,
  latitude
) => {
  const images = imageIds ? imageIds : [];
  const documents = documentIds ? documentIds : [];
  const mediaIds = images.concat(documents);
  return new Promise((resolve, reject) => {
    authAxios({
      method: "put",
      url: URL_TO_LIEGENSCHAFT_API + "/v1/liegenschaften/" + propertyId,
      data: {
        organisationId: organisationId,
        nummer: nummer,
        name: name,
        beschreibung: beschreibung,
        anmerkung: anmerkung,
        lohnindex: lohnindex,
        eigentuemer: eigentuemer,
        verantwortlicher: verantwortlicher,
        hauptBildId: hauptBildId,
        hauptPlanId: hauptPlanId,
        mediaIds: mediaIds,
        adresse: {
          strasse: strasse,
          hausnummer: hausnummer,
          postleitzahl: postleitzahl,
          stadt: stadt,
          region: region,
          land: land,
          longitude: longitude,
          latitude: latitude,
        },
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//CREATE GEBÄUDE IN LIEGENSCHAFT
export const createGebaeudeInLiegenschaft = (
  propertyId,
  nummer,
  name,
  eigentuemer,
  verantwortlicher,
  beschreibung,
  anmerkung,
  baujahr,
  bruttoGeschossflaeche,
  nettoGeschossflaeche,
  aussenanlagenflaeche,
  mietflaeche,
  anzahlEtagen,
  anzahlEtagenOberirdisch,
  anzahlEtagenUnterirdisch,
  anzahlTreppenhaeuser,
  anzahlAufzuege,
  anzahlStellplaetze,
  anzahlNutzerPersonen,
  anzahlBauteile,
  kostenNettoFmProJahr,
  jahresMieteinnahmen,
  nutzungsanteilOffice,
  nutzungsanteilRetail,
  nutzungsanteilWohnen,
  nutzungsanteilLogistik,
  nutzungsanteilSonstige,
  tiefgarage,
  fassadenbefahranlage,
  denkmalschutz,
  hauptbetriebszeitVon,
  hauptbetriebszeitBis,
  baualtersstufe,
  ausstattungsgrad,
  hauptBildId,
  hauptPlanId,
  imageIds,
  documentIds,
  strasse,
  hausnummer,
  postleitzahl,
  stadt,
  region,
  land,
  longitude,
  latitude
) => {
  const images = imageIds ? imageIds : [];
  const documents = documentIds ? documentIds : [];
  const mediaIds = images.concat(documents);
  return new Promise((resolve, reject) => {
    authAxios({
      method: "put",
      url:
        URL_TO_LIEGENSCHAFT_API +
        "/v1/liegenschaften/" +
        propertyId +
        "/gebaeude",
      data: {
        nummer: nummer,
        name: name,
        eigentuemer: eigentuemer,
        verantwortlicher: verantwortlicher,
        beschreibung: beschreibung,
        anmerkung: anmerkung,
        baujahr: baujahr,
        bruttoGeschossflaeche: bruttoGeschossflaeche,
        nettoGeschossflaeche: nettoGeschossflaeche,
        aussenanlagenflaeche: aussenanlagenflaeche,
        mietflaeche: mietflaeche,
        anzahlEtagen: anzahlEtagen,
        anzahlEtagenOberirdisch: anzahlEtagenOberirdisch,
        anzahlEtagenUnterirdisch: anzahlEtagenUnterirdisch,
        anzahlTreppenhaeuser: anzahlTreppenhaeuser,
        anzahlAufzuege: anzahlAufzuege,
        anzahlStellplaetze: anzahlStellplaetze,
        anzahlNutzerPersonen: anzahlNutzerPersonen,
        anzahlBauteile: anzahlBauteile,
        kostenNettoFmProJahr: kostenNettoFmProJahr,
        jahresMieteinnahmen: jahresMieteinnahmen,
        nutzungsanteilOffice: nutzungsanteilOffice,
        nutzungsanteilRetail: nutzungsanteilRetail,
        nutzungsanteilWohnen: nutzungsanteilWohnen,
        nutzungsanteilLogistik: nutzungsanteilLogistik,
        nutzungsanteilSonstige: nutzungsanteilSonstige,
        tiefgarage: tiefgarage,
        fassadenbefahranlage: fassadenbefahranlage,
        denkmalschutz: denkmalschutz,
        hauptbetriebszeitVon: hauptbetriebszeitVon,
        hauptbetriebszeitBis: hauptbetriebszeitBis,
        baualtersstufe: baualtersstufe,
        ausstattungsgrad: ausstattungsgrad,
        hauptBildId: hauptBildId,
        hauptPlanId: hauptPlanId,
        mediaIds: mediaIds,
        adresse: {
          strasse: strasse,
          hausnummer: hausnummer,
          postleitzahl: postleitzahl,
          stadt: stadt,
          region: region,
          land: land,
          longitude: longitude,
          latitude: latitude,
        },
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//UPDATE GEBÄUDE IN LIEGENSCHAFT BY ID
export const updateGebaeudeInLiegenschaft = (
  propertyId,
  buildingId,
  nummer,
  name,
  eigentuemer,
  verantwortlicher,
  beschreibung,
  anmerkung,
  baujahr,
  bruttoGeschossflaeche,
  nettoGeschossflaeche,
  aussenanlagenflaeche,
  mietflaeche,
  anzahlEtagen,
  anzahlEtagenOberirdisch,
  anzahlEtagenUnterirdisch,
  anzahlTreppenhaeuser,
  anzahlAufzuege,
  anzahlStellplaetze,
  anzahlNutzerPersonen,
  anzahlBauteile,
  kostenNettoFmProJahr,
  jahresMieteinnahmen,
  nutzungsanteilOffice,
  nutzungsanteilRetail,
  nutzungsanteilWohnen,
  nutzungsanteilLogistik,
  nutzungsanteilSonstige,
  tiefgarage,
  fassadenbefahranlage,
  denkmalschutz,
  hauptbetriebszeitVon,
  hauptbetriebszeitBis,
  baualtersstufe,
  ausstattungsgrad,
  hauptBildId,
  hauptPlanId,
  imageIds,
  documentIds,
  strasse,
  hausnummer,
  postleitzahl,
  stadt,
  region,
  land,
  longitude,
  latitude
) => {
  const images = imageIds ? imageIds : [];
  const documents = documentIds ? documentIds : [];
  const mediaIds = images.concat(documents);
  return new Promise((resolve, reject) => {
    authAxios({
      method: "put",
      url:
        URL_TO_LIEGENSCHAFT_API +
        "/v1/liegenschaften/" +
        propertyId +
        "/gebaeude/" +
        buildingId,
      data: {
        nummer: nummer,
        name: name,
        eigentuemer: eigentuemer,
        verantwortlicher: verantwortlicher,
        beschreibung: beschreibung,
        anmerkung: anmerkung,
        baujahr: baujahr,
        bruttoGeschossflaeche: bruttoGeschossflaeche,
        nettoGeschossflaeche: nettoGeschossflaeche,
        aussenanlagenflaeche: aussenanlagenflaeche,
        mietflaeche: mietflaeche,
        anzahlEtagen: anzahlEtagen,
        anzahlEtagenOberirdisch: anzahlEtagenOberirdisch,
        anzahlEtagenUnterirdisch: anzahlEtagenUnterirdisch,
        anzahlTreppenhaeuser: anzahlTreppenhaeuser,
        anzahlAufzuege: anzahlAufzuege,
        anzahlStellplaetze: anzahlStellplaetze,
        anzahlNutzerPersonen: anzahlNutzerPersonen,
        anzahlBauteile: anzahlBauteile,
        kostenNettoFmProJahr: kostenNettoFmProJahr,
        jahresMieteinnahmen: jahresMieteinnahmen,
        nutzungsanteilOffice: nutzungsanteilOffice,
        nutzungsanteilRetail: nutzungsanteilRetail,
        nutzungsanteilWohnen: nutzungsanteilWohnen,
        nutzungsanteilLogistik: nutzungsanteilLogistik,
        nutzungsanteilSonstige: nutzungsanteilSonstige,
        tiefgarage: tiefgarage,
        fassadenbefahranlage: fassadenbefahranlage,
        denkmalschutz: denkmalschutz,
        hauptbetriebszeitVon: hauptbetriebszeitVon,
        hauptbetriebszeitBis: hauptbetriebszeitBis,
        baualtersstufe: baualtersstufe,
        ausstattungsgrad: ausstattungsgrad,
        hauptBildId: hauptBildId,
        hauptPlanId: hauptPlanId,
        mediaIds: mediaIds,
        adresse: {
          strasse: strasse,
          hausnummer: hausnummer,
          postleitzahl: postleitzahl,
          stadt: stadt,
          region: region,
          land: land,
          longitude: longitude,
          latitude: latitude,
        },
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//CALL ALL AUDIT-META-INFO FOR LIEGENSCHAFTEN
export const callMetaAuditInformationForLiegenschaften = (propertyIds) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "post",
      url: URL_TO_AUDIT_API + "/v1/tools/audit/property",
      data: { ids: propertyIds },
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//CALL ALL AUDITS FOR LIEGENSCHAFT
export const callAllAuditProjectsForLiegenschaft = (propertyId) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "get",
      url: URL_TO_AUDIT_API + "/v1/tools/audit/property/" + propertyId,
      data: {},
    })
      .then((response) => {
        const data = response.data.data;
        //transform strigified data to json object
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].payload) {
              const payload = JSON.parse(data[i].payload);
              data[i].payload = payload;
            }
          }
        }
        resolve(data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//CALL AUDIT-PROJECT
export const callAuditProject = (projectId) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "get",
      url: URL_TO_AUDIT_API + "/v1/tools/audit/project/" + projectId,
      data: {},
    })
      .then((response) => {
        let data = response.data;
        //transform strigified data to json object
        const payload = JSON.parse(data.payload);
        data.payload = payload;
        resolve(data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//CALL SNAPSHOT-FROM-PROJECT
export const callAuditSnapshot = (projectId, snapshotId) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "get",
      url:
        URL_TO_AUDIT_API +
        "/v1/tools/audit/project/" +
        projectId +
        "/snapshot/" +
        snapshotId,
      data: {},
    })
      .then((response) => {
        let data = response.data;
        //transform strigified data to json object
        const payload = JSON.parse(data.payload);
        data.payload = payload;
        resolve(data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//SAVE PROJECT
export const saveProjectAtServer = (
  projectId,
  templateId,
  propertyId,
  lockId,
  title,
  auditNumber,
  description,
  payload,
  rating,
  contractAmount
) => {
  const url =
    URL_TO_AUDIT_API + "/v1/tools/audit/project/" + projectId + "/" + lockId;
  return new Promise((resolve, reject) => {
    authAxios({
      method: "put",
      url: url,
      data: {
        templateId: templateId,
        propertyId: propertyId,
        title: title,
        auditNumber: auditNumber,
        description: description,
        payload: payload,
        rating: rating,
        contractAmount: contractAmount,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//CREATE SNAPSHOT
export const createSnapshotAtServer = (projectId, versionName, payload) => {
  const url =
    URL_TO_AUDIT_API + "/v1/tools/audit/project/" + projectId + "/snapshot";
  return new Promise((resolve, reject) => {
    authAxios({
      method: "put",
      url: url,
      data: {
        versionName: versionName,
        payload: payload,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//ENSURE THAT USER IS STILL MAINTAINER OF THIS PROJECT
export const ensureUserIsMaintainer = (
  projectId,
  userId,
  userFirstname,
  userLastname,
  userEmail
) => {
  console.log("ensureUserIsMaintainer");
  return new Promise((resolve, reject) => {
    authAxios({
      method: "put",
      url:
        URL_TO_AUDIT_API +
        "/v1/tools/audit/project/" +
        projectId +
        "/maintainer",
      data: {
        userId: userId,
        userFirstname: userFirstname,
        userLastname: userLastname,
        userEmail: userEmail,
        permissions: [
          "RUN",
          "EDIT",
          "VIEW",
          "SYNC",
          "DELETE",
          "REPORT",
          "SNAPSHOT",
        ],
      },
    })
      .then((response) => {
        const entry = response.data;
        resolve(entry);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//CALL ALL LOCKS FOR USER
export const callAllActiveLocksForUser = (userId) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "get",
      url: URL_TO_AUDIT_API + "/v1/tools/audit/user/" + userId + "/lock",
      data: {},
    })
      .then((response) => {
        const entry = response.data;
        resolve(entry);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//CREATE LOCK FOR USER
export const createLockForMaintainer = (
  projectId,
  maintainerId,
  lockForOnlineUse
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "put",
      url: URL_TO_AUDIT_API + "/v1/tools/audit/project/" + projectId + "/lock",
      data: {},
      params: {
        maintainerId: maintainerId,
        isOnlineLock: lockForOnlineUse,
      },
    })
      .then((response) => {
        const entry = response.data;
        resolve(entry);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//RELEASE LOCK FOR USER
export const releaseLockForMaintainer = (projectId, maintainerId) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "delete",
      url: URL_TO_AUDIT_API + "/v1/tools/audit/project/" + projectId + "/lock",
      data: {},
      params: {
        maintainerId: maintainerId,
      },
    })
      .then((response) => {
        const entry = response.data;
        resolve(entry);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//CALL ALL AUDIT-TEMPLATES (shorten version)
export const callAllAuditTemplatesShort = () => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "get",
      url: URL_TO_AUDIT_API + "/v1/tools/audit/template/usable",
      data: {},
    })
      .then((response) => {
        const entry = response.data;
        resolve(entry);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//CALL AUDIT-TEMPLATE BY ID (with payload)
export const callAuditTemplate = (templateId) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "get",
      url: URL_TO_AUDIT_API + "/v1/tools/audit/template/" + templateId,
      data: {},
    })
      .then((response) => {
        let data = response.data;
        //transform strigified data to json object
        const payload = JSON.parse(data.payload);
        data.payload = payload;
        resolve(data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//CREATE NEW ADIT PROJECT
export const createAuditProject = (
  templateId,
  propertyId,
  title,
  auditNumber,
  description,
  payload,
  rating,
  contractAmount
) => {
  const stringifiedPayload = JSON.stringify(payload);
  return new Promise((resolve, reject) => {
    authAxios({
      method: "put",
      url: URL_TO_AUDIT_API + "/v1/tools/audit/project",
      data: {
        templateId: templateId,
        propertyId: propertyId,
        title: title,
        auditNumber: auditNumber,
        description: description,
        payload: stringifiedPayload,
        rating: rating,
        contractAmount: contractAmount,
      },
    })
      .then((response) => {
        let data = response.data;
        //transform strigified data to json object
        const payload = JSON.parse(data.payload);
        data.payload = payload;
        resolve(data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//download template lv file
export const downloadLvTemplate = () => {
  return new Promise((resolve, reject) => {
    const src = URL_TO_AUDIT_API + "/v1/tools/lv/template";
    authAxios({
      method: "get",
      url: src,
      responseType: "blob",
    })
      .then((response) => {
        const entry = response.data;
        resolve(entry);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//upload & convert lv file
export const convertLVTemplateToJson = (file) => {
  return new Promise((resolve, reject) => {
    const src = URL_TO_AUDIT_API + "/v1/tools/lv/convert";
    const fd = new FormData();
    fd.append("file", file);
    authAxios
      .put(src, fd)
      .then((res) => {
        const entry = res.data;
        resolve(entry);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//export lv from project as file
export const exportLvFromProject = (projectId, prettyPrint) => {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams([
      ["prettyPrint", prettyPrint ? prettyPrint : false],
    ]);
    const src = URL_TO_AUDIT_API + "/v1/tools/v1/tools/audit/project/";
    authAxios
      .get(src + projectId + "/download_lv", { params })
      .then((response) => {
        const entry = response.data;
        resolve(entry);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

//export lv from snapshot as file
export const exportLvFromSnapshot = (projectId, snapshotId, prettyPrint) => {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams([
      ["prettyPrint", prettyPrint ? prettyPrint : false],
    ]);
    const src = URL_TO_AUDIT_API + "/v1/tools/v1/tools/audit/project/";
    const src2 = projectId + "​/snapshot​/" + snapshotId + "/download_lv";
    authAxios
      .get(src + src2, { params })
      .then((response) => {
        const entry = response.data;
        resolve(entry);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};
