import {
  LOGOUT_USER,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  ACCESS_TOKEN_REQUEST,
  ACCESS_TOKEN_SUCCESS,
  ACCESS_TOKEN_FAILURE,
  READ_USER_DATA_REQUEST,
  READ_USER_DATA_SUCCESS,
  READ_USER_DATA_FAILURE,
  SET_MAINTAINER_ID,
  DELETE_MAINTAINER_ID,
  STORE_LIEGENSCHAFTEN_SHORT,
} from "./appActionTypes";
import jwt from "jsonwebtoken";

const initialState = {
  refreshToken: null,
  accessToken: null,
  loginError: false,
  loginPending: false,
  onlineAccessPossible: false,
  offlineAccessPossible: false,
  currentUserData: null,
  currentUserDataPending: false,
  currentUserDataError: false,
  //liegenschaftenView
  allLiegenschaftenShort: null,
  //audit-adapt
  maintainerId: null,
};

const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      sessionStorage.removeItem("rT");
      sessionStorage.removeItem("aT");
      window.location.reload();
      return {
        ...state,
        refreshToken: null,
        accessToken: null,
        loginError: false,
        loginPending: false,
        onlineAccessPossible: false,
        offlineAccessPossible: false,
        allLiegenschaftenShort: null,
        allLiegenschaftenShortPending: false,
        allLiegenschaftenShortError: false,
        imgForLiegenschaftenList: null,
        imgForLiegenschaftenPending: false,
        imgForLiegenschaftenError: false,
      };
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        refreshToken: null,
        accessToken: null,
        loginPending: true,
        loginError: false,
      };
    case LOGIN_USER_SUCCESS:
      const token = action.payload;
      const decoded = jwt.decode(token, { complete: true });
      sessionStorage.setItem("rT", token && decoded ? token : "");
      return {
        ...state,
        refreshToken: token && decoded ? token : null,
        accessToken: null,
        loginPending: true,
        loginError: false,
      };
    case LOGIN_USER_FAILURE:
      sessionStorage.removeItem("rT");
      sessionStorage.removeItem("aT");
      return {
        ...state,
        refreshToken: null,
        accessToken: null,
        loginPending: false,
        loginError: true,
      };

    case ACCESS_TOKEN_REQUEST:
      return {
        ...state,
        accessToken: null,
        loginError: false,
        loginPending: true,
      };
    case ACCESS_TOKEN_SUCCESS:
      sessionStorage.setItem("aT", action.payload);
      return {
        ...state,
        accessToken: action.payload,
        loginError: false,
        loginPending: true,
        onlineAccessPossible: true,
      };
    case ACCESS_TOKEN_FAILURE:
      sessionStorage.removeItem("rT");
      sessionStorage.removeItem("aT");
      return {
        ...state,
        accessToken: null,
        loginError: true,
        loginPending: false,
      };

    case READ_USER_DATA_REQUEST:
      return {
        ...state,
        currentUserData: null,
        currentUserDataPending: true,
        currentUserDataError: false,
      };
    case READ_USER_DATA_SUCCESS:
      return {
        ...state,
        currentUserData: action.payload,
        currentUserDataPending: false,
        currentUserDataError: false,
        loginPending: false,
      };
    case READ_USER_DATA_FAILURE:
      return {
        ...state,
        currentUserData: null,
        currentUserDataPending: false,
        currentUserDataError: true,
      };

    case STORE_LIEGENSCHAFTEN_SHORT:
      return {
        ...state,
        allLiegenschaftenShort: action.payload,
      };

    case SET_MAINTAINER_ID:
      return {
        ...state,
        maintainerId: action.payload,
      };

    case DELETE_MAINTAINER_ID:
      return {
        ...state,
        maintainerId: null,
      };

    default:
      return state;
  }
};

export default templateReducer;
