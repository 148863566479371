import React, { useState, useRef } from "react";
import {
  BeeAndroidCameraDialog,
  BeeDeletionDialog,
  BeeImageSlider,
  BeeToast,
  BeeUploadDialog,
} from "bee-atomic-components";
import {
  ACCEPTED_FILE_FORMATS,
  ACCEPTED_IMAGE_FORMATS,
  DEFAULT_IMAGE_SIZE,
  IMG_ANDROID_HEIGHT,
  IMG_ANDROID_PRESERVE_ASPECT_RATIO,
  IMG_ANDROID_QUALITY,
  IMG_ANDROID_WIDTH,
  URL_TO_MEDIA_SERVER,
} from "../../Helper/constants";
import {
  execIfOnAndroid,
  execIfOnWeb,
  openStoredMediaFileOnAndroid,
  runsOnWeb,
  storeOfflineMediaOnAndroidInProject,
  writeDocumentFromBlobOnAndroid,
} from "../../Helper/CapacitorHelper";
import fileDownload from "js-file-download";
import {
  downloadDocumentFromServer,
  uploadDocumentToServer,
  uploadImageToServer,
} from "../../Helper/NetworkHelper";
import _ from "lodash";
import { convertCategoryTree } from "../../Helper/QuestionaireHelper";
import { useSwipeable } from "react-swipeable";
import { v4 as uuidv4 } from "uuid";
import BeeAuditQuestion from "../Components/BeeAuditQuestion";

function AuditUIQuestion({
  homeUrl,
  breadcrumbData,
  liegenschaftId,
  projectId,
  warningMessage,
  readOnly,
  offlineMode,
  showLogout,
  viewOptions,
  selectedView,
  question,
  category,
  categories,
  categoryTree,
  imageLookup,
  documentLookup,
  onLogout,
  onSave,
  onSelectCategory,
  onSelectView,
  onChooseQuestion,
  onAdaptQuestion,
  onToggleToCategorySelect,
  onAddDocumentToLookup,
  onAddImageToLookup,
}) {
  //question-data
  const [currQ, setCurrQ] = useState(null);
  const [categoryProgress, setCategoryProgress] = useState(0);
  const [currSeCats, setCurrSeCats] = useState(null);
  const [currDocId, setCurrDocId] = useState(null);
  const [currImgId, setCurrImgId] = useState(null);
  //document-creation-adaption-deletion
  const [v_DocDialog, setV_DocDialog] = useState(false);
  const [v_DocDelDialog, setV_DocDelDialog] = useState(false);
  const [docUploadProg, setDocUploadProg] = useState(false);
  const [docUploadError, setDocUploadError] = useState();
  //image-creation-adaption-deletion
  const [v_ImgDialog, setV_ImgDialog] = useState(false);
  const [v_ImgDelDialog, setV_ImgDelDialog] = useState(false);
  const [imgUploadProg, setImgUploadProg] = useState(false);
  const [imgUploadError, setImgUploadError] = useState();
  //android
  const [v_AImgDialog, setAV_ImgDialog] = useState(false);
  const [aImgUploadProg, setAImgUploadProg] = useState(false);
  const [aImgUploadError, setAImgUploadError] = useState(false);
  //references
  const gallery = useRef(null);
  const toast = useRef(null);

  ///////////////////////
  // OUTSIDE CALLBACKS //
  ///////////////////////
  function logout() {
    if (onLogout) {
      onLogout();
    }
  }

  function save() {
    if (onSave) {
      onSave();
    }
  }

  function selectCategory(category) {
    if (onSelectCategory) {
      onSelectCategory(category);
    }
  }

  function selectView(view) {
    if (onSelectView) {
      onSelectView(view);
    }
  }

  function changeQuestionToId(questionId) {
    if (onChooseQuestion) {
      onChooseQuestion(questionId);
    }
  }

  function toggleToCategorySelect() {
    if (onToggleToCategorySelect) {
      onToggleToCategorySelect();
    }
  }

  function notifyDataChanged(question) {
    if (onAdaptQuestion && question && !readOnly) {
      onAdaptQuestion(question);
    }
  }

  function addToDocLookup(document) {
    if (!readOnly && onAddDocumentToLookup) {
      onAddDocumentToLookup(document);
    }
  }

  function addToImgLookup(image) {
    if (!readOnly && onAddImageToLookup) {
      onAddImageToLookup(image);
    }
  }

  ////////////////
  // SETUP DATA //
  ////////////////
  function prepareQuestion(question, categories) {
    if (question) {
      if (currQ === null || currQ.id !== question.frageID) {
        let data = {};
        if (question) {
          const antwortOptionen = extractAntwortOptionen(
            question.antwortOptionen
          );
          data = {
            id: question.frageID,
            text: question.frageText,
            description: question.zusatzText,
            sequence: question.sequence,
            selectedAnswerId: transformSelectedAnswerId(
              question,
              antwortOptionen
            ),
            comments:
              question.ergebnis && question.ergebnis.anmerkung
                ? question.ergebnis.anmerkung
                : "",
            answers: antwortOptionen,
            documents: extractDocuments(question),
            images: extractImages(question),
          };
        }
        setCurrQ(data);
        setCurrSeCats(filterNonSelectableCategories(categories));
      }
    }
  }

  const extractAntwortOptionen = (optionen) => {
    let data = [];
    if (optionen) {
      for (let i = 0; i < optionen.length; i++) {
        data.push({
          id: optionen[i].antwortOptionID,
          text: optionen[i].antwortOptionText,
          weight: optionen[i].gewicht,
          description: optionen[i].zusatzText,
        });
      }
    }
    return data;
  };

  const transformSelectedAnswerId = (question, antwortOptionen) => {
    if (question && question.ergebnis && question.ergebnis.antwortWert) {
      if (question.ergebnis.antwortWert === -1) {
        return -1;
      } else {
        if (antwortOptionen.length >= question.ergebnis.antwortWert) {
          return antwortOptionen[question.ergebnis.antwortWert - 1].id;
        }
      }
    }
    return null;
  };

  const extractDocuments = (question) => {
    const docs = [];
    if (
      documentLookup &&
      question &&
      question.ergebnis &&
      question.ergebnis.dokumente
    ) {
      question.ergebnis.dokumente.forEach((document) => {
        const entry = documentLookup.get(document.mediaID);
        if (entry) {
          docs.push({
            id: entry.id,
            title: entry.title,
            filename: entry.filename,
            fileSrc: offlineMode ? entry.url : URL_TO_MEDIA_SERVER + entry.url,
            timestamp: new Date(entry.createdAt),
            size: entry.fileSize,
            type: entry.type,
            copyright: entry.copyright,
            secured: entry.access ? true : false,
            fileKey: entry.access,
            mime: entry.mimetype,
          });
        }
      });
    }
    return docs;
  };

  const extractImages = (question) => {
    const images = [];
    if (
      imageLookup &&
      question &&
      question.ergebnis &&
      question.ergebnis.bilder
    ) {
      question.ergebnis.bilder.forEach((image) => {
        const entry = imageLookup.get(image.mediaID);
        let version = null;
        if (entry) {
          for (let i = 0; i < entry.versions.length; i++) {
            const v = entry.versions[i];
            //default to first version
            if (!version) {
              version = v;
            }
            //search for medium pic
            if (v && v.vLabel) {
              if (v.vLabel === DEFAULT_IMAGE_SIZE) {
                version = v;
              }
            }
          }
        }
        if (entry && version) {
          images.push({
            id: entry.id,
            url: offlineMode ? version.url : URL_TO_MEDIA_SERVER + version.url,
            key: version.access,
            alt: null,
            title: entry.title,
            copyright: entry.copyright,
            secured: version.access ? true : false,
            showPlaceholder: false,
            mime: entry.mimetype,
          });
        }
      });
    }
    return images;
  };

  const filterNonSelectableCategories = (categories) => {
    if (categories) {
      return categories.filter((value, index, arr) => {
        return value.questions > 0;
      });
    }
    return [];
  };

  ////////////////
  // VIEW LOGIC //
  ////////////////
  function toggleToNextQuestion() {
    if (question && category && category.questionIds) {
      for (let i = 0; i < category.questionIds.length; i++) {
        //found current question
        if (question.frageID === category.questionIds[i]) {
          let nextQuestionId = null;
          if (i < category.questionIds.length - 1) {
            nextQuestionId = category.questionIds[i + 1];
          } else {
            //if i is the last entry of list start at front
            nextQuestionId = category.questionIds[0];
          }
          changeQuestionToId(nextQuestionId);
          break;
        }
      }
    }
    window.scrollTo(0, 0);
  }

  function toggleToPreviousQuestion() {
    if (question && category && category.questionIds) {
      for (let i = 0; i < category.questionIds.length; i++) {
        //found current question
        if (question.frageID === category.questionIds[i]) {
          let nextQuestionId = null;
          if (i === 0) {
            //use the last entry of the list
            nextQuestionId =
              category.questionIds[category.questionIds.length - 1];
          } else {
            nextQuestionId = category.questionIds[i - 1];
          }
          changeQuestionToId(nextQuestionId);
          break;
        }
      }
    }
    window.scrollTo(0, 0);
  }

  function clickAnswer(answerId) {
    let clickedPosition = -1;
    if (answerId !== -1) {
      //search selected answer for clickedPosition
      for (let i = 0; i < question.antwortOptionen.length; i++) {
        if (question.antwortOptionen[i].antwortOptionID === answerId) {
          clickedPosition = i + 1;
        }
      }
    }
    //adapt at stored question
    let q = currQ;
    if (q.selectedAnswerId === answerId) {
      q.selectedAnswerId = null;
    } else {
      q.selectedAnswerId = answerId;
    }
    setCurrQ(q);
    //call adaption at parent
    if (!question.ergebnis) {
      question.ergebnis = {};
    }
    if (question.ergebnis.antwortWert === clickedPosition) {
      question.ergebnis.antwortWert = null;
    } else {
      question.ergebnis.antwortWert = clickedPosition;
    }
    notifyDataChanged(question);
  }

  function adaptComment(value) {
    //adapt at stored question
    let q = currQ;
    q.comments = value;
    setCurrQ(q);
    //call adaption at parent
    if (question) {
      let qu = question;
      if (!qu.ergebnis) {
        qu.ergebnis = {};
      }
      qu.ergebnis.anmerkung = value;
      notifyDataChanged(qu);
    }
  }

  function clickImageCallback(entryId) {
    if (gallery && gallery.current) {
      for (let i = 0; i < currQ.images.length; i++) {
        if (currQ.images[i].id === entryId) {
          gallery.current.showImage(i);
          break;
        }
      }
      gallery.current.openGallery();
    }
  }

  function downloadDocument(entryId) {
    for (let i = 0; i < currQ.documents.length; i++) {
      if (currQ.documents[i].id === entryId) {
        const src = currQ.documents[i].fileSrc;
        const secretKey = currQ.documents[i].fileKey;
        if (offlineMode) {
          //just open the document
          openStoredMediaFileOnAndroid(
            liegenschaftId,
            projectId,
            entryId,
            currQ.documents[i].filename,
            currQ.documents[i].mime,
            function error() {
              toast.current.show({
                severity: "error",
                summary: "Fehler",
                detail: "Das Dokument kann leider nicht geöffnet werden",
                sticky: false,
                closable: true,
                life: 2000,
              });
            }
          );
        } else {
          downloadDocumentFromServer(src, secretKey)
            .then((entry) => {
              execIfOnWeb(() =>
                fileDownload(entry, currQ.documents[i].filename)
              );
              execIfOnAndroid(() =>
                writeDocumentFromBlobOnAndroid(
                  entry,
                  currQ.documents[i].filename,
                  entry.type,
                  true
                )
              );
            })
            .catch((error) => {
              toast.current.show({
                severity: "error",
                summary: "Fehler",
                detail: "Der Download ist leider fehlgeschlagen",
                sticky: false,
                closable: true,
                life: 2000,
              });
            });
        }
        break;
      }
    }
  }

  function createNewDocument(data) {
    if (data) {
      setDocUploadProg(true);
      setDocUploadError(null);
      if (offlineMode) {
        //store for offline usage
        storeOfflineMediaOnAndroidInProject(
          liegenschaftId,
          projectId,
          data.file,
          data.file.name,
          function success(payload) {
            let convertedDoc = null;
            if (currQ) {
              const q = currQ;
              if (!q.documents) {
                q.documents = [];
              }
              convertedDoc = {
                id: payload.id,
                title: data.name,
                filename: data.file.name,
                fileSrc: payload.url,
                timestamp: new Date(),
                size: data.file.size,
                type: data.file.type,
                fileKey: null,
                copyright: null,
                secured: false,
                mime: data.file.type,
              };
              q.documents.push(convertedDoc);
              setCurrQ(q);
            }
            //call adaption at parent
            if (question) {
              let qu = question;
              //if there is no "ergebnis"
              if (!qu.ergebnis) {
                qu.ergebnis = {};
              }
              //if there is no "dokumente" in "ergebnis"
              if (!qu.ergebnis.dokumente) {
                qu.ergebnis.dokumente = [];
              }
              qu.ergebnis.dokumente.push({
                mediaID: payload.id,
                mediaName: data.name,
                mediaTyp: data.file.type,
                localURI: payload.url,
                remoteURI: payload.url,
                datum: new Date(),
                dateiGroesse: data.file.size,
                dateiName: data.file.name,
                additionalProperties: {},
              });
              notifyDataChanged(qu);
            }
            //add entry to documentlookup
            addToDocLookup(convertedDoc);
            setDocUploadProg(false);
            setV_DocDialog(false);
          },
          function error(error) {
            setDocUploadProg(false);
            setDocUploadError("Speicherung ist leider fehlgeschlagen.");
          }
        );
      } else {
        uploadDocumentToServer(data.file, data.name, data.originalFileName)
          .then((document) => {
            if (document) {
              if (currQ) {
                const q = currQ;
                if (!q.documents) {
                  q.documents = [];
                }
                const convertedDoc = {
                  id: document.id,
                  title: document.title,
                  filename: document.filename,
                  fileSrc: URL_TO_MEDIA_SERVER + document.url,
                  timestamp: new Date(document.createdAt),
                  size: document.fileSize,
                  type: document.type,
                  copyright: document.copyright,
                  secured: document.access ? true : false,
                  fileKey: document.access,
                };
                q.documents.push(convertedDoc);
                setCurrQ(q);
              }
              //call adaption at parent
              if (question) {
                let qu = question;
                //if there is no "ergebnis"
                if (!qu.ergebnis) {
                  qu.ergebnis = {};
                }
                //if there is no "dokumente" in "ergebnis"
                if (!qu.ergebnis.dokumente) {
                  qu.ergebnis.dokumente = [];
                }
                qu.ergebnis.dokumente.push({
                  mediaID: document.id,
                  mediaName: document.title,
                  mediaTyp: document.type,
                  localURI: null,
                  remoteURI: URL_TO_MEDIA_SERVER + document.url,
                  datum: document.createdAt,
                  dateiGroesse: document.fileSize,
                  dateiName: document.filename,
                  additionalProperties: {},
                });
                notifyDataChanged(qu);
              }
              //add entry to documentlookup
              addToDocLookup(document);
            }
            setDocUploadProg(false);
            setV_DocDialog(false);
          })
          .catch((error) => {
            setDocUploadProg(false);
            setDocUploadError("Upload ist leider fehlgeschlagen.");
          });
      }
    }
  }

  function createNewImage(data, androidVersion) {
    if (data) {
      if (androidVersion) {
        setAImgUploadProg(true);
        setAImgUploadError(null);
      } else {
        setImgUploadProg(true);
        setImgUploadError(null);
      }
      if (offlineMode) {
        //store for offline usage
        storeOfflineMediaOnAndroidInProject(
          liegenschaftId,
          projectId,
          data.file,
          "IMG_" + uuidv4() + ".jpeg",
          function success(payload) {
            if (payload) {
              //add image to stored question
              if (currQ) {
                const q = currQ;
                if (!q.images) {
                  q.images = [];
                }
                const convertedImage = {
                  id: payload.id,
                  url: payload.url,
                  key: null,
                  alt: null,
                  title: data.name,
                  copyright: null,
                  secured: false,
                  showPlaceholder: false,
                };
                q.images.push(convertedImage);
                setCurrQ(q);
              }
              //call adaption at parent
              if (question) {
                let qu = question;
                //if there is no "ergebnis"
                if (!qu.ergebnis) {
                  qu.ergebnis = {};
                }
                //if there is no "bilder" in "ergebnis"
                if (!qu.ergebnis.bilder) {
                  qu.ergebnis.bilder = [];
                }
                qu.ergebnis.bilder.push({
                  mediaID: payload.id,
                });
                notifyDataChanged(qu);
              }
              const imageForStore = {
                id: payload.id,
                title: data.name,
                filename: data.file.name,
                fileSize: data.file.size,
                copyright: null,
                createdAt: new Date(),
                createdFromUser: null,
                orientation: "VERTICAL",
                dominantColor: null,
                keywords: null,
                versions: [
                  {
                    id: payload.id,
                    getvLabel: "ORIGIN",
                    height: 0,
                    width: 0,
                    url: payload.url,
                    type: data.file.type,
                    access: null,
                    access_expire: null,
                  },
                ],
              };
              //add entry to imageLookup
              addToImgLookup(imageForStore);
              setAImgUploadProg(false);
              setAV_ImgDialog(false);
            }
          },
          function error(error) {
            setAImgUploadProg(false);
            setAImgUploadError("Die Speicherung ist leider fehlgeschlagen.");
          }
        );
      } else {
        uploadImageToServer(data.file, data.name, data.originalFileName)
          .then((image) => {
            if (image) {
              //add image to stored question
              if (currQ) {
                const q = currQ;
                if (!q.images) {
                  q.images = [];
                }
                let version = null;
                for (let i = 0; i < image.versions.length; i++) {
                  const v = image.versions[i];
                  //default to first version
                  if (!version) {
                    version = v;
                  }
                  //search for medium pic
                  if (v && v.vLabel) {
                    if (v.vLabel === DEFAULT_IMAGE_SIZE) {
                      version = v;
                    }
                  }
                }
                const convertedImage = {
                  id: image.id,
                  url: URL_TO_MEDIA_SERVER + version.url,
                  key: version.access,
                  alt: null,
                  title: image.title,
                  copyright: image.copyright,
                  secured: version.access ? true : false,
                  showPlaceholder: false,
                };
                q.images.push(convertedImage);
                setCurrQ(q);
              }
              //call adaption at parent
              if (question) {
                let qu = question;
                //if there is no "ergebnis"
                if (!qu.ergebnis) {
                  qu.ergebnis = {};
                }
                //if there is no "bilder" in "ergebnis"
                if (!qu.ergebnis.bilder) {
                  qu.ergebnis.bilder = [];
                }
                qu.ergebnis.bilder.push({
                  mediaID: image.id,
                });
                notifyDataChanged(qu);
              }
              //add entry to imageLookup
              addToImgLookup(image);
            }
            if (androidVersion) {
              setAImgUploadProg(false);
              setAV_ImgDialog(false);
            } else {
              setImgUploadProg(false);
              setV_ImgDialog(false);
            }
          })
          .catch((error) => {
            if (androidVersion) {
              setAImgUploadProg(false);
              setAImgUploadError("Upload ist leider fehlgeschlagen.");
            } else {
              setImgUploadProg(false);
              setImgUploadError("Upload ist leider fehlgeschlagen.");
            }
          });
      }
    }
  }

  function deleteDocument(entryId) {
    //remove document from stored questions
    if (currQ) {
      const q = currQ;
      if (!q.documents) {
        q.documents = [];
      }
      q.documents = q.documents.filter((item) => {
        return item.id !== entryId;
      });
      setCurrQ(q);
    }
    //call adaption at parent
    if (question) {
      let qu = question;
      //if there is no "ergebnis"
      if (!qu.ergebnis) {
        qu.ergebnis = {};
      }
      //if there is no "dokumente" in "ergebnis"
      if (!qu.ergebnis.dokumente) {
        qu.ergebnis.dokumente = [];
      }
      qu.ergebnis.dokumente = qu.ergebnis.dokumente.filter((item) => {
        return item.mediaID !== entryId;
      });
      notifyDataChanged(qu);
    }
  }

  function deleteImage(entryId) {
    //remove document from stored questions
    if (currQ) {
      const q = currQ;
      if (!q.images) {
        q.images = [];
      }
      q.images = q.images.filter((item) => {
        return item.id !== entryId;
      });
      setCurrQ(q);
    }
    //call adaption at parent
    if (question) {
      let qu = question;
      //if there is no "ergebnis"
      if (!qu.ergebnis) {
        qu.ergebnis = {};
      }
      //if there is no "bilder" in "ergebnis"
      if (!qu.ergebnis.bilder) {
        qu.ergebnis.bilder = [];
      }
      qu.ergebnis.bilder = qu.ergebnis.bilder.filter((item) => {
        return item.mediaID !== entryId;
      });
      notifyDataChanged(qu);
    }
  }

  ////////////
  // HELPER //
  ////////////
  function convertDocumentIdToTitle(documentId) {
    const defaultValue = "dieses Dokument";
    if (documentLookup) {
      const val = documentLookup.get(documentId);
      return val && val.title ? val.title : defaultValue;
    }
    return defaultValue;
  }

  function convertImageIdToTitle(imageId) {
    const defaultValue = "dieses Bild";
    if (imageLookup) {
      const val = imageLookup.get(imageId);
      return val && val.title ? val.title : defaultValue;
    }
    return defaultValue;
  }

  ////////////////////////////
  // INJECTED VIEW ELEMENTS //
  ////////////////////////////

  const injectFileDeletionConfirmationDialog = () => {
    return (
      <BeeDeletionDialog
        id={"bee-audit-ui-audit-confirm-document-deletion"}
        visible={v_DocDelDialog}
        message={
          "Möchten Sie " +
          convertDocumentIdToTitle(currDocId) +
          " wirklich löschen?"
        }
        acceptLabel={"Löschen"}
        rejectLabel={"Abbrechen"}
        header={"Dokument löschen"}
        type={"primary"}
        onAccept={() => {
          if (currDocId) {
            deleteDocument(currDocId);
          }
          setV_DocDelDialog(false);
        }}
        onReject={() => setV_DocDelDialog(false)}
        onHide={() => setV_DocDelDialog(false)}
      />
    );
  };

  const injectImageDeletionConfirmationDialog = () => {
    return (
      <BeeDeletionDialog
        id={"bee-audit-ui-audit-confirm-image-deletion"}
        visible={v_ImgDelDialog}
        message={
          "Möchten Sie " +
          convertImageIdToTitle(currImgId) +
          " wirklich löschen?"
        }
        acceptLabel={"Löschen"}
        rejectLabel={"Abbrechen"}
        header={"Bild löschen"}
        type={"primary"}
        onAccept={() => {
          if (currImgId) {
            deleteImage(currImgId);
          }
          setV_ImgDelDialog(false);
        }}
        onReject={() => setV_ImgDelDialog(false)}
        onHide={() => setV_ImgDelDialog(false)}
      />
    );
  };

  const injectAndroidCameraDialog = () => {
    return (
      <BeeAndroidCameraDialog
        id={"bee-audit-question-ui-document"}
        header={"Bild hochladen"}
        visible={v_AImgDialog}
        showProgress={aImgUploadProg}
        errorMsg={aImgUploadError}
        type={"primary"}
        img_quality={IMG_ANDROID_QUALITY}
        img_width={IMG_ANDROID_WIDTH}
        img_height={IMG_ANDROID_HEIGHT}
        img_preserveAspectRatio={IMG_ANDROID_PRESERVE_ASPECT_RATIO}
        onHide={() => setAV_ImgDialog(false)}
        onUpload={(data) => {
          createNewImage(data, true);
        }}
      />
    );
  };

  const injectFileUploadDialog = () => {
    return (
      <BeeUploadDialog
        type={"primary"}
        visible={v_DocDialog}
        disabled={false}
        locale={"de-DE"}
        header={"Dokument hochladen"}
        info={null}
        titleLabel={"Benennung"}
        titleVisible={true}
        copyrightLabel={null}
        copyrightVisible={false}
        dropzoneTitle={"Upload"}
        dropzoneDescription={
          "Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder Klicken Sie hinzufügen"
        }
        dropzoneAddLabel={"Klicken zum Hinzufügen"}
        dropzoneFormats={ACCEPTED_FILE_FORMATS}
        progressVisible={docUploadProg}
        progressMessage={"Dokument wird hochgeladen"}
        errorVisible={docUploadError ? true : false}
        errorHeadline={docUploadError}
        errorDescription={docUploadError}
        onHide={() => setV_DocDialog(false)}
        onUpload={(data) => createNewDocument(data, false)}
      />
    );
  };

  const injectImageUploadDialog = () => {
    return (
      <BeeUploadDialog
        type={"primary"}
        visible={v_ImgDialog}
        disabled={false}
        locale={"de-DE"}
        header={"Bild hochladen"}
        info={null}
        titleLabel={"Benennung"}
        titleVisible={true}
        copyrightLabel={null}
        copyrightVisible={false}
        dropzoneTitle={"Upload"}
        dropzoneDescription={
          "Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder Klicken Sie hinzufügen"
        }
        dropzoneAddLabel={"Klicken zum Hinzufügen"}
        dropzoneFormats={ACCEPTED_IMAGE_FORMATS}
        progressVisible={imgUploadProg}
        progressMessage={"Bild wird hochgeladen"}
        errorVisible={imgUploadError ? true : false}
        errorHeadline={imgUploadError}
        errorDescription={imgUploadError}
        onHide={() => setV_ImgDialog(false)}
        onUpload={(data) => createNewImage(data, false)}
      />
    );
  };

  const injectImageGallery = () => {
    return (
      <BeeImageSlider
        className={"bee-img-slider"}
        type={"primary"}
        ref={gallery}
        images={currQ ? currQ.images : []}
        activeIndex={0}
      />
    );
  };

  const injectNotificationToast = () => {
    return (
      <BeeToast
        id={"bee-audit-ui-question-toast"}
        ref={toast}
        position={"top-right"}
      />
    );
  };

  function calcProgress() {
    const data = convertCategoryTree(_.cloneDeep(categoryTree));
    if (data && data.length > 0) {
      const val = data.filter((obj) => {
        return obj.id === category.id;
      });
      if (val && val.length === 1) {
        const progress = val[0].progress ? val[0].progress : 0;
        const questions = val[0].questions ? val[0].questions : 1;
        const completion = Math.round((progress / questions) * 100);
        if (categoryProgress !== completion) {
          setCategoryProgress(completion);
        }
      }
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => toggleToNextQuestion(),
    onSwipedRight: (eventData) => toggleToPreviousQuestion(),
  });

  return (
    <div {...handlers}>
      {prepareQuestion(question, categories)}
      {calcProgress()}
      <BeeAuditQuestion
        homeUrl={homeUrl}
        breadcrumbData={breadcrumbData}
        showSave={!readOnly}
        showLogout={showLogout}
        warningMessage={warningMessage}
        categoryProgressPercent={categoryProgress}
        overallProgress={
          categoryTree && categoryTree.length > 0 && categoryTree[0].data
            ? categoryTree[0].data.progress
            : 0
        }
        maxOverallProgress={
          categoryTree && categoryTree.length > 0 && categoryTree[0].data
            ? categoryTree[0].data.questions
            : 0
        }
        question={currQ}
        viewOptions={viewOptions}
        selectedView={selectedView}
        categories={currSeCats}
        selectedCategory={category}
        readOnly={readOnly}
        onSave={() => save()}
        onLogout={() => logout()}
        onAnswerClick={(e, answerId) => clickAnswer(answerId)}
        onCommentChanged={(value) => adaptComment(value)}
        onImageClick={(imageId) => clickImageCallback(imageId)}
        onImageRemove={(imageId) => {
          setCurrImgId(imageId);
          setV_ImgDelDialog(true);
        }}
        onImageAdd={() => {
          execIfOnWeb(() => setV_ImgDialog(true));
          execIfOnAndroid(() => setAV_ImgDialog(true));
        }}
        onDocumentDownload={(documentId) => downloadDocument(documentId)}
        onDocumentDelete={(documentId) => {
          setCurrDocId(documentId);
          setV_DocDelDialog(true);
        }}
        onDocumentAdd={() => setV_DocDialog(true)}
        onPreviousQuestion={() => toggleToPreviousQuestion()}
        onNextQuestion={() => toggleToNextQuestion()}
        onSelectCategory={(category) => selectCategory(category)}
        onSelectView={(view) => selectView(view)}
        onToggleToCategoryView={() => toggleToCategorySelect()}
      />
      {/* dialogs */}
      {v_DocDialog ? injectFileUploadDialog() : null}
      {injectFileDeletionConfirmationDialog()}
      {v_ImgDialog ? injectImageUploadDialog() : null}
      {injectImageDeletionConfirmationDialog()}
      {v_AImgDialog ? injectAndroidCameraDialog() : null}
      {/* gallery */}
      {injectImageGallery()}
      {/* toast */}
      {injectNotificationToast()}
    </div>
  );
}

export default AuditUIQuestion;
