import React, { useState } from "react";
import { useId } from "react-id-generator";
import BeeAuditHeader from "../Fragments/BeeAuditHeader";
import BeeAuditSetupLeistungsubersicht from "../Fragments/BeeAuditSetupLeistungsubersicht";
import {
  BeeValidatedDropDown,
  BeeValidatedTextInput,
  BeeLinkButton,
  BeeContentHeadline,
  BeeButton,
  BeeOutlinedButton,
} from "bee-atomic-components";
import "./BeeAuditSetup.scss";

export function BeeAuditSetup({
  templates,
  selectedTemplate,
  title,
  leistungsSetup,
  homeUrl,
  breadcrumbData,
  warningMessage,
  liegenschaftTitle,
  isCurrencySetup,
  lvTemplateUploaded,
  // showLoadingTemplates,
  // errorLoadingTemplates,
  // showLoadingLeistungsSetup,
  // errorLoadingLeistungsSetup,
  onCancel,
  onCreateAudit,
  onLogout,
  onSelectTemplate,
  onChangeTitle,
  onToggleSelection,
  onAdaptValue,
  onDownloadTemplate,
  onUploadTemplate,
  onConnectBeestate,
}) {
  const currentId = useId(1, "bee-audit-setup-")[0];

  const [errorType, setErrorType] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [adaptedType, setAdaptedType] = useState(false);
  const [adaptedTitle, setAdaptedTitle] = useState(false);

  // showLoadingTemplates       => true wenn noch templates geladen werden für das dropdown (wird zuerst geladen)
  // errorLoadingTemplates      => wenn das templates laden schiefgelaufen ist
  // showLoadingLeistungsSetup  => true wenn noch daten geladen werden für die tabelle (wird im anschluss geladen)
  // errorLoadingLeistungsSetup => wenn das daten laden schiefgelaufen ist

  //classNames
  const headlineClassNames = "bee-audit-setup-headline";
  const auditTypeClassNames = "bee-audit-setup-type";
  const auditTitleClassNames = "bee-audit-setup-title";
  const LVBtnSectionClassNames = "bee-audit-setup-LV-btns";
  const LVSectionHeadline = "Leistungsverzeichnis hinterlegen";
  const classNames = "bee-audit-setup";
  const contentClassNames = "m-4 p-2";
  const footerBtnClassNames = "grid justify-content-end bee-audit-setup-btns";

  //label
  const headline = "Neues Audit für " + liegenschaftTitle;
  const titleErrorLabel =
    errorTitle && !adaptedTitle ? "Bitte Titel eingeben" : null;
  const titleNeutralLabel =
    errorTitle && adaptedTitle ? "Bitte Titel eingeben" : null;
  const titleLabel = "Titel der Qualitätsmaßname";
  const auditTypeLabel = "Typ des Audits";
  const auditTypeErrorLabel =
    errorType && !adaptedType ? "Bitte Typ auswählen" : null;
  const auditTypeNeutralLabel =
    errorType && adaptedType ? "Bitte Typ auswählen" : null;

  const cancleBtnLabel = "Abbrechen";
  const createBtnLabel = "Neues Audit anlegen";
  const downloadLVBtnLabel = "LV-Template herunterladen";
  const uploadLVBtnLabel = "LV aus Template hochladen";
  const connectLVBtnLabel = "LV aus Beestate beziehen";

  function callLogout(e) {
    if (onLogout) {
      onLogout(e);
    }
  }

  function cancelSetup(e) {
    if (onCancel) {
      onCancel(e);
    }
  }

  function selectTemplate(template) {
    setAdaptedType(true);
    if (onSelectTemplate) {
      onSelectTemplate(template);
    }
  }

  function changeTitle(e) {
    setAdaptedTitle(true);
    if (onChangeTitle) {
      onChangeTitle(e.target.value);
    }
  }

  //only fire accept callback if audit data are valid!
  function createAudit(e) {
    if (validate()) {
      if (onCreateAudit) {
        onCreateAudit(e);
      }
    }
  }

  function changeSelection(newStatus, data) {
    if (onToggleSelection) {
      onToggleSelection(newStatus, data);
    }
  }

  function changeValue(newValue, data) {
    if (onAdaptValue) {
      onAdaptValue(newValue, data);
    }
  }

  function downloadTemplate(e) {
    if (onDownloadTemplate) {
      onDownloadTemplate(e);
    }
  }

  function uploadTemplate(e) {
    if (onUploadTemplate) {
      onUploadTemplate(e);
    }
  }

  function connectToBeestate(e) {
    if (onConnectBeestate) {
      onConnectBeestate(e);
    }
  }

  function validateTitle() {
    //adapt larger checks
    return title ? true : false;
  }

  function validate() {
    //set all adapted to false
    setAdaptedType(false);
    setAdaptedTitle(false);
    //test errors
    const validType = selectedTemplate ? true : false;
    const validTitle = validateTitle();
    //show errors
    setErrorType(!validType);
    setErrorTitle(!validTitle);
    return validType && validTitle;
  }

  const injectHeader = () => {
    return (
      <BeeAuditHeader
        homeUrl={homeUrl}
        items={breadcrumbData}
        type={"dark"}
        warningMessage={warningMessage}
        showAnomalies={false}
        showSave={true}
        showLogout={true}
        showProgress={false}
        onSave={(e) => createAudit(e)}
        onLogout={(e) => callLogout(e)}
      />
    );
  };

  const injectHeadline = () => {
    return (
      <div className={headlineClassNames}>
        <BeeContentHeadline label={headline} headline={"h1"} type={"primary"} />
      </div>
    );
  };

  const injectDropdown = () => {
    return (
      <div className={auditTypeClassNames}>
        <BeeValidatedDropDown
          label={auditTypeLabel}
          errorLabel={auditTypeErrorLabel}
          neutralLabel={auditTypeNeutralLabel}
          value={selectedTemplate}
          options={templates}
          optionLabel={"title"}
          disabled={false}
          formstate={
            errorType && adaptedType ? "neutral" : errorType ? "error" : "none"
          }
          readOnly={false}
          required={true}
          onChange={(template) => selectTemplate(template)}
        />
      </div>
    );
  };

  const injectTitleInput = () => {
    return (
      <div className={auditTitleClassNames}>
        <BeeValidatedTextInput
          label={titleLabel}
          errorLabel={titleErrorLabel}
          neutralLabel={titleNeutralLabel}
          value={title}
          disabled={false}
          formstate={
            errorTitle && adaptedTitle
              ? "neutral"
              : errorTitle
              ? "error"
              : "none"
          }
          readOnly={false}
          required={true}
          onChange={(e) => changeTitle(e)}
        />
      </div>
    );
  };

  const injectLeistungsubersicht = () => {
    return (
      <BeeAuditSetupLeistungsubersicht
        liegenschaftTitle={null}
        type={"dark"}
        data={leistungsSetup}
        readOnly={false}
        currencyMode={isCurrencySetup}
        currency={"EUR"}
        locale={"de-DE"}
        onToggleSelection={(newStatus, data) =>
          changeSelection(newStatus, data)
        }
        onAdaptValue={(newValue, data) => changeValue(newValue, data)}
      />
    );
  };

  const injectCancelButton = () => {
    return (
      <BeeLinkButton
        label={cancleBtnLabel}
        raised={false}
        disabled={false}
        type={"primary"}
        onClick={(e) => cancelSetup(e)}
      />
    );
  };

  const injectCreateButton = () => {
    return (
      <BeeButton
        label={createBtnLabel}
        disabled={!(title && selectedTemplate)}
        type={"secondary"}
        onClick={(e) => createAudit(e)}
      />
    );
  };

  const injectLeistungsverzeichnisSetup = () => {
    return (
      <div className={LVBtnSectionClassNames}>
        <BeeContentHeadline
          label={LVSectionHeadline}
          headline={"h2"}
          type={"primary"}
        />
        <div className="grid">
          <div className="col-12 sm:col-4">
            <BeeOutlinedButton
              label={downloadLVBtnLabel}
              disabled={!leistungsSetup}
              type={"primary"}
              onClick={(e) => downloadTemplate(e)}
            />
          </div>
          <div className="col-12 sm:col-4">
            <BeeButton
              label={uploadLVBtnLabel}
              disabled={!leistungsSetup}
              type={"secondary"}
              onClick={(e) => uploadTemplate(e)}
            />
          </div>
          <div className="col-12 sm:col-4">
            <BeeButton
              label={connectLVBtnLabel}
              disabled={!leistungsSetup}
              type={"primary"}
              onClick={(e) => connectToBeestate(e)}
            />
          </div>
        </div>
        {lvTemplateUploaded ? (
          <div className={"bee-audit-setup-lv-upload-successful"}>
            {
              "Ein LV wurde hochgeladen, darauf aufbauend wird eine Leistungsprüfung durchgeführt."
            }
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div id={currentId} className={classNames}>
      {injectHeader()}
      <div className={contentClassNames}>
        {injectHeadline()}
        {injectDropdown()}
        {injectTitleInput()}
        {leistungsSetup ? injectLeistungsverzeichnisSetup() : null}
        {leistungsSetup ? injectLeistungsubersicht() : null}
        <div className={footerBtnClassNames}>
          {injectCancelButton()}
          {injectCreateButton()}
        </div>
      </div>
    </div>
  );
}

export default BeeAuditSetup;
