import React from "react";
import { useId } from "react-id-generator";
import {
  BeeValidatedTextInput,
  BeeValidatedDateInput,
  BeeValidatedTextArea,
} from "bee-atomic-components";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditInputGrunddaten.scss";

export function BeeAuditInputGrunddaten({
  title,
  number,
  reportDate,
  auditDate,
  auditor,
  description,
  serviceProvider,
  serviceProviderShort,
  authority,
  isAdaptedTitle,
  isAdaptedNumber,
  isAdaptedReportDate,
  isAdaptedAuditDate,
  isAdaptedAuditor,
  isAdaptedDescription,
  isAdaptedServiceProvider,
  isAdaptedServiceProviderShort,
  isAdaptedAuthority,
  isErrorTitle,
  isErrorNumber,
  isErrorReportDate,
  isErrorAuditDate,
  isErrorAuditor,
  isErrorDescription,
  isErrorServiceProvider,
  isErrorServiceProviderShort,
  isErrorAuthority,
  readOnly,
  onChangeTitle,
  onChangeNumber,
  onChangeReportDate,
  onChangeAuditDate,
  onChangeAuditor,
  onChangeDescription,
  onChangeServiceProvider,
  onChangeAuthority,
  onChangeServiceProviderShort,
}) {
  const currentId = useId(1, "bee-audit-form-grunddaten-")[0];

  //classNames
  const classNames = "bee-audit-form-grunddaten";
  const gridClassNames = "grid";
  const titleColClassNames = "col-12 sm:col-12 md:col-6 lg:col-6";
  const numberColClassNames = "col-12 sm:col-12 md:col-3 lg:col-3";
  const reportDateColClassNames = "col-12 sm:col-12 md:col-3 lg:col-3";
  const auditDateColClassNames = "col-12 sm:col-12 md:col-3 lg:col-3";
  const auditorColClassNames = "col-12 sm:col-12 md:col-9 lg:col-9";
  const descriptionColClassNames = "col-12 sm:col-12 md:col-12 lg:col-12";
  const serviceProvColClassNames = "col-12 sm:col-12 md:col-6 lg:col-6";
  const authorityColClassNames = "col-12 sm:col-12 md:col-6 lg:col-3";
  const serviceProvShortColClassNames = "col-12 sm:col-12 md:col-12 lg:col-3";

  //setup values and naming
  const title_label = "Audit-Titel";
  const title_error_msg = "Bitte Titel angeben.";
  const number_label = "Audit-Nummer";
  const number_error_msg = "Bitte Audit-Nummer angeben.";
  const reportDate_label = "Berichtsdatum";
  const reportDate_error_msg = "Bitte Berichtsdatum angeben.";
  const auditDate_label = "Auditdatum";
  const auditDate_error_msg = "Bitte Auditdatum angeben.";
  const auditor_label = "Auditor";
  const auditor_error_msg = "Bitte Auditor angeben.";
  const description_label = "Beschreibung";
  const description_error_msg = "Bitte Beschreibung angeben.";
  const serviceProvider_label = "Dienstleister (DL)";
  const serviceProvider_error_msg = "Bitte Dienstleister angeben.";
  const authority_label = "Verantwortlicher DL";
  const authority_error_msg = "Bitte Verantwortlicher angeben.";
  const serviceProviderShort_label = "DL Kurzname";
  const serviceProviderShort_error_msg = "Bitte DL Kurzname angeben.";

  function changeTitle(value) {
    if (onChangeTitle) {
      onChangeTitle(value);
    }
  }

  function changeNumber(value) {
    if (onChangeNumber) {
      onChangeNumber(value);
    }
  }

  function changeReportDate(value) {
    if (onChangeReportDate) {
      onChangeReportDate(value);
    }
  }

  function changeAuditDate(value) {
    if (onChangeAuditDate) {
      onChangeAuditDate(value);
    }
  }
  
  function changeAuditor(value) {
    if (onChangeAuditor) {
      onChangeAuditor(value);
    }
  }

  function changeDescription(value) {
    if (onChangeDescription) {
      onChangeDescription(value);
    }
  }

  function changeServiceProvider(value) {
    if (onChangeServiceProvider) {
      onChangeServiceProvider(value);
    }
  }

  function changeAuthority(value) {
    if (onChangeAuthority) {
      onChangeAuthority(value);
    }
  }
  
  function changeServiceProviderShort(value) {
    if (onChangeServiceProviderShort) {
      onChangeServiceProviderShort(value);
    }
  }

  const inputServiceProviderShort = () => {
    return (
      <BeeValidatedTextInput
        readOnly={readOnly}
        label={serviceProviderShort_label}
        errorLabel={
          isErrorServiceProviderShort && !isAdaptedServiceProviderShort
            ? serviceProviderShort_error_msg
            : null
        }
        neutralLabel={
          isErrorServiceProviderShort && isAdaptedServiceProviderShort
            ? serviceProvider_error_msg
            : null
        }
        value={serviceProviderShort ? serviceProviderShort : ""}
        formstate={
          isErrorServiceProviderShort && isAdaptedServiceProviderShort
            ? "neutral"
            : isErrorServiceProviderShort
            ? "error"
            : "none"
        }
        disabled={false}
        required={true}
        onChange={(e) => changeServiceProviderShort(e.target.value)}
      />
    );
  };

  const inputAuthority = () => {
    return (
      <BeeValidatedTextInput
        readOnly={readOnly}
        label={authority_label}
        errorLabel={
          isErrorAuthority && !isAdaptedAuthority ? authority_error_msg : null
        }
        neutralLabel={
          isErrorAuthority && isAdaptedAuthority ? authority_error_msg : null
        }
        value={authority ? authority : ""}
        formstate={
          isErrorAuthority && isAdaptedAuthority
            ? "neutral"
            : isErrorAuthority
            ? "error"
            : "none"
        }
        disabled={false}
        required={true}
        onChange={(e) => changeAuthority(e.target.value)}
      />
    );
  };

  const inputServiceProvider = () => {
    return (
      <BeeValidatedTextInput
        readOnly={readOnly}
        label={serviceProvider_label}
        errorLabel={
          isErrorServiceProvider && !isAdaptedServiceProvider
            ? serviceProvider_error_msg
            : null
        }
        neutralLabel={
          isErrorServiceProvider && isAdaptedServiceProvider
            ? serviceProvider_error_msg
            : null
        }
        value={serviceProvider ? serviceProvider : ""}
        formstate={
          isErrorServiceProvider && isAdaptedServiceProvider
            ? "neutral"
            : isErrorServiceProvider
            ? "error"
            : "none"
        }
        disabled={false}
        required={true}
        onChange={(e) => changeServiceProvider(e.target.value)}
      />
    );
  };

  const inputDescription = () => {
    return (
      <BeeValidatedTextArea
        readOnly={readOnly}
        disabled={false}
        label={description_label}
        errorLabel={
          isErrorDescription && !isAdaptedDescription
            ? description_error_msg
            : null
        }
        neutralLabel={
          isErrorDescription && isAdaptedDescription
            ? description_error_msg
            : null
        }
        value={description ? description : ""}
        rows={readOnly ? 1 : 5}
        formstate={
          isErrorDescription && isAdaptedDescription
            ? "neutral"
            : isErrorDescription
            ? "error"
            : "none"
        }
        autoResize={true}
        required={true}
        onChange={(e) => changeDescription(e.target.value)}
      />
    );
  };

  const inputReportDate = () => {
    return (
      <BeeValidatedDateInput
        label={reportDate_label}
        errorLabel={
          isErrorReportDate && !isAdaptedReportDate
            ? reportDate_error_msg
            : null
        }
        neutralLabel={
          isErrorReportDate && isAdaptedReportDate ? reportDate_error_msg : null
        }
        value={reportDate}
        disabled={false}
        formstate={
          isErrorReportDate && isAdaptedReportDate
            ? "neutral"
            : isErrorReportDate
            ? "error"
            : "none"
        }
        readOnly={readOnly}
        required={false}
        onChange={(e) => changeReportDate(e.value)}
      />
    );
  };

  const inputAuditDate = () => {
    return (
      <BeeValidatedDateInput
        label={auditDate_label}
        errorLabel={
          isErrorAuditDate && !isAdaptedAuditDate ? auditDate_error_msg : null
        }
        neutralLabel={
          isErrorAuditDate && isAdaptedAuditDate ? auditDate_error_msg : null
        }
        value={auditDate}
        disabled={false}
        formstate={
          isErrorAuditDate && isAdaptedAuditDate
            ? "neutral"
            : isErrorAuditDate
            ? "error"
            : "none"
        }
        readOnly={readOnly}
        required={false}
        onChange={(e) => changeAuditDate(e.value)}
      />
    );
  };

  const inputNumber = () => {
    return (
      <BeeValidatedTextInput
        readOnly={readOnly}
        label={number_label}
        errorLabel={isErrorNumber && !isAdaptedNumber ? number_error_msg : null}
        neutralLabel={
          isErrorNumber && isAdaptedNumber ? number_error_msg : null
        }
        value={number ? number : ""}
        formstate={
          isErrorNumber && isAdaptedNumber
            ? "neutral"
            : isErrorNumber
            ? "error"
            : "none"
        }
        disabled={false}
        required={true}
        onChange={(e) => changeNumber(e.target.value)}
      />
    );
  };

  const inputTitle = () => {
    return (
      <BeeValidatedTextInput
        readOnly={readOnly}
        label={title_label}
        errorLabel={isErrorTitle && !isAdaptedTitle ? title_error_msg : null}
        neutralLabel={isErrorTitle && isAdaptedTitle ? title_error_msg : null}
        value={title ? title : ""}
        disabled={false}
        formstate={
          isErrorTitle && isAdaptedTitle
            ? "neutral"
            : isErrorTitle
            ? "error"
            : "none"
        }
        required={true}
        onChange={(e) => changeTitle(e.target.value)}
      />
    );
  };

  const inputAuditor = () => {
    return (
      <BeeValidatedTextInput
        readOnly={readOnly}
        label={auditor_label}
        errorLabel={
          isErrorAuditor && !isAdaptedAuditor ? auditor_error_msg : null
        }
        neutralLabel={
          isErrorAuditor && isAdaptedAuditor ? auditor_error_msg : null
        }
        value={auditor ? auditor : ""}
        disabled={false}
        formstate={
          isErrorAuditor && isAdaptedAuditor
            ? "neutral"
            : isErrorAuditor
            ? "error"
            : "none"
        }
        required={true}
        onChange={(e) => changeAuditor(e.target.value)}
      />
    );
  };

  return (
    <div id={currentId} className={classNames}>
      <div className={gridClassNames}>
        <div className={titleColClassNames}>{inputTitle()}</div>
        <div className={numberColClassNames}>{inputNumber()}</div>
        <div className={reportDateColClassNames}>{inputReportDate()}</div>
        <div className={auditorColClassNames}>{inputAuditor()}</div>
        <div className={auditDateColClassNames}>{inputAuditDate()}</div>
        <div className={descriptionColClassNames}>{inputDescription()}</div>
        <div className={serviceProvColClassNames}>{inputServiceProvider()}</div>
        <div className={authorityColClassNames}>{inputAuthority()}</div>
        <div className={serviceProvShortColClassNames}>
          {inputServiceProviderShort()}
        </div>
      </div>
    </div>
  );
}

export default BeeAuditInputGrunddaten;
