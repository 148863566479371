import React, { useState } from "react";
import { useId } from "react-id-generator";
import BeeAuditHeader from "../Fragments/BeeAuditHeader";
import BeeAuditQuestionMultipleChoice from "../Fragments/BeeAuditQuestionMultipleChoice";
import {
  BeeBadge,
  BeeTextArea,
  BeeContentHeadline,
  BeeFlatImageGallery,
  BeeDocumentTable,
  BeeOutlinedButton,
  BeeLinkButton,
  BeeMixButton,
  BeeKnob,
  BeeDropDown,
  BeeContentParagraph,
  BeeSequencePosition,
  BeeInfoDialog,
} from "bee-atomic-components";
import BeeAuditTabs from "../Fragments/BeeAuditTabs";
import _ from "lodash";
import "./BeeAuditQuestion.scss";
import { LOW_PERCENTAGE_QUESTION } from "../../Helper/constants";
import { runsOnWeb } from "../../Helper/CapacitorHelper";

export function BeeAuditQuestion({
  homeUrl,
  breadcrumbData,
  showSave,
  showLogout,
  warningMessage,
  categoryProgressPercent,
  overallProgress,
  maxOverallProgress,
  question,
  categories,
  selectedCategory,
  readOnly,
  viewOptions,
  selectedView,
  onSave,
  onLogout,
  onAnswerClick,
  onCommentChanged,
  onImageClick,
  onImageRemove,
  onImageAdd,
  onDocumentView,
  onDocumentDownload,
  onDocumentDelete,
  onDocumentAdd,
  onPreviousQuestion,
  onNextQuestion,
  onSelectCategory,
  onSelectView,
  onToggleToCategoryView,
}) {
  const currentId = useId(1, "bee-audit-question-")[0];
  const [questionInfoVisible, setQuestionInfoVisible] = useState(false);

  //classNames
  const classNames = "bee-audit-question";
  const questionSectionClassNames = "m-4 p-2 bee-audit-question-content";
  const progressSectionClassNames =
    " grid justify-content-center bee-audit-question-category-headline";
  const progressKnobClassNames =
    " col-fixed col-align-center bee-audit-question-category-headline-knob";
  const progressDropDownClassNames =
    "col col-align-center bee-audit-q-category-dropdown";
  const progressBadgeClassNames =
    "col-fixed col-align-center bee-audit-question-progress-badge";
  const commentInfoClassNames = "bee-audit-question-comment-info";
  const addDocumentBtnClassNames = "d-inline-flex";

  //label
  const commentsSectionHeadline = "Anmerkungen";
  const picturesSectionHeadline = "Fotos";
  const documentsSectionHeadline = "Dokumente";
  const commentsInputLabel = "Anmerkungen";
  const addDocumentsBtnLabel = "Dokument hinzufügen";
  const progressBadgeLabel =
    "Frage: " +
    calcProgress(selectedCategory) +
    "/" +
    calcAmount(selectedCategory);
  const progressDropDownLabel = "Ausgewählte Fragen";

  function saveCallback(e) {
    if (onSave) {
      onSave(e);
    }
  }

  function logoutCallback(e) {
    if (onLogout) {
      onLogout(e);
    }
  }

  function clickAnswerCallback(e, answerId) {
    if (onAnswerClick) {
      onAnswerClick(e, answerId);
    }
  }

  function adaptCommentCallback(value) {
    if (onCommentChanged) {
      onCommentChanged(value);
    }
  }

  function clickImageCallback(imageId) {
    if (onImageClick) {
      onImageClick(imageId);
    }
  }

  function removeImageCallback(imageId) {
    if (onImageRemove) {
      onImageRemove(imageId);
    }
  }

  function addImageCallback(e) {
    if (onImageAdd) {
      onImageAdd(e);
    }
  }

  function viewDocumentCallback(documentId) {
    if (onDocumentView) {
      onDocumentView(documentId);
    }
  }

  function downloadDocumentCallback(documentId) {
    if (onDocumentDownload) {
      onDocumentDownload(documentId);
    }
  }

  function deleteDocumentCallback(documentId) {
    if (onDocumentDelete) {
      onDocumentDelete(documentId);
    }
  }

  function addDocumentCallback(e) {
    if (onDocumentAdd) {
      onDocumentAdd(e);
    }
  }

  function previousQuestionCallback(e) {
    if (onPreviousQuestion) {
      onPreviousQuestion(e);
    }
  }

  function nextQuestionCallback(e) {
    if (onNextQuestion) {
      setQuestionInfoVisible(false);
      onNextQuestion(e);
    }
  }

  function selectCategory(category) {
    if (onSelectCategory) {
      setQuestionInfoVisible(false);
      onSelectCategory(category);
    }
  }

  function toggleToCategoryView() {
    if (onToggleToCategoryView) {
      setQuestionInfoVisible(false);
      onToggleToCategoryView();
    }
  }

  function selectView(selection) {
    if (onSelectView && selection) {
      onSelectView(selection);
    }
  }

  function calcAmount(selectedCategory) {
    if (selectedCategory && selectedCategory.questionIds) {
      return selectedCategory.questionIds.length;
    }
  }

  function calcProgress(selectedCategory) {
    if (question && selectedCategory && selectedCategory.questionIds) {
      //loop over ids and search for current questionId
      for (let i = 0; i < selectedCategory.questionIds.length; i++) {
        if (question.id === selectedCategory.questionIds[i]) {
          return i + 1;
        }
      }
    }
  }

  const injectProgressSection = () => {
    return (
      <div className={progressSectionClassNames}>
        <div className={progressKnobClassNames}>
          <BeeKnob
            value={
              categoryProgressPercent
                ? categoryProgressPercent > 100
                  ? 100
                  : categoryProgressPercent < 0
                  ? 0
                  : categoryProgressPercent
                : 0
            }
            minValue={0}
            maxValue={100}
            step={1}
            size={"xSmall"}
            type={"primary"}
            disabled={false}
            readOnly={true}
          />
        </div>
        <div className={progressDropDownClassNames}>
          <BeeDropDown
            label={progressDropDownLabel}
            value={selectedCategory}
            options={categories}
            optionLabel={"combinedTitle"}
            disabled={false}
            formstate={"neutral"}
            readOnly={false}
            required={false}
            onChange={(category) => selectCategory(category)}
          />
        </div>
        {selectedCategory ? (
          <div className={progressBadgeClassNames}>
            <BeeBadge
              label={progressBadgeLabel}
              type={"secondary"}
              size="medium"
            />
          </div>
        ) : null}
      </div>
    );
  };

  const injectQuestion = () => {
    return (
      <>
        <BeeSequencePosition
          type={"primary"}
          visible={true}
          data={question ? question.sequence : null}
        />
        <BeeContentHeadline
          label={question ? question.text : null}
          headline={"h1"}
          type={"primary"}
        />
        {question && question.description ? (
          <i
            className="pi pi-info-circle"
            onClick={() => {
              setQuestionInfoVisible(true);
            }}
          ></i>
        ) : null}

        <BeeAuditQuestionMultipleChoice
          answers={question ? question.answers : null}
          selectedId={question ? question.selectedAnswerId : null}
          optionNotAccurate={true}
          readOnly={readOnly}
          onClickAnswer={(e, answerId) => clickAnswerCallback(e, answerId)}
        />
      </>
    );
  };

  const injectCommentsSection = () => {
    //test if answer is below threshold
    let enforceComment = false;
    if (question && question.answers && question.selectedAnswerId) {
      const selection = _.find(question.answers, function (o) {
        return o.id === question.selectedAnswerId;
      });
      if (selection && selection.weight <= LOW_PERCENTAGE_QUESTION) {
        enforceComment = true;
      }
    }
    return (
      <div
        className={
          enforceComment
            ? "bee-audit-question-comment bee-audit-question-comment-enforce"
            : "bee-audit-question-comment"
        }
      >
        <BeeContentHeadline
          label={commentsSectionHeadline}
          headline={"h2"}
          type={enforceComment ? "secondary" : "primary"}
        />
        {enforceComment ? (
          <div className={commentInfoClassNames}>
            <i className="pi pi-info" />
            <BeeContentParagraph
              text={
                "Sollte die einfache Antwort auf die Frage nicht ausreichend erscheinen, Sie hier eine zusätzliche Bemerkung hinzufügen. Bei einer Antwort, welche zu einer Qualitätsbewertung von 50% oder schlechter führt, werden Sie aufgefordert eine Anmerkung zuzufügen. Dies ist wichtig, um den Dienstleister von der Notwendigkeit einer Verbesserung in diesem Bereich zu überzeugen. Bitte beachten Sie, dass die Anmerkung auch im Report erscheinen werden."
              }
              size={"medium"}
              type={"secondary"}
            />
          </div>
        ) : (
          <BeeContentParagraph
            text={
              "Sollte die einfache Antwort auf die Frage nicht ausreichend erscheinen, Sie hier eine zusätzliche Bemerkung hinzufügen. Bei einer Antwort, welche zu einer Qualitätsbewertung von 50% oder schlechter führt, werden Sie aufgefordert eine Anmerkung zuzufügen. Dies ist wichtig, um den Dienstleister von der Notwendigkeit einer Verbesserung in diesem Bereich zu überzeugen. Bitte beachten Sie, dass die Anmerkung auch im Report erscheinen werden."
            }
            size={"medium"}
            type={"primary"}
          />
        )}

        <BeeTextArea
          label={commentsInputLabel}
          value={question ? question.comments : null}
          rows={5}
          disabled={readOnly}
          autoResize={true}
          formstate={"neutral"}
          readOnly={readOnly}
          required={enforceComment}
          onChange={(event) => adaptCommentCallback(event.target.value)}
        />
      </div>
    );
  };

  const injectGallerySection = () => {
    return (
      <>
        <BeeContentHeadline label={picturesSectionHeadline} headline={"h2"} />
        <BeeContentParagraph
          text={
            "Bitte füge an dieser Stelle Fotos hinzu, welche die Beantwortung der Frage stützen. Entsprechende Fotos werden im Bericht hinterlegt."
          }
          size={"medium"}
          type={"primary"}
        />
        <BeeFlatImageGallery
          activateHover={runsOnWeb()}
          readOnly={readOnly}
          showAddOption={!readOnly}
          images={question ? question.images : null}
          onClick={(imageId) => clickImageCallback(imageId)}
          onRemove={(imageId) => removeImageCallback(imageId)}
          onAdd={(e) => addImageCallback(e)}
        />
      </>
    );
  };

  const injectDocumentSection = () => {
    return (
      <>
        <BeeContentHeadline label={documentsSectionHeadline} headline={"h2"} />
        <BeeContentParagraph
          text={
            "Bitte füge an dieser Stelle Dokumente hinzu, welche die Beantwortung der Frage stützen. Entsprechende Dokumente werden im Bericht hinterlegt."
          }
          size={"medium"}
          type={"primary"}
        />
        <BeeDocumentTable
          data={question ? question.documents : null}
          readOnly={readOnly}
          showView={true}
          showDownload={true}
          showDelete={!readOnly}
          onView={(documentId) => viewDocumentCallback(documentId)}
          onDownload={(documentId) => downloadDocumentCallback(documentId)}
          onDelete={(documentId) => deleteDocumentCallback(documentId)}
        />
        {!readOnly ? (
          <div className={addDocumentBtnClassNames}>
            <BeeOutlinedButton
              label={addDocumentsBtnLabel}
              disabled={readOnly}
              type={"primary"}
              onClick={(e) => addDocumentCallback(e)}
            />
          </div>
        ) : null}
      </>
    );
  };

  const injectHeader = () => {
    return (
      <BeeAuditHeader
        disabled={false}
        homeUrl={homeUrl}
        items={breadcrumbData}
        type={"dark"}
        maxProgress={maxOverallProgress}
        progress={overallProgress}
        warningMessage={warningMessage}
        showSave={showSave}
        showLogout={showLogout}
        showProgress={true}
        showLeftRight={true}
        onSave={(e) => saveCallback(e)}
        onLogout={(e) => logoutCallback(e)}
        onBackPressed={(e) => previousQuestionCallback(e)}
        onForwardPressed={(e) => nextQuestionCallback(e)}
      />
    );
  };

  const injectTabs = () => {
    return (
      <BeeAuditTabs
        options={viewOptions}
        selectedOption={selectedView}
        disabled={false}
        type={"primary"}
        onSelect={(option) => selectView(option)}
      />
    );
  };

  const injectBottomNav = () => {
    return (
      <div className="grid justify-content-between bee-audit-question-bottom-nav">
        <BeeMixButton
          label={"Vorherige Frage"}
          iconClass={"pi pi-caret-left"}
          disabled={false}
          type={"primary"}
          iconPos={"left"}
          onClick={(e) => previousQuestionCallback(e)}
        />
        <BeeLinkButton
          label={"zur Kategorieauswahl"}
          raised={false}
          disabled={false}
          type={"secondary"}
          onClick={(e) => toggleToCategoryView(e)}
        />
        <BeeMixButton
          label={"Nächste Frage"}
          iconClass={"pi pi-caret-right"}
          disabled={false}
          type={"primary"}
          iconPos={"right"}
          onClick={(e) => nextQuestionCallback(e)}
        />
      </div>
    );
  };

  return (
    <div id={currentId} className={classNames}>
      {injectHeader()}
      {injectTabs()}
      {injectProgressSection()}
      <div className={questionSectionClassNames}>
        {injectQuestion()}
        {readOnly ? null : injectCommentsSection()}
        {readOnly &&
        !(question && question.images && question.images.length > 0)
          ? null
          : injectGallerySection()}
        {readOnly &&
        !(question && question.documents && question.documents.length > 0)
          ? null
          : injectDocumentSection()}
        {injectBottomNav()}
      </div>
      {questionInfoVisible ? (
        <BeeInfoDialog
          type={"default"}
          visible={questionInfoVisible}
          message={question.description}
          acceptLabel={"Ok"}
          header={"Fragebeschreibung"}
          onAccept={() => setQuestionInfoVisible(false)}
        />
      ) : null}
    </div>
  );
}

export default BeeAuditQuestion;
