import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import AuditUIOverview from "../Tabs/AuditUIOverview";
import AuditUICategory from "../Tabs/AuditUICategory";
import AuditUIAuffalligkeit from "../Tabs/AuditUIAuffalligkeit";
import AuditUIQuestion from "../Tabs/AuditUIQuestion";
import AuditUILv from "../Tabs/AuditUILv";
import BeeAuditDialogSnapshots from "../Dialogs/BeeAuditDialogSnapshots";
import {
  AUDIT_VIEW_AUFFALLIGKEIT,
  AUDIT_VIEW_CATEGORY,
  AUDIT_VIEW_INFO,
  AUDIT_VIEW_LV,
  AUDIT_VIEW_QUESTION,
  DEFAULT_IMAGE_SIZE,
  DURATION_NOTIFICATION_ERROR,
  DURATION_NOTIFICATION_SUCCESS,
  DURATION_NOTIFICATION_SUCCESS_LONG,
  PATH_ERROR,
  URL_TO_MEDIA_SERVER,
  VIEWS_NO_LV,
  VIEWS_WITH_LV,
} from "../../Helper/constants";
import {
  clearMaintainerId,
  logoutUser,
  setMaintainerId,
} from "../../redux/app/appActions";
import {
  adaptAnswersInPayload,
  convertCategoryTree,
  createCategoryRating,
  createCategoryTree,
  createMapOfQuestions,
  extractAllDocumentIdsForConverting,
  extractAllImageIdsForConverting,
  extractLeistungsSetup,
  extractMaintainerWithPendingLocks,
  prepareShowingMode,
} from "../../Helper/QuestionaireHelper";
import {
  BeeConfirmDialog,
  BeeToast,
  BeeDeletionDialog,
} from "bee-atomic-components";
import {
  callAuditProject,
  callAuditSnapshot,
  callLiegenschaftById,
  createLockForMaintainer,
  createSnapshotAtServer,
  ensureUserIsMaintainer,
  saveProjectAtServer,
  translateMediaIds,
} from "../../Helper/NetworkHelper";

import {
  breadCrumbHome,
  createBreadCrumbAudit,
} from "../../Helper/BreadCrumbHelper";
import _ from "lodash";
import { removeAllOnlinePendingLocksForUserId } from "../../Helper/RemovePendingLocksHelper";
import { createExcelReport, createWordReport } from "../../Helper/ReportHelper";
import {
  execIfOnAndroid,
  execIfOnWeb,
  runsOnWeb,
  writeDocumentFromBlobOnAndroid,
} from "../../Helper/CapacitorHelper";
import fileDownload from "js-file-download";
import { formatDate } from "../../Helper/Util";
import BeeAuditDialogAuffalligkeiten from "../Dialogs/BeeAuditDialogAuffalligkeiten";
import {
  convertDataToLv,
  convertLvToSaveableData,
} from "../../Helper/LvHelper";

function OnlineAuditUI(props) {
  const query = new URLSearchParams(props.location.search);
  const liegenschaftId = query.get("lid");
  const auditId = query.get("aid");
  const snapshotId = query.get("sid");
  const startInWorkingMode = query.get("adp");
  const [currentView, setCurrentView] = useState(AUDIT_VIEW_INFO);
  const [auditSetupComplete, setAuditSetupComplete] = useState(false);
  const [toggleForRender, setToggleForRender] = useState(true);
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  //states
  const [i_title, setTitle] = useState();
  const [i_number, setNumber] = useState();
  const [i_reportDate, setReportDate] = useState();
  const [i_auditDate, setAuditDate] = useState();
  const [i_auditor, setAuditor] = useState();
  const [i_description, setDescription] = useState();
  const [i_serviceProvider, setServiceProvider] = useState();
  const [i_serviceProviderShort, setServiceProviderShort] = useState();
  const [i_authority, setAuthority] = useState();
  const [adaptedTitle, setAdaptedTitle] = useState(false);
  const [adaptedNumber, setAdaptedNumber] = useState(false);
  const [adaptedReportDate, setAdaptedReportDate] = useState(false);
  const [adaptedAuditDate, setAdaptedAuditDate] = useState(false);
  const [adaptedAuditor, setAdaptedAuditor] = useState(false);
  const [adaptedDescription, setAdaptedDescription] = useState(false);
  const [adaptedServiceProvider, setAdaptedServiceProvider] = useState(false);
  const [adaptedServiceProviderShort, setAdaptedServiceProviderShort] =
    useState(false);
  const [adaptedAuthority, setAdaptedAuthority] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorNumber, setErrorNumber] = useState(false);
  const [errorReportDate, setErrorReportDate] = useState(false);
  const [errorAuditDate, setErrorAuditDate] = useState(false);
  const [errorAuditor, setErrorAuditor] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);
  const [errorServiceProvider, setErrorServiceProvider] = useState(false);
  const [errorServiceProviderShort, setErrorServiceProviderShort] =
    useState(false);
  const [errorAuthority, setErrorAuthority] = useState(false);
  //eindruck gebäude
  const [conditionBuildingDescr, setConditionBuildingDescr] = useState();
  const [conditionBuildingImages, setConditionBuildingImages] = useState();
  //working data
  const [stakeholder, setStakeholder] = useState([]);
  const [basis, setBasis] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [auffalligkeiten, setAuffalligkeiten] = useState([]);
  const [leistungssetup, setLeistungssetup] = useState();
  const [questionLookup, setQuestionLookup] = useState();
  const [imgLookup, setImgLookup] = useState();
  const [docLookup, setDocLookup] = useState();
  const [visibleQuestion, setVisibleQuestion] = useState();
  const [categoryLookup, setCategoryLookup] = useState();
  const [categoryTree, setCategoryTree] = useState();
  const [categoryRating, setCategoryRating] = useState(null);
  const [currentProject, setCurrentProject] = useState();
  const [currentLeistungsverzeichnis, setCurrentLeistungsverzeichnis] =
    useState(null);
  const [snapshotTitle, setSnapshotTitle] = useState();
  const [loadingProjectPending, setLoadingProjectPending] = useState(true);
  const [loadingProjectError, setLoadingProjectError] = useState(false);
  const [allImages, setAllImages] = useState(null);
  const [allDocuments, setAllDocuments] = useState(null);
  const [currentLock, setCurrentLock] = useState(null);
  const [foreignLockWarning, setForeignLockWarning] = useState(null);
  //refs
  const toast = useRef(null);
  //dialogs
  const [snapshotDialogVisible, setSnapshotDialogVisible] = useState(false);
  const [snapshotCreationInProgress, setSnapshotCreationInProgress] =
    useState(false);
  const [snapshotCreationError, setSnapshotCreationError] = useState(false);
  const [v_AufDialog, setV_AufDialog] = useState(false);
  const [v_AufDelDialog, setV_AufDelDialog] = useState(false);
  // DATA
  const [selEntry, setSelEntry] = useState(null);
  //confirm
  const [v_LogoutDialog, setV_LogoutDialog] = useState(false);
  const [v_SaveDialog, setV_SaveDialog] = useState(false);
  const [v_SaveDialogParams, setV_SaveDialogParams] = useState(null);

  const snapshotCreationErrorMsg =
    "Der Snapshot konnte leider nicht erstellt werden";

  //initial loading
  useEffect(() => {
    if (liegenschaftId && auditId && !snapshotId && props.currentUser) {
      callAuditProject(auditId)
        .then((project) => {
          setLoadingProjectPending(false);
          setCurrentProject(_.cloneDeep(project));
          //clone the leistungsverzeichnis if existed
          if (
            project &&
            project.payload &&
            project.payload.leistungsverzeichnis
          ) {
            setCurrentLeistungsverzeichnis(
              _.cloneDeep(project.payload.leistungsverzeichnis)
            );
          }
          //adapt warning foreign locks
          const maintainerWithLocks = extractMaintainerWithPendingLocks(
            props.currentUser.id,
            project
          );
          let names = [];
          if (maintainerWithLocks && maintainerWithLocks.length > 0) {
            for (let j = 0; j < maintainerWithLocks.length; j++) {
              const fName = maintainerWithLocks[j].userFirstname;
              const lName = maintainerWithLocks[j].userLastname;
              names.push(fName + " " + lName);
            }
            const warning =
              "Achtung " +
              names +
              " bearbeitet dieses Dokument gerade. Bitte nehmen Sie keine Änderungen vor!";
            setForeignLockWarning(warning);
          }
          if (project) {
            //load images and documents
            const docIds = extractAllDocumentIdsForConverting(project.payload);
            if (docIds) {
              translateMediaIds(docIds)
                .then((data) => {
                  setAllDocuments(data);
                })
                .catch((error) => {
                  //FIXME notify user that doc translation failed
                });
            } else {
              setAllDocuments([]);
            }
            const imgIds = extractAllImageIdsForConverting(project.payload);
            if (imgIds) {
              translateMediaIds(imgIds)
                .then((data) => {
                  setAllImages(data);
                })
                .catch((error) => {
                  //FIXME notify user that image translation failed
                });
            } else {
              setAllImages([]);
            }
          }
          //if success and startInWorkingMode
          if (startInWorkingMode) {
            //ensure user is maintainer
            ensureUserIsMaintainer(
              auditId,
              props.currentUser.id,
              props.currentUser.userFirstname,
              props.currentUser.userLastname,
              props.currentUser.username
            )
              .then((maintainer) => {
                props.setMaintainerId(maintainer.id);
                //get lock for this maintainer
                createLockForMaintainer(auditId, maintainer.id, true)
                  .then((data) => {
                    setCurrentLock(data);
                    setReadOnlyMode(false);
                  })
                  .catch((error) => {
                    //FIXME notify user that maintainer could not be written => only view mode!!
                  });
              })
              .catch((error) => {
                //FIXME notify user that maintainer could not be written => only view mode!!
              });
          }
        })
        .catch((error) => {
          setLoadingProjectError(true);
          setLoadingProjectPending(false);
        });
    } else if (auditId && snapshotId) {
      callAuditSnapshot(auditId, snapshotId)
        .then((project) => {
          setLoadingProjectPending(false);
          setCurrentProject(_.cloneDeep(project));
          setSnapshotTitle(project.versionName);
          //clone the leistungsverzeichnis if existed
          if (
            project &&
            project.payload &&
            project.payload.leistungsverzeichnis
          ) {
            setCurrentLeistungsverzeichnis(
              _.cloneDeep(project.payload.leistungsverzeichnis)
            );
          }
          if (project) {
            //load images and documents
            const docIds = extractAllDocumentIdsForConverting(project.payload);
            if (docIds) {
              translateMediaIds(docIds)
                .then((data) => {
                  setAllDocuments(data);
                })
                .then((error) => {
                  //FIXME notify user that doc translation failed
                });
            } else {
              setAllDocuments([]);
            }
            const imgIds = extractAllImageIdsForConverting(project.payload);
            if (imgIds) {
              translateMediaIds(imgIds)
                .then((data) => {
                  setAllImages(data);
                })
                .catch((error) => {
                  //FIXME notify user that image translation failed
                });
            } else {
              setAllImages([]);
            }
          }
        })
        .catch((error) => {
          setLoadingProjectError(true);
          setLoadingProjectPending(false);
        });
    } else {
      //there are invalid values for this side - redirect to error!!
      props.history.push({
        pathname: PATH_ERROR,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkAuditSetupComplete() {
    if (!auditSetupComplete && currentProject && allImages && allDocuments) {
      setAuditSetupComplete(true);
      const title =
        currentProject && currentProject.payload
          ? currentProject.payload.auditName
          : null;
      const number =
        currentProject && currentProject.payload
          ? currentProject.payload.auditNummer
          : null;
      const reportDate =
        currentProject &&
        currentProject.payload &&
        currentProject.payload.reportDatum
          ? new Date(currentProject.payload.reportDatum)
          : new Date();
      const auditDate =
        currentProject &&
        currentProject.payload &&
        currentProject.payload.auditDatum
          ? new Date(currentProject.payload.auditDatum)
          : new Date();
      const auditor =
        currentProject && currentProject.payload
          ? currentProject.payload.auditor
          : null;
      const description =
        currentProject && currentProject.payload
          ? currentProject.payload.beschreibung
          : null;
      const provider =
        currentProject &&
        currentProject.payload &&
        currentProject.payload.dienstleister
          ? currentProject.payload.dienstleister.dienstleisterName
          : null;
      const verantwortlicher =
        currentProject && currentProject.payload
          ? currentProject.payload.verantwortlicher
          : null;
      const providerShort =
        currentProject &&
        currentProject.payload &&
        currentProject.payload.dienstleister
          ? currentProject.payload.dienstleister.dienstleisterKurzname
          : null;
      const basis =
        currentProject && currentProject.payload && currentProject.payload.basis
          ? currentProject.payload.basis
          : [];
      //setup basis
      if (basis && basis.length > 0) {
        //convert date to date-object
        basis.forEach((entry) => {
          if (entry.date) {
            entry.date = new Date(entry.date);
          }
        });
      }
      setTitle(title);
      setNumber(number);
      setReportDate(reportDate);
      setAuditDate(auditDate);
      setAuditor(auditor);
      setDescription(description);
      setServiceProvider(provider);
      setServiceProviderShort(providerShort);
      setAuthority(verantwortlicher);
      setBasis(basis);
      //extract Map of QuestionId, Question
      const mapQuestions = createMapOfQuestions(currentProject);
      setQuestionLookup(mapQuestions);
      //extract category lookup for questionView using categoryTree
      const categoryTree = createCategoryTree(currentProject);
      setCategoryTree(categoryTree);
      setCategoryRating(createCategoryRating(currentProject));
      //create category lookup
      const categoryLookup = convertCategoryTree(categoryTree);
      setCategoryLookup(categoryLookup);
      //extract leistungssetup
      setLeistungssetup(extractLeistungsSetup(currentProject));
      //set the first question as default
      if (categoryTree && categoryTree.length > 0) {
        const rootId = categoryTree[0].key;
        const cats = categoryLookup.filter((entry) => entry.id === rootId);
        if (cats.length > 0) {
          setSelectedCategory(cats[0]);
          const currQid = selectFirstQuestionOfCategory(cats[0]);
          setVisibleQuestion(mapQuestions.get(currQid));
        }
      }
      //create doc and image lookup (id, entry)
      const imageLookup = new Map();
      if (allImages) {
        allImages.forEach(function (entry) {
          const image = entry.image;
          imageLookup.set(image.id, image);
        });
      }
      setImgLookup(imageLookup);
      const docLookup = new Map();
      if (allDocuments) {
        allDocuments.forEach(function (entry) {
          const doc = entry.document;
          docLookup.set(doc.id, doc);
        });
      }
      setDocLookup(docLookup);
      //extract stakeholder
      let stakeholderEntries = [];
      const sh =
        currentProject && currentProject.payload
          ? currentProject.payload.personen
          : null;
      if (sh && sh.length > 0) {
        for (let i = 0; i < sh.length; i++) {
          stakeholderEntries.push({
            id: sh[i].personID,
            name: sh[i].personName,
            role: sh[i].rolle,
            company: sh[i].unternehmen,
            participation: sh[i].teilnahme,
            shared: sh[i].verteilt,
          });
        }
      }
      setStakeholder(stakeholderEntries);
      //extract documents
      let documentEntries = [];
      const docs =
        currentProject && currentProject.payload
          ? currentProject.payload.dokumente
          : null;
      if (docs && docs.length > 0) {
        docs.forEach(function (document) {
          const entry = docLookup.get(document.mediaID);
          if (entry) {
            documentEntries.push({
              id: entry.id,
              title: entry.title,
              filename: entry.filename,
              fileSrc: URL_TO_MEDIA_SERVER + entry.url,
              timestamp: new Date(entry.createdAt),
              size: entry.fileSize,
              type: entry.type,
              copyright: entry.copyright,
              secured: entry.access ? true : false,
              fileKey: entry.access,
            });
          }
        });
      }
      setDocuments(documentEntries);
      //extract auffalligkeiten
      let auffalligkeitEntries = [];
      const auf_entries =
        currentProject && currentProject.payload
          ? currentProject.payload.auffaelligkeiten
          : null;
      if (auf_entries && auf_entries.length > 0) {
        auf_entries.forEach(function (entry) {
          auffalligkeitEntries.push({
            id: entry.auffaelligkeitID,
            title: entry.auffaelligkeitTitel,
            date: new Date(entry.datum),
            categoryId: entry.kategorieId,
            categoryLabel: convertCatIdToLabel(
              entry.kategorieId,
              categoryTree,
              null
            ),
            stateId: entry.statusId,
            typeValue: entry.typeValue,
            causeArray: entry.causeArray,
            description: entry.beschreibung,
            auditor: entry.auditor,
            documents: extractDocumentsForAuffalligkeit(entry, docLookup),
            images: extractImagesForAuffalligkeit(entry, imageLookup),
          });
        });
      }
      setAuffalligkeiten(auffalligkeitEntries);
      //extract conditionBuilding
      const condition =
        currentProject && currentProject.payload
          ? currentProject.payload.conditionBuilding
          : null;
      if (condition) {
        if (condition.beschreibung) {
          setConditionBuildingDescr(condition.beschreibung);
        }
        if (condition.bilder) {
          setConditionBuildingImages(
            extractImagesForConditionBuilding(condition.bilder, imageLookup)
          );
        } else {
          setConditionBuildingImages([]);
        }
      }
    }
  }

  function convertCatIdToLabel(catId, array, label) {
    if (array && catId) {
      for (const cat of array) {
        const currLabel = label ? label : cat.label;
        if (cat.key === catId) {
          return currLabel;
        }
        if (cat.children) {
          const result = convertCatIdToLabel(catId, cat.children, currLabel);
          if (result) {
            return result;
          }
        }
      }
    }
  }

  const extractDocumentsForAuffalligkeit = (auffalligkeit, documentLookup) => {
    const docs = [];
    if (documentLookup && auffalligkeit && auffalligkeit.dokumente) {
      auffalligkeit.dokumente.forEach(function (document) {
        const entry = documentLookup.get(document.mediaID);
        if (entry) {
          docs.push({
            id: entry.id,
            title: entry.title,
            filename: entry.filename,
            fileSrc: URL_TO_MEDIA_SERVER + entry.url,
            timestamp: new Date(entry.createdAt),
            size: entry.fileSize,
            type: entry.type,
            copyright: entry.copyright,
            secured: entry.access ? true : false,
            fileKey: entry.access,
          });
        }
      });
    }
    return docs;
  };

  const extractImagesForConditionBuilding = (mediaIds, imageLookup) => {
    const images = [];
    if (imageLookup && mediaIds) {
      mediaIds.forEach(function (image) {
        const entry = imageLookup.get(image.mediaID);
        let version = null;
        if (entry) {
          for (let i = 0; i < entry.versions.length; i++) {
            const v = entry.versions[i];
            //default to first version
            if (!version) {
              version = v;
            }
            //search for medium pic
            if (v && v.vLabel) {
              if (v.vLabel === DEFAULT_IMAGE_SIZE) {
                version = v;
              }
            }
          }
        }
        if (entry && version) {
          images.push({
            id: entry.id,
            url: URL_TO_MEDIA_SERVER + version.url,
            key: version.access,
            alt: null,
            title: entry.title,
            copyright: entry.copyright,
            secured: version.access ? true : false,
            showPlaceholder: false,
          });
        }
      });
    }
    return images;
  };

  const extractImagesForAuffalligkeit = (auffalligkeit, imageLookup) => {
    const images = [];
    if (imageLookup && auffalligkeit && auffalligkeit.bilder) {
      auffalligkeit.bilder.forEach(function (image) {
        const entry = imageLookup.get(image.mediaID);
        let version = null;
        if (entry) {
          for (let i = 0; i < entry.versions.length; i++) {
            const v = entry.versions[i];
            //default to first version
            if (!version) {
              version = v;
            }
            //search for medium pic
            if (v && v.vLabel) {
              if (v.vLabel === DEFAULT_IMAGE_SIZE) {
                version = v;
              }
            }
          }
        }
        if (entry && version) {
          images.push({
            id: entry.id,
            url: URL_TO_MEDIA_SERVER + version.url,
            key: version.access,
            alt: null,
            title: entry.title,
            copyright: entry.copyright,
            secured: version.access ? true : false,
            showPlaceholder: false,
          });
        }
      });
    }
    return images;
  };

  function performSave(generateSnapshotAfter, snapshotTitle) {
    if (foreignLockWarning) {
      setV_SaveDialog(true);
      setV_SaveDialogParams({
        generateSnapshotAfter,
        snapshotTitle,
      });
    } else {
      save(generateSnapshotAfter, snapshotTitle);
    }
  }

  function generateCurrentProjectState() {
    if (currentProject && validateFields()) {
      const title = i_title;
      const auditNumber = i_number;
      const description = i_description;
      const provider = i_serviceProvider;
      const authority = i_authority;
      const reportDate = i_reportDate;
      const auditDate = i_auditDate; //FIXME check if date!!!!
      const auditor = i_auditor;
      const serviceProviderShort = i_serviceProviderShort;
      const auditBasis = basis;
      //auffalligkeiten
      let adaptedPayload = currentProject.payload;
      if (adaptedPayload) {
        //set leistungsverzeichnis
        if (currentLeistungsverzeichnis) {
          //forced re-calculate leistungsverzeichnis
          const convertLV = convertDataToLv(currentLeistungsverzeichnis);
          const lv = convertLvToSaveableData(convertLV ? convertLV.data : null);
          adaptedPayload.leistungsverzeichnis = lv;
        }
        //set rating
        if (categoryRating) {
          adaptedPayload.rating = categoryRating;
        }
        //calculate stakeholder
        const personen = [];
        if (stakeholder && stakeholder.length > 0) {
          for (let i = 0; i < stakeholder.length; i++) {
            personen.push({
              personID: stakeholder[i].id,
              personName: stakeholder[i].name,
              rolle: stakeholder[i].role,
              unternehmen: stakeholder[i].company,
              teilnahme: stakeholder[i].participation,
              verteilt: stakeholder[i].shared,
            });
          }
        }
        //calculate documents
        const dokumente = [];
        if (documents && documents.length > 0) {
          for (let i = 0; i < documents.length; i++) {
            dokumente.push({
              mediaID: documents[i].id,
            });
          }
        }
        //calculate and set answers
        adaptAnswersInPayload(adaptedPayload, questionLookup);
        //calculate auffalligkeiten
        const adaptedAuffalligkeiten = [];
        if (auffalligkeiten && auffalligkeiten.length > 0) {
          for (let i = 0; i < auffalligkeiten.length; i++) {
            adaptedAuffalligkeiten.push({
              auffaelligkeitID: auffalligkeiten[i].id,
              auffaelligkeitTitel: auffalligkeiten[i].title,
              datum: auffalligkeiten[i].date,
              kategorieId: auffalligkeiten[i].categoryId,
              statusId: auffalligkeiten[i].stateId,
              typeValue: auffalligkeiten[i].typeValue,
              causeArray: auffalligkeiten[i].causeArray,
              beschreibung: auffalligkeiten[i].description,
              auditor: auffalligkeiten[i].auditor,
              dokumente: helperConvertAuffalligkeitDocumentsForStore(
                auffalligkeiten[i]
              ),
              bilder: helperConvertAuffalligkeitImagesForStore(
                auffalligkeiten[i]
              ),
            });
          }
        }
        //calculate conditionBuilding
        let conditionBuilding = {
          bilder: helperConvertBuildingConditionImagesForStore(),
          beschreibung: conditionBuildingDescr,
        };
        //calculate lastEditorName
        let lastEditorName = null;
        if (props.currentUser) {
          if (props.currentUser.userFirstname) {
            lastEditorName = props.currentUser.userFirstname;
          }
          if (props.currentUser.userLastname) {
            if (lastEditorName) {
              lastEditorName =
                lastEditorName + " " + props.currentUser.userLastname;
            } else {
              lastEditorName = props.currentUser.userLastname;
            }
          }
        }
        adaptedPayload.auditName = title;
        adaptedPayload.auditNummer = auditNumber;
        adaptedPayload.auffaelligkeiten = adaptedAuffalligkeiten;
        adaptedPayload.auditDatum = auditDate;
        adaptedPayload.auditor = auditor;
        adaptedPayload.beschreibung = description;
        adaptedPayload.verantwortlicher = authority;
        adaptedPayload.dienstleister = {
          dienstleisterName: provider,
          dienstleisterKurzname: serviceProviderShort,
        };
        // adaptedPayload.bilder = ""; //FIXME BEEN REMOVED!!
        adaptedPayload.reportDatum = reportDate;
        adaptedPayload.dokumente = dokumente;
        adaptedPayload.lastedit = new Date();
        adaptedPayload.lastEditorName = lastEditorName;
        adaptedPayload.personen = personen;
        adaptedPayload.basis = auditBasis;
        // adaptedPayload.questionaire = ""; //FIXME
        adaptedPayload.conditionBuilding = conditionBuilding;
      }
      return adaptedPayload;
    }
  }

  function save(generateSnapshotAfter, snapshotTitle) {
    if (currentProject) {
      if (validateFields()) {
        const projectId = currentProject.id;
        const templateId = currentProject.templateId;
        const propertyId = currentProject.propertyId;
        const payload = JSON.stringify(generateCurrentProjectState());
        const qId =
          currentProject &&
          currentProject.payload &&
          currentProject.payload.questionaire &&
          currentProject.payload.questionaire.questionaireID
            ? currentProject.payload.questionaire.questionaireID
            : null;
        let rating = null;
        if (categoryRating) {
          let val = _.find(categoryRating, ["id", qId]);
          if (val) {
            rating = val.value;
          }
        }
        let contractAmount = 0;
        if (currentLeistungsverzeichnis) {
          const tmp = convertDataToLv(currentLeistungsverzeichnis);
          if (tmp && tmp.sumGP) {
            contractAmount = tmp.sumGP;
          }
        }
        saveProjectAtServer(
          projectId,
          templateId,
          propertyId,
          currentLock.id,
          i_title,
          i_number,
          i_description,
          payload,
          rating,
          contractAmount
        )
          .then((entry) => {
            if (generateSnapshotAfter && snapshotTitle) {
              setSnapshotCreationInProgress(true);
              setSnapshotCreationError(null);
              createSnapshotAtServer(projectId, snapshotTitle, payload)
                .then((payload) => {
                  toast.current.show({
                    severity: "success",
                    summary: "Speichern erfolgreich",
                    detail:
                      "Das Projekt wurde gespeichert und der aktuelle Stand als '" +
                      snapshotTitle +
                      "' eingefroren",
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_SUCCESS_LONG,
                  });
                  setSnapshotCreationInProgress(false);
                  setSnapshotCreationError(null);
                  setSnapshotDialogVisible(false);
                })
                .catch((error) => {
                  toast.current.show({
                    severity: "error",
                    summary: "Snapshot Erstellung fehlgeschlagen.",
                    detail:
                      "Das Projekt wurde gespeichert. Leider konnte der Stand nicht als Snapshot erstellt werden.",
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR,
                  });
                  setSnapshotCreationInProgress(false);
                  setSnapshotCreationError(snapshotCreationErrorMsg);
                });
            } else {
              toast.current.show({
                severity: "success",
                summary: "Speichern erfolgreich",
                detail: "Das Projekt wurde gespeichert.",
                sticky: false,
                closable: true,
                life: DURATION_NOTIFICATION_SUCCESS,
              });
            }
          })
          .catch((error) => {
            toast.current.show({
              severity: "error",
              summary: "Speicherung fehlgeschlagen.",
              detail: "Das Projekt konnte leider nicht gespeichert werden.",
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR,
            });
          });
      } else {
        //CONTAINS ERRORS => NOTIFY USER
        setCurrentView("view_data");
        toast.current.show({
          severity: "error",
          summary: "Speicherung nicht möglich.",
          detail:
            "Das Projekt enthält noch Fehler. Bitte korrigieren Sie diese vor der Speicherung!",
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_ERROR,
        });
      }
    }
  }

  function helperConvertBuildingConditionImagesForStore() {
    const data = [];
    if (conditionBuildingImages) {
      for (let i = 0; i < conditionBuildingImages.length; i++) {
        data.push({
          mediaID: conditionBuildingImages[i].id,
        });
      }
    }
    return data;
  }

  function helperConvertAuffalligkeitImagesForStore(auffalligkeit) {
    const data = [];
    if (auffalligkeit && auffalligkeit.images) {
      for (let i = 0; i < auffalligkeit.images.length; i++) {
        data.push({
          mediaID: auffalligkeit.images[i].id,
        });
      }
    }
    return data;
  }

  function helperConvertAuffalligkeitDocumentsForStore(auffalligkeit) {
    const data = [];
    if (auffalligkeit && auffalligkeit.documents) {
      for (let i = 0; i < auffalligkeit.documents.length; i++) {
        data.push({
          mediaID: auffalligkeit.documents[i].id,
        });
      }
    }
    return data;
  }

  function changeTitle(value) {
    setAdaptedTitle(true);
    setTitle(value);
  }

  function changeNumber(value) {
    setAdaptedNumber(true);
    setNumber(value);
  }

  function changeReportDate(value) {
    setAdaptedReportDate(true);
    setReportDate(value);
  }

  function changeAuditDate(value) {
    setAdaptedAuditDate(true);
    setAuditDate(value);
  }

  function changeAuditor(value) {
    setAdaptedAuditor(true);
    setAuditor(value);
  }

  function changeDescription(value) {
    setAdaptedDescription(true);
    setDescription(value);
  }

  function changeServiceProvider(value) {
    setAdaptedServiceProvider(true);
    setServiceProvider(value);
  }

  function changeServiceProviderShort(value) {
    setAdaptedServiceProviderShort(true);
    setServiceProviderShort(value);
  }

  function changeAuthority(value) {
    setAdaptedAuthority(true);
    setAuthority(value);
  }

  function validateFieldTitle() {
    let valid = true;
    if (i_title) {
      //other validations in this field
    } else {
      valid = false;
    }
    return valid;
  }

  function validateFieldNumber() {
    let valid = true;
    if (i_number) {
      //other validations in this field
    } else {
      valid = false;
    }
    return valid;
  }

  function validateFieldReportDate() {
    let valid = true;
    if (i_reportDate) {
      //other validations in this field
    } else {
      valid = false;
    }
    return valid;
  }

  function validateFieldAuditDate() {
    let valid = true;
    if (i_auditDate) {
      //other validations in this field
    } else {
      valid = false;
    }
    return valid;
  }

  function validateFieldAuditor() {
    let valid = true;
    if (i_auditor) {
      //other validations in this field
    } else {
      valid = false;
    }
    return valid;
  }

  function validateFieldDescription() {
    let valid = true;
    if (i_description) {
      //other validations in this field
    } else {
      valid = false;
    }
    return valid;
  }

  function validateFieldServiceProvider() {
    let valid = true;
    if (i_serviceProvider) {
      //other validations in this field
    } else {
      valid = false;
    }
    return valid;
  }

  function validateFieldServiceProviderShort() {
    let valid = true;
    if (i_serviceProviderShort) {
      //other validations in this field
    } else {
      valid = false;
    }
    return valid;
  }

  function validateFieldAuthority() {
    let valid = true;
    if (i_authority) {
      //other validations in this field
    } else {
      valid = false;
    }
    return valid;
  }

  function validateFields() {
    //set all adapted to false
    setAdaptedTitle(false);
    setAdaptedNumber(false);
    setAdaptedReportDate(false);
    setAdaptedAuditDate(false);
    setAdaptedAuditor(false);
    setAdaptedDescription(false);
    setAdaptedServiceProvider(false);
    setAdaptedServiceProviderShort(false);
    setAdaptedAuthority(false);
    //test errors
    const vTitle = validateFieldTitle();
    const vNumber = validateFieldNumber();
    const vReportDate = validateFieldReportDate();
    const vAuditDate = validateFieldAuditDate();
    const vAuditor = validateFieldAuditor();
    const vDescr = validateFieldDescription();
    const vServiceProvider = validateFieldServiceProvider();
    const vServiceProviderShort = validateFieldServiceProviderShort();
    const vAuthority = validateFieldAuthority();
    setErrorTitle(!vTitle);
    setErrorNumber(!vNumber);
    setErrorReportDate(!vReportDate);
    setErrorAuditDate(!vAuditDate);
    setErrorAuditor(!vAuditor);
    setErrorDescription(!vDescr);
    setErrorServiceProvider(!vServiceProvider);
    setErrorServiceProviderShort(!vServiceProviderShort);
    setErrorAuthority(!vAuthority);
    return (
      vTitle &&
      vNumber &&
      vReportDate &&
      vAuditDate &&
      vAuditor &&
      vDescr &&
      vServiceProvider &&
      vServiceProviderShort &&
      vAuthority
    );
  }

  function selectQuestionCategory(category) {
    if (
      categoryLookup &&
      category.data &&
      category.data.questionIds &&
      category.data.questionIds.length
    ) {
      //choose category
      for (let i = 0; i < categoryLookup.length; i++) {
        if (categoryLookup[i].id === category.key) {
          setSelectedCategory(categoryLookup[i]);
          const currentQuestionId = selectFirstQuestionOfCategory(
            categoryLookup[i]
          );
          setVisibleQuestion(questionLookup.get(currentQuestionId));
          break;
        }
      }
      setCurrentView(AUDIT_VIEW_QUESTION);
    }
  }

  function selectFirstQuestionOfCategory(category) {
    if (category && category.questionIds && category.questionIds.length > 0) {
      return category.questionIds[0];
    }
    return null;
  }

  function downloadExcelReport() {
    let namePrefix = "";
    if (props.liegenschaften) {
      const currLs = _.find(props.liegenschaften, function (o) {
        return o.id === liegenschaftId;
      });
      if (currLs) {
        namePrefix =
          currLs.nummer + "_" + currLs.name + "_" + currLs.adresse.stadt + "_";
      }
    }
    const filename =
      namePrefix + i_title + "_" + formatDate(new Date()) + ".xlsx";
    //forced recalculate leistungsverzeichnis for defaulting old stuff
    const convertLV = convertDataToLv(currentLeistungsverzeichnis);
    const lv = convertLvToSaveableData(convertLV ? convertLV.data : null);
    createExcelReport(lv)
      .then((entry) => {
        execIfOnWeb(() => {
          fileDownload(entry, filename);
        });
        execIfOnAndroid(() => {
          writeDocumentFromBlobOnAndroid(
            entry,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            true
          );
        });
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Fehler",
          detail: "Der Download ist leider fehlgeschlagen",
          sticky: false,
          closable: true,
          life: 2000,
        });
      });
  }

  function downloadWordReport() {
    let namePrefix = "";
    if (props.liegenschaften) {
      const currLs = _.find(props.liegenschaften, function (o) {
        return o.id === liegenschaftId;
      });
      if (currLs) {
        namePrefix =
          currLs.nummer + "_" + currLs.name + "_" + currLs.adresse.stadt + "_";
      }
    }
    const filename =
      namePrefix + i_title + "_" + formatDate(new Date()) + ".docx";
    const auditProjectData = generateCurrentProjectState();
    const imageData = Array.from(imgLookup.values());
    const docData = Array.from(docLookup.values());
    const combinedDocuments = _.uniqBy([...docData, ...documents], "id");
    callLiegenschaftById(liegenschaftId)
      .then((liegenschaftData) => {
        createWordReport(
          auditProjectData,
          imageData,
          combinedDocuments,
          liegenschaftData
        )
          .then((entry) => {
            execIfOnWeb(() => {
              fileDownload(entry, filename);
            });
            execIfOnAndroid(() => {
              writeDocumentFromBlobOnAndroid(
                entry,
                filename,
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                true
              );
            });
          })
          .catch((error) => {
            toast.current.show({
              severity: "error",
              summary: "Fehler",
              detail: "Der Download ist leider fehlgeschlagen",
              sticky: false,
              closable: true,
              life: 2000,
            });
          });
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Fehler",
          detail:
            "Leider ist das Bereitstellen der benötigten Daten fehlgeschlagen.",
          sticky: false,
          closable: true,
          life: 2000,
        });
      });
  }

  /* Auffalligkeiten dialog methods */

  function openAuffalligkeitenDialog(entryId) {
    selectCurrentAuffalligkeit(entryId);
    setV_AufDialog(true);
  }

  function selectCurrentAuffalligkeit(entryId) {
    //if data empty then "create"
    let foundEntry = false;
    if (entryId && auffalligkeiten) {
      for (let i = 0; i < auffalligkeiten.length; i++) {
        if (auffalligkeiten[i].id === entryId) {
          setSelEntry(_.cloneDeep(auffalligkeiten[i]));
          foundEntry = true;
          break;
        }
      }
    }
    //clear if not found
    if (!foundEntry) {
      setSelEntry(null);
    }
  }

  function saveAuffalligkeitChange(data) {
    //create or update value
    let adapted = false;
    const entries = auffalligkeiten;
    let auditor = null;
    const user = props.currentUser;
    if (user) {
      if (user.userFirstname) {
        auditor = user.userFirstname;
      }
      if (user.userLastname) {
        if (auditor) {
          auditor = auditor + " " + user.userLastname;
        } else {
          auditor = user.userLastname;
        }
      }
    }

    data.auditor = auditor;

    //adapt data
    if (data && data.id) {
      for (let i = 0; i < entries.length; i++) {
        if (data.id === entries[i].id) {
          entries[i].id = data.id;
          entries[i].title = data.title;
          entries[i].date = data.date;
          entries[i].categoryId = data.categoryId;
          entries[i].categoryLabel = data.categoryLabel;
          entries[i].stateId = data.stateId;
          entries[i].typeValue = data.typeValue;
          entries[i].causeArray = data.causeArray;
          entries[i].description = data.description;
          entries[i].auditor = data.auditor;
          entries[i].documents = data.documents;
          entries[i].images = data.images;
          adapted = true;
          break;
        }
      }

      //if not found then create new
      if (!adapted) {
        entries.push(data);
      }
    }
    setSelEntry(null);
    setV_AufDialog(false);
    setAuffalligkeiten(entries);
  }

  function confirmAuffalligkeitDeletion(entryId) {
    const entries = auffalligkeiten;
    const values = [];
    if (entryId) {
      for (let i = 0; i < entries.length; i++) {
        if (entryId !== entries[i].id) {
          values.push(entries[i]);
        }
      }
    }
    setAuffalligkeiten(values);
  }

  /* View components */

  const injectCreateSnapshotDialog = () => {
    return (
      <BeeAuditDialogSnapshots
        id={"bee-audit-ui-audit-snapshot"}
        type={"primary"}
        header={"Snapshot erstellen"}
        visible={snapshotDialogVisible}
        showProgress={snapshotCreationInProgress}
        errorMsg={snapshotCreationError}
        onHide={() => {
          //reset stored state
          setSnapshotCreationInProgress(false);
          setSnapshotCreationError(null);
          //hide
          setSnapshotDialogVisible(false);
        }}
        onUpload={(title) => performSave(true, title)}
      />
    );
  };

  const injectAuffalligkeitDeletionConfirmationDialog = () => {
    return (
      <BeeDeletionDialog
        id={"bee-audit-ui-audit-confirm-auffalligkeit-deletion"}
        visible={v_AufDelDialog}
        message={
          "Möchten Sie " +
          (selEntry ? selEntry.title : "diese Auffalligkeit") +
          " wirklich löschen?"
        }
        acceptLabel={"Löschen"}
        rejectLabel={"Abbrechen"}
        header={"Auffälligkeit löschen"}
        type={"primary"}
        onAccept={() => {
          if (selEntry) {
            confirmAuffalligkeitDeletion(selEntry.id);
          }
          setV_AufDelDialog(false);
        }}
        onReject={() => setV_AufDelDialog(false)}
        onHide={() => setV_AufDelDialog(false)}
      />
    );
  };

  const injectAuffalligkeitAdaptionDialog = () => {
    return (
      <BeeAuditDialogAuffalligkeiten
        data={selEntry}
        visible={v_AufDialog}
        categoryTree={
          categoryTree && categoryTree[0] && categoryTree[0].children
            ? categoryTree[0].children
            : []
        }
        readOnly={readOnlyMode}
        activateHover={runsOnWeb()}
        offlineMode={false}
        liegenschaftId={liegenschaftId}
        projectId={auditId}
        onAddDocumentToLookup={(document) =>
          setDocLookup(docLookup.set(document.id, document))
        }
        onAddImageToLookup={(image) =>
          setImgLookup(imgLookup.set(image.id, image))
        }
        onSave={(data) => saveAuffalligkeitChange(data)}
        onCancel={() => setV_AufDialog(false)}
        onHide={() => setV_AufDialog(false)}
      />
    );
  };

  const injectAuditOverviewUI = () => {
    return (
      <AuditUIOverview
        homeUrl={breadCrumbHome}
        breadcrumbData={createBreadCrumbAudit(
          liegenschaftId,
          i_title,
          snapshotTitle
        )}
        liegenschaftId={liegenschaftId}
        projectId={auditId}
        warningMessage={foreignLockWarning}
        readOnly={readOnlyMode}
        offlineMode={false}
        showLogout={true}
        showSnapshot={!readOnlyMode}
        showWordReport={true}
        showExcelReport={currentLeistungsverzeichnis ? true : false}
        viewOptions={currentLeistungsverzeichnis ? VIEWS_WITH_LV : VIEWS_NO_LV}
        imageLookup={imgLookup}
        selectedView={currentView}
        auditTitle={i_title}
        auditNumber={i_number}
        auditReportDate={i_reportDate}
        auditDate={i_auditDate}
        auditAuditor={i_auditor}
        auditDescription={i_description}
        auditServiceProvider={i_serviceProvider}
        auditServiceProviderShort={i_serviceProviderShort}
        auditAuthority={i_authority}
        conditionBuildingImgs={conditionBuildingImages}
        conditionBuildingDescr={conditionBuildingDescr}
        isAdaptedTitle={adaptedTitle}
        isAdaptedNumber={adaptedNumber}
        isAdaptedReportDate={adaptedReportDate}
        isAdaptedAuditDate={adaptedAuditDate}
        isAdaptedAuditor={adaptedAuditor}
        isAdaptedDescription={adaptedDescription}
        isAdaptedServiceProvider={adaptedServiceProvider}
        isAdaptedServiceProviderShort={adaptedServiceProviderShort}
        isAdaptedAuthority={adaptedAuthority}
        isErrorTitle={errorTitle}
        isErrorNumber={errorNumber}
        isErrorReportDate={errorReportDate}
        isErrorAuditDate={errorAuditDate}
        isErrorAuditor={errorAuditor}
        isErrorDescription={errorDescription}
        isErrorServiceProvider={errorServiceProvider}
        isErrorServiceProviderShort={errorServiceProviderShort}
        isErrorAuthority={errorAuthority}
        documents={documents}
        stakeholders={stakeholder}
        basis={basis}
        leistungsSetup={leistungssetup}
        isCurrencySetup={prepareShowingMode(currentProject)}
        categoryTree={categoryTree}
        onLogout={() => setV_LogoutDialog(true)}
        onSave={() => performSave(false, null)}
        onChangeTitle={(value) => changeTitle(value)}
        onChangeNumber={(value) => changeNumber(value)}
        onChangeReportDate={(value) => changeReportDate(value)}
        onChangeAuditDate={(value) => changeAuditDate(value)}
        onChangeAuditor={(value) => changeAuditor(value)}
        onChangeDescription={(value) => changeDescription(value)}
        onChangeServiceProvider={(value) => changeServiceProvider(value)}
        onChangeServiceProviderShort={(value) =>
          changeServiceProviderShort(value)
        }
        onChangeAuthority={(value) => changeAuthority(value)}
        onChangeStakeholder={(data) => setStakeholder(data)}
        onChangeBasis={(data) => setBasis(data)}
        onChangeDocuments={(data) => setDocuments(data)}
        onChangeConditionBuildingDescr={(value) =>
          setConditionBuildingDescr(value)
        }
        onSelectView={(view) => setCurrentView(view)}
        onGenerateSnapshot={() => setSnapshotDialogVisible(true)}
        onGenerateWordReport={() => downloadWordReport()}
        onGenerateExcelReport={() => downloadExcelReport()}
        onAddImageToLookup={(image) =>
          setImgLookup(imgLookup.set(image.id, image))
        }
        onConditionBuildingImgChanged={(imgArray) =>
          setConditionBuildingImages(imgArray)
        }
      />
    );
  };

  const injectAuditCategoryUI = () => {
    return (
      <AuditUICategory
        homeUrl={breadCrumbHome}
        breadcrumbData={createBreadCrumbAudit(
          liegenschaftId,
          i_title,
          snapshotTitle
        )}
        warningMessage={foreignLockWarning}
        readOnly={readOnlyMode}
        showLogout={true}
        viewOptions={currentLeistungsverzeichnis ? VIEWS_WITH_LV : VIEWS_NO_LV}
        selectedView={currentView}
        categoryTree={categoryTree}
        categoryRating={categoryRating}
        onLogout={() => setV_LogoutDialog(true)}
        onSave={() => performSave(false, null)}
        onCategorySelect={(category) => selectQuestionCategory(category)}
        onSelectView={(view) => setCurrentView(view)}
      />
    );
  };

  const injectAuditLvUI = () => {
    return (
      <AuditUILv
        homeUrl={breadCrumbHome}
        breadcrumbData={createBreadCrumbAudit(
          liegenschaftId,
          i_title,
          snapshotTitle
        )}
        warningMessage={foreignLockWarning}
        categoryTree={categoryTree}
        readOnly={readOnlyMode}
        lvData={currentLeistungsverzeichnis}
        username={
          props.currentUser
            ? props.currentUser.userFirstname +
              " " +
              props.currentUser.userLastname
            : null
        }
        showLogout={true}
        showReport={true}
        viewOptions={currentLeistungsverzeichnis ? VIEWS_WITH_LV : VIEWS_NO_LV}
        selectedView={currentView}
        onChangeData={(data) => {
          setCurrentLeistungsverzeichnis(data);
        }}
        onLogout={() => setV_LogoutDialog(true)}
        onSave={() => performSave(false, null)}
        onSelectView={(view) => setCurrentView(view)}
        onGenerateExcelReport={() => downloadExcelReport()}
      />
    );
  };

  const injectAuditQuestionUI = () => {
    return (
      <AuditUIQuestion
        homeUrl={breadCrumbHome}
        breadcrumbData={createBreadCrumbAudit(
          liegenschaftId,
          i_title,
          snapshotTitle
        )}
        liegenschaftId={liegenschaftId}
        projectId={auditId}
        warningMessage={foreignLockWarning}
        readOnly={readOnlyMode}
        offlineMode={false}
        showLogout={true}
        viewOptions={currentLeistungsverzeichnis ? VIEWS_WITH_LV : VIEWS_NO_LV}
        selectedView={currentView}
        categories={categoryLookup}
        category={selectedCategory}
        categoryTree={categoryTree}
        question={visibleQuestion}
        imageLookup={imgLookup}
        documentLookup={docLookup}
        onLogout={() => setV_LogoutDialog(true)}
        onSave={() => performSave(false, null)}
        onSelectCategory={(category) => {
          setVisibleQuestion(
            questionLookup.get(selectFirstQuestionOfCategory(category))
          );
          setSelectedCategory(category);
        }}
        onSelectView={(view) => setCurrentView(view)}
        onChooseQuestion={(questionId) =>
          setVisibleQuestion(questionLookup.get(questionId))
        }
        onAdaptQuestion={(question) => {
          const newQuestionLookup = questionLookup.set(
            question.frageID,
            question
          );
          setQuestionLookup(newQuestionLookup);
          setVisibleQuestion(question);
          setToggleForRender(!toggleForRender);
          adaptAnswersInPayload(currentProject.payload, newQuestionLookup);
          //update values in categoryTree
          setCategoryTree(createCategoryTree(currentProject));
          setCategoryRating(createCategoryRating(currentProject));
        }}
        onToggleToCategorySelect={() => {
          setCurrentView(AUDIT_VIEW_CATEGORY);
          window.scrollTo(0, 0);
        }}
        onAddDocumentToLookup={(document) =>
          setDocLookup(docLookup.set(document.id, document))
        }
        onAddImageToLookup={(image) =>
          setImgLookup(imgLookup.set(image.id, image))
        }
      />
    );
  };

  const injectAuditAuffalligkeiten = () => {
    return (
      <AuditUIAuffalligkeit
        homeUrl={breadCrumbHome}
        breadcrumbData={createBreadCrumbAudit(
          liegenschaftId,
          i_title,
          snapshotTitle
        )}
        liegenschaftId={liegenschaftId}
        projectId={auditId}
        auffalligkeiten={auffalligkeiten}
        warningMessage={foreignLockWarning}
        currentUser={props.currentUser}
        readOnly={readOnlyMode}
        offlineMode={false}
        showLogout={true}
        categoryTree={categoryTree}
        viewOptions={currentLeistungsverzeichnis ? VIEWS_WITH_LV : VIEWS_NO_LV}
        selectedView={currentView}
        onViewAuffalligkeit={(entryId) => openAuffalligkeitenDialog(entryId)}
        onDeleteAuffalligkeit={(entryId) => {
          selectCurrentAuffalligkeit(entryId);
          setV_AufDelDialog(true);
        }}
        onEditAuffalligkeit={(entryId) => openAuffalligkeitenDialog(entryId)}
        onAddAuffalligkeit={() => openAuffalligkeitenDialog()}
        onSave={() => performSave(false, null)}
        onLogout={() => setV_LogoutDialog(true)}
        onSelectView={(view) => setCurrentView(view)}
        onChangeAuffalligkeiten={(entries) => setAuffalligkeiten(entries)}
        onAddDocumentToLookup={(document) =>
          setDocLookup(docLookup.set(document.id, document))
        }
        onAddImageToLookup={(image) =>
          setImgLookup(imgLookup.set(image.id, image))
        }
      />
    );
  };

  const injectCurrentView = () => {
    if (currentProject) {
      if (currentView === AUDIT_VIEW_AUFFALLIGKEIT) {
        return injectAuditAuffalligkeiten();
      } else if (currentView === AUDIT_VIEW_CATEGORY) {
        return injectAuditCategoryUI();
      } else if (currentView === AUDIT_VIEW_QUESTION) {
        return injectAuditQuestionUI();
      } else if (currentView === AUDIT_VIEW_LV) {
        return injectAuditLvUI();
      } else {
        return injectAuditOverviewUI();
      }
    }
  };

  const injectNotificationToast = () => {
    return (
      <BeeToast id={"bee-audit-ui-toast"} ref={toast} position={"top-right"} />
    );
  };

  const injectLogoutConfirmation = () => {
    return (
      <BeeConfirmDialog
        visible={v_LogoutDialog}
        message={
          "Möchten Sie sich wirklich ausloggen? Ungespeicherte Änderungen werden in diesem Fall verworfen!"
        }
        header={"Logout bestätigen"}
        type={"default"}
        onAccept={() => {
          removeAllOnlinePendingLocksForUserId();
          props.logoutUser();
          setV_LogoutDialog(false);
        }}
        onReject={() => setV_LogoutDialog(false)}
        onHide={() => setV_LogoutDialog(false)}
        acceptLabel={"Ausloggen"}
        rejectLabel={"Abbrechen"}
      />
    );
  };

  const injectSaveConfirmation = () => {
    return (
      <BeeConfirmDialog
        visible={v_SaveDialog}
        message={
          "Möchten Sie das Projekt wirklich speichern? Es wird aktuell von einer anderen Person verwendet. Dies kann zum gegenseitigen Überschreiben und ungewolltem Verhalten führen!"
        }
        header={"Speicherung bestätigen"}
        type={"default"}
        onAccept={() => {
          save(
            v_SaveDialogParams.generateSnapshotAfter,
            v_SaveDialogParams.snapshotTitle
          );
          setV_SaveDialog(false);
        }}
        onReject={() => setV_SaveDialog(false)}
        onHide={() => setV_SaveDialog(false)}
        acceptLabel={"Speichern"}
        rejectLabel={"Abbrechen"}
      />
    );
  };

  //create view & run calculations
  return (
    <div>
      {toggleForRender ? null : null}
      {injectCurrentView()}
      {checkAuditSetupComplete()}
      {/* DIALOGS */}
      {snapshotDialogVisible ? injectCreateSnapshotDialog() : null}
      {v_LogoutDialog ? injectLogoutConfirmation() : null}
      {v_SaveDialog ? injectSaveConfirmation() : null}
      {v_AufDialog ? injectAuffalligkeitAdaptionDialog() : null}
      {v_AufDelDialog ? injectAuffalligkeitDeletionConfirmationDialog() : null}
      {injectNotificationToast()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.app.currentUserData,
    maintainerId: state.app.maintainerId,
    onlineAccessPossible: state.app.onlineAccessPossible,
    //use carefully, may not exists!!
    liegenschaften: state.app.allLiegenschaftenShort,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    setMaintainerId: (maintainerId) => dispatch(setMaintainerId(maintainerId)),
    clearMaintainerId: () => dispatch(clearMaintainerId()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlineAuditUI);
