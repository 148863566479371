import React from "react";
import {
  BeeBreadcrumb,
  BeeIconButton,
  BeeToolBarButton,
  BeeLoadingBar,
  BeeIcon,
} from "bee-atomic-components";
import { useId } from "react-id-generator";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditHeader.scss";

export function BeeAuditHeader({
  disabled,
  homeUrl,
  items,
  type,
  maxProgress,
  progress,
  warningMessage,
  showSave,
  showLogout,
  showProgress,
  showLeftRight,
  onBackPressed,
  onForwardPressed,
  onSave,
  onLogout,
}) {
  const currentId = useId(1, "bee-audit-header-")[0];

  // classNames
  const typeClassName =
    type === "dark"
      ? " bee-audit-header-dark"
      : type === "light"
      ? " bee-audit-header-light"
      : " bee-audit-header-dark";
  const classNames = "bee-audit-header" + typeClassName;
  const headClassNames = showProgress
    ? "grid flex bee-audit-header-head"
    : "grid flex";
  const breadcrumbClassNames = "col";
  const progressAreaClassNames =
    "bee-audit-header-progress-area grid justify-content-between";
  const progressBarClassNames =
    "bee-audit-header-progress-bar col-offset-2 col-8";
  const progressTextClassNames =
    "bee-audit-header-progress-text col-offset-2 col-8";
  const warningMsgClassNames = "bee-audit-header-warning";

  //btns classNames
  const btnsClassNames = "col-fixed align-self-center ";
  const saveBtnClassNames = btnsClassNames + "bee-audit-header-save";
  const logOutBtnClassNames = btnsClassNames + "bee-audit-header-signOut";
  const leftArrowBtnClassNames =
    btnsClassNames + "bee-audit-header-progress-left-arrow";
  const rightArrowBtnClassNames =
    btnsClassNames + "bee-audit-header-progress-right-arrow";

  //icons
  const warningMsgIcon = "pi pi-exclamation-triangle";
  const warningMsgIconSize = "large";
  const saveIcon = "pi pi-save";
  const logOutIcon = "pi pi-sign-out";
  const leftArrowIcon = "pi pi-angle-left";
  const rightArrowIcon = "pi pi-angle-right";

  //progress
  const currProgress = progress + "/" + maxProgress;
  const calculatedProgress = maxProgress ? (progress / maxProgress) * 100 : 0;

  function clickSave(e) {
    if (onSave) {
      onSave(e);
    }
  }

  function clickLogout(e) {
    if (onLogout) {
      onLogout(e);
    }
  }

  function backPressed(e) {
    if (onBackPressed && !disabled) {
      onBackPressed(e);
    }
  }

  function forwardPressed(e) {
    if (onForwardPressed && !disabled) {
      onForwardPressed(e);
    }
  }

  return (
    <>
      <div id={currentId} className={classNames}>
        <div className={headClassNames}>
          <div className={breadcrumbClassNames}>
            <BeeBreadcrumb
              items={items}
              homeUrl={homeUrl}
              type={"primary"}
              border={false}
            />
          </div>
          {showSave ? (
            <div className={saveBtnClassNames}>
              <BeeToolBarButton
                iconClass={saveIcon}
                rounded={true}
                disabled={disabled}
                type={"primary"}
                onClick={(e) => clickSave(e)}
              />
            </div>
          ) : null}
          {showLogout ? (
            <div className={logOutBtnClassNames}>
              <BeeToolBarButton
                iconClass={logOutIcon}
                rounded={true}
                disabled={disabled}
                type={"primary"}
                onClick={(e) => clickLogout(e)}
              />
            </div>
          ) : null}
        </div>
        {showProgress ? (
          <div className={progressAreaClassNames}>
            {showLeftRight ? (
              <span className={leftArrowBtnClassNames}>
                <BeeIconButton
                  iconClass={leftArrowIcon}
                  rounded={true}
                  disabled={disabled}
                  type={"primary"}
                  onClick={(e) => backPressed(e)}
                />
              </span>
            ) : null}
            <div className={progressBarClassNames}>
              <BeeLoadingBar
                value={calculatedProgress}
                indeterminate={false}
                type={"primary"}
              />
            </div>
            <div className={progressTextClassNames}>{currProgress}</div>

            {showLeftRight ? (
              <span className={rightArrowBtnClassNames}>
                <BeeIconButton
                  iconClass={rightArrowIcon}
                  rounded={true}
                  type={"primary"}
                  disabled={disabled}
                  onClick={(e) => forwardPressed(e)}
                />
              </span>
            ) : null}
          </div>
        ) : null}

        {warningMessage ? (
          <div className={warningMsgClassNames}>
            <BeeIcon
              iconClass={warningMsgIcon}
              size={warningMsgIconSize}
              type={"primary"}
            ></BeeIcon>
            <span>{warningMessage}</span>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default BeeAuditHeader;
