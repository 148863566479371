import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { PrivateOnlineRoute } from "../Helper/PrivateOnlineRoute";
import { PrivateOfflineRoute } from "../Helper/PrivateOfflineRoute";
import OnlineAuditSetup from "../UI/Pages/OnlineAuditSetup";
import OnlineAuditSelect from "../UI/Pages/OnlineAuditSelect";
import Error404Page from "../UI/Pages/Error404Page";
import OnlineLiegenschaftDetail from "../UI/Pages/OnlineLiegenschaftDetail";
import OnlineLiegenschaftenOverview from "../UI/Pages/OnlineLiegenschaftenOverview";
import AuditLogin from "../UI/Pages/AuditLogin";

import OfflineAuditOverviewPage from "../UI/Pages/OfflineAuditOverviewPage";
import OnlineAuditUI from "../UI/Pages/OnlineAuditUI";
import OfflineAuditUI from "../UI/Pages/OfflineAuditUI";
import {
  PATH_LOGIN,
  PATH_OFFLINE_AUDIT,
  PATH_OFFLINE_OVERVIEW,
  PATH_ONLINE_AUDIT,
  PATH_ONLINE_DETAIL_LIEGENSCHAFT,
  PATH_ONLINE_OVERVIEW_AUDIT,
  PATH_ONLINE_OVERVIEW_LIEGENSCHAFTEN,
  PATH_ONLINE_SETUP_AUDIT,
  PATH_ROOT,
} from "../Helper/constants";
import "../aa_style/generaltheme.css";

function App(props) {
  return (
    <div className="app">
      <Switch>
        <Route exact path={PATH_ROOT} component={AuditLogin} />
        <Route exact path={PATH_LOGIN} component={AuditLogin} />
        {/* securedRoutes */}

        <PrivateOnlineRoute
          exact
          path={PATH_ONLINE_OVERVIEW_LIEGENSCHAFTEN}
          component={OnlineLiegenschaftenOverview}
        />
        <PrivateOnlineRoute
          exact
          path={PATH_ONLINE_DETAIL_LIEGENSCHAFT}
          component={OnlineLiegenschaftDetail}
        />
        <PrivateOnlineRoute
          exact
          path={PATH_ONLINE_OVERVIEW_AUDIT}
          component={OnlineAuditSelect}
        />
        <PrivateOnlineRoute
          exact
          path={PATH_ONLINE_SETUP_AUDIT}
          component={OnlineAuditSetup}
        />
        <PrivateOnlineRoute
          exact
          path={PATH_ONLINE_AUDIT}
          component={OnlineAuditUI}
        />
        {/* OfflineRoutes => only on mobile! */}
        <PrivateOfflineRoute
          exact
          path={PATH_OFFLINE_OVERVIEW}
          component={OfflineAuditOverviewPage}
        />
        <PrivateOfflineRoute
          exact
          path={PATH_OFFLINE_AUDIT}
          component={OfflineAuditUI}
        />
        {/* fallback */}
        <Route path="*" component={Error404Page} />
      </Switch>
    </div>
  );
}

export default withRouter(App);
