import { authAxios } from "./AuthService";
import { URL_TO_AUDIT_API } from "./constants";

export const createExcelReport = (lvData) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "put",
      url: URL_TO_AUDIT_API + "/v1/tools/audit/report/excel",
      responseType: "blob",
      data: {
        lv: lvData,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const createWordReport = (
  auditProjectData,
  imageData,
  documentData,
  liegenschaftData
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: "put",
      url: URL_TO_AUDIT_API + "/v1/tools/audit/report/word",
      responseType: "blob",
      data: {
        project: auditProjectData,
        images: imageData,
        documents: documentData,
        liegenschaft: liegenschaftData,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};
