import React from "react";
import BeeAuditOverview from "../Components/BeeAuditOverview";

function AuditUICategory({
  homeUrl,
  breadcrumbData,
  warningMessage,
  viewOptions,
  selectedView,
  categoryTree,
  categoryRating,
  readOnly,
  showLogout,
  onLogout,
  onSave,
  onCategorySelect,
  onSelectView,
}) {
  ///////////////////////
  // OUTSIDE CALLBACKS //
  ///////////////////////
  function logout() {
    if (onLogout) {
      onLogout();
    }
  }

  function save() {
    if (onSave) {
      onSave();
    }
  }

  function selectCategory(category) {
    if (onCategorySelect) {
      onCategorySelect(category);
    }
  }

  function selectView(view) {
    if (onSelectView) {
      onSelectView(view);
    }
  }

  ////////////////
  // SETUP DATA //
  ////////////////
  function removeEmptyCategories(categories) {
    if (categories) {
      let data = categories;
      //remove itself if empty
      if (
        data &&
        data.length > 0 &&
        data[0].data &&
        data[0].data.questions > 0
      ) {
        if (data[0].children) {
          data[0].children = removeEmptyChildren(data[0]);
        }
        return data;
      }
    }
    return [];
  }

  function removeEmptyChildren(parent) {
    if (parent && parent.children) {
      //test if this child has children
      for (let i = 0; i < parent.children.length; i++) {
        if (
          parent.children[i].children &&
          parent.children[i].children.length > 0
        ) {
          parent.children[i].children = removeEmptyChildren(parent.children[i]);
        }
      }
      //filter all categories without children
      return parent.children.filter((value, index, arr) => {
        return value.data.questions > 0;
      });
    }
  }

  return (
    <BeeAuditOverview
      categories={removeEmptyCategories(categoryTree)}
      categoryRating={categoryRating}
      homeUrl={homeUrl}
      breadcrumbData={breadcrumbData}
      viewOptions={viewOptions}
      selectedView={selectedView}
      overallProgress={
        categoryTree && categoryTree.length > 0 && categoryTree[0].data
          ? categoryTree[0].data.progress
          : 0
      }
      maxOverallProgress={
        categoryTree && categoryTree.length > 0 && categoryTree[0].data
          ? categoryTree[0].data.questions
          : 0
      }
      warningMessage={warningMessage}
      showSave={!readOnly}
      showLogout={showLogout}
      onCategorySelect={(category) => selectCategory(category)}
      onSave={() => save()}
      onLogout={() => logout()}
      onSelectView={(view) => selectView(view)}
    />
  );
}

export default AuditUICategory;
