import _ from "lodash";
import flatten from "tree-flatten";

export function convertLvToCategoryMap(lv) {
  const map = new Map();
  if (lv && lv.data) {
    lv.data.forEach((node) => {
      let flat = flatten(node, "children");
      if (flat) {
        flat.forEach((entry) => {
          map.set(entry.key, entry);
        });
      }
    });
  }
  return map;
}

export function convertDataToLv(lvData) {
  const payload = _.cloneDeep(lvData.categories);
  let adapted = [];
  let sum0 = 0.0;
  let sum0qualA = 0.0;
  let sum0quanA = 0.0;
  //level 1
  for (let i = 0; i < payload.length; i++) {
    let c1 = payload[i];
    const p1 = c1.positions;
    const c1children = [];

    let sum1 = 0.0;
    let sum1qualA = 0.0;
    let sum1quanA = 0.0;
    if (p1) {
      for (let ip = 0; ip < p1.length; ip++) {
        const lvCp1 = extractLvPos(p1[ip], 1);
        c1children.push(lvCp1);
        if (!p1[ip].deactivated) {
          sum1qualA += lvCp1.data.qualityAdaptedGp;
          sum1quanA += lvCp1.data.quantityAdaptedGp;
        }
        sum1 += lvCp1.data.gp;
      }
    }
    //level 2
    if (c1.categories) {
      for (let j = 0; j < c1.categories.length; j++) {
        let c2 = c1.categories[j];
        const p2 = c2.positions;
        const c2children = [];
        let sum2 = 0.0;
        let sum2qualA = 0.0;
        let sum2quanA = 0.0;
        if (p2) {
          for (let jp = 0; jp < p2.length; jp++) {
            const lvCp2 = extractLvPos(p2[jp], 2);
            c2children.push(lvCp2);
            if (!p2[jp].deactivated) {
              sum2qualA += lvCp2.data.qualityAdaptedGp;
              sum2quanA += lvCp2.data.quantityAdaptedGp;
            }
            sum2 += lvCp2.data.gp;
          }
        }
        //level 3
        if (c2.categories) {
          for (let k = 0; k < c2.categories.length; k++) {
            const c3 = c2.categories[k];
            const p3 = c3.positions;
            const c3children = [];
            let sum3 = 0.0;
            let sum3qualA = 0.0;
            let sum3quanA = 0.0;
            if (p3) {
              for (let kp = 0; kp < p3.length; kp++) {
                const lvCp3 = extractLvPos(p3[kp], 3);
                c3children.push(lvCp3);
                if (!p3[kp].deactivated) {
                  sum3qualA += lvCp3.data.qualityAdaptedGp;
                  sum3quanA += lvCp3.data.quantityAdaptedGp;
                }
                sum3 += lvCp3.data.gp;
              }
            }
            const lvC3 = extractLvCat(
              c3,
              3,
              sum3,
              sum3qualA,
              sum3quanA,
              c3children,
              c3.categories ? false : true
            );
            c2children.push(lvC3);
            sum2 += sum3;
            sum2qualA += sum3qualA;
            sum2quanA += sum3quanA;
          }
        }
        const lvC2 = extractLvCat(
          c2,
          2,
          sum2,
          sum2qualA,
          sum2quanA,
          c2children,
          c2.categories ? false : true
        );
        c1children.push(lvC2);
        sum1 += sum2;
        sum1qualA += sum2qualA;
        sum1quanA += sum2quanA;
      }
    }
    const lvC1 = extractLvCat(
      c1,
      1,
      sum1,
      sum1qualA,
      sum1quanA,
      c1children,
      c1.categories ? false : true
    );
    adapted.push(lvC1);
    sum0 += sum1;
    sum0qualA += sum1qualA;
    sum0quanA += sum1quanA;
  }

  const meanReduction =
    sum0quanA && sum0quanA !== 0 ? (sum0qualA / sum0quanA) * 100 : 0;

  return {
    sumGP: sum0,
    qualitySumGPadapted: sum0qualA,
    quantitySumGPadapted: sum0quanA,
    meanReduction: meanReduction,
    data: adapted,
  };
}

function extractLvPos(pos, depth) {
  let clazz = "lv-depth-pos-" + depth;
  if (pos) {
    const initActive =
      pos.adaptedOptionalPositionActivated !== null &&
      pos.adaptedOptionalPositionActivated !== undefined
        ? pos.adaptedOptionalPositionActivated
        : pos.optionalPositionActivated;
    const quantityAdaptedGp =
      pos.optionalPosition && initActive === true
        ? calculateQuantityGP(pos.adaptAmount, pos.adaptUnitPrice)
        : pos.optionalPosition
        ? 0
        : calculateQuantityGP(pos.adaptAmount, pos.adaptUnitPrice);
    return {
      key: pos.posNumber,
      data: {
        type: "pos",
        number: pos.posNumber,
        title: pos.name,
        unit: pos.origUnit,
        amount: pos.origAmount,
        ep: pos.origUnitPrice,
        gp:
          pos.optionalPosition === false
            ? calculateGP(pos.origAmount, 100, pos.origUnitPrice)
            : pos.optionalPosition === true &&
              pos.optionalPositionActivated === true
            ? calculateGP(pos.origAmount, 100, pos.origUnitPrice)
            : 0,
        showRemove: true,
        reductionPercentage: pos.state === "UGP" ? 0 : pos.adaptCompletion,
        qualityAdaptedGp: calculateQualityGP(
          quantityAdaptedGp,
          pos.state === "UGP" ? 0 : pos.adaptCompletion
        ),
        quantityAdaptedGp: quantityAdaptedGp,
        fulfillment: pos.state,
        comment: pos.comment,
        adaptedUnit: pos.adaptUnit,
        adaptedAmount: pos.adaptAmount,
        adaptedEp: pos.adaptUnitPrice,
        adaptedOptionalPositionActivated: initActive,
        deactivated: pos.deactivated,
        newPosition: pos.newPosition,
        epCode: pos.epCode,
        optionalPosition: pos.optionalPosition,
        optionalPositionActivated: pos.optionalPositionActivated,
        posNumberOld: pos.posNumberOld,
        supplementPosition: pos.supplementPosition,
        facilityIdentificationCode: pos.facilityIdentificationCode,
      },
      className: clazz,
    };
  }
}

function extractLvCat(
  cat,
  depth,
  sum,
  qualityAdaptedSum,
  quantityAdaptedSum,
  children,
  showAdd
) {
  let clazz = "lv-depth-" + depth;
  if (cat) {
    return {
      key: cat.levelCode,
      data: {
        type: "cat",
        number: cat.levelCode,
        title: cat.title,
        gp: sum,
        qualityAdaptedGp: qualityAdaptedSum,
        quantityAdaptedGp: quantityAdaptedSum,
        showAdd: showAdd,
        showAmount: true,
        catMeanReduction:
          quantityAdaptedSum && quantityAdaptedSum !== 0
            ? (qualityAdaptedSum / quantityAdaptedSum) * 100
            : 0,
        // catAuffaelligkeiten: 10,
      },
      className: clazz,
      children: children,
      selectable: showAdd,
    };
  }
}

export function calculateGP(amount, completion, price) {
  return amount * (completion / 100) * price;
}

export function calculateQuantityGP(amount, price) {
  return amount * price;
}

export function calculateQualityGP(price, reductionPercentage) {
  return reductionPercentage
    ? price * (reductionPercentage / 100)
    : reductionPercentage === 0
    ? 0
    : null;
}

export function convertLvToSaveableData(lvData) {
  //FIXME REWORK in tenth-cent to omit floatingpoint arithmetic
  if (lvData) {
    let answer = {};
    let data = convertEntriesToLV(lvData);
    if (data.categories) {
      answer.categories = data.categories;
    }
    if (data.positions) {
      answer.positions = data.positions;
    }
    return answer;
  }
}

function convertEntriesToLV(entryArray) {
  const categories = [];
  const positions = [];
  if (entryArray && entryArray.length > 0) {
    for (let i = 0; i < entryArray.length; i++) {
      let e1 = entryArray[i];
      if (e1 && e1.data && e1.data.type) {
        if (e1.data.type === "pos") {
          positions.push(convertPosToLV(e1, i + 1));
        } else if (e1.data.type === "cat") {
          let cat = convertCatToLV(e1);
          //check children
          if (e1.children && e1.children.length > 0) {
            let data = convertEntriesToLV(e1.children);
            if (data.categories) {
              cat.categories = data.categories;
            }
            if (data.positions) {
              cat.positions = data.positions;
            }
          }
          categories.push(cat);
        }
      }
    }
  }
  return {
    categories: categories.length > 0 ? categories : null,
    positions: positions.length > 0 ? positions : null,
  };
}

function convertCatToLV(entry) {
  if (entry && entry.data) {
    return {
      levelCode: entry.data.number,
      title: entry.data.title,
    };
  }
}

function convertPosToLV(entry, sortNumber) {
  if (entry && entry.data) {
    return {
      adaptAmount: entry.data.adaptedAmount,
      adaptCompletion: entry.data.reductionPercentage,
      adaptUnit: entry.data.adaptedUnit,
      adaptUnitPrice: entry.data.adaptedEp,
      adaptedOptionalPositionActivated:
        entry.data.adaptedOptionalPositionActivated,
      comment: entry.data.comment,
      state: entry.data.fulfillment,
      deactivated: entry.data.deactivated,
      name: entry.data.title,
      newPosition: entry.data.newPosition,
      origAmount: entry.data.amount,
      origUnit: entry.data.unit,
      origUnitPrice: entry.data.ep,
      posNumber: entry.data.number,
      sortNumber: sortNumber,
      epCode: entry.data.epCode,
      optionalPosition: entry.data.optionalPosition,
      optionalPositionActivated: entry.data.optionalPositionActivated,
      posNumberOld: entry.data.posNumberOld,
      supplementPosition: entry.data.supplementPosition,
      facilityIdentificationCode: entry.data.facilityIdentificationCode,
    };
  }
}
