import React from "react";
import { useId } from "react-id-generator";
import { Dialog } from "primereact/dialog";
import {
  BeeLinkButton,
  BeeButton,
  BeeValidatedTextInput,
  BeeValidatedCurrencyInput,
  BeeValidatedNumberInput,
  BeeTreeSelect,
  BeeValidatedDropDown,
  BeeValidatedCheckbox,
} from "bee-atomic-components";
import { POS_DROPDOWN, NORMALPOS, BEDARFSPOS } from "../../Helper/constants";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditLvPositionDialog.scss";

export function BeeAuditLvPositionDialog({
  visible,
  readOnly,
  currency,
  locale,
  categoryOptions,
  selectedCategory,
  number,
  title,
  amount,
  unit,
  ep,
  optionalPosition,
  optionalPositionActivated,
  isErrorNumber,
  isErrorTitle,
  isErrorAmount,
  isErrorUnit,
  isErrorEp,
  isErrorOptionalPosition,
  isErrorOptionalPositionActivated,
  isAdaptedNumber,
  isAdaptedTitle,
  isAdaptedAmount,
  isAdaptedUnit,
  isAdaptedEp,
  isAdaptedOptionalPosition,
  isAdaptedOptionalPositionActivated,
  acceptLabel,
  rejectLabel,
  header,
  type,
  onAccept,
  onReject,
  onHide,
  onSelectCategory,
  onChangeNumber,
  onChangeTitle,
  onChangeAmount,
  onChangeUnit,
  onChangeEp,
  onChangeIsOptionalPosition,
  onChangeIsOptionalPositionActivated,
}) {
  const currentId = useId(1, "bee-audit-lv-position-dialog")[0];

  //classNames
  const typeClassNames =
    type === "primary"
      ? " bee-audit-lv-position-dialog-primary"
      : type === "secondary"
      ? " bee-audit-lv-position-dialog-secondary"
      : " bee-audit-lv-position-dialog-primary";
  const classNames = "bee-audit-lv-position-dialog" + typeClassNames;

  //label
  const l_PosNumber = "Positionsnummer";
  const l_PosCategory = "Kategorie";
  const l_PosTitle = "Name der Position";
  const l_OptionalPosition = "Positionsart";
  const l_OptionalPositionActivated = "Aktiviert";
  const l_PosAmount = "Menge";
  const l_PosUnit = "Einheit";
  const l_PosEP = "Einheitspreis";
  const el_PosNumber = "Bitte Positionsnummer angeben";
  const el_PosTitle = "Bitte Name der Position angeben";
  const el_OptionalPosition = "Bitte Positionsart angeben";
  const el_OptionalPositionActivated = "Bitte Aktivierung angeben";
  const el_PosAmount = "Bitte Menge angeben";
  const el_PosUnit = "Bitte Einheit angeben";
  const el_PosEP = "Bitte EP angeben";

  function hide() {
    if (onHide) {
      onHide();
    }
  }

  function reject() {
    if (onReject) {
      onReject();
    }
  }

  function accept() {
    if (onAccept) {
      onAccept();
    }
  }

  function selectCategory(category) {
    if (!readOnly && onSelectCategory) {
      onSelectCategory(category);
    }
  }

  function changeNumber(value) {
    if (!readOnly && onChangeNumber) {
      onChangeNumber(value);
    }
  }

  function changeTitle(value) {
    if (!readOnly && onChangeTitle) {
      onChangeTitle(value);
    }
  }

  function changeIsOptionalPosition(value) {
    console.log(value);
    if (!readOnly && onChangeIsOptionalPosition) {
      if (value === BEDARFSPOS.value) {
        onChangeIsOptionalPosition(true);
      } else {
        onChangeIsOptionalPosition(false);
      }
    }
  }

  function changeIsOptionalPositionActivated(value) {
    if (!readOnly && onChangeIsOptionalPositionActivated) {
      onChangeIsOptionalPositionActivated(value);
    }
  }

  function changeAmount(value) {
    if (!readOnly && onChangeAmount) {
      if (value < 0) {
        onChangeAmount(0);
      } else {
        onChangeAmount(value);
      }
    }
  }

  function changeUnit(value) {
    if (!readOnly && onChangeUnit) {
      onChangeUnit(value);
    }
  }

  function changeEp(value) {
    if (!readOnly && onChangeEp) {
      if (value < 0) {
        onChangeEp(0);
      } else {
        onChangeEp(value);
      }
    }
  }

  const injectCategorySelect = () => {
    return (
      <BeeTreeSelect
        label={l_PosCategory}
        value={selectedCategory}
        options={categoryOptions}
        disabled={false}
        readOnly={readOnly}
        formstate={"neutral"}
        required={false}
        onChange={(selection) => {
          selectCategory(selection);
        }}
      />
    );
  };

  const injectPosNumber = () => {
    return (
      <BeeValidatedTextInput
        label={l_PosNumber}
        errorLabel={isErrorNumber && !isAdaptedNumber ? el_PosNumber : null}
        neutralLabel={isErrorNumber && isAdaptedNumber ? el_PosNumber : null}
        validLabel={null}
        value={number}
        disabled={true}
        formstate={
          isErrorNumber && isAdaptedNumber
            ? "neutral"
            : isErrorNumber
            ? "error"
            : "none"
        }
        readOnly={true}
        required={true}
        onChange={(e) => changeNumber(e.target.value)}
      />
    );
  };

  const injectPosTitle = () => {
    return (
      <BeeValidatedTextInput
        label={l_PosTitle}
        errorLabel={isErrorTitle && !isAdaptedTitle ? el_PosTitle : null}
        neutralLabel={isErrorTitle && isAdaptedTitle ? el_PosTitle : null}
        validLabel={null}
        value={title}
        disabled={false}
        formstate={
          isErrorTitle && isAdaptedTitle
            ? "neutral"
            : isErrorTitle
            ? "error"
            : "none"
        }
        readOnly={readOnly}
        required={true}
        onChange={(e) => changeTitle(e.target.value)}
      />
    );
  };

  const injectOptionalPosition = () => {
    return (
      <BeeValidatedDropDown
        label={l_OptionalPosition}
        errorLabel={
          isErrorOptionalPosition && !isAdaptedOptionalPosition
            ? el_OptionalPosition
            : null
        }
        neutralLabel={
          isErrorOptionalPosition && isAdaptedOptionalPosition
            ? el_OptionalPosition
            : null
        }
        validLabel={null}
        value={optionalPosition ? BEDARFSPOS.value : NORMALPOS.value}
        options={POS_DROPDOWN}
        optionLabel={"label"}
        disabled={false}
        formstate={
          isErrorOptionalPosition && isAdaptedOptionalPosition
            ? "neutral"
            : isErrorOptionalPosition
            ? "error"
            : "none"
        }
        readOnly={readOnly}
        required={true}
        onChange={(e) => changeIsOptionalPosition(e)}
      />
    );
  };

  const injectOptionalPositionActivated = () => {
    return (
      <BeeValidatedCheckbox
        label={l_OptionalPositionActivated}
        errorLabel={
          isErrorOptionalPositionActivated &&
          !isAdaptedOptionalPositionActivated
            ? el_OptionalPositionActivated
            : null
        }
        neutralLabel={
          isErrorOptionalPositionActivated && isAdaptedOptionalPositionActivated
            ? el_OptionalPositionActivated
            : null
        }
        validLabel={null}
        value={optionalPositionActivated}
        disabled={
          !optionalPosition || optionalPosition === NORMALPOS.value
            ? true
            : false
        }
        formstate={
          isErrorOptionalPositionActivated && isAdaptedOptionalPositionActivated
            ? "neutral"
            : isErrorOptionalPositionActivated
            ? "error"
            : "none"
        }
        stateTrue={"Ja"}
        stateFalse={"Nein"}
        required={false}
        readOnly={readOnly}
        onChange={(e) => changeIsOptionalPositionActivated(e.target.checked)}
      />
    );
  };

  const injectPosAmount = () => {
    return (
      <BeeValidatedNumberInput
        label={l_PosAmount}
        errorLabel={isErrorAmount && !isAdaptedAmount ? el_PosAmount : null}
        neutralLabel={isErrorAmount && isAdaptedAmount ? el_PosAmount : null}
        validLabel={null}
        value={amount}
        minFractionDigits={0}
        maxFractionDigits={2}
        disabled={false}
        grouping={true}
        formstate={
          isErrorAmount && isAdaptedAmount
            ? "neutral"
            : isErrorAmount
            ? "error"
            : "none"
        }
        readOnly={readOnly}
        required={true}
        onChange={(e) => changeAmount(e.target.value)}
      />
    );
  };

  const injectPosUnit = () => {
    return (
      <BeeValidatedTextInput
        label={l_PosUnit}
        errorLabel={isErrorUnit && !isAdaptedUnit ? el_PosUnit : null}
        neutralLabel={isErrorUnit && isAdaptedUnit ? el_PosUnit : null}
        validLabel={null}
        value={unit}
        disabled={false}
        formstate={
          isErrorUnit && isAdaptedUnit
            ? "neutral"
            : isErrorUnit
            ? "error"
            : "none"
        }
        readOnly={readOnly}
        required={true}
        onChange={(e) => changeUnit(e.target.value)}
      />
    );
  };

  const injectPosEp = () => {
    return (
      <BeeValidatedCurrencyInput
        label={l_PosEP}
        errorLabel={isErrorEp && !isAdaptedEp ? el_PosEP : null}
        neutralLabel={isErrorEp && isAdaptedEp ? el_PosEP : null}
        validLabel={null}
        value={ep}
        disabled={false}
        currency={currency}
        locale={locale}
        formstate={
          isErrorEp && isAdaptedEp ? "neutral" : isErrorEp ? "error" : "none"
        }
        readOnly={readOnly}
        required={true}
        onChange={(e) => changeEp(e.target.value)}
      />
    );
  };

  function calculateGp() {
    if (ep && amount) {
      return ep * amount;
    } else {
      return null;
    }
  }

  const injectPosGp = () => {
    return (
      <BeeValidatedCurrencyInput
        label={"Gesamtpreis"}
        errorLabel={null}
        neutralLabel={null}
        validLabel={null}
        value={calculateGp()}
        disabled={false}
        minFractionDigits={0}
        maxFractionDigits={3}
        currency={currency}
        locale={locale}
        formstate={"neutral"}
        readOnly={true}
        required={false}
        onChange={null}
      />
    );
  };

  const renderFooter = () => {
    return (
      <div className="bee-audit-lv-position-dialog-btns">
        <BeeLinkButton
          label={rejectLabel}
          raised={false}
          disabled={false}
          type={"primary"}
          onClick={(e) => reject(e)}
        />
        <BeeButton
          label={acceptLabel}
          disabled={false}
          type={type}
          onClick={(e) => accept(e)}
        />
      </div>
    );
  };

  return (
    <Dialog
      id={currentId}
      className={classNames}
      header={header}
      visible={visible}
      modal={true}
      footer={renderFooter()}
      onHide={() => hide()}
    >
      <div className="grid">
        <div className="col-12 lg:col-3 md:col-3 sm:col-6">
          {injectPosNumber()}
        </div>
        <div className="col-12 lg:col-9 md:col-9 sm:col-6">
          {injectCategorySelect()}
        </div>
        <div className="col-12 lg:col-12 md:col-12 sm:col-12 ">
          {injectPosTitle()}
        </div>
        <div className="col-6 lg:col-6 md:col-6 sm:col-6 ">
          {injectOptionalPosition()}
        </div>
        <div className="col-6 lg:col-6 md:col-6 sm:col-6 ">
          {injectOptionalPositionActivated()}
        </div>
        <div className="col-6 lg:col-3 md:col-3 sm:col-3">
          {injectPosAmount()}
        </div>
        <div className="col-6 lg:col-3 md:col-3 sm:col-3">
          {injectPosUnit()}
        </div>
        <div className="col-6 lg:col-3 md:col-3 sm:col-3">{injectPosEp()}</div>
        <div className="col-6 lg:col-3 md:col-3 sm:col-3">{injectPosGp()}</div>
      </div>
    </Dialog>
  );
}

export default BeeAuditLvPositionDialog;
