import React from "react";
import { useId } from "react-id-generator";
import {
  BeeContentHeadline,
  BeeImage,
  BeeToolBarButton,
  BeeBadge,
} from "bee-atomic-components";
import { Card } from "primereact/card";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditLiegenschaftPreview.scss";

export function BeeAuditLiegenschaftPreview({
  title,
  number,
  city,
  imageUrl,
  imageKey,
  imageAlt,
  imageTitle,
  imageCopyright,
  isImageSecured,
  showPlaceHolder,
  lastAuditDate,
  countAudits,
  orientation,
  activateHover,
  onClick,
  onEdit,
}) {
  const currentId = useId(1, "bee-audit-liegenschaft-prev-")[0];

  //classNames
  const activateHoverClassNames = activateHover
    ? "bee-audit-liegenschaft-prev-hover"
    : "bee-audit-liegenschaft-prev-noHover";
  const orientationClassName =
    orientation === "card"
      ? "bee-audit-liegenschaft-prev-card "
      : "bee-audit-liegenschaft-prev-list ";
  const classNames =
    "bee-audit-liegenschaft-prev " +
    orientationClassName +
    activateHoverClassNames;
  const editBtnIcon = "pi pi-pencil";
  const cardEditBtnClassNames = "bee-audit-liegenschaft-prev-card-edit-btn";
  const cardHeadlineClassNames = "bee-audit-liegenschaft-prev-card-headline";
  const cardImgClassNames = "bee-audit-liegenschaft-prev-card-img";
  const cardTableClassNames = "bee-audit-liegenschaft-prev-card-table";
  const listEditBtnClassNames = "bee-audit-liegenschaft-prev-list-edit-btn";
  const listHeadlineClassNames = "bee-audit-liegenschaft-prev-list-headline";
  const listContentClassNames = "bee-audit-liegenschaft-prev-list-content";
  const listTableClassNames = "bee-audit-liegenschaft-prev-list-table";

  //label
  const labelNumberAudits = "Anzahl Audits:";
  const labelLastAudit = "Letztes Audit:";

  function clicked(e) {
    if (onClick) {
      onClick(e);
    }
  }

  function edit(e) {
    if (onEdit) {
      onEdit(e);
    }
  }

  const injectHeadline = () => {
    return (
      <>
        {orientation === "list" ? (
          (city && !number) || (!city && number) ? (
            <div>{city + number}</div>
          ) : city || number ? (
            <div>{city + " | " + number}</div>
          ) : (
            <div></div>
          )
        ) : orientation === "card" ? (
          city ? (
            <div>{city}</div>
          ) : (
            <div></div>
          )
        ) : null}
        <BeeContentHeadline label={title} headline={"h2"} />
        {orientation === "card" ? (
          number ? (
            <div>{number}</div>
          ) : (
            <div></div>
          )
        ) : null}
      </>
    );
  };

  const injectEditButton = () => {
    return (
      <BeeToolBarButton
        iconClass={editBtnIcon}
        rounded={true}
        disabled={false}
        type={"primary"}
        onClick={(e) => edit(e)}
      />
    );
  };

  const injectImage = () => {
    return (
      <BeeImage
        img_url={imageUrl}
        img_key={imageKey}
        alt={imageAlt}
        title={imageTitle}
        copyright={imageCopyright}
        isSecured={isImageSecured}
        showPlaceholder={showPlaceHolder}
      />
    );
  };
  const injectBadge = () => {
    const auditCount = countAudits ? "" + countAudits : "0";
    return <BeeBadge label={auditCount} type={"primary"} size={"medium"} />;
  };

  const injectDate = () => {
    return lastAuditDate ? lastAuditDate.toLocaleDateString("de-DE") : " - ";
  };

  const injectCardLayout = () => {
    return (
      <>
        <div className={cardEditBtnClassNames}>{injectEditButton()}</div>
        <div onClick={(e) => clicked(e)}>
          <div className={cardHeadlineClassNames}>{injectHeadline()}</div>

          <div className={cardImgClassNames}>
            {injectImage()}
            <table className={cardTableClassNames}>
              <tbody>
                <tr>
                  <td>{labelNumberAudits}</td>
                  <td>{injectBadge()}</td>
                </tr>
                <tr>
                  <td>{labelLastAudit}</td>
                  <td>{injectDate()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  const injectListLayout = () => {
    return (
      <>
        <div className={listEditBtnClassNames}>
          <div>{injectEditButton()}</div>
        </div>
        <div className={listContentClassNames} onClick={(e) => clicked(e)}>
          <div className={listHeadlineClassNames}>{injectHeadline()}</div>
          <div className={listTableClassNames}>
            <table>
              <tbody>
                <tr>
                  <td>{labelNumberAudits}</td>
                  <td>{injectBadge()}</td>
                </tr>
                <tr>
                  <td>{labelLastAudit}</td>
                  <td>{injectDate()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  return (
    <Card id={currentId} className={classNames}>
      {orientation === "card"
        ? injectCardLayout()
        : orientation === "list"
        ? injectListLayout()
        : injectListLayout()}
    </Card>
  );
}

export default BeeAuditLiegenschaftPreview;
