import React, { useState, useRef } from "react";
import BeeAuditDialogStakeholder from "../Dialogs/BeeAuditDialogStakeholder";
import {
  BeeAndroidCameraDialog,
  BeeDeletionDialog,
  BeeImageSlider,
  BeeToast,
  BeeUploadDialog,
} from "bee-atomic-components";
import {
  ACCEPTED_FILE_FORMATS,
  ACCEPTED_IMAGE_FORMATS,
  DEFAULT_IMAGE_SIZE,
  DURATION_NOTIFICATION_ERROR,
  IMG_ANDROID_HEIGHT,
  IMG_ANDROID_PRESERVE_ASPECT_RATIO,
  IMG_ANDROID_QUALITY,
  IMG_ANDROID_WIDTH,
  URL_TO_MEDIA_SERVER,
} from "../../Helper/constants";
import fileDownload from "js-file-download";
import {
  downloadDocumentFromServer,
  uploadDocumentToServer,
  uploadImageToServer,
} from "../../Helper/NetworkHelper";
import {
  execIfOnAndroid,
  execIfOnWeb,
  openStoredMediaFileOnAndroid,
  storeOfflineMediaOnAndroidInProject,
  writeDocumentFromBlobOnAndroid,
} from "../../Helper/CapacitorHelper";
import BeeAuditData from "../Components/BeeAuditData";
import BeeAuditDialogBasis from "../Dialogs/BeeAuditDialogBasis";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

function AuditUIOverview({
  homeUrl,
  breadcrumbData,
  liegenschaftId,
  projectId,
  warningMessage,
  readOnly,
  offlineMode,
  showLogout,
  showSnapshot,
  showWordReport,
  showExcelReport,
  viewOptions,
  imageLookup,
  selectedView,
  auditTitle,
  auditNumber,
  auditReportDate,
  auditDate,
  auditAuditor,
  auditDescription,
  auditServiceProvider,
  auditServiceProviderShort,
  auditAuthority,
  conditionBuildingImgs,
  conditionBuildingDescr,
  isAdaptedTitle,
  isAdaptedNumber,
  isAdaptedReportDate,
  isAdaptedAuditDate,
  isAdaptedAuditor,
  isAdaptedDescription,
  isAdaptedServiceProvider,
  isAdaptedServiceProviderShort,
  isAdaptedAuthority,
  isErrorTitle,
  isErrorNumber,
  isErrorReportDate,
  isErrorAuditDate,
  isErrorAuditor,
  isErrorDescription,
  isErrorServiceProvider,
  isErrorServiceProviderShort,
  isErrorAuthority,
  documents,
  stakeholders,
  basis,
  leistungsSetup,
  isCurrencySetup,
  categoryTree,
  onLogout,
  onSave,
  onChangeTitle,
  onChangeNumber,
  onChangeReportDate,
  onChangeAuditDate,
  onChangeAuditor,
  onChangeDescription,
  onChangeServiceProvider,
  onChangeServiceProviderShort,
  onChangeAuthority,
  onChangeStakeholder,
  onChangeBasis,
  onChangeDocuments,
  onChangeConditionBuildingDescr,
  onSelectView,
  onGenerateSnapshot,
  onGenerateWordReport,
  onGenerateExcelReport,
  onAddImageToLookup,
  onConditionBuildingImgChanged,
}) {
  //Adaption dialogs
  const [v_DocDialog, setV_DocDialog] = useState(false);
  const [v_StkDialog, setV_StkDialog] = useState(false);
  const [v_BsDialog, setV_BsDialog] = useState(false);
  const [docUploadProg, setDocUploadProg] = useState(false);
  const [docUploadError, setDocUploadError] = useState();
  //deletion confirmation
  const [v_StkDelDialog, setV_StkDelDialog] = useState(false);
  const [v_DocDelDialog, setV_DocDelDialog] = useState(false);
  const [v_BsDelDialog, setV_BsDelDialog] = useState(false);
  //data
  const [currStakeholer, setCurrStakeholer] = useState(null);
  const [currDoc, setCurrDoc] = useState(null);
  const [currBasis, setCurrBasis] = useState(null);
  //references
  const toast = useRef(null);
  const gallery = useRef(null);
  //image-creation-adaption-deletion
  const [currImgId, setCurrImgId] = useState(null);
  const [v_ImgDialog, setV_ImgDialog] = useState(false);
  const [v_ImgDelDialog, setV_ImgDelDialog] = useState(false);
  const [imgUploadProg, setImgUploadProg] = useState(false);
  const [imgUploadError, setImgUploadError] = useState();
  //android
  const [v_AImgDialog, setAV_ImgDialog] = useState(false);
  const [aImgUploadProg, setAImgUploadProg] = useState(false);
  const [aImgUploadError, setAImgUploadError] = useState(false);

  ///////////////////////
  // OUTSIDE CALLBACKS //
  ///////////////////////
  function changeConditionBuildingDescr(value) {
    if (onChangeConditionBuildingDescr) {
      onChangeConditionBuildingDescr(value);
    }
  }

  function generateSnapshot() {
    if (onGenerateSnapshot) {
      onGenerateSnapshot();
    }
  }

  function selectView(view) {
    if (onSelectView && view) {
      onSelectView(view);
    }
  }

  function generateWordReport(e) {
    if (onGenerateWordReport) {
      onGenerateWordReport(e);
    }
  }

  function generateExcelReport(e) {
    if (onGenerateExcelReport) {
      onGenerateExcelReport(e);
    }
  }

  function changeTitle(value) {
    if (onChangeTitle) {
      onChangeTitle(value);
    }
  }

  function changeNumber(value) {
    if (onChangeNumber) {
      onChangeNumber(value);
    }
  }

  function changeReportDate(value) {
    if (onChangeReportDate) {
      onChangeReportDate(value);
    }
  }

  function changeAuditDate(value) {
    if (onChangeAuditDate) {
      onChangeAuditDate(value);
    }
  }

  function changeAuditor(value) {
    if (onChangeAuditor) {
      onChangeAuditor(value);
    }
  }

  function changeDescription(value) {
    if (onChangeDescription) {
      onChangeDescription(value);
    }
  }

  function changeServiceProvider(value) {
    if (onChangeServiceProvider) {
      onChangeServiceProvider(value);
    }
  }
  function changeServiceProviderShort(value) {
    if (onChangeServiceProviderShort) {
      onChangeServiceProviderShort(value);
    }
  }

  function changeAuthority(value) {
    if (onChangeAuthority) {
      onChangeAuthority(value);
    }
  }

  function changeStakeholder(data) {
    if (onChangeStakeholder) {
      onChangeStakeholder(data);
    }
  }

  function changeBasis(data) {
    if (onChangeBasis) {
      onChangeBasis(data);
    }
  }

  function changeDocuments(data) {
    if (onChangeDocuments) {
      onChangeDocuments(data);
    }
  }

  function addToImgLookup(image) {
    if (!readOnly && onAddImageToLookup) {
      onAddImageToLookup(image);
    }
  }

  ////////////
  // HELPER //
  ////////////

  function convertImageIdToTitle(imageId) {
    const defaultValue = "dieses Bild";
    if (imageLookup) {
      const val = imageLookup.get(imageId);
      return val && val.title ? val.title : defaultValue;
    }
    return defaultValue;
  }

  function deleteImage(entryId) {
    if (conditionBuildingImgs) {
      const currImgs = _.filter(conditionBuildingImgs, (item) => {
        return item.id !== entryId;
      });
      onConditionBuildingImgChanged(currImgs);
    }
  }

  //////////////////
  // DIALOG LOGIC //
  //////////////////
  function selectCurrentStakeholder(entryId) {
    //if data empty then "create"
    let foundEntry = false;
    if (entryId && stakeholders) {
      for (let i = 0; i < stakeholders.length; i++) {
        if (stakeholders[i].id === entryId) {
          setCurrStakeholer(stakeholders[i]);
          foundEntry = true;
          break;
        }
      }
    }
    //clear if not found
    if (!foundEntry) {
      setCurrStakeholer(null);
    }
  }

  function clickImageCallback(entryId) {
    if (gallery && gallery.current) {
      for (let i = 0; i < conditionBuildingImgs.length; i++) {
        if (conditionBuildingImgs[i].id === entryId) {
          gallery.current.showImage(i);
          break;
        }
      }
      gallery.current.openGallery();
    }
  }

  function openStakeholderDialog(entryId) {
    selectCurrentStakeholder(entryId);
    setV_StkDialog(true);
  }

  function saveStakeholderChange(data) {
    //create or update value
    let adapted = false;
    const entries = stakeholders;
    if (data && data.id) {
      for (let i = 0; i < entries.length; i++) {
        if (data.id === entries[i].id) {
          //update values
          entries[i].name = data.name;
          entries[i].role = data.role;
          entries[i].company = data.company;
          entries[i].participation = data.participation;
          entries[i].shared = data.shared;
          adapted = true;
          break;
        }
      }
      //if not found then create new
      if (!adapted) {
        //add additionalProperties to new created entry
        if (!data.additionalProperties) {
          data.additionalProperties = null;
        }
        entries.push(data);
      }
    }
    setV_StkDialog(false);
    changeStakeholder(entries);
  }

  function confirmStakeholderDeletion(entryId) {
    const entries = stakeholders;
    const values = [];
    if (entryId) {
      for (let i = 0; i < entries.length; i++) {
        if (entryId !== entries[i].id) {
          values.push(entries[i]);
        }
      }
    }
    changeStakeholder(values);
  }

  function selectCurrentBasis(entryId) {
    //if data empty then "create"
    let foundEntry = false;
    if (entryId && basis) {
      for (let i = 0; i < basis.length; i++) {
        if (basis[i].id === entryId) {
          setCurrBasis(basis[i]);
          foundEntry = true;
          break;
        }
      }
    }
    //clear if not found
    if (!foundEntry) {
      setCurrBasis(null);
    }
  }

  function createNewImage(data, androidVersion) {
    if (data) {
      if (androidVersion) {
        setAImgUploadProg(true);
        setAImgUploadError(null);
      } else {
        setImgUploadProg(true);
        setImgUploadError(null);
      }
      if (offlineMode) {
        //store for offline usage
        storeOfflineMediaOnAndroidInProject(
          liegenschaftId,
          projectId,
          data.file,
          "IMG_" + uuidv4() + ".jpeg",
          function success(payload) {
            if (payload) {
              const currImgs = conditionBuildingImgs
                ? _.cloneDeep(conditionBuildingImgs)
                : [];
              const convertedImage = {
                id: payload.id,
                url: payload.url,
                key: null,
                alt: null,
                title: data.name,
                copyright: null,
                secured: false,
                showPlaceholder: false,
              };
              currImgs.push(convertedImage);
              onConditionBuildingImgChanged(currImgs);
              //add entry to imageLookup
              const imageForStore = {
                id: payload.id,
                title: data.name,
                filename: data.file.name,
                fileSize: data.file.size,
                copyright: null,
                createdAt: new Date(),
                createdFromUser: null,
                orientation: "VERTICAL",
                dominantColor: null,
                keywords: null,
                versions: [
                  {
                    id: payload.id,
                    getvLabel: "ORIGIN",
                    height: 0,
                    width: 0,
                    url: payload.url,
                    type: data.file.type,
                    access: null,
                    access_expire: null,
                  },
                ],
              };
              //add entry to imageLookup
              addToImgLookup(imageForStore);
              setAImgUploadProg(false);
              setAV_ImgDialog(false);
            }
          },
          function error(error) {
            setAImgUploadProg(false);
            setAImgUploadError("Die Speicherung ist leider fehlgeschlagen.");
          }
        );
      } else {
        uploadImageToServer(data.file, data.name, data.originalFileName)
          .then((image) => {
            if (image) {
              //add image to stored data
              const currImgs = conditionBuildingImgs
                ? _.cloneDeep(conditionBuildingImgs)
                : [];
              let version = null;
              for (let i = 0; i < image.versions.length; i++) {
                const v = image.versions[i];
                //default to first version
                if (!version) {
                  version = v;
                }
                //search for medium pic
                if (v && v.vLabel) {
                  if (v.vLabel === DEFAULT_IMAGE_SIZE) {
                    version = v;
                  }
                }
              }
              const convertedImage = {
                id: image.id,
                url: URL_TO_MEDIA_SERVER + version.url,
                key: version.access,
                alt: null,
                title: image.title,
                copyright: image.copyright,
                secured: version.access ? true : false,
                showPlaceholder: false,
              };
              currImgs.push(convertedImage);
              onConditionBuildingImgChanged(currImgs);
              //add entry to imageLookup
              addToImgLookup(image);
            }
            if (androidVersion) {
              setAImgUploadProg(false);
              setAV_ImgDialog(false);
            } else {
              setImgUploadProg(false);
              setV_ImgDialog(false);
            }
          })
          .catch((error) => {
            if (androidVersion) {
              setAImgUploadProg(false);
              setAImgUploadError("Upload ist leider fehlgeschlagen.");
            } else {
              setImgUploadProg(false);
              setImgUploadError("Upload ist leider fehlgeschlagen.");
            }
          });
      }
    }
  }

  function openBasisDialog(entryId) {
    selectCurrentBasis(entryId);
    setV_BsDialog(true);
  }

  function saveBasisChange(data) {
    //create or update value
    let adapted = false;
    const entries = basis;
    if (data && data.id) {
      for (let i = 0; i < entries.length; i++) {
        if (data.id === entries[i].id) {
          //update values
          entries[i].type = data.type;
          entries[i].date = data.date;
          entries[i].comment = data.comment;
          adapted = true;
          break;
        }
      }
      //if not found then create new
      if (!adapted) {
        //add additionalProperties to new created entry
        if (!data.additionalProperties) {
          data.additionalProperties = null;
        }
        entries.push(data);
      }
    }
    setV_BsDialog(false);
    changeBasis(entries);
  }

  function confirmBasisDeletion(entryId) {
    const entries = basis;
    const values = [];
    if (entryId) {
      for (let i = 0; i < entries.length; i++) {
        if (entryId !== entries[i].id) {
          values.push(entries[i]);
        }
      }
    }
    changeBasis(values);
  }

  function selectCurrentDocument(entryId) {
    //if data empty then "create"
    let foundEntry = false;
    if (entryId && documents) {
      for (let i = 0; i < documents.length; i++) {
        if (documents[i].id === entryId) {
          setCurrDoc(documents[i]);
          foundEntry = true;
          break;
        }
      }
    }
    //clear if not found
    if (!foundEntry) {
      setCurrDoc(null);
    }
  }

  function downloadDocument(entryId) {
    for (let i = 0; i < documents.length; i++) {
      if (documents[i].id === entryId) {
        if (offlineMode) {
          //just open the document
          openStoredMediaFileOnAndroid(
            liegenschaftId,
            projectId,
            entryId,
            documents[i].filename,
            documents[i].mime,
            function error() {
              toast.current.show({
                severity: "error",
                summary: "Fehler",
                detail: "Das Dokument kann leider nicht geöffnet werden",
                sticky: false,
                closable: true,
                life: 2000,
              });
            }
          );
        } else {
          downloadDocumentFromServer(documents[i].fileSrc, documents[i].fileKey)
            .then((response) => {
              execIfOnWeb(() => {
                fileDownload(response, documents[i].filename);
              });
              execIfOnAndroid(() => {
                writeDocumentFromBlobOnAndroid(
                  response,
                  documents[i].filename,
                  response.type,
                  true
                );
              });
            })
            .catch((error) => {
              toast.current.show({
                severity: "error",
                summary: "Fehler",
                detail: "Der Download ist leider fehlgeschlagen.",
                sticky: false,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR,
              });
            });
        }
        break;
      }
    }
  }

  function createNewDocument(data) {
    if (data) {
      setDocUploadProg(true);
      if (offlineMode) {
        //store for offline usage
        storeOfflineMediaOnAndroidInProject(
          liegenschaftId,
          projectId,
          data.file,
          data.file.name,
          function success(payload) {
            const entries = documents;
            //add created entry to list
            let entry = {
              id: payload.id,
              title: data.name,
              filename: data.file.name,
              fileSrc: payload.url,
              timestamp: new Date(),
              size: data.file.size,
              type: data.file.type,
              fileKey: null,
              copyright: null,
              secured: false,
              mime: data.file.type,
            };
            entries.push(entry);
            setDocUploadProg(false);
            setV_DocDialog(false);
            changeDocuments(entries);
          },
          function error(error) {
            setDocUploadProg(false);
            setDocUploadError("Speicherung ist leider fehlgeschlagen.");
          }
        );
      } else {
        uploadDocumentToServer(data.file, data.name, data.originalFileName)
          .then((result) => {
            const entries = documents;
            //add created entry to list
            if (result) {
              let entry = {
                id: result.id,
                title: result.title,
                filename: data.file.name,
                fileSrc: URL_TO_MEDIA_SERVER + result.url,
                timestamp: new Date(result.createdAt),
                size: data.file.size,
                type: result.type,
                fileKey: result.access,
                copyright: result.copyright,
                secured: result.access ? true : false,
              };
              entries.push(entry);
            }
            setDocUploadProg(false);
            setV_DocDialog(false);
            changeDocuments(entries);
          })
          .catch((error) => {
            setDocUploadProg(false);
            setDocUploadError("Upload ist leider fehlgeschlagen.");
          });
      }
    }
  }

  function confirmDocumentDeletion(entryId) {
    const entries = documents;
    const values = [];
    if (entryId) {
      for (let i = 0; i < entries.length; i++) {
        if (entryId !== entries[i].id) {
          values.push(entries[i]);
        }
      }
    }
    changeDocuments(values);
  }

  const injectStakeholderDeletionConfirmationDialog = () => {
    return (
      <BeeDeletionDialog
        id={"bee-audit-ui-audit-confirm-stakeholder-deletion"}
        visible={v_StkDelDialog}
        message={
          "Möchten Sie " +
          (currStakeholer ? currStakeholer.name : "die beteiligte Person") +
          " wirklich löschen?"
        }
        acceptLabel={"Löschen"}
        rejectLabel={"Abbrechen"}
        header={"Beteiligte Person löschen"}
        type={"primary"}
        onAccept={() => {
          if (currStakeholer) {
            confirmStakeholderDeletion(currStakeholer.id);
          }
          setV_StkDelDialog(false);
        }}
        onReject={() => setV_StkDelDialog(false)}
        onHide={() => setV_StkDelDialog(false)}
      />
    );
  };

  const injectStakeholderAdaptionDialog = () => {
    return (
      <BeeAuditDialogStakeholder
        id={"bee-audit-ui-audit-stakeholder"}
        data={currStakeholer}
        visible={v_StkDialog}
        onSave={(data) => saveStakeholderChange(data)}
        onCancel={() => setV_StkDialog(false)}
        onHide={() => setV_StkDialog(false)}
      />
    );
  };

  const injectBasisDeletionConfirmationDialog = () => {
    return (
      <BeeDeletionDialog
        id={"bee-audit-ui-audit-confirm-basis-deletion"}
        visible={v_BsDelDialog}
        message={"Möchten Sie die gewählte Audit-Grundlage wirklich löschen?"}
        acceptLabel={"Löschen"}
        rejectLabel={"Abbrechen"}
        header={"Auditgrundlage löschen"}
        type={"primary"}
        onAccept={() => {
          if (currBasis) {
            confirmBasisDeletion(currBasis.id);
          }
          setV_BsDelDialog(false);
        }}
        onReject={() => setV_BsDelDialog(false)}
        onHide={() => setV_BsDelDialog(false)}
      />
    );
  };

  const injectBasisAdaptionDialog = () => {
    return (
      <BeeAuditDialogBasis
        type={"default"}
        data={currBasis}
        visible={v_BsDialog}
        readOnly={readOnly}
        onSave={(data) => {
          saveBasisChange(data);
        }}
        onCancel={() => setV_BsDialog(false)}
        onHide={() => setV_BsDialog(false)}
      />
    );
  };

  const injectFileDeletionConfirmationDialog = () => {
    return (
      <BeeDeletionDialog
        id={"bee-audit-ui-audit-confirm-document-deletion"}
        visible={v_DocDelDialog}
        message={
          "Möchten Sie " +
          (currDoc ? currDoc.title : "dieses Dokument") +
          " wirklich löschen?"
        }
        acceptLabel={"Löschen"}
        rejectLabel={"Abbrechen"}
        header={"Dokument löschen"}
        type={"primary"}
        onAccept={() => {
          if (currDoc) {
            confirmDocumentDeletion(currDoc.id);
          }
          setV_DocDelDialog(false);
        }}
        onReject={() => setV_DocDelDialog(false)}
        onHide={() => setV_DocDelDialog(false)}
      />
    );
  };

  const injectFileUploadDialog = () => {
    return (
      <BeeUploadDialog
        type={"primary"}
        visible={v_DocDialog}
        disabled={false}
        locale={"de-DE"}
        header={"Dokument hochladen"}
        info={null}
        titleLabel={"Bezeichnung"}
        titleVisible={true}
        copyrightLabel={null}
        copyrightVisible={false}
        dropzoneTitle={"Upload"}
        dropzoneDescription={
          "Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder Klicken Sie hinzufügen"
        }
        dropzoneAddLabel={"Klicken zum Hinzufügen"}
        dropzoneFormats={ACCEPTED_FILE_FORMATS}
        progressVisible={docUploadProg}
        progressMessage={"Dokument wird hochgeladen"}
        errorVisible={docUploadError ? true : false}
        errorHeadline={docUploadError}
        errorDescription={docUploadError}
        onHide={() => setV_DocDialog(false)}
        onUpload={(data) => createNewDocument(data)}
      />
    );
  };

  const injectNotificationToast = () => {
    return (
      <BeeToast
        id={"bee-audit-ui-question-toast"}
        ref={toast}
        position={"top-right"}
      />
    );
  };

  const injectImageDeletionConfirmationDialog = () => {
    return (
      <BeeDeletionDialog
        id={"bee-audit-ui-audit-confirm-image-deletion"}
        visible={v_ImgDelDialog}
        message={
          "Möchten Sie " +
          convertImageIdToTitle(currImgId) +
          " wirklich löschen?"
        }
        acceptLabel={"Löschen"}
        rejectLabel={"Abbrechen"}
        header={"Bild löschen"}
        type={"primary"}
        onAccept={() => {
          if (currImgId) {
            deleteImage(currImgId);
          }
          setV_ImgDelDialog(false);
        }}
        onReject={() => setV_ImgDelDialog(false)}
        onHide={() => setV_ImgDelDialog(false)}
      />
    );
  };

  const injectAndroidCameraDialog = () => {
    return (
      <BeeAndroidCameraDialog
        id={"bee-audit-question-ui-document"}
        header={"Bild hochladen"}
        visible={v_AImgDialog}
        showProgress={aImgUploadProg}
        errorMsg={aImgUploadError}
        type={"primary"}
        img_quality={IMG_ANDROID_QUALITY}
        img_width={IMG_ANDROID_WIDTH}
        img_height={IMG_ANDROID_HEIGHT}
        img_preserveAspectRatio={IMG_ANDROID_PRESERVE_ASPECT_RATIO}
        onHide={() => setAV_ImgDialog(false)}
        onUpload={(data) => {
          createNewImage(data, true);
        }}
      />
    );
  };

  const injectImageUploadDialog = () => {
    return (
      <BeeUploadDialog
        type={"primary"}
        visible={v_ImgDialog}
        disabled={false}
        locale={"de-DE"}
        header={"Bild hochladen"}
        info={null}
        titleLabel={"Benennung"}
        titleVisible={true}
        copyrightLabel={null}
        copyrightVisible={false}
        dropzoneTitle={"Upload"}
        dropzoneDescription={
          "Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder Klicken Sie hinzufügen"
        }
        dropzoneAddLabel={"Klicken zum Hinzufügen"}
        dropzoneFormats={ACCEPTED_IMAGE_FORMATS}
        progressVisible={imgUploadProg}
        progressMessage={"Bild wird hochgeladen"}
        errorVisible={imgUploadError ? true : false}
        errorHeadline={imgUploadError}
        errorDescription={imgUploadError}
        onHide={() => setV_ImgDialog(false)}
        onUpload={(data) => createNewImage(data, false)}
      />
    );
  };

  const injectImageGallery = () => {
    return (
      <BeeImageSlider
        className={"bee-img-slider"}
        type={"primary"}
        ref={gallery}
        images={conditionBuildingImgs ? conditionBuildingImgs : []}
        activeIndex={0}
      />
    );
  };

  return (
    <div>
      <BeeAuditData
        homeUrl={homeUrl}
        breadcrumbData={breadcrumbData}
        progress={
          categoryTree && categoryTree.length > 0 && categoryTree[0].data
            ? categoryTree[0].data.progress
            : 0
        }
        maxProgress={
          categoryTree && categoryTree.length > 0 && categoryTree[0].data
            ? categoryTree[0].data.questions
            : 0
        }
        viewOptions={viewOptions}
        selectedView={selectedView}
        warningMessage={warningMessage}
        auditTitle={auditTitle}
        auditNumber={auditNumber}
        auditReportDate={auditReportDate}
        auditDate={auditDate}
        auditAuditor={auditAuditor}
        auditDescription={auditDescription}
        auditServiceProvider={auditServiceProvider}
        auditAuthority={auditAuthority}
        auditServiceProviderShort={auditServiceProviderShort}
        isAdaptedTitle={isAdaptedTitle}
        isAdaptedNumber={isAdaptedNumber}
        isAdaptedReportDate={isAdaptedReportDate}
        isAdaptedAuditDate={isAdaptedAuditDate}
        isAdaptedAuditor={isAdaptedAuditor}
        isAdaptedDescription={isAdaptedDescription}
        isAdaptedServiceProvider={isAdaptedServiceProvider}
        isAdaptedServiceProviderShort={isAdaptedServiceProviderShort}
        isAdaptedAuthority={isAdaptedAuthority}
        isErrorTitle={isErrorTitle}
        isErrorNumber={isErrorNumber}
        isErrorReportDate={isErrorReportDate}
        isErrorAuditDate={isErrorAuditDate}
        isErrorAuditor={isErrorAuditor}
        isErrorDescription={isErrorDescription}
        isErrorServiceProvider={isErrorServiceProvider}
        isErrorServiceProviderShort={isErrorServiceProviderShort}
        isErrorAuthority={isErrorAuthority}
        documents={documents}
        stakeholders={stakeholders}
        basis={basis}
        leistungsSetup={leistungsSetup}
        isCurrencySetup={isCurrencySetup}
        conditionBuildingImgs={conditionBuildingImgs}
        conditionBuildingDescr={conditionBuildingDescr}
        readOnly={readOnly}
        showSave={!readOnly}
        showLogout={showLogout}
        showSnapshot={showSnapshot}
        showWordReport={showWordReport}
        showExcelReport={showExcelReport}
        onSave={() => onSave()}
        onLogout={() => onLogout()}
        onChangeTitle={(value) => changeTitle(value)}
        onChangeNumber={(value) => changeNumber(value)}
        onChangeReportDate={(value) => changeReportDate(value)}
        onChangeAuditDate={(value) => changeAuditDate(value)}
        onChangeAuditor={(value) => changeAuditor(value)}
        onChangeDescription={(value) => changeDescription(value)}
        onChangeServiceProvider={(value) => changeServiceProvider(value)}
        onChangeServiceProviderShort={(value) =>
          changeServiceProviderShort(value)
        }
        onChangeAuthority={(value) => changeAuthority(value)}
        //DOCUMENT
        onAddDocument={() => setV_DocDialog(true)}
        onDownloadDocument={(entryId) => downloadDocument(entryId)}
        onDeleteDocument={(entryId) => {
          selectCurrentDocument(entryId);
          setV_DocDelDialog(true);
        }}
        //STAKEHOLDER
        onAddStakeholder={() => openStakeholderDialog()}
        onViewStakeholder={(stakeholderId) =>
          openStakeholderDialog(stakeholderId)
        }
        onDeleteStakeholder={(stakeholderId) => {
          selectCurrentStakeholder(stakeholderId);
          setV_StkDelDialog(true);
        }}
        //BASIS
        onAddBasis={() => openBasisDialog()}
        onViewBasis={(basisId) => openBasisDialog(basisId)}
        onDeleteBasis={(basisId) => {
          selectCurrentBasis(basisId);
          setV_BsDelDialog(true);
        }}
        onSelectView={(view) => selectView(view)}
        onGenerateWordReport={() => generateWordReport()}
        onGenerateExcelReport={() => generateExcelReport()}
        onGenerateSnapshot={() => generateSnapshot()}
        //Building Condition
        onChangeConditionBuildingDescr={(value) =>
          changeConditionBuildingDescr(value)
        }
        onImageClick={(imageId) => clickImageCallback(imageId)}
        onImageRemove={(imageId) => {
          setCurrImgId(imageId);
          setV_ImgDelDialog(true);
        }}
        onImageAdd={() => {
          execIfOnWeb(() => setV_ImgDialog(true));
          execIfOnAndroid(() => setAV_ImgDialog(true));
        }}
      />
      {/* DIALOGS */}
      {v_StkDialog ? injectStakeholderAdaptionDialog() : null}
      {injectStakeholderDeletionConfirmationDialog()}
      {v_BsDialog ? injectBasisAdaptionDialog() : null}
      {injectBasisDeletionConfirmationDialog()}
      {v_DocDialog ? injectFileUploadDialog() : null}
      {injectFileDeletionConfirmationDialog()}
      {v_ImgDialog ? injectImageUploadDialog() : null}
      {injectImageDeletionConfirmationDialog()}
      {v_AImgDialog ? injectAndroidCameraDialog() : null}
      {/* toast */}
      {injectNotificationToast()}
      {/* gallery */}
      {injectImageGallery()}
    </div>
  );
}

export default AuditUIOverview;
