import {
  callAllActiveLocksForUser,
  releaseLockForMaintainer,
} from "./NetworkHelper";
import jwt from "jsonwebtoken";

export function removeAllOnlinePendingLocksForUserId() {
  const token = sessionStorage.getItem("aT");
  let userId = null;
  if (token) {
    const decoded = jwt.decode(token, { complete: true });
    if (decoded) {
      userId =
        decoded.payload && decoded.payload.iss ? decoded.payload.iss : null;
    }
  }
  if (userId) {
    callAllActiveLocksForUser(userId)
      .then((data) => {
        const entries = data.data;
        for (let i = 0; i < entries.length; i++) {
          const lock = entries[i];
          if (lock.onlineLock === true) {
            //if this lock is an online lock => release it
            releaseLockForMaintainer(lock.projectId, lock.maintainerId);
          }
        }
      })
      .catch(() => {
        //show error?!
      });
  }
}
