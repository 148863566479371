import React from "react";
import { useId } from "react-id-generator";
import BeeAuditHeader from "../Fragments/BeeAuditHeader";
import BeeAuditSelectTable from "../Fragments/BeeAuditSelectTable";
import {
  BeeButton,
  BeeOutlinedButton,
  BeeContentHeadline,
  BeeErrorLabel,
  BeeLoadingSpinner,
} from "bee-atomic-components";
import "./BeeAuditSelect.scss";

export function BeeAuditSelect({
  liegenschaftName,
  homeUrl,
  breadcrumbData,
  warningMessage,
  readOnly,
  data,
  syncPossible,
  showProgress,
  showOffline,
  errorMsg,
  onViewVersion,
  onViewProject,
  onRunProject,
  onDownload,
  onUpload,
  onWorkOffline,
  onLogout,
  onAddAudit,
}) {
  const currentId = useId(1, "bee-audit-select-")[0];

  //classNames
  const classNames = "bee-audit-select";
  const contentClassNames = "m-4 p-2 bee-audit-select-content";
  const footerBtnClassNames =
    "grid justify-content-between bee-audit-select-footer-buttons";

  //label
  const headline = "Audit-Übersicht";
  const addAuditLabel = "Neues Audit anlegen";
  const workOfflineLabel = "Offline Arbeiten";

  function logout(e) {
    if (onLogout) {
      onLogout(e);
    }
  }

  function workOffline(e) {
    if (onWorkOffline) {
      onWorkOffline(e);
    }
  }

  function addAudit(e) {
    if (onAddAudit) {
      onAddAudit(e);
    }
  }

  function upload(itemId) {
    if (onUpload) {
      onUpload(itemId);
    }
  }

  function download(itemId) {
    if (onDownload) {
      onDownload(itemId);
    }
  }

  function viewVersion(itemId) {
    if (onViewVersion) {
      onViewVersion(itemId);
    }
  }

  function viewProject(itemId) {
    if (onViewProject) {
      onViewProject(itemId);
    }
  }

  function runProject(itemId) {
    if (onRunProject) {
      onRunProject(itemId);
    }
  }

  function injectHeader() {
    return (
      <BeeAuditHeader
        homeUrl={homeUrl}
        items={breadcrumbData}
        type={"dark"}
        warningMessage={warningMessage}
        showSave={false}
        showLogout={true}
        showProgress={false}
        onSave={null}
        onLogout={(e) => logout(e)}
      />
    );
  }
  function injectHeadlines() {
    return (
      <>
        <BeeContentHeadline label={headline} headline={"h1"} type={"primary"} />
        {showProgress && !errorMsg ? (
          <BeeLoadingSpinner type={"secondary"} />
        ) : null}
        <BeeContentHeadline
          label={liegenschaftName}
          headline={"h2"}
          type={"primary"}
        />
      </>
    );
  }
  function injectAuditSelectSection() {
    return (
      <>
        {errorMsg ? (
          <BeeErrorLabel size={"large"} label={errorMsg} visible={true} />
        ) : null}
        <BeeAuditSelectTable
          type={"dark"}
          data={data}
          syncPossible={syncPossible}
          onViewVersion={(itemId) => viewVersion(itemId)}
          onViewProject={(itemId) => viewProject(itemId)}
          onRunProject={(itemId) => runProject(itemId)}
          onDownload={(itemId) => download(itemId)}
          onUpload={(itemId) => upload(itemId)}
        />
      </>
    );
  }

  function injectFooterBtnRow() {
    return (
      <>
        <div>
          <BeeOutlinedButton
            label={addAuditLabel}
            disabled={readOnly}
            type={"primary"}
            onClick={(e) => addAudit(e)}
          />
        </div>
        <div>
          {showOffline ? (
            <BeeButton
              label={workOfflineLabel}
              disabled={readOnly}
              type={"secondary"}
              onClick={(e) => workOffline(e)}
            />
          ) : null}
        </div>
      </>
    );
  }

  return (
    <div id={currentId} className={classNames}>
      {injectHeader()}
      <div className={contentClassNames}>
        {injectHeadlines()}
        {injectAuditSelectSection()}
        <div className={footerBtnClassNames}>{injectFooterBtnRow()}</div>
      </div>
    </div>
  );
}

export default BeeAuditSelect;
