import React, { useState } from "react";
import { useId } from "react-id-generator";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Card } from "primereact/card";
import {
  BeeAddEntryCard,
  BeeContentHeadline,
  BeeLoadingSpinner,
  BeeErrorLabel,
  BeeSearchBar,
  BeeValidatedDropDown,
} from "bee-atomic-components";
import "./BeeAuditLiegenschaftSelect.scss";
import BeeAuditLiegenschaftPreview from "../Fragments/BeeAuditLiegenschaftPreview";
import BeeAuditHeader from "../Fragments/BeeAuditHeader";
import {
  DROPDOWN_LIEGENSCHAFT_SORT,
  LIEGENSCHAFT_SORT_ADDRESS,
  LIEGENSCHAFT_SORT_BUILDING_NO,
  LIEGENSCHAFT_SORT_CITY,
  LIEGENSCHAFT_SORT_COUNT_AUDITS,
  LIEGENSCHAFT_SORT_LAST_AUDIT,
} from "../../Helper/constants";
import _ from "lodash";

export function BeeAuditLiegenschaftSelect({
  data,
  homeUrl,
  breadcrumbData,
  warningMessage,
  layout,
  activateHover,
  showProgress,
  errorMsg,
  onClick,
  onAdd,
  onEdit,
  onLogout,
}) {
  const currentId = useId(1, "bee-audit-liegenschaft-select-")[0];
  const [currLayout, setLayout] = useState(layout);
  const [visibleData, setVisibleData] = useState();
  const [initData, setInitData] = useState();
  const NO_FILTER = " - keine Filterung - ";
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(LIEGENSCHAFT_SORT_CITY);
  const [filterOptions, setFilterOptions] = useState();
  const [currentFilter, setCurrentFilter] = useState(NO_FILTER);
  const [forceReRender, setForceReRender] = useState(false);

  //classNames
  const orientationClassNames =
    currLayout === "list"
      ? "bee-audit-liegenschaft-select-list col-12 "
      : "bee-audit-liegenschaft-select-card ";
  const hoverClassNames = activateHover
    ? "bee-audit-liegenschaft-select-hover"
    : "bee-audit-liegenschaft-select-noHover";
  const classNames = orientationClassNames + hoverClassNames;
  const addCardClassNames = " bee-audit-liegenschaft-select-add-card";
  const headlineClassNames =
    "bee-audit-liegenschaft-select-content m-4 p-2 justify-content-start";
  const dataViewClassNames = errorMsg ? "d-flex jc-between" : "d-flex jc-end";
  //label
  const labelEmptyMessage = "Keine Liegenschaften verfügbar!";
  const headline = "Liegenschaften";

  function filterUsingSearchTermSortAndFilter(
    data,
    search,
    sort,
    currentFilter
  ) {
    setSearch(search);
    setSort(sort);
    setCurrentFilter(currentFilter);
    if (!search || search === "") {
      filterUsingFilter(currentFilter, sort, data);
    } else {
      if (data && data.length > 0) {
        let result = _.cloneDeep(data);
        result = _.filter(result, function (ls) {
          const attr = [];
          attr.push(_.toLower(ls.stadt ? ls.stadt : ""));
          attr.push(_.toLower(ls.strasse ? ls.strasse : ""));
          attr.push(_.toLower(ls.postleitzahl ? ls.postleitzahl : ""));
          attr.push(_.toLower(ls.hausnummer ? ls.hausnummer : ""));
          attr.push(_.toLower(ls.number ? ls.number : ""));
          attr.push(_.toLower(ls.title ? ls.title : ""));
          return _.includes(attr.join(" "), _.toLower(search));
        });
        filterUsingFilter(currentFilter, sort, result);
      }
    }
  }

  function filterUsingFilter(currentFilter, sort, payload) {
    if (!currentFilter || currentFilter === NO_FILTER) {
      sortCurrentData(sort, payload);
    } else {
      let result = _.cloneDeep(payload);
      result = _.filter(result, function (ls) {
        return ls && ls.stadt && ls.stadt === currentFilter ? true : false;
      });
      sortCurrentData(sort, result);
    }
  }

  function sortCurrentData(sort, payload) {
    let ls = _.cloneDeep(payload);
    //test if add is contained
    let containsAdd = false;
    if (ls && ls.length > 0) {
      for (let i = 0; i < ls.length; i++) {
        if (Object.keys(ls[i]).length === 0) {
          containsAdd = true;
        }
      }
    }
    //remove empty add if contained
    _.remove(ls, function (n) {
      return Object.keys(n).length === 0;
    });
    //sort by choice
    switch (sort) {
      case LIEGENSCHAFT_SORT_CITY:
        ls = _.sortBy(ls, ["stadt", "number"]);
        break;
      case LIEGENSCHAFT_SORT_ADDRESS:
        ls = _.sortBy(ls, ["strasse", "number"]);
        break;
      case LIEGENSCHAFT_SORT_BUILDING_NO:
        ls = _.sortBy(ls, ["number"]);
        break;
      case LIEGENSCHAFT_SORT_COUNT_AUDITS:
        ls = _.sortBy(ls, ["countAudits", "number"]);
        ls = _.reverse(ls);
        break;
      case LIEGENSCHAFT_SORT_LAST_AUDIT:
        let values = _.cloneDeep(ls);
        let noAudits = _.remove(values, function (n) {
          return !n.lastAuditDate;
        });
        values = _.sortBy(values, ["lastAuditDate", "number"]);
        values = _.reverse(values);
        noAudits = _.sortBy(noAudits, ["lastAuditDate", "number"]);
        ls = [...values, ...noAudits];
        break;
      default:
      // code block
    }
    if (containsAdd) {
      setVisibleData([{}, ...ls]);
    } else {
      setVisibleData(ls);
    }
    //force rerender view
    setForceReRender(!forceReRender);
  }

  function getCityFilter() {
    if (filterOptions) {
      return filterOptions;
    } else {
      //calculate first use
      if (visibleData && visibleData.length > 0) {
        let cities = [];
        for (let i = 0; i < visibleData.length; i++) {
          if (visibleData[i] && visibleData[i].stadt) {
            cities.push(visibleData[i].stadt);
          }
        }
        cities = _.uniq(cities);
        cities.sort();
        let tmp = [];
        tmp.push(NO_FILTER);
        const output = [...tmp, ...cities];
        setFilterOptions(output);
        return output;
      } else {
        return null;
      }
    }
  }

  function addNewEntry(e) {
    if (onAdd) {
      onAdd(e);
    }
  }

  function editLiegenschaft(lId) {
    if (onEdit) {
      onEdit(lId);
    }
  }

  function clickLiegenschaft(lId) {
    if (onClick) {
      onClick(lId);
    }
  }

  function logout() {
    if (onLogout) {
      onLogout();
    }
  }

  function injectHeader() {
    return (
      <BeeAuditHeader
        homeUrl={homeUrl}
        items={breadcrumbData}
        type={"dark"}
        warningMessage={warningMessage}
        maxProgress={null}
        progress={null}
        showSave={false}
        showLogout={true}
        showProgress={false}
        onLogout={() => logout()}
      />
    );
  }

  function adaptDataIfNeccessary() {
    if (!_.isEqual(data, initData)) {
      setInitData(data);
      //force recreate dropdown
      setFilterOptions(null);
      filterUsingSearchTermSortAndFilter(data, search, sort, currentFilter);
    }
  }

  function renderDataViewHeadline() {
    return (
      <div className={dataViewClassNames}>
        {errorMsg ? (
          <BeeErrorLabel size={"large"} label={errorMsg} visible={true} />
        ) : null}
        <DataViewLayoutOptions
          layout={currLayout}
          onChange={(e) => setLayout(e.value)}
        />
      </div>
    );
  }

  function createPreviewEntry(liegenschaft, orientation) {
    const lId = liegenschaft.id;
    return (
      <BeeAuditLiegenschaftPreview
        activateHover={activateHover}
        title={liegenschaft.title}
        number={liegenschaft.number}
        city={liegenschaft.stadt}
        imageUrl={liegenschaft.image ? liegenschaft.image.url : null}
        imageKey={liegenschaft.image ? liegenschaft.image.key : null}
        imageAlt={liegenschaft.image ? liegenschaft.image.alt : null}
        imageTitle={liegenschaft.image ? liegenschaft.image.title : null}
        imageCopyright={
          liegenschaft.image ? liegenschaft.image.copyright : null
        }
        isImageSecured={liegenschaft.image ? liegenschaft.image.secured : false}
        showPlaceHolder={
          liegenschaft.image ? liegenschaft.image.showPlaceholder : false
        }
        lastAuditDate={liegenschaft.lastAuditDate}
        countAudits={liegenschaft.countAudits}
        orientation={orientation}
        onClick={() => clickLiegenschaft(lId)}
        onEdit={() => editLiegenschaft(lId)}
      />
    );
  }

  function template(liegenschaft) {
    if (!liegenschaft || !liegenschaft.id) {
      return (
        <Card className={classNames + addCardClassNames}>
          <div onClick={(e) => addNewEntry(e)}>
            <BeeAddEntryCard />
          </div>
        </Card>
      );
    }
    return (
      <div className={classNames}>
        {createPreviewEntry(
          liegenschaft,
          currLayout === "list" ? "list" : "card"
        )}
      </div>
    );
  }

  return (
    <div id={currentId}>
      {adaptDataIfNeccessary()}
      {injectHeader()}
      {forceReRender ? <></> : null}
      <div className={headlineClassNames}>
        <BeeContentHeadline label={headline} headline={"h1"} type={"primary"} />
        {showProgress && !errorMsg ? (
          <BeeLoadingSpinner type={"secondary"} />
        ) : null}
        <div className="grid audit-overview-search-area">
          <div className="col">
            <BeeValidatedDropDown
              label={"Filterung (Stadt)"}
              value={currentFilter}
              options={getCityFilter()}
              disabled={false}
              formstate={"neutral"}
              readOnly={false}
              required={false}
              onChange={(e) =>
                filterUsingSearchTermSortAndFilter(initData, search, sort, e)
              }
            />
          </div>
          <div className="col">
            <BeeValidatedDropDown
              label={"Sortieren"}
              value={sort}
              options={DROPDOWN_LIEGENSCHAFT_SORT}
              disabled={false}
              formstate={"neutral"}
              readOnly={false}
              required={false}
              onChange={(e) =>
                filterUsingSearchTermSortAndFilter(
                  initData,
                  search,
                  e,
                  currentFilter
                )
              }
            />
          </div>
          <div className="col">
            <BeeSearchBar
              label={"Suche"}
              value={search}
              disabled={false}
              readOnly={false}
              required={false}
              formstate={"neutral"}
              type={"primary"}
              onSearch={(e) =>
                filterUsingSearchTermSortAndFilter(
                  initData,
                  e,
                  sort,
                  currentFilter
                )
              }
            />
          </div>
        </div>
        <DataView
          value={visibleData}
          layout={currLayout}
          itemTemplate={template}
          header={renderDataViewHeadline()}
          emptyMessage={labelEmptyMessage}
        />
      </div>
    </div>
  );
}

export default BeeAuditLiegenschaftSelect;
