import React, { useEffect, useState, useRef } from "react";
import { BeeToast } from "bee-atomic-components";
import BeeAuditOfflineSelect from "../Components/BeeAuditOfflineSelect";
import {
  breadCrumbOfflineHome,
  breadCrumbOfflineOverview,
} from "../../Helper/BreadCrumbHelper";
import {
  DURATION_NOTIFICATION_ERROR,
  PATH_LOGIN,
  PATH_OFFLINE_AUDIT,
  STORAGE_AFFIX_LIEGENSCHAFT,
  STORAGE_AFFIX_PROJECT,
} from "../../Helper/constants";
import {
  getStoredProjects,
  readJsonFileFromDisk,
} from "../../Helper/CapacitorHelper";
import { createCategoryTree } from "../../Helper/QuestionaireHelper";

function OfflineAuditOverviewPage(props) {
  const [projects, setProjects] = useState([]);
  const [projectLookup, setProjectLookup] = useState(new Map());
  const toast = useRef(null);

  //function init on page-load
  useEffect(() => {
    const payload = [];
    const lookup = new Map();
    getStoredProjects(
      function success(data) {
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const projectUrl = data[i];
            //for each project, load liegenschaft.json & project.json
            const pathToLiegenschaft = projectUrl + STORAGE_AFFIX_LIEGENSCHAFT;
            readJsonFileFromDisk(
              pathToLiegenschaft,
              function success(liegenschaftJson) {
                const pathToProject = projectUrl + STORAGE_AFFIX_PROJECT;
                readJsonFileFromDisk(
                  pathToProject,
                  function success(projectJson) {
                    if (projectJson && liegenschaftJson) {
                      const project = JSON.parse(projectJson);
                      const liegenschaft = JSON.parse(liegenschaftJson);
                      lookup.set(project.id, projectUrl);
                      const locks = [];
                      const maintainer = project ? project.maintainer : null;
                      for (let j = 0; j < maintainer.length; j++) {
                        const person = maintainer[j];
                        //search for active locks from other users
                        if (person) {
                          const fName = person.userFirstname;
                          const lName = person.userLastname;
                          if (person.locks && person.locks.length > 0) {
                            for (let x = 0; x < person.locks.length; x++) {
                              const lock = person.locks[x];
                              if (
                                lock &&
                                lock.createdAt !== null &&
                                lock.releasedAt === null
                              ) {
                                const online = lock.onlineLock
                                  ? ""
                                  : "(heruntergeladen)";
                                locks.push(fName + " " + lName + " " + online);
                              }
                            }
                          }
                        }
                      }
                      payload.push({
                        id: project.id ? project.id : null,
                        progress: calculateProgress(project),
                        type:
                          project.payload &&
                          project.payload.questionaire &&
                          project.payload.questionaire.questionaireName
                            ? project.payload.questionaire.questionaireName
                            : project.templateId,
                        title:
                          project.payload && project.payload.auditName
                            ? project.payload.auditName
                            : "",
                        lastChange: new Date(
                          project.lastUpdate
                            ? project.lastUpdate
                            : project.createdAt
                        ),
                        dienstleister: extractDienstleister(project.payload),
                        rating: calculateRating(project.payload),
                        lockedBy: locks && locks.length > 0 ? locks : null,
                        localversion: true,
                        liegenschaftId: liegenschaft.id
                          ? liegenschaft.id
                          : null,
                        liegenschaftTitle: liegenschaft.name
                          ? liegenschaft.name
                          : null,
                        liegenschaftNummer: liegenschaft.nummer
                          ? liegenschaft.nummer
                          : null,
                      });
                      //push state live if all projects has been loaded
                      if (data.length === payload.length) {
                        setProjects(payload);
                        setProjectLookup(lookup);
                      }
                    }
                  },
                  function error() {
                    toast.current.show({
                      severity: "error",
                      summary: "Laden fehlgeschlagen.",
                      detail:
                        "Die gespeicherten Daten des Projekts konnten leider nicht geladen werden.",
                      sticky: false,
                      closable: true,
                      life: DURATION_NOTIFICATION_ERROR,
                    });
                  }
                );
              },
              function error() {
                toast.current.show({
                  severity: "error",
                  summary: "Laden fehlgeschlagen.",
                  detail:
                    "Die gespeicherten Daten der Liegenschaft konnten leider nicht geladen werden.",
                  sticky: false,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR,
                });
              }
            );
          }
        }
      },
      function error() {
        setProjects([]);
        toast.current.show({
          severity: "error",
          summary: "Laden fehlgeschlagen.",
          detail:
            "Es konnten leider keine heruntergeladenen Projekte geladen werden.",
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_ERROR,
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function extractDienstleister(auditPayload) {
    if (auditPayload) {
      if (
        auditPayload.dienstleister &&
        auditPayload.dienstleister.dienstleisterKurzname
      ) {
        return auditPayload.dienstleister.dienstleisterKurzname;
      }
    }
    return "(not given)";
  }

  function calculateProgress(project) {
    if (project) {
      const pay = createCategoryTree(project);
      if (pay && pay.length > 0) {
        const data = pay[0].data;
        const progress = data.progress ? data.progress : 0;
        const questions = data.questions ? data.questions : 1;
        const completion = progress / questions;
        return Math.round(completion * 100);
      }
    }
    return 0;
  }

  function calculateRating(auditPayload) {
    if (auditPayload) {
      //FIXME implement calculate progress!!
    }
    return -1;
  }

  function workOnline() {
    props.history.push({
      pathname: PATH_LOGIN,
    });
  }

  function clickAudit(id) {
    const filePath = projectLookup.get(id);
    if (filePath) {
      let fields = filePath.split("/");
      const liegenschaftId = fields[2];
      const projectId = fields[3];
      props.history.push({
        pathname: PATH_OFFLINE_AUDIT,
        search: "?aid=" + projectId + "&lid=" + liegenschaftId,
      });
    }
  }

  const injectNotificationToast = () => {
    return (
      <BeeToast id={"bee-audit-ui-toast"} ref={toast} position={"top-right"} />
    );
  };

  return (
    <div>
      <BeeAuditOfflineSelect
        homeUrl={breadCrumbOfflineHome}
        breadcrumbData={breadCrumbOfflineOverview}
        warningMessage={null}
        data={projects}
        onClick={(id) => clickAudit(id)}
        onWorkOnline={() => workOnline()}
      />
      {injectNotificationToast()}
    </div>
  );
}

export default OfflineAuditOverviewPage;
