import React, { useEffect, useState } from "react";
import _ from "lodash";
import "./BeeAuditLvQuality.scss";
import {
  BeeCurrencyInput,
  BeeNumberInput,
  BeeSlider,
  BeeValidatedDropDown,
} from "bee-atomic-components";
import {
  CURRENCY,
  ERFUELLT,
  FULFILLMENTARRAY,
  LOCALE,
  MINDERUNG,
  NACHERFUELLT,
  NACHZUERFUELLEN,
  UNGEPRUEFT,
} from "../../Helper/constants";

function BeeAuditLvQuality({ data, readOnly, onChange }) {
  const [percentageDisabled, setPercentageDisabled] = useState(false);
  const [reductionPercentage, setReductionPercentage] = useState(
    data.reductionPercentage
  );

  const readOnlyClassName = readOnly ? " readOnly" : "";
  const deactivatedClassName = data.deactivated ? " deactivated" : "";
  const colorClassName = isQualityColored() ? " colored" : "";
  const tableCatClassName =
    "bee-audit-lv-quality cat" +
    readOnlyClassName +
    deactivatedClassName +
    colorClassName;
  const tablePosClassName =
    "bee-audit-lv-quality pos" +
    readOnlyClassName +
    deactivatedClassName +
    colorClassName;

  useEffect(() => {
    if (data.type === "pos") {
      if (data.reductionPercentage !== reductionPercentage) {
        setReductionPercentage(data.reductionPercentage);
      }
    } else if (data.type === "cat") {
      if (data.catMeanReduction !== reductionPercentage) {
        setReductionPercentage(data.catMeanReduction);
      }
    }
    disableAdaptionView(data.fulfillment);
  }, [data]);

  function change(node) {
    if (!readOnly && onChange) {
      onChange(node);
    }
  }

  function changeFulfillment(value) {
    if (!readOnly && onChange) {
      let adaptedPercent = data.reductionPercentage;
      switch (value) {
        case UNGEPRUEFT.value:
          //set to 0 by default
          adaptedPercent = 0;
          break;
        case ERFUELLT.value:
          //set to 100 by default
          adaptedPercent = 100;
          break;
        case MINDERUNG.value:
          break;
        case NACHERFUELLT.value:
          //set to 100 by default
          if (reductionPercentage !== 100) {
            adaptedPercent = 100;
          }
          break;
        case NACHZUERFUELLEN.value:
          //set to 0 by default if nothing was adapted
          if (reductionPercentage === 100) {
            adaptedPercent = 0;
          }
          break;
        default:
          break;
      }
      data.reductionPercentage = adaptedPercent;
      data.fulfillment = value;
      change(data);
    }
  }

  function changeReduction(value) {
    if (!readOnly && onChange) {
      data.reductionPercentage = value;
      change(data);
    }
  }

  function disableAdaptionView(value) {
    switch (value) {
      case UNGEPRUEFT.value:
        setPercentageDisabled(true);
        break;
      case ERFUELLT.value:
        setPercentageDisabled(true);
        break;
      case MINDERUNG.value:
        setPercentageDisabled(false);
        break;
      case NACHERFUELLT.value:
        setPercentageDisabled(true);
        break;
      case NACHZUERFUELLEN.value:
        setPercentageDisabled(false);
        break;
      default:
        setPercentageDisabled(false);
        break;
    }
  }

  function isQualityColored() {
    if (data.type === "pos") {
      if (data && data.qualityAdaptedGp > 0 && !data.deactivated) {
        if (data.reductionPercentage < 100 || reductionPercentage < 100) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  }

  return (
    <>
      {data && data.type === "cat" ? (
        <table className={tableCatClassName}>
          <tbody>
            <tr>
              <td className={"platzhalter"}></td>
              <td className={"fulfillment-percentage-td readOnly"}>
                <BeeNumberInput
                  label={"%"}
                  value={data.catMeanReduction}
                  minFractionDigits={0}
                  maxFractionDigits={0}
                  stepSize={1}
                  disabled={false}
                  grouping={false}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
              <td className={"gp-td readOnly"}>
                <BeeCurrencyInput
                  label={"GP"}
                  value={data.qualityAdaptedGp}
                  disabled={false}
                  minFractionDigits={0}
                  maxFractionDigits={3}
                  currency={CURRENCY}
                  locale={LOCALE}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
            </tr>
          </tbody>
        </table>
      ) : data && data.type === "pos" ? (
        <>
          <table className={tablePosClassName}>
            <tbody>
              <tr>
                <td className={"fulfillment-drop-td"}>
                  <BeeValidatedDropDown
                    label={"Erfüllungsgrad"}
                    value={data.fulfillment}
                    options={FULFILLMENTARRAY}
                    optionLabel={"label"}
                    disabled={readOnly || data.deactivated}
                    formstate={"neutral"}
                    readOnly={readOnly || data.deactivated}
                    required={false}
                    onChange={(fulfillment) => changeFulfillment(fulfillment)}
                  />
                </td>
                <td className={"fulfillment-percentage-td-collapsed readOnly"}>
                  <BeeNumberInput
                    label={"%"}
                    value={reductionPercentage}
                    minFractionDigits={0}
                    maxFractionDigits={0}
                    stepSize={1}
                    disabled={false}
                    grouping={false}
                    formstate={"neutral"}
                    readOnly={true}
                    required={false}
                    onChange={null}
                  />
                </td>
                <td className={"gp-td readOnly"}>
                  <BeeCurrencyInput
                    label={"GP"}
                    value={data.qualityAdaptedGp}
                    disabled={false}
                    minFractionDigits={0}
                    maxFractionDigits={3}
                    currency={CURRENCY}
                    locale={LOCALE}
                    formstate={"neutral"}
                    readOnly={true}
                    required={false}
                    onChange={null}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td className={"fulfillment-slider-td"}>
                          <BeeSlider
                            value={reductionPercentage}
                            min={0}
                            max={100}
                            step={1}
                            disabled={
                              readOnly || data.deactivated || percentageDisabled
                            }
                            type={"primary"}
                            onChange={(e) => setReductionPercentage(e.value)}
                            onSlideEnd={(e) => changeReduction(e.value)}
                          />
                        </td>
                        <td
                          className={
                            percentageDisabled
                              ? "fulfillment-percentage-td readOnly"
                              : "fulfillment-percentage-td"
                          }
                        >
                          <BeeNumberInput
                            label={"%"}
                            value={reductionPercentage}
                            minFractionDigits={0}
                            maxFractionDigits={0}
                            stepSize={1}
                            disabled={
                              readOnly || data.deactivated || percentageDisabled
                            }
                            grouping={false}
                            formstate={"neutral"}
                            readOnly={
                              readOnly || data.deactivated || percentageDisabled
                            }
                            required={false}
                            onChange={(e) => {
                              let val = e.value;
                              val = val < 0 ? 0 : val > 100 ? 100 : val;
                              //if larger than 100 or less then 0 rerender
                              if (val !== e) {
                                setReductionPercentage(val);
                              }
                              changeReduction(val);
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>

                <td className={"platzhalter-fulfillment"}></td>
              </tr>
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
}

export default BeeAuditLvQuality;
