import React from "react";
import "./BeeAuditLvContract.scss";
import {
  BeeTextInput,
  BeeIconButton,
  BeeCurrencyInput,
} from "bee-atomic-components";
import { CURRENCY, LOCALE } from "../../Helper/constants";

function BeeAuditLvContract({ data, readOnly, onDelete, onAdd, onRestore }) {
  const deactivatedClassName = data.deactivated ? " deactivated" : "";
  const newPositionClassName = data.newPosition ? " newPosition" : "";

  const classNames =
    "bee-audit-lv-contract" + deactivatedClassName + newPositionClassName;

  function injectHeadline() {
    if (data.optionalPosition && data.newPosition) {
      return "[" + data.number + "B]  - " + data.title + " *NEU";
    } else if (data.optionalPosition) {
      return "[" + data.number + "B]  - " + data.title;
    } else if (data.newPosition) {
      return "[" + data.number + "] - " + data.title + " *NEU";
    } else {
      return "[" + data.number + "] - " + data.title;
    }
  }

  function added(e) {
    //returnt ganzen datenstaz data
    if (onAdd) {
      onAdd(e);
    }
  }

  function deleted(e) {
    //returnt ganzen datenstaz data
    if (onDelete) {
      onDelete(e);
    }
  }

  function restored(e) {
    if (onRestore) {
      onRestore(e);
    }
  }

  return (
    <>
      {data && data.type === "cat" ? (
        <table className={classNames}>
          <tbody>
            <tr>
              <td className={"title-td readOnly"}>
                <BeeTextInput
                  label={null}
                  value={injectHeadline()}
                  disabled={false}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
              <td className={"gp-td readOnly"}>
                <BeeCurrencyInput
                  label={"GP"}
                  value={data.gp}
                  disabled={false}
                  minFractionDigits={0}
                  maxFractionDigits={3}
                  currency={CURRENCY}
                  locale={LOCALE}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
              {!readOnly ? (
                <td className="add-btn-td">
                  <BeeIconButton
                    iconClass={"pi pi-plus-circle"}
                    rounded={false}
                    disabled={readOnly}
                    type={"light"}
                    onClick={() => added(data)}
                  />
                </td>
              ) : null}
            </tr>
          </tbody>
        </table>
      ) : data && data.type === "pos" ? (
        <table className={classNames}>
          <tbody>
            <tr>
              <td className={"title-td readOnly"}>
                <BeeTextInput
                  label={null}
                  value={injectHeadline()}
                  disabled={false}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
              <td className={"gp-td readOnly"}>
                <BeeCurrencyInput
                  label={"GP"}
                  value={data.gp}
                  disabled={false}
                  minFractionDigits={0}
                  maxFractionDigits={3}
                  currency={CURRENCY}
                  locale={LOCALE}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
              <td className={readOnly ? "del-btn-td readOnly" : "del-btn-td"}>
                {data.deactivated ? (
                  <BeeIconButton
                    iconClass={"pi pi-undo"}
                    rounded={false}
                    disabled={readOnly}
                    type={"light"}
                    onClick={() => restored(data)}
                  />
                ) : (
                  <BeeIconButton
                    iconClass={"pi pi-trash"}
                    rounded={false}
                    disabled={readOnly}
                    type={"light"}
                    onClick={() => deleted(data)}
                  />
                )}
              </td>
            </tr>
            <tr>
              <td className={"readOnly"}>
                <div className="grid">
                  <div class="lv-contract-pos-infos col-12 md:col-12 lg:col-12 xl:col-4">
                    <BeeTextInput
                      label={"Menge"}
                      value={
                        data.amount && !data.unit
                          ? data.amount
                          : data.amount && data.unit
                          ? `${data.amount} ${data.unit}`
                          : !data.amount && data.unit
                          ? data.unit
                          : null
                      }
                      disabled={false}
                      formstate={"neutral"}
                      readOnly={true}
                      required={false}
                      onChange={null}
                    />
                  </div>
                  <div class="lv-contract-pos-infos col-12 md:col-12 lg:col-12 xl:col-4">
                    <BeeTextInput
                      label={"Pos.Nr.Alt"}
                      value={
                        data && data.posNumberOld ? data.posNumberOld : "-"
                      }
                      disabled={false}
                      formstate={"neutral"}
                      readOnly={true}
                      required={false}
                      onChange={null}
                    />
                  </div>
                  <div class="lv-contract-pos-infos col-12 md:col-12 lg:col-12 xl:col-4">
                    <BeeTextInput
                      label={"AKS"}
                      value={
                        data && data.facilityIdentificationCode
                          ? data.facilityIdentificationCode
                          : "-"
                      }
                      disabled={false}
                      formstate={"neutral"}
                      readOnly={true}
                      required={false}
                      onChange={null}
                    />
                  </div>
                </div>
              </td>
              <td className={"ep-td readOnly"}>
                <BeeCurrencyInput
                  label={"EP"}
                  value={data.ep}
                  disabled={false}
                  minFractionDigits={0}
                  maxFractionDigits={3}
                  currency={data.currency}
                  locale={data.locale}
                  formstate={"neutral"}
                  readOnly={true}
                  required={false}
                  onChange={null}
                />
              </td>
            </tr>
            <tr />
          </tbody>
        </table>
      ) : null}
    </>
  );
}

export default BeeAuditLvContract;
