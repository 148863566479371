import {
  LOGOUT_USER,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  ACCESS_TOKEN_REQUEST,
  ACCESS_TOKEN_SUCCESS,
  ACCESS_TOKEN_FAILURE,
  READ_USER_DATA_REQUEST,
  READ_USER_DATA_SUCCESS,
  READ_USER_DATA_FAILURE,
  SET_MAINTAINER_ID,
  DELETE_MAINTAINER_ID,
  STORE_LIEGENSCHAFTEN_SHORT,
} from "./appActionTypes";
import { authAxios } from "../../Helper/AuthService";
import axios from "axios";
import { URL_TO_AUTH_API } from "../../Helper/constants";
import jwt from "jsonwebtoken";

//SIMPLE CALLS
export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const setMaintainerId = (maintainerId) => {
  return {
    type: SET_MAINTAINER_ID,
    payload: maintainerId,
  };
};

export const clearMaintainerId = () => {
  return {
    type: DELETE_MAINTAINER_ID,
  };
};

export const storeLiegenschaftenShort = (payload) => {
  return {
    type: STORE_LIEGENSCHAFTEN_SHORT,
    payload: payload,
  };
};

//CALLS FOR SIDE-EFFECT-FUNCTIONS
export const loginUserRequest = () => {
  return {
    type: LOGIN_USER_REQUEST,
  };
};
export const loginUserSuccess = (token) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: token,
  };
};
export const loginUserFailure = (error) => {
  return {
    type: LOGIN_USER_FAILURE,
    payload: error,
  };
};

export const accessTokenRequest = () => {
  return {
    type: ACCESS_TOKEN_REQUEST,
  };
};
export const accessTokenSuccess = (token) => {
  return {
    type: ACCESS_TOKEN_SUCCESS,
    payload: token,
  };
};
export const accessTokenFailure = (error) => {
  return {
    type: ACCESS_TOKEN_FAILURE,
    payload: error,
  };
};

export const readUserDataRequest = () => {
  return {
    type: READ_USER_DATA_REQUEST,
  };
};
export const readUserDataSuccess = (token) => {
  return {
    type: READ_USER_DATA_SUCCESS,
    payload: token,
  };
};
export const readUserDataFailure = (error) => {
  return {
    type: READ_USER_DATA_FAILURE,
    payload: error,
  };
};

///////////////////////////////////////////////////
//FUNCTIONS with side effects
export const loginUser = (username, password) => {
  return (dispatch) => {
    dispatch(loginUserRequest());
    //call with normal axios (not authenticated!!)
    axios({
      method: "put",
      url: URL_TO_AUTH_API + "/v1/public/authenticate",
      headers: {},
      data: { password: password, username: username },
    })
      .then((response) => {
        const token = response.data.token;
        dispatch(loginUserSuccess(token));
        //call the access token
        dispatch(callAccessToken(token));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(loginUserFailure(errorMsg));
      });
  };
};

export const callAccessToken = (refreshToken) => {
  return (dispatch) => {
    dispatch(accessTokenRequest());
    authAxios({
      method: "put",
      url: URL_TO_AUTH_API + "/v1/public/token/refresh",
      headers: { Authorization: `Bearer ${refreshToken}` },
      data: {},
    })
      .then((response) => {
        const accessToken = response.data.token;
        dispatch(accessTokenSuccess(accessToken));
        //call the user for this data
        let userId = jwt.decode(accessToken).iss;
        dispatch(readUserData(userId, accessToken));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(accessTokenFailure(errorMsg));
      });
  };
};

export const readUserData = (userId) => {
  return (dispatch) => {
    dispatch(readUserDataRequest());
    authAxios({
      method: "get",
      url: URL_TO_AUTH_API + "/v1/user/" + userId,
      headers: {},
      data: {},
    })
      .then((response) => {
        const data = response.data;
        dispatch(readUserDataSuccess(data));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(readUserDataFailure(errorMsg));
      });
  };
};
