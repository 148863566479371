import React, { useState } from "react";

import {
  BeeButton,
  BeeLinkButton,
  BeeValidatedTextInput,
  BeeErrorLabel,
  BeeLoadingBar,
} from "bee-atomic-components";

import { Dialog } from "primereact/dialog";
import { useId } from "react-id-generator";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditDialogSnapshots.scss";

export function BeeAuditDialogSnapshots({
  type,
  header,
  visible,
  showProgress,
  errorMsg,
  onHide,
  onUpload,
}) {
  const currentId = useId(1, "bee-audit-dialog-snapshot")[0];

  const [title, setTitle] = useState("");
  const [errorTitle, setErrorTitle] = useState(false);
  const [adaptedTitle, setAdaptedTitle] = useState(false);

  //classNames
  const typeClassNames =
    type === "primary"
      ? "bee-audit-dialog-snapshot-primary"
      : type === "secondary"
      ? "bee-audit-dialog-snapshot-secondary"
      : "bee-audit-dialog-snapshot-primary";
  const classNames = "bee-audit-dialog-snapshot " + typeClassNames;

  //label
  const acceptLabel = "Speichern";
  const rejectLabel = "Abbrechen";
  const inputLabel = "Snapshot-Name";
  const inputErrorLabel = "Bitte Benennung für diesen Snapshot angeben";
  const inputNeutralLabel = "Bitte Benennung für diesen Snapshot angeben";

  function hide() {
    if (onHide) {
      onHide();
    }
  }

  function accept() {
    if (validate()) {
      if (onUpload) {
        onUpload(title);
      }
    }
  }

  function validate() {
    setAdaptedTitle(false);
    const validTitle = validateFieldTitle();
    setErrorTitle(!validTitle);
    return { validTitle };
  }

  function validateFieldTitle() {
    let valid = true;
    if (title) {
      //other validations in this field
    } else {
      valid = false;
    }
    return valid;
  }

  function onChangeTitle(value) {
    setAdaptedTitle(true);
    setTitle(value);
  }
  function onKeyPress(e) {
    if (e.keyCode === 13 && title && !showProgress && visible) {
      accept();
    }
    if (e.keyCode === 27 && visible) {
      hide();
    }
  }

  const renderFooter = () => {
    return (
      <div>
        <BeeLinkButton
          label={rejectLabel}
          raised={false}
          disabled={false}
          type={"primary"}
          onClick={() => hide()}
        />
        <BeeButton
          label={acceptLabel}
          disabled={!title || showProgress}
          type={type}
          onClick={() => accept()}
        />
      </div>
    );
  };

  return (
    <div onKeyDown={(e) => onKeyPress(e)} tabIndex={0}>
      <Dialog
        id={currentId}
        header={header}
        visible={visible}
        className={classNames}
        modal={true}
        footer={renderFooter()}
        onHide={() => hide()}
      >
        <BeeErrorLabel
          size={"large"}
          label={errorMsg}
          visible={errorMsg ? true : false}
        />
        <BeeValidatedTextInput
          label={inputLabel}
          errorLabel={inputErrorLabel}
          neutralLabel={inputNeutralLabel}
          validLabel={null}
          value={title}
          disabled={false}
          formstate={
            adaptedTitle && errorTitle ? "neutral" : errorTitle ? "error" : null
          }
          readOnly={false}
          required={true}
          onChange={(e) => onChangeTitle(e.target.value)}
        />
        {showProgress ? (
          <BeeLoadingBar indeterminate={true} type={"primary"} />
        ) : null}
      </Dialog>
    </div>
  );
}

export default BeeAuditDialogSnapshots;
