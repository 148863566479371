import React, { useCallback, useState, useEffect } from "react";
import _, { debounce } from "lodash";
import { BeeTextInput } from "bee-atomic-components";

export function BeeDebouncedTextInput({
  label,
  value,
  node,
  disabled,
  formstate,
  readOnly,
  required,
  delay,
  onChange,
}) {
  const [entry, setEntry] = useState(value);
  const currDelay = delay ? delay : 500;
  const pay = node;

  useEffect(() => {
    setEntry(value);
  }, [value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((data, pay) => {
      onChange(data, pay);
    }, currDelay),
    []
  );

  const userInputChanged = (value) => {
    setEntry(value);
    debouncedSearch(value, pay);
  };

  return (
    <>
      <BeeTextInput
        label={label}
        value={entry}
        disabled={disabled}
        formstate={formstate}
        readOnly={readOnly}
        required={required}
        onChange={(e) => {
          userInputChanged(e.target.value);
        }}
      />
    </>
  );
}

export default BeeDebouncedTextInput;
