import React, { useState } from "react";
import { useId } from "react-id-generator";
import { Dialog } from "primereact/dialog";
import {
  BeeButton,
  BeeLinkButton,
  BeeValidatedTextArea,
  BeeValidatedDropDown,
  BeeValidatedTimeInput,
  BeeValidatedDateInput,
} from "bee-atomic-components";
import { v4 as uuidv4 } from "uuid";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditDialogBasis.scss";
import { GRUNDLAGENTYPEN } from "../../Helper/constants";

export function BeeAuditDialogBasis({
  type,
  data,
  visible,
  readOnly,
  onSave,
  onCancel,
  onHide,
}) {
  const currentId = useId(1, "bee-audit-dialog-basis")[0];

  //state
  const [sId, setId] = useState(data ? data.id : uuidv4());
  const [sType, setType] = useState(
    data ? data.type : GRUNDLAGENTYPEN[0].value
  );
  const [sDate, setDate] = useState(data ? data.date : new Date());
  const [sComment, setComment] = useState(data ? data.comment : "");

  const [adaptedType, setAdaptedType] = useState(false);
  const [adaptedDate, setAdaptedDate] = useState(false);
  const [adaptedComment, setAdaptedComment] = useState(false);

  const [errorType, setErrorType] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [errorComment, setErrorComment] = useState(false);

  const headline_adapt = "Auditgrundlage bearbeiten";
  const headline_create = "Auditgrundlage hinzufügen";
  const type_error_msg = "Bitte Typ auswählen";
  const date_error_msg = "Bitte Datum auswählen";
  const comment_error_msg = "Bitte Anmerkung eingeben";

  //classNames
  const typeClassNames =
    type === "primary"
      ? "bee-audit-dialog-basis-primary"
      : type === "secondary"
      ? "bee-audit-dialog-basis-secondary"
      : "bee-audit-dialog-basis-primary";

  const classNames = "bee-audit-dialog-basis " + typeClassNames;
  const btnRowClassNames =
    "grid justify-content-end bee-audit-dialog-basis-btns";

  function save() {
    if (validateInputs()) {
      if (onSave) {
        onSave({
          id: sId,
          type: sType,
          date: sDate,
          comment: sComment,
        });
      }
      clearData();
    }
  }

  function clearData() {
    setId(null);
    setType(null);
    setDate(null);
    setComment(null);
    //reset errors and validations
    setAdaptedType(false);
    setAdaptedDate(false);
    setAdaptedComment(false);
    setErrorType(false);
    setErrorDate(false);
    setErrorComment(false);
  }

  function cancel(e) {
    clearData();
    if (onCancel) {
      onCancel(e);
    }
  }

  function hide() {
    clearData();
    if (onHide) {
      onHide();
    }
  }

  function onKeyPress(e) {
    if (e.keyCode === 13 && !readOnly && visible) {
      save();
    }
    if (e.keyCode === 27 && !readOnly && visible) {
      cancel(e);
    }
    if (e.keyCode === 27 && readOnly && visible) {
      hide();
    }
  }

  function validateInputs() {
    let allValid = true;
    //validate Type
    if (!sType) {
      setErrorType(true);
      allValid = false;
    } else {
      setErrorType(false);
    }
    //validate Date
    if (!sDate) {
      setErrorDate(true);
      allValid = false;
    } else {
      setErrorDate(false);
    }
    //validate Comment
    if (!sComment) {
      setErrorComment(true);
      allValid = false;
    } else {
      setErrorComment(false);
    }
    return allValid;
  }

  const injectType = () => {
    return (
      <BeeValidatedDropDown
        label={"Typ"}
        errorLabel={errorType && !adaptedType ? type_error_msg : null}
        neutralLabel={errorType && adaptedType ? type_error_msg : null}
        value={sType ? sType : GRUNDLAGENTYPEN[0].value}
        options={GRUNDLAGENTYPEN}
        optionLabel={"label"}
        disabled={false}
        formstate={
          errorType && adaptedType ? "neutral" : errorType ? "error" : "none"
        }
        readOnly={readOnly}
        required={true}
        onChange={(e) => {
          setAdaptedType(true);
          setType(e);
        }}
      />
    );
  };
  const injectDate = () => {
    return (
      <BeeValidatedDateInput
        label={"Datum"}
        errorLabel={errorDate && !adaptedDate ? date_error_msg : null}
        neutralLabel={errorDate && adaptedDate ? date_error_msg : null}
        value={sDate ? sDate : new Date()}
        disabled={false}
        formstate={
          errorDate && adaptedDate ? "neutral" : errorDate ? "error" : "none"
        }
        readOnly={readOnly}
        required={true}
        onChange={(e) => {
          setAdaptedDate(true);
          setDate(e.target.value);
        }}
      />
    );
  };

  const injectComment = () => {
    return (
      <BeeValidatedTextArea
        label={"Anmerkung"}
        errorLabel={errorComment && !adaptedComment ? comment_error_msg : null}
        neutralLabel={errorComment && adaptedComment ? comment_error_msg : null}
        value={sComment ? sComment : ""}
        rows={3}
        disabled={false}
        autoResize={false}
        formstate={
          errorComment && adaptedComment
            ? "neutral"
            : errorComment
            ? "error"
            : "none"
        }
        readOnly={readOnly}
        required={true}
        onChange={(e) => {
          setAdaptedComment(true);
          setComment(e.target.value);
        }}
      />
    );
  };

  const injectButtonRow = () => {
    return (
      <div className={btnRowClassNames}>
        {readOnly ? (
          <BeeButton
            label={"OK"}
            disabled={false}
            type={type}
            onClick={() => save()}
          />
        ) : (
          <>
            <BeeLinkButton
              label={"Abbrechen"}
              raised={false}
              disabled={false}
              type={"primary"}
              onClick={(e) => cancel(e)}
            />
            <BeeButton
              label={data ? "Übernehmen" : "Erstellen"}
              disabled={false}
              type={type}
              onClick={() => save()}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div id={currentId} onKeyDown={(e) => onKeyPress(e)} tabIndex={0}>
      <Dialog
        id={currentId}
        header={data ? headline_adapt : headline_create}
        visible={visible}
        className={classNames}
        modal={true}
        onHide={() => hide()}
      >
        <div className="grid">
          <div className="col-12 sm:col-12 md:col-6 lg:col-9">
            {injectType()}
          </div>
          <div className="col-12 sm:col-12 md:col-6 lg:col-3">
            {injectDate()}
          </div>
          <div className="col-12 sm:col-12 md:col-12 lg:col-12">
            {injectComment()}
          </div>
          <div className="col-12">{injectButtonRow()}</div>
        </div>
      </Dialog>
    </div>
  );
}

export default BeeAuditDialogBasis;
