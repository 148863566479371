import axios from "axios";
import {
  URL_TO_AUTH_API,
  URL_TO_REFRESH_TOKEN_AT_AUTH_API,
} from "../Helper/constants";
import { BrowserRouter as Router } from "react-router-dom";

export const authAxios = axios.create({
  // baseURL: URL_TO_AUTH_API,
});

// Add a request interceptor
authAxios.interceptors.request.use(
  (config) => {
    //load accessToken from session
    const accessToken = sessionStorage.getItem("aT");
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
authAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    //breaking the loop if refreshtoken is invalid
    if (
      error.response.status === 401 &&
      originalRequest.url === URL_TO_AUTH_API + "/v1/public/authenticate"
    ) {
      Router.push("/login");
      return Promise.reject(error);
    }
    //otherwise check if other request failed
    if (
      error.response.status === 401 &&
      originalRequest &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return axios({
        method: "put",
        cache: "no-cache",
        url: URL_TO_REFRESH_TOKEN_AT_AUTH_API,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("rT")}`,
        },
        redirect: "follow",
        referrer: "no-referrer",
        data: {},
      })
        .then((response) => {
          const token = response.data.token;
          //store token
          sessionStorage.setItem("aT", token);
          //resend request using new token
          console.log("RETRY!!!");
          return authAxios(originalRequest);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return Promise.reject(error.response);
  }
);
