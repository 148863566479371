import React, { useEffect, useState } from "react";
import { useId } from "react-id-generator";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BeeContentHeadline, BeeIconButton } from "bee-atomic-components";
import { STATES_OF_AUFFALLIGKEIT, TYPE_DROPDOWN } from "../../Helper/constants";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditAuffalligkeitenTable.scss";
import _ from "lodash";

export function BeeAuditAuffalligkeitenTable({
  id,
  data,
  type,
  readOnly,
  showEdit,
  showDelete,
  onView,
  onDelete,
  onEdit,
}) {
  const currentId = useId(1, "bee-audit-auffaligk-table-");
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  //screen widths
  const p_sm = 576;
  const p_md = 768;
  const p_lg = 992;

  //classNames
  const typeClassNames =
    type === "dark"
      ? "bee-audit-auffalligk-table-dark"
      : type === "light"
      ? "bee-audit-auffalligk-table-light"
      : "bee-audit-auffalligk-table-light";
  const classNames = "bee-audit-auffalligk-table " + typeClassNames;
  const tableClassNames = "p-datatable-sm";
  const titleColClassNames = "bee-audit-auffalligk-table-col-title";
  const auffTypeColClassNames = "bee-audit-auffalligk-table-col-type";
  const stateColClassNames = "bee-audit-auffalligk-table-col-state";
  const dateColClassNames = "bee-audit-auffalligk-table-col-date";
  const auditorColClassNames = "bee-audit-auffalligk-table-col-auditor";
  const actionColClassNames =
    "bee-audit-auffalligk-table-col-action" +
    calculateActionColWidthClassName();

  //labels
  const titleColLabel = "Titel";
  const auffTypeColLabel = "Typ";
  const stateColLabel = "Status";

  const dateColLabel = "Datum";
  const auditorColLabel = "Auditor";
  const actionColLabel = "";
  const emptyMessage = "Keine Auffälligkeiten vorhanden.";

  //icons
  const viewBtnIcon = "pi pi-eye";
  const editBtnIcon = "pi pi-pencil";
  const deleteBtnIcon = "pi pi-trash";

  function calculateActionColWidthClassName() {
    const buttonCount = readOnly
      ? 1
      : (showEdit ? 1 : 0) + (showDelete ? 1 : 0);

    const actionColWidthClassNames =
      buttonCount === 1
        ? " bee-audit-auffalligk-table-col-action-oneBtn"
        : buttonCount === 2
        ? " bee-audit-auffalligk-table-col-action-twoBtn"
        : "";
    return actionColWidthClassNames;
  }

  function view(itemId) {
    if (onView) {
      onView(itemId);
    }
  }

  function deleteEntry(itemId) {
    if (onDelete) {
      onDelete(itemId);
    }
  }

  function editEntry(itemId) {
    if (onEdit) {
      onEdit(itemId);
    }
  }

  function calculateAuffTypeLabel(rowData) {
    if (rowData.typeValue) {
      let entry = _.find(TYPE_DROPDOWN, function (t) {
        return t.value === rowData.typeValue;
      });
      return entry ? entry.label : null;
    }
  }
  function calculateStateLabel(rowData) {
    if (rowData.stateId) {
      let entry = _.find(STATES_OF_AUFFALLIGKEIT, function (a) {
        return a.id === rowData.stateId;
      });
      return entry ? entry.title : null;
    }
  }

  function titleTemplate(rowData) {
    if (rowData) {
      return <p headername={titleColLabel}>{rowData.title}</p>;
    }
  }

  function auffTypeTemplate(rowData) {
    if (rowData) {
      const type = calculateAuffTypeLabel(rowData);
      return <p headername={auffTypeColLabel}>{type}</p>;
    }
  }
  function stateTemplate(rowData) {
    if (rowData) {
      return <p headername={stateColLabel}>{calculateStateLabel(rowData)}</p>;
    }
  }

  function creationDateTemplate(rowData) {
    if (rowData) {
      const date = rowData.date;

      return date ? (
        <p headername={dateColLabel}>{date.toLocaleDateString("de-DE")}</p>
      ) : null;
    }
  }

  function auditorTemplate(rowData) {
    if (rowData) {
      return <p headername={auditorColLabel}>{rowData.auditor}</p>;
    }
  }

  function actionTemplate(rowData) {
    const rowDataId = rowData.id;
    if (rowData) {
      return (
        <div headername={actionColLabel}>
          {readOnly ? (
            <BeeIconButton
              iconClass={viewBtnIcon}
              rounded={false}
              disabled={false}
              type={"primary"}
              onClick={() => view(rowDataId)}
            />
          ) : (
            <>
              {showEdit ? (
                <BeeIconButton
                  iconClass={editBtnIcon}
                  rounded={false}
                  disabled={false}
                  type={"primary"}
                  onClick={() => editEntry(rowDataId)}
                />
              ) : null}
              {showDelete ? (
                <BeeIconButton
                  iconClass={deleteBtnIcon}
                  rounded={false}
                  disabled={readOnly}
                  type={"danger"}
                  onClick={() => deleteEntry(rowDataId)}
                />
              ) : null}
            </>
          )}
        </div>
      );
    }
  }

  function rowGroupHeaderTemplate(data) {
    return (
      <div>
        <BeeContentHeadline
          label={data.categoryLabel}
          headline={"h4"}
          type={"secondary"}
        />
      </div>
    );
  }

  return (
    <div id={currentId} className={classNames}>
      <DataTable
        value={data}
        className={tableClassNames}
        emptyMessage={emptyMessage}
        removableSort
        rowGroupMode="subheader"
        sortMode="single"
        sortOrder={1}
        groupRowsBy="categoryLabel"
        sortField="categoryLabel"
        rowGroupHeaderTemplate={rowGroupHeaderTemplate}
      >
        <Column
          className={titleColClassNames}
          body={titleTemplate}
          header={titleColLabel}
        />
        <Column
          className={auffTypeColClassNames}
          body={auffTypeTemplate}
          header={auffTypeColLabel}
        />

        <Column
          className={auditorColClassNames}
          body={auditorTemplate}
          header={auditorColLabel}
        />
        {width > p_md ? (
          <Column
            className={stateColClassNames}
            body={stateTemplate}
            header={stateColLabel}
          />
        ) : null}
        <Column
          className={dateColClassNames}
          body={creationDateTemplate}
          header={dateColLabel}
        />
        {showEdit || showDelete ? (
          <Column
            className={actionColClassNames}
            body={actionTemplate}
            header={actionColLabel}
          />
        ) : null}
      </DataTable>
    </div>
  );
}

export default BeeAuditAuffalligkeitenTable;
