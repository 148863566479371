import React from "react";
import { BeeSelectButton } from "bee-atomic-components";
import { useId } from "react-id-generator";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditTabs.scss";

export function BeeAuditTabs({
  options,
  selectedOption,
  disabled,
  type,
  onSelect,
}) {
  const currentId = useId(1, "bee-audit-tabs-")[0];

  //classNames
  const classNames = "bee-audit-tabs";

  //icons
  const grunddatenIcon = "pi pi-home";
  const auffalligkeitenIcon = "pi pi-exclamation-triangle";
  const LVIcon = "pi pi-book";
  const questionsIcon = "pi pi-question";
  const categoriesIcon = "pi pi-folder-open";

  function select(value) {
    if (!disabled && onSelect) {
      onSelect(value);
    }
  }

  const iconOptions = (options) => {
    let iconOptions = options;
    for (let i in options) {
      //fixme need to be smart replaced somehow
      if (options[i].label === "Grunddaten") {
        iconOptions[i] = {
          icon: grunddatenIcon,
          label: "Grunddaten",
          value: options[i].value,
        };
      }
      if (options[i].label === "Auffälligkeiten") {
        iconOptions[i] = {
          icon: auffalligkeitenIcon,
          label: "Auffälligkeiten",
          value: options[i].value,
        };
      }
      if (options[i].label === "Kategorien") {
        iconOptions[i] = {
          icon: categoriesIcon,
          label: "Kategorien",
          value: options[i].value,
        };
      }
      if (options[i].label === "Fragen") {
        iconOptions[i] = {
          icon: questionsIcon,
          label: "Fragen",
          value: options[i].value,
        };
      }
      if (options[i].label === "Leistungsverzeichnis") {
        iconOptions[i] = {
          icon: LVIcon,
          label: "Leistungsverzeichnis",
          value: options[i].value,
        };
      }
    }

    return iconOptions;
  };

  const itemTemplate = (option) => {
    return (
      <>
        <i className={option.icon}></i>
        <span>{option.label}</span>
      </>
    );
  };

  return (
    <div className={classNames}>
      <BeeSelectButton
        id={currentId}
        disabled={disabled}
        selectedOption={selectedOption}
        type={type}
        options={iconOptions(options)}
        itemTemplate={itemTemplate}
        onSelect={(value) => select(value)}
      />
    </div>
  );
}

export default BeeAuditTabs;
