import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { BeeConfirmDialog, BeeToast } from "bee-atomic-components";
import {
  logoutUser,
  storeLiegenschaftenShort,
} from "../../redux/app/appActions";
import {
  URL_TO_MEDIA_SERVER,
  PATH_TO_DEFAULT_PROPERTY_IMAGE,
  PATH_ONLINE_DETAIL_LIEGENSCHAFT,
  PATH_ONLINE_OVERVIEW_AUDIT,
  DURATION_NOTIFICATION_ERROR_LONG,
} from "../../Helper/constants";
import { breadCrumbLiegenschaftOverview } from "../../Helper/BreadCrumbHelper";
import { removeAllOnlinePendingLocksForUserId } from "../../Helper/RemovePendingLocksHelper";
import {
  callAllLiegenschaftenShort,
  callMetaAuditInformationForLiegenschaften,
  errorToMsg,
  translateMediaIds,
} from "../../Helper/NetworkHelper";
import { runsOnWeb } from "../../Helper/CapacitorHelper";
import BeeAuditLiegenschaftSelect from "../Components/BeeAuditLiegenschaftSelect";

function OnlineLiegenschaftenOverview(props) {
  //confirm
  const [v_LogoutDialog, setV_LogoutDialog] = useState(false);
  const [propertyPending, setPropertyPending] = useState(true);
  const [propertyError, setPropertyError] = useState(false);
  const [data, setData] = useState();
  const toast = useRef(null);

  useEffect(() => {
    //remove all pending locks
    removeAllOnlinePendingLocksForUserId();
    //load all liegenschaften on page load
    callAllLiegenschaftenShort()
      .then((entries) => {
        setPropertyPending(false);
        setPropertyError(false);
        props.storeLiegenschaftenShort(entries);
        //call render!
        prepareLiegenschaftData(entries, null, null);
        //export image and property-ids
        let imgIds = [];
        let propertyIds = [];
        if (entries) {
          for (let i = 0; i < entries.length; i++) {
            const entry = entries[i];
            if (entry) {
              //images
              if (entry.hauptBildId) {
                imgIds.push(entry.hauptBildId);
              }
              //meta info
              if (entry.id) {
                propertyIds.push(entry.id);
              }
            }
          }
        }
        translateMediaIds(imgIds)
          .then((images) => {
            prepareLiegenschaftData(entries, images, null);
            loadMetaInfoFromServer(propertyIds, images, entries);
          })
          .catch((error) => {
            loadMetaInfoFromServer(propertyIds, null, entries);
            if (toast && toast.current) {
              toast.current.show({
                severity: "error",
                summary:
                  "Bilder konnten nicht geladen werden " + errorToMsg(error),
                detail:
                  "Leider konnten die Bilder nicht vollständig geladen werden. Bitte versuchen Sie es später erneut.",
                sticky: false,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG,
              });
            }
          });
      })
      .catch((error) => {
        setPropertyPending(false);
        setPropertyError(true);
        if (toast && toast.current) {
          toast.current.show({
            severity: "error",
            summary:
              "Liegenschaften konnten nicht geladen werden " +
              errorToMsg(error),
            detail:
              "Leider konnten die Liegenschaften nicht geladen werden. Bitte versuchen Sie es später erneut.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadMetaInfoFromServer(propertyIds, images, liegenschaften) {
    callMetaAuditInformationForLiegenschaften(propertyIds)
      .then((entries) => {
        prepareLiegenschaftData(liegenschaften, images, entries);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: "error",
            summary: "Audit-Service nicht erreichbar " + errorToMsg(error),
            detail:
              "Leider konnten die Bilder nicht vollständig geladen werden. Bitte versuchen Sie es später erneut.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG,
          });
        }
      });
  }

  function editLiegenschaft(lId) {
    if (props.onlineAccessPossible && sessionStorage.getItem("rT") !== null) {
      props.history.push({
        pathname: PATH_ONLINE_DETAIL_LIEGENSCHAFT,
        search: "?lid=" + lId,
      });
    }
  }

  function clickLiegenschaft(lId) {
    if (props.onlineAccessPossible && sessionStorage.getItem("rT") !== null) {
      props.history.push({
        pathname: PATH_ONLINE_OVERVIEW_AUDIT,
        search: "?lid=" + lId,
      });
    }
  }

  function addLiegenschaft() {
    if (props.onlineAccessPossible && sessionStorage.getItem("rT") !== null) {
      props.history.push(PATH_ONLINE_DETAIL_LIEGENSCHAFT);
    }
  }

  function prepareLiegenschaftData(entries, images, info) {
    let payload = [];
    if (entries) {
      //add view for adding new liegenschaft
      payload.push({});
      //add all loaded liegenschaften
      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];
        const metaInfo = translatePropertyIdToAuditMetaInfo(entry.id, info);
        let data = {
          id: entry.id,
          title: entry.name,
          number: entry.nummer,
          image: translateImageIdToData(entry.hauptBildId, images),
          strasse: entry.adresse ? entry.adresse.strasse : "",
          hausnummer: entry.adresse ? entry.adresse.hausnummer : "",
          postleitzahl: entry.adresse ? entry.adresse.postleitzahl : "",
          stadt: entry.adresse ? entry.adresse.stadt : "",
          lastAuditDate:
            metaInfo && metaInfo.lastAudit
              ? new Date(metaInfo.lastAudit)
              : null,
          countAudits: metaInfo ? metaInfo.amount : null,
        };
        payload.push(data);
      }
    }
    setData(payload);
  }

  function translateImageIdToData(imageId, images) {
    if (imageId) {
      if (images) {
        for (let i = 0; i < images.length; i++) {
          const image = images[i].image;
          if (image.id === imageId) {
            for (let i = 0; i < image.versions.length; i++) {
              const version = image.versions[i];
              if (version && version.vLabel) {
                if (version.vLabel === "MEDIUM") {
                  return {
                    id: imageId,
                    url: URL_TO_MEDIA_SERVER + version.url,
                    key: version.access,
                    title: null,
                    alt: null,
                    copyright: null,
                    secured: version.access ? true : false,
                    showPlaceholder: false,
                  };
                }
              }
            }
          }
        }
      } else {
        //show placeholder until loaded
        return {
          id: imageId,
          url: null,
          key: null,
          title: null,
          alt: null,
          copyright: null,
          secured: false,
          showPlaceholder: true,
        };
      }
    } else {
      //show default property image
      return {
        id: null,
        url: PATH_TO_DEFAULT_PROPERTY_IMAGE,
        key: null,
        title: null,
        alt: null,
        copyright: null,
        secured: false,
        showPlaceholder: false,
      };
    }
  }

  function translatePropertyIdToAuditMetaInfo(propertyId, metaInfo) {
    if (propertyId && metaInfo) {
      return metaInfo.find((entry) => {
        return entry.propertyId === propertyId;
      });
    }
    return null;
  }

  const injectLogoutConfirmation = () => {
    return (
      <BeeConfirmDialog
        visible={v_LogoutDialog}
        message={"Möchten Sie sich wirklich ausloggen?"}
        header={"Logout bestätigen"}
        type={"default"}
        onAccept={() => {
          removeAllOnlinePendingLocksForUserId();
          props.logoutUser();
          setV_LogoutDialog(false);
        }}
        onReject={() => setV_LogoutDialog(false)}
        onHide={() => setV_LogoutDialog(false)}
        acceptLabel={"Ausloggen"}
        rejectLabel={"Abbrechen"}
      />
    );
  };

  const injectNotificationToast = () => {
    return (
      <BeeToast id={"bee-audit-ui-toast"} ref={toast} position={"top-right"} />
    );
  };

  return (
    <>
      <BeeAuditLiegenschaftSelect
        data={data}
        homeUrl={null}
        breadcrumbData={breadCrumbLiegenschaftOverview}
        layout={"grid"}
        activateHover={runsOnWeb()}
        showProgress={propertyPending}
        errorMsg={propertyError ? "Die Abfrage ist fehlgeschlagen." : null}
        onClick={(id) => clickLiegenschaft(id)}
        onAdd={() => addLiegenschaft()}
        onEdit={(id) => editLiegenschaft(id)}
        onLogout={() => setV_LogoutDialog(true)}
      />
      {v_LogoutDialog ? injectLogoutConfirmation() : null}
      {injectNotificationToast()}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    onlineAccessPossible: state.app.onlineAccessPossible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    storeLiegenschaftenShort: (entries) =>
      dispatch(storeLiegenschaftenShort(entries)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineLiegenschaftenOverview);
