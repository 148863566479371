export const LOGOUT_USER = "LOGOUT_USER";
export const SET_MAINTAINER_ID = "SET_MAINTAINER_ID";
export const DELETE_MAINTAINER_ID = "DELETE_MAINTAINER_ID";
export const STORE_LIEGENSCHAFTEN_SHORT = "STORE_LIEGENSCHAFTEN_SHORT";

export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

export const READ_USER_DATA_REQUEST = "READ_USER_DATA_REQUEST";
export const READ_USER_DATA_SUCCESS = "READ_USER_DATA_SUCCESS";
export const READ_USER_DATA_FAILURE = "READ_USER_DATA_FAILURE";

export const ACCESS_TOKEN_REQUEST = "ACCESS_TOKEN_REQUEST";
export const ACCESS_TOKEN_SUCCESS = "ACCESS_TOKEN_SUCCESS";
export const ACCESS_TOKEN_FAILURE = "ACCESS_TOKEN_FAILURE";
