import React from "react";
import { connect } from "react-redux";

function Error404Page(props) {
  const query = new URLSearchParams(props.location.search);
  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <h1>Error404Page</h1>
          {JSON.stringify(query)}
          <p>
            Use this page to start from scratch and place your custom content.
          </p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    // numOfIceCream: state.iceCream.numOfIceCream,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // buyIceCream: () => dispatch(buyIceCream()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Error404Page);
