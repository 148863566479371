import React, { useState } from "react";
import "./BeeAuditLvComment.scss";
import { BeeTextInput, BeeIconButton } from "bee-atomic-components";
import { ScrollPanel } from "primereact/scrollpanel";
import { ENTER } from "../../Helper/keyCodes";
import BeeDebouncedTextArea from "../Components/BeeDebouncedTextArea";
import { DELAY_DEBOUNCED } from "../../Helper/constants";

function BeeAuditLvComment({ data, username, collapsed, readOnly, onChange }) {
  const [curInputComment, setCurInputComment] = useState(null);
  const deactivatedClassName = data.deactivated ? " deactivated" : "";
  const classNames = "bee-audit-lv-comment " + deactivatedClassName;

  function change(comment) {
    if (!readOnly && onChange) {
      data.comment = comment;
      onChange(data);
    }
  }

  function adaptInputComment(value) {
    if (!readOnly) {
      setCurInputComment(value);
    }
  }

  function addComment() {
    if (!readOnly && onChange) {
      const currentDate = new Date();
      const CommentToAdd = `[${currentDate.toLocaleString(
        "de-DE"
      )} - ${username}]: ${curInputComment}\n`;
      change(data.comment ? CommentToAdd + data.comment : CommentToAdd);
      setCurInputComment("");
    }
  }

  function handleKeyDown(e) {
    if (e.keyCode === ENTER && !e.altKey) {
      addComment();
    }
  }

  return (
    <>
      {data && data.type === "cat" ? (
        <span style={{ visibility: "hidden" }} className={classNames + " cat"}>
          <BeeTextInput
            label=""
            value={null}
            disabled={true}
            formstate={"neutral"}
            readOnly={true}
            required={false}
            onChange={null}
          />
        </span>
      ) : data && data.type === "pos" ? (
        collapsed ? (
          <table className={classNames + " pos"}>
            <tbody>
              <tr>
                <td className={"add-comment-td"}>
                  <div
                    onKeyDown={(e) => {
                      handleKeyDown(e);
                    }}
                  >
                    <BeeTextInput
                      label="Neuer Kommentar"
                      value={curInputComment}
                      disabled={readOnly || data.deactivated}
                      formstate={"neutral"}
                      readOnly={readOnly || data.deactivated}
                      required={false}
                      onChange={(e) => adaptInputComment(e.target.value)}
                    />
                  </div>
                </td>
                <td className={"add-comment-btn-td"}>
                  <BeeIconButton
                    iconClass="pi pi-plus"
                    rounded={false}
                    disabled={readOnly || data.deactivated}
                    type={"primary"}
                    onClick={() => addComment()}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} className={"ta-comment-td"}>
                  <ScrollPanel>
                    <BeeDebouncedTextArea
                      label={"Kommentare"}
                      value={data.comment}
                      node={data}
                      rows={4}
                      disabled={readOnly || data.deactivated}
                      autoResize={true}
                      formstate={"neutral"}
                      readOnly={readOnly || data.deactivated}
                      required={false}
                      delay={DELAY_DEBOUNCED}
                      onChange={(e) => change(e)}
                    />
                  </ScrollPanel>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table className={"bee-audit-lv-comment collapsed"}>
            <tbody>
              <tr>
                <td className="open-comment-btn-td">
                  <BeeIconButton
                    iconClass={
                      data.comment ? "pi pi-envelope" : "pi pi-comment"
                    }
                    rounded={true}
                    disabled={false}
                    type={"light"}
                    onClick={null}
                  />
                </td>
              </tr>
              <tr>
                <td
                  className={"comment-btn-platzhalter"}
                  style={{ visibility: "hidden" }}
                >
                  <BeeIconButton
                    iconClass={
                      data.comment ? "pi pi-envelope" : "pi pi-comment"
                    }
                    rounded={true}
                    disabled={true}
                    type={"light"}
                    onClick={null}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )
      ) : null}
    </>
  );
}

export default BeeAuditLvComment;
