import React, { useEffect, useState, useRef } from "react";
import {
  BeeConfirmDialog,
  BeeLiegenschaftData,
  BeeGebaeudeData,
  BeeToast,
  countries,
  BeeImageSlider,
  BeeFileUploadDialog,
  BeeDeletionDialog,
  BeeAndroidCameraDialog,
  BeeTabview,
  BeeContentHeadline,
  BeeOutlinedButton,
} from "bee-atomic-components";
import { connect } from "react-redux";
import {
  breadCrumbHome,
  createBreadCrumbLiegenschaftSetup,
} from "../../Helper/BreadCrumbHelper";
import {
  ACCEPTED_FILE_FORMATS,
  ACCEPTED_IMAGE_FORMATS,
  DEFAULT_IMAGE_SIZE,
  DURATION_NOTIFICATION_ERROR,
  DURATION_NOTIFICATION_ERROR_LONG,
  DURATION_NOTIFICATION_INFO,
  DURATION_NOTIFICATION_SUCCESS,
  IMG_ANDROID_HEIGHT,
  IMG_ANDROID_PRESERVE_ASPECT_RATIO,
  IMG_ANDROID_QUALITY,
  IMG_ANDROID_WIDTH,
  URL_TO_MEDIA_SERVER,
} from "../../Helper/constants";
import {
  callAllGebaeudeInLiegenschaft,
  callLiegenschaftById,
  createGebaeudeInLiegenschaft,
  createLiegenschaftById,
  downloadAvailableAusstattungsgrad,
  downloadAvailableBaualtersstufen,
  downloadDocumentFromServer,
  errorToMsg,
  translateMediaIds,
  updateGebaeudeInLiegenschaft,
  updateLiegenschaftById,
  uploadDocumentToServer,
  uploadImageToServer,
} from "../../Helper/NetworkHelper";
import { removeAllOnlinePendingLocksForUserId } from "../../Helper/RemovePendingLocksHelper";
import { logoutUser } from "../../redux/app/appActions";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  execIfOnAndroid,
  execIfOnWeb,
  runsOnWeb,
  writeDocumentFromBlobOnAndroid,
} from "../../Helper/CapacitorHelper";
import fileDownload from "js-file-download";
import "./OnlineLiegenschaftDetail.scss";
import BeeAuditHeader from "../Fragments/BeeAuditHeader";

function OnlineLiegenschaftDetail(props) {
  const query = new URLSearchParams(props.location.search);
  const liegenschaftId = query.get("lid");
  //references
  const propertyGallery = useRef(null);
  const buildingGallery = useRef(null);
  const toast = useRef(null);
  //lookups
  const [imgLookup, setImgLookup] = useState(new Map());
  const [docLookup, setDocLookup] = useState(new Map());
  //dialogs
  const [v_LogoutDialog, setV_LogoutDialog] = useState(false);
  const [currImgId, setCurrImgId] = useState(null);
  const [currDocId, setCurrDocId] = useState(null);
  const [v_ImgDialog, setV_ImgDialog] = useState(false);
  const [v_ImgDelDialog, setV_ImgDelDialog] = useState(false);
  const [imgUploadProg, setImgUploadProg] = useState(false);
  const [imgUploadError, setImgUploadError] = useState(false);
  const [v_DocDialog, setV_DocDialog] = useState(false);
  const [v_DocDelDialog, setV_DocDelDialog] = useState(false);
  const [docUploadProg, setDocUploadProg] = useState(false);
  const [docUploadError, setDocUploadError] = useState(false);
  //android
  const [v_AImgDialog, setAV_ImgDialog] = useState(false);
  const [aImgUploadProg, setAImgUploadProg] = useState(false);
  const [aImgUploadError, setAImgUploadError] = useState(false);
  //view-state
  const [activeIndex, setActiveIndex] = useState(0);
  const [showBuildings, setShowBuildings] = useState(false);
  const [addBuildingActive, setAddBuildingActive] = useState(false);
  const [readOnlyProperty, setReadOnlyProperty] = useState(true);
  const [readOnlyBuilding, setReadOnlyBuilding] = useState(true);
  const [propertyMode, setPropertyMode] = useState(null);
  const [forceRerenderMix, setForceRerenderMix] = useState(false);
  //values
  const [property, setProperty] = useState(null);
  const [propertyPending, setPropertyPending] = useState(false);
  const [propertyError, setPropertyError] = useState(false);
  const [allBuildings, setAllBuildings] = useState([]);
  const [allBuildingsPending, setAllBuildingsPending] = useState(false);
  const [allBuildingsError, setAllBuildingsError] = useState();
  const [allBaualtersstufen, setAllBaualtersstufen] = useState();
  const [allAusstattungsgrade, setAllAusstattungsgrade] = useState();
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  //property attributes
  const [propName, setPropName] = useState();
  const [propNummer, setPropNummer] = useState();
  const [propBeschreibung, setPropBeschreibung] = useState();
  const [propEigentuemer, setPropEigentuemer] = useState();
  const [propVerantwortlicher, setPropVerantwortlicher] = useState();
  const [propBemerkung, setPropBemerkung] = useState();
  const [propStreet, setPropStreet] = useState();
  const [propStreetNumber, setPropStreetNumber] = useState();
  const [propZip, setPropZip] = useState();
  const [propCity, setPropCity] = useState();
  const [propCountry, setPropCountry] = useState();
  const [propRegion, setPropRegion] = useState();
  const [propImgs, setPropImgs] = useState(null);
  const [propDocs, setPropDocs] = useState(null);
  //adapted
  const [propAName, setPropAName] = useState(false);
  const [propANummer, setPropANummer] = useState(false);
  const [propABeschreibung, setPropABeschreibung] = useState(false);
  const [propAEigentuemer, setPropAEigentuemer] = useState(false);
  const [propAVerantwortlicher, setPropAVerantwortlicher] = useState(false);
  const [propABemerkung, setPropABemerkung] = useState(false);
  const [propAStreet, setPropAStreet] = useState(false);
  const [propAStreetNumber, setPropAStreetNumber] = useState(false);
  const [propAZip, setPropAZip] = useState(false);
  const [propACity, setPropACity] = useState(false);
  const [propACountry, setPropACountry] = useState(false);
  const [propARegion, setPropARegion] = useState(false);
  const [propEName, setPropEName] = useState(false);
  const [propENummer, setPropENummer] = useState(false);
  const [propEBeschreibung, setPropEBeschreibung] = useState(false);
  const [propEEigentuemer, setPropEEigentuemer] = useState(false);
  const [propEVerantwortlicher, setPropEVerantwortlicher] = useState(false);
  const [propEBemerkung, setPropEBemerkung] = useState(false);
  const [propEStreet, setPropEStreet] = useState(false);
  const [propEStreetNumber, setPropEStreetNumber] = useState(false);
  const [propEZip, setPropEZip] = useState(false);
  const [propECity, setPropECity] = useState(false);
  const [propECountry, setPropECountry] = useState(false);
  const [propERegion, setPropERegion] = useState(false);
  //building attributes
  const [bName, setBName] = useState();
  const [bNummer, setBNummer] = useState();
  const [bBeschreibung, setBBeschreibung] = useState();
  const [bEigentuemer, setBEigentuemer] = useState();
  const [bVerantwortlicher, setBVerantwortlicher] = useState();
  const [bBemerkung, setBBemerkung] = useState();
  const [bStreet, setBStreet] = useState();
  const [bStreetNumber, setBStreetNumber] = useState();
  const [bZip, setBZip] = useState();
  const [bCity, setBCity] = useState();
  const [bCountry, setBCountry] = useState();
  const [bRegion, setBRegion] = useState();
  const [bAName, setBAName] = useState(false);
  const [bANummer, setBANummer] = useState(false);
  const [bABeschreibung, setBABeschreibung] = useState(false);
  const [bAEigentuemer, setBAEigentuemer] = useState(false);
  const [bAVerantwortlicher, setBAVerantwortlicher] = useState(false);
  const [bABemerkung, setBABemerkung] = useState(false);
  const [bAStreet, setBAStreet] = useState(false);
  const [bAStreetNumber, setBAStreetNumber] = useState(false);
  const [bAZip, setBAZip] = useState(false);
  const [bACity, setBACity] = useState(false);
  const [bACountry, setBACountry] = useState(false);
  const [bARegion, setBARegion] = useState(false);
  const [bEName, setBEName] = useState(false);
  const [bENummer, setBENummer] = useState(false);
  const [bEBeschreibung, setBEBeschreibung] = useState(false);
  const [bEEigentuemer, setBEEigentuemer] = useState(false);
  const [bEVerantwortlicher, setBEVerantwortlicher] = useState(false);
  const [bEBemerkung, setBEBemerkung] = useState(false);
  const [bEStreet, setBEStreet] = useState(false);
  const [bEStreetNumber, setBEStreetNumber] = useState(false);
  const [bEZip, setBEZip] = useState(false);
  const [bECity, setBECity] = useState(false);
  const [bECountry, setBECountry] = useState(false);
  const [bERegion, setBERegion] = useState(false);
  const [bImgs, setBImgs] = useState(null);
  const [bDocs, setBDocs] = useState(null);
  const [bBaujahr, setBBaujahr] = useState(0);
  const [bBruttogeschossflaeche, setBBruttogeschossflaeche] = useState(0);
  const [bNettogeschossflaeche, setBNettogeschossflaeche] = useState(0);
  const [bAussenanlageflaeche, setBAussenanlageflaeche] = useState(0);
  const [bMietflaeche, setBMietflaeche] = useState(0);
  const [bStellplaetze, setBStellplaetze] = useState(0);
  const [bGeschosseUeberGrund, setBGeschosseUeberGrund] = useState(0);
  const [bGeschosseUnterGrund, setBGeschosseUnterGrund] = useState(0);
  const [bAnzahlTreppenhaeuser, setBAnzahlTreppenhaeuser] = useState(0);
  const [bAnzahlBauteile, setBAnzahlBauteile] = useState(0);
  const [bAnzahlAufzuege, setBAnzahlAufzuege] = useState(0);
  const [bAnzahlNutzerPersonen, setBAnzahlNutzerPersonen] = useState(0);
  const [bJahresmieteinnahmen, setBJahresmieteinnahmen] = useState(null);
  const [bNettoFmKosten, setBNettoFmKosten] = useState(null);
  const [bDenkmalschutz, setBDenkmalschutz] = useState(null);
  const [bTiefgarage, setBTiefgarage] = useState(null);
  const [bFassadenbefahranlage, setBFassadenbefahranlage] = useState(null);
  const [bBaualtersstufe, setBBaualtersstufe] = useState(null);
  const [bAusstattungsgrad, setBAusstattungsgrad] = useState(null);
  const [bAOffice, setBAOffice] = useState(20);
  const [bARetail, setBARetail] = useState(20);
  const [bAWohnen, setBAWohnen] = useState(20);
  const [bALogistik, setBALogistik] = useState(20);
  const [bASonstige, setBASonstige] = useState(20);
  const [bABaujahr, setBABaujahr] = useState(false);
  const [bABruttogeschossflaeche, setBABruttogeschossflaeche] = useState(false);
  const [bANettogeschossflaeche, setBANettogeschossflaeche] = useState(false);
  const [bAAussenanlageflaeche, setBAAussenanlageflaeche] = useState(false);
  const [bAMietflaeche, setBAMietflaeche] = useState(false);
  const [bAStellplaetze, setBAStellplaetze] = useState(false);
  const [bAGeschosseUeberGrund, setBAGeschosseUeberGrund] = useState(false);
  const [bAGeschosseUnterGrund, setBAGeschosseUnterGrund] = useState(false);
  const [bAAnzahlTreppenhaeuser, setBAAnzahlTreppenhaeuser] = useState(false);
  const [bAAnzahlBauteile, setBAAnzahlBauteile] = useState(false);
  const [bAAnzahlAufzuege, setBAAnzahlAufzuege] = useState(false);
  const [bAAnzahlNutzerPersonen, setBAAnzahlNutzerPersonen] = useState(false);
  const [bAJahresmieteinnahmen, setBAJahresmieteinnahmen] = useState();
  const [bANettoFmKosten, setBANettoFmKosten] = useState(false);
  const [bADenkmalschutz, setBADenkmalschutz] = useState();
  const [bATiefgarage, setBATiefgarage] = useState();
  const [bAFassadenbefahranlage, setBAFassadenbefahranlage] = useState();
  const [bABaualtersstufe, setBABaualtersstufe] = useState();
  const [bAAusstattungsgrad, setBAAusstattungsgrad] = useState();
  const [bEBaujahr, setBEBaujahr] = useState(false);
  const [bEBruttogeschossflaeche, setBEBruttogeschossflaeche] = useState(false);
  const [bENettogeschossflaeche, setBENettogeschossflaeche] = useState(false);
  const [bEAussenanlageflaeche, setBEAussenanlageflaeche] = useState(false);
  const [bEMietflaeche, setBEMietflaeche] = useState(false);
  const [bEStellplaetze, setBEStellplaetze] = useState(false);
  const [bEGeschosseUeberGrund, setBEGeschosseUeberGrund] = useState(false);
  const [bEGeschosseUnterGrund, setBEGeschosseUnterGrund] = useState(false);
  const [bEAnzahlTreppenhaeuser, setBEAnzahlTreppenhaeuser] = useState(false);
  const [bEAnzahlBauteile, setBEAnzahlBauteile] = useState(false);
  const [bEAnzahlAufzuege, setBEAnzahlAufzuege] = useState(false);
  const [bEAnzahlNutzerPersonen, setBEAnzahlNutzerPersonen] = useState(false);
  const [bEJahresmieteinnahmen, setBEJahresmieteinnahmen] = useState(false);
  const [bENettoFmKosten, setBENettoFmKosten] = useState(false);
  const [bEDenkmalschutz, setBEDenkmalschutz] = useState();
  const [bETiefgarage, setBETiefgarage] = useState();
  const [bEFassadenbefahranlage, setBEFassadenbefahranlage] = useState();
  const [bEBaualtersstufe, setBEBaualtersstufe] = useState();
  const [bEAusstattungsgrad, setBEAusstattungsgrad] = useState();
  //FIXME GEOCODING SERVICE!!!!!

  //initial loading
  useEffect(() => {
    //remove all pending locks
    removeAllOnlinePendingLocksForUserId();
    //call liegenschaft and buildings
    setReadOnlyProperty(liegenschaftId ? true : false);
    if (liegenschaftId && !property && !propertyPending) {
      setPropertyPending(true);
      setPropertyError(false);
      setAllBuildingsPending(true);
      setAllBuildingsError(false);
      callLiegenschaftById(liegenschaftId)
        .then((property) => {
          setProperty(property);
          initPropertyAttributes(property);
          setPropertyPending(false);
          setPropertyError(false);
          //call all buildings for property
          callAllGebaeudeInLiegenschaft(liegenschaftId)
            .then((buildings) => {
              setAllBuildings(buildings);
              setAllBuildingsPending(false);
              setAllBuildingsError(false);
              if (buildings && buildings.length > 0) {
                setShowBuildings(true);
                setSelectedBuilding(buildings[0]);
                initBuildingAttributes(buildings[0]);
                setForceRerenderMix(!forceRerenderMix);
              }
              //call all mediaIds
              const mediaIds = extractAllMediaIds(property, buildings);
              translateMediaIds(mediaIds)
                .then((data) => {
                  setupMediaLookups(data);
                })
                .catch((error) => {
                  toast.current.show({
                    severity: "error",
                    summary:
                      "Bilder konnten nicht geladen werden " +
                      errorToMsg(error),
                    detail:
                      "Leider konnten die Bilder nicht vollständig geladen werden. Bitte versuchen Sie es später erneut.",
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG,
                  });
                });
            })
            .catch((error) => {
              setAllBuildingsPending(false);
              setAllBuildingsError(true);
              toast.current.show({
                severity: "error",
                summary:
                  "Gebäude konnten nicht geladen werden " + errorToMsg(error),
                detail:
                  "Leider konnten die Gebäude dieser Liegenschaft nicht vollständig geladen werden. Bitte versuchen Sie es später erneut.",
                sticky: false,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG,
              });
            });
        })
        .catch((error) => {
          setPropertyPending(false);
          setPropertyError(true);
          toast.current.show({
            severity: "error",
            summary:
              "Liegenschaft konnte nicht geladen werden " + errorToMsg(error),
            detail:
              "Leider konnten die Liegenschaft nicht geladen werden. Bitte versuchen Sie es später erneut.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG,
          });
        });
      downloadAvailableBaualtersstufen()
        .then((data) => {
          const baualtersstufenSelect = [];
          if (data) {
            for (let i = 0; i < data.length; i++) {
              baualtersstufenSelect.push({
                label: data[i],
                value: data[i],
              });
            }
          }
          setAllBaualtersstufen(baualtersstufenSelect);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Baualtersstufen nicht abrufbar " + errorToMsg(error),
            detail:
              "Leider konnten die verfügbaren Baualtersstufen nicht geladen werden.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG,
          });
        });
      downloadAvailableAusstattungsgrad()
        .then((data) => {
          const ausstattungsgradSelect = [];
          if (data) {
            for (let i = 0; i < data.length; i++) {
              ausstattungsgradSelect.push({
                label: data[i],
                value: data[i],
              });
            }
          }
          setAllAusstattungsgrade(ausstattungsgradSelect);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Ausstattungsgrade nicht abrufbar " + errorToMsg(error),
            detail:
              "Leider konnten die verfügbaren Ausstattungsgrade nicht geladen werden.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG,
          });
        });
    }
  }, []);

  function extractAllMediaIds(property, buildings) {
    const mediaIds = [];
    //extract ids from property
    if (property) {
      if (property.hauptBildId) {
        mediaIds.push(property.hauptBildId);
      }
      if (property.hauptPlanId) {
        mediaIds.push(property.hauptPlanId);
      }
      if (property.mediaIds) {
        mediaIds.push(...property.mediaIds);
      }
    }
    //extract ids from buildings
    if (buildings) {
      buildings.forEach((building) => {
        if (building) {
          if (building.hauptBildId) {
            mediaIds.push(building.hauptBildId);
          }
          if (building.hauptPlanId) {
            mediaIds.push(building.hauptPlanId);
          }
          if (building.mediaIds) {
            mediaIds.push(...building.mediaIds);
          }
        }
      });
    }
    return mediaIds;
  }

  function setupMediaLookups(mediaData) {
    const imgLookup = new Map();
    const docLookup = new Map();
    if (mediaData) {
      mediaData.forEach((entry) => {
        if (entry.image) {
          const image = entry.image;
          imgLookup.set(image.id, image);
        } else if (entry.document) {
          const doc = entry.document;
          docLookup.set(doc.id, doc);
        }
      });
    }
    setImgLookup(imgLookup);
    setDocLookup(docLookup);
    //reset media caches
    setPropImgs(null);
    setPropDocs(null);
    setBImgs(null);
    setBDocs(null);
  }

  function initPropertyAttributes(property) {
    if (property) {
      const data = _.cloneDeep(property);
      setPropName(data.name);
      setPropNummer(data.nummer);
      setPropBeschreibung(data.beschreibung);
      setPropEigentuemer(data.eigentuemer);
      setPropVerantwortlicher(data.verantwortlicher);
      setPropBemerkung(data.anmerkung);
      if (data.adresse) {
        setPropStreet(data.adresse.strasse);
        setPropStreetNumber(data.adresse.hausnummer);
        setPropZip(data.adresse.postleitzahl);
        setPropCity(data.adresse.stadt);
        const country = _.find(countries, function (c) {
          return c.id === data.adresse.land;
        });
        setPropCountry(country);
        setPropRegion(
          _.find(country.regions, function (r) {
            return r.id === data.adresse.region;
          })
        );
      }
    }
  }

  function initBuildingAttributes(data) {
    if (data) {
      const build = _.cloneDeep(data);
      setBName(build.name);
      setBNummer(build.nummer);
      setBBeschreibung(build.beschreibung);
      setBEigentuemer(build.eigentuemer);
      setBVerantwortlicher(build.verantwortlicher);
      setBBemerkung(build.anmerkung);
      if (build.adresse) {
        setBStreet(build.adresse.strasse);
        setBStreetNumber(build.adresse.hausnummer);
        setBZip(build.adresse.postleitzahl);
        setBCity(build.adresse.stadt);
        const country = _.find(countries, function (c) {
          return c.id === build.adresse.land;
        });
        setBCountry(country);
        setBRegion(
          _.find(country.regions, function (r) {
            return r.id === build.adresse.region;
          })
        );
      }
      setBBaujahr(build.baujahr);
      setBBruttogeschossflaeche(build.bruttoGeschossflaeche);
      setBNettogeschossflaeche(build.nettoGeschossflaeche);
      setBAussenanlageflaeche(build.aussenanlagenflaeche);
      setBMietflaeche(build.mietflaeche);
      setBStellplaetze(build.anzahlStellplaetze);
      setBGeschosseUeberGrund(build.anzahlEtagenOberirdisch);
      setBGeschosseUnterGrund(build.anzahlEtagenUnterirdisch);
      setBAnzahlTreppenhaeuser(build.anzahlTreppenhaeuser);
      setBAnzahlBauteile(build.anzahlBauteile);
      setBAnzahlAufzuege(build.anzahlAufzuege);
      setBAnzahlNutzerPersonen(build.anzahlNutzerPersonen);
      setBJahresmieteinnahmen(build.jahresMieteinnahmen);
      setBNettoFmKosten(build.kostenNettoFmProJahr);
      setBDenkmalschutz(build.denkmalschutz);
      setBTiefgarage(build.tiefgarage);
      setBFassadenbefahranlage(build.fassadenbefahranlage);
      setBBaualtersstufe(build.baualtersstufe);
      setBAusstattungsgrad(build.ausstattungsgrad);
      setBAOffice(build.nutzungsanteilOffice);
      setBARetail(build.nutzungsanteilRetail);
      setBAWohnen(build.nutzungsanteilWohnen);
      setBALogistik(build.nutzungsanteilLogistik);
      setBASonstige(build.nutzungsanteilSonstige);
    } else {
      //reset to default!
      setBName("");
      setBNummer("");
      setBBeschreibung("");
      setBEigentuemer("");
      setBVerantwortlicher("");
      setBBemerkung("");
      setBStreet("");
      setBStreetNumber("");
      setBZip("");
      setBCity("");
      setBCountry(null);
      setBRegion(null);
      setBBaujahr(null);
      setBBruttogeschossflaeche(null);
      setBNettogeschossflaeche(null);
      setBAussenanlageflaeche(null);
      setBMietflaeche(null);
      setBStellplaetze(null);
      setBGeschosseUeberGrund(null);
      setBGeschosseUnterGrund(null);
      setBAnzahlTreppenhaeuser(null);
      setBAnzahlBauteile(null);
      setBAnzahlAufzuege(null);
      setBAnzahlNutzerPersonen(null);
      setBJahresmieteinnahmen(null);
      setBNettoFmKosten(null);
      setBDenkmalschutz(null);
      setBTiefgarage(null);
      setBFassadenbefahranlage(null);
      setBBaualtersstufe(null);
      setBAusstattungsgrad(null);
      setBAOffice(20);
      setBARetail(20);
      setBAWohnen(20);
      setBALogistik(20);
      setBASonstige(20);
    }
    //reset error states
    setBEName(false);
    setBENummer(false);
    setBEBeschreibung(false);
    setBEEigentuemer(false);
    setBEVerantwortlicher(false);
    setBEBemerkung(false);
    setBEStreet(false);
    setBEStreetNumber(false);
    setBEZip(false);
    setBECity(false);
    setBECountry(false);
    setBERegion(false);
    setBEBaujahr(false);
    setBEBruttogeschossflaeche(false);
    setBENettogeschossflaeche(false);
    setBEAussenanlageflaeche(false);
    setBEMietflaeche(false);
    setBEStellplaetze(false);
    setBEGeschosseUeberGrund(false);
    setBEGeschosseUnterGrund(false);
    setBEAnzahlTreppenhaeuser(false);
    setBEAnzahlBauteile(false);
    setBEAnzahlAufzuege(false);
    setBEAnzahlNutzerPersonen(false);
    setBEJahresmieteinnahmen(false);
    setBENettoFmKosten(false);
    setBEDenkmalschutz(false);
    setBETiefgarage(false);
    setBEFassadenbefahranlage(false);
    setBEBaualtersstufe(false);
    setBEAusstattungsgrad(false);
  }

  function getPropertyImgs() {
    if (propImgs) {
      return propImgs;
    } else if (property && imgLookup) {
      const imgArray = [];
      //search all images
      const imgIds = [];
      if (property) {
        if (property.hauptBildId) {
          imgIds.push(property.hauptBildId);
        }
        if (property.mediaIds) {
          property.mediaIds.forEach((entry) => {
            if (imgLookup.has(entry)) {
              imgIds.push(entry);
            }
          });
        }
        //convert into images
        imgIds.forEach(function (imageId) {
          const entry = imgLookup.get(imageId);
          let version = null;
          if (entry) {
            for (let i = 0; i < entry.versions.length; i++) {
              const v = entry.versions[i];
              //default to first version
              if (!version) {
                version = v;
              }
              //search for medium pic
              if (v && v.vLabel) {
                if (v.vLabel === DEFAULT_IMAGE_SIZE) {
                  version = v;
                }
              }
            }
            if (version) {
              imgArray.push({
                id: entry.id,
                url: URL_TO_MEDIA_SERVER + version.url,
                key: version.access,
                alt: null,
                title: entry.title,
                copyright: entry.copyright,
                secured: version.access ? true : false,
                showPlaceholder: false,
              });
            }
          }
        });
      }
      setPropImgs(imgArray);
      return imgArray;
    } else {
      return [];
    }
  }

  function getBuildingImgs() {
    if (bImgs) {
      return bImgs;
    } else if (selectedBuilding && imgLookup) {
      const imgArray = [];
      //search all images
      const imgIds = [];
      if (selectedBuilding) {
        if (selectedBuilding.hauptBildId) {
          imgIds.push(selectedBuilding.hauptBildId);
        }
        if (selectedBuilding.mediaIds) {
          selectedBuilding.mediaIds.forEach((entry) => {
            if (imgLookup.has(entry)) {
              imgIds.push(entry);
            }
          });
        }
        //convert into images
        imgIds.forEach(function (imageId) {
          const entry = imgLookup.get(imageId);
          let version = null;
          if (entry) {
            for (let i = 0; i < entry.versions.length; i++) {
              const v = entry.versions[i];
              //default to first version
              if (!version) {
                version = v;
              }
              //search for medium pic
              if (v && v.vLabel) {
                if (v.vLabel === DEFAULT_IMAGE_SIZE) {
                  version = v;
                }
              }
            }
            if (version) {
              imgArray.push({
                id: entry.id,
                url: URL_TO_MEDIA_SERVER + version.url,
                key: version.access,
                alt: null,
                title: entry.title,
                copyright: entry.copyright,
                secured: version.access ? true : false,
                showPlaceholder: false,
              });
            }
          }
        });
      }
      setBImgs(imgArray);
      return imgArray;
    } else {
      return [];
    }
  }

  function getPropertyDocs() {
    if (propDocs) {
      return propDocs;
    } else if (property && docLookup) {
      const docArray = [];
      //search all documents
      const docIds = [];
      if (property) {
        if (property.hauptPlanId) {
          docIds.push(property.hauptPlanId);
        }
        if (property.mediaIds) {
          property.mediaIds.forEach((entry) => {
            if (docLookup.has(entry)) {
              docIds.push(entry);
            }
          });
        }
        //convert to documents
        docIds.forEach(function (docId) {
          const entry = docLookup.get(docId);
          if (entry) {
            docArray.push({
              id: entry.id,
              title: entry.title,
              filename: entry.filename,
              fileSrc: URL_TO_MEDIA_SERVER + entry.url,
              timestamp: new Date(entry.createdAt),
              size: entry.fileSize,
              type: entry.type,
              copyright: entry.copyright,
              secured: entry.access ? true : false,
              fileKey: entry.access,
            });
          }
        });
      }
      setPropDocs(docArray);
      return docArray;
    } else {
      return [];
    }
  }

  function getBuildingDocs() {
    if (bDocs) {
      return bDocs;
    } else if (selectedBuilding && docLookup) {
      const docArray = [];
      //search all documents
      const docIds = [];
      if (selectedBuilding) {
        if (selectedBuilding.hauptPlanId) {
          docIds.push(selectedBuilding.hauptPlanId);
        }
        if (selectedBuilding.mediaIds) {
          selectedBuilding.mediaIds.forEach((entry) => {
            if (docLookup.has(entry)) {
              docIds.push(entry);
            }
          });
        }
        //convert to documents
        docIds.forEach(function (docId) {
          const entry = docLookup.get(docId);
          if (entry) {
            docArray.push({
              id: entry.id,
              title: entry.title,
              filename: entry.filename,
              fileSrc: URL_TO_MEDIA_SERVER + entry.url,
              timestamp: new Date(entry.createdAt),
              size: entry.fileSize,
              type: entry.type,
              copyright: entry.copyright,
              secured: entry.access ? true : false,
              fileKey: entry.access,
            });
          }
        });
      }
      setBDocs(docArray);
      return docArray;
    } else {
      return [];
    }
  }

  function saveProperty() {
    const errorName = !(propName && propName.length > 0);
    const errorNummer = !(propNummer && propNummer.length > 0);
    const errorBeschreibung = false;
    const errorEigentuemer = false;
    const errorVerantwortlicher = false;
    const errorBemerkung = false;
    const errorStreet = !(propStreet && propStreet.length > 0);
    const errorStreetNo = !(propStreetNumber && propStreetNumber.length > 0);
    const errorZip = !(propZip && propZip.length > 0);
    const errorCity = !(propCity && propCity.length > 0);
    const errorCountry = !(propCountry && propCountry.id);
    const errorRegion = !(propRegion && propRegion.id);
    setPropEName(errorName);
    setPropENummer(errorNummer);
    setPropEBeschreibung(errorBeschreibung);
    setPropEEigentuemer(errorEigentuemer);
    setPropEVerantwortlicher(errorVerantwortlicher);
    setPropEBemerkung(errorBemerkung);
    setPropEStreet(errorStreet);
    setPropEStreetNumber(errorStreetNo);
    setPropEZip(errorZip);
    setPropECity(errorCity);
    setPropECountry(errorCountry);
    setPropERegion(errorRegion);
    //send to server
    if (
      !errorName &&
      !errorNummer &&
      !errorBeschreibung &&
      !errorEigentuemer &&
      !errorVerantwortlicher &&
      !errorBemerkung &&
      !errorStreet &&
      !errorStreetNo &&
      !errorZip &&
      !errorCity &&
      !errorCountry &&
      !errorRegion
    ) {
      savePropOnServer();
    }
  }

  function savePropOnServer() {
    const currOrganisationId = property ? property.organisationId : uuidv4(); //FIXME real OrganisationId!!!
    const currLohnindex = property ? property.lohnindex : null;
    const currLongitude = property ? property.longitude : null; //FIXME need geocoding
    const currLatitude = property ? property.latitude : null; //FIXME need geocoding
    const currRegion = propRegion ? propRegion.id : null;
    const currCountry = propCountry ? propCountry.id : null;
    const hauptBildId = propImgs && propImgs.length > 0 ? propImgs[0].id : null;
    const imageIds = [];
    if (propImgs && propImgs.length > 1) {
      //skip main image
      for (let i = 1; i < propImgs.length; i++) {
        imageIds.push(propImgs[i].id);
      }
    }
    const hauptPlanId = propDocs && propDocs.length > 0 ? propDocs[0].id : null;
    const docIds = [];
    if (propDocs && propDocs.length > 1) {
      //skip main image
      for (let j = 1; j < propDocs.length; j++) {
        docIds.push(propDocs[j].id);
      }
    }
    if (liegenschaftId) {
      //update liegenschaft
      updateLiegenschaftById(
        liegenschaftId,
        currOrganisationId,
        propNummer,
        propName,
        propBeschreibung,
        propBemerkung,
        currLohnindex,
        propEigentuemer,
        propVerantwortlicher,
        hauptBildId,
        hauptPlanId,
        imageIds,
        docIds,
        propStreet,
        propStreetNumber,
        propZip,
        propCity,
        currRegion,
        currCountry,
        currLongitude,
        currLatitude
      )
        .then(() => {
          setReadOnlyProperty(true);
          //FIXME show error to user
          toast.current.show({
            severity: "success",
            summary: "Speicherung erfolgreich",
            detail: "Die Änderungen wurden gespeichert.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_SUCCESS,
          });
        })
        .catch(() => {
          //FIXME show error to user
          toast.current.show({
            severity: "error",
            summary: "Speicherung fehlgeschlagen.",
            detail: "Die Liegenschaft konnte leider nicht gespeichert werden.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR,
          });
        });
    } else {
      //create new liegenschaft
      createLiegenschaftById(
        currOrganisationId,
        propNummer,
        propName,
        propBeschreibung,
        propBemerkung,
        currLohnindex,
        propEigentuemer,
        propVerantwortlicher,
        hauptBildId,
        hauptPlanId,
        imageIds,
        docIds,
        propStreet,
        propStreetNumber,
        propZip,
        propCity,
        currRegion,
        currCountry,
        currLongitude, //FIXME need geocoding
        currLatitude //FIXME need geocoding
      )
        .then((data) => {
          setReadOnlyProperty(true);
          //use the created property as current-values
          setProperty(data);
          initPropertyAttributes(data);
          toast.current.show({
            severity: "success",
            summary: "Erstellen erfolgreich",
            detail:
              "Die Liegenschaft " + propName + " wurde erfolgreich erstellt.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_SUCCESS,
          });
        })
        .catch(() => {
          //FIXME show error to user
          toast.current.show({
            severity: "error",
            summary: "Erstellen fehlgeschlagen.",
            detail:
              "Die Liegenschaft " +
              propName +
              " konnte leider nicht erstellt werden.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR,
          });
        });
    }
  }

  function clickImageCallback(entryId) {
    if (propertyMode) {
      if (propertyGallery && propertyGallery.current && propImgs) {
        for (let i = 0; i < propImgs.length; i++) {
          if (propImgs[i].id === entryId) {
            propertyGallery.current.showImage(i);
            break;
          }
        }
        propertyGallery.current.openGallery();
      }
    } else {
      //building mode
      if (buildingGallery && buildingGallery.current && bImgs) {
        for (let i = 0; i < bImgs.length; i++) {
          if (bImgs[i].id === entryId) {
            buildingGallery.current.showImage(i);
            break;
          }
        }
        buildingGallery.current.openGallery();
      }
    }
  }

  function downloadDocument(entryId) {
    const currentDocs = propertyMode ? propDocs : bDocs;
    for (let i = 0; i < currentDocs.length; i++) {
      if (currentDocs[i].id === entryId) {
        const src = currentDocs[i].fileSrc;
        const secretKey = currentDocs[i].fileKey;
        downloadDocumentFromServer(src, secretKey)
          .then((entry) => {
            execIfOnWeb(() => {
              fileDownload(entry, currentDocs[i].filename);
            });
            execIfOnAndroid(() => {
              writeDocumentFromBlobOnAndroid(
                entry,
                currentDocs[i].filename,
                entry.type,
                true
              );
            });
          })
          .catch((error) => {
            toast.current.show({
              severity: "error",
              summary: "Download fehlgeschlagen " + errorToMsg(error),
              detail:
                "Leider konnte das Dokument nicht heruntergeladen werden. Bitte versuchen Sie es später erneut.",
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG,
            });
          });
        break;
      }
    }
  }

  function createNewDocument(data) {
    if (data) {
      setDocUploadProg(true);
      setDocUploadError(null);
      uploadDocumentToServer(data.file, data.name, data.originalFileName)
        .then((document) => {
          if (document) {
            const adaptedDocs = propertyMode
              ? propDocs
                ? propDocs
                : []
              : bDocs
              ? bDocs
              : [];
            const convertedDoc = {
              id: document.id,
              title: document.title,
              filename: document.filename,
              fileSrc: URL_TO_MEDIA_SERVER + document.url,
              timestamp: new Date(document.createdAt),
              size: document.fileSize,
              type: document.type,
              copyright: document.copyright,
              secured: document.access ? true : false,
              fileKey: document.access,
            };
            adaptedDocs.push(convertedDoc);
            if (propertyMode) {
              setPropDocs(adaptedDocs);
            } else {
              setBDocs(adaptedDocs);
            }
            //add entry to documentLookup
            docLookup.set(document.id, document);
          }
          setDocUploadProg(false);
          setV_DocDialog(false);
        })
        .catch((error) => {
          setDocUploadProg(false);
          setDocUploadError("Upload ist leider fehlgeschlagen.");
          toast.current.show({
            severity: "error",
            summary: "Upload fehlgeschlagen " + errorToMsg(error),
            detail:
              "Leider konnte die Datei hochgeladen werden. Bitte versuchen Sie es später erneut.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG,
          });
        });
    }
  }

  function createNewImage(data, androidVersion) {
    if (data) {
      if (androidVersion) {
        setAImgUploadProg(true);
        setAImgUploadError(true);
      } else {
        setImgUploadProg(true);
        setImgUploadError(true);
      }
      uploadImageToServer(data.file, data.name, data.originalFileName)
        .then((image) => {
          if (image) {
            const propertyImgs = propImgs ? propImgs : [];
            const buildingImgs = bImgs ? bImgs : [];
            const adaptedImages = propertyMode ? propertyImgs : buildingImgs;
            let version = null;
            for (let i = 0; i < image.versions.length; i++) {
              const v = image.versions[i];
              //default to first version
              if (!version) {
                version = v;
              }
              //search for medium pic
              if (v && v.vLabel) {
                if (v.vLabel === DEFAULT_IMAGE_SIZE) {
                  version = v;
                }
              }
            }
            const convertedImage = {
              id: image.id,
              url: URL_TO_MEDIA_SERVER + version.url,
              key: version.access,
              alt: null,
              title: image.title,
              copyright: image.copyright,
              secured: version.access ? true : false,
              showPlaceholder: false,
            };
            adaptedImages.push(convertedImage);
            if (propertyMode) {
              setPropImgs(adaptedImages);
            } else {
              setBImgs(adaptedImages);
            }
            //add entry to imageLookup
            imgLookup.set(image.id, image);
          }
          if (androidVersion) {
            setAImgUploadProg(false);
            setAV_ImgDialog(false);
          } else {
            setImgUploadProg(false);
            setV_ImgDialog(false);
          }
        })
        .catch((error) => {
          if (androidVersion) {
            setAImgUploadProg(false);
            setAImgUploadError("Upload ist leider fehlgeschlagen.");
          } else {
            setImgUploadProg(false);
            setImgUploadError("Upload ist leider fehlgeschlagen.");
          }
          toast.current.show({
            severity: "error",
            summary: "Upload fehlgeschlagen " + errorToMsg(error),
            detail:
              "Leider konnte die Datei hochgeladen werden. Bitte versuchen Sie es später erneut.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG,
          });
        });
    }
  }

  function deleteImage(entryId) {
    //remove image from stored Images
    if (propertyMode) {
      if (propImgs) {
        const imgs = propImgs.filter(function (item) {
          return item.id !== entryId;
        });
        setPropImgs(imgs);
      }
    } else {
      if (bImgs) {
        const imgs = bImgs.filter(function (item) {
          return item.id !== entryId;
        });
        setBImgs(imgs);
      }
    }
  }

  function deleteDocument(entryId) {
    //remove image from stored Images
    if (propertyMode) {
      if (propDocs) {
        const docs = propDocs.filter(function (item) {
          return item.id !== entryId;
        });
        setPropDocs(docs);
      }
    } else {
      if (bDocs) {
        const docs = bDocs.filter(function (item) {
          return item.id !== entryId;
        });
        setBDocs(docs);
      }
    }
  }

  //building
  function saveBuilding() {
    const errorName = !(bName && bName.length > 0);
    const errorNummer = !(bNummer && bNummer.length > 0);
    const errorBeschreibung = false;
    const errorEigentuemer = false;
    const errorVerantwortlicher = false;
    const errorBemerkung = false;
    const errorStreet = !(bStreet && bStreet.length > 0);
    const errorStreetNo = !(bStreetNumber && bStreetNumber.length > 0);
    const errorZip = !(bZip && bZip.length > 0);
    const errorCity = !(bCity && bCity.length > 0);
    const errorCountry = !(bCountry && bCountry.id);
    const errorRegion = !(bRegion && bRegion.id);
    const errorBaujahr = false;
    const errorBruttogeschossflaeche = false;
    const errorNettogeschossflaeche = false;
    const errorAussenanlageflaeche = false;
    const errorMietflaeche = false;
    const errorStellplaetze = false;
    const errorGeschosseUeberGrund = false;
    const errorGeschosseUnterGrund = false;
    const errorAnzahlTreppenhaeuser = false;
    const errorAnzahlBauteile = false;
    const errorAnzahlAufzuege = false;
    const errorAnzahlNutzerPersonen = false;
    const errorJahresmieteinnahmen = false;
    const errorNettoFmKosten = false;
    const errorDenkmalschutz = false;
    const errorTiefgarage = false;
    const errorFassadenbefahranlage = false;
    const errorBaualtersstufe = false;
    const errorAusstattungsgrad = false;
    setBEName(errorName);
    setBENummer(errorNummer);
    setBEBeschreibung(errorBeschreibung);
    setBEEigentuemer(errorEigentuemer);
    setBEVerantwortlicher(errorVerantwortlicher);
    setBEBemerkung(errorBemerkung);
    setBEStreet(errorStreet);
    setBEStreetNumber(errorStreetNo);
    setBEZip(errorZip);
    setBECity(errorCity);
    setBECountry(errorCountry);
    setBERegion(errorRegion);
    setBEBaujahr(errorBaujahr);
    setBEBruttogeschossflaeche(errorBruttogeschossflaeche);
    setBENettogeschossflaeche(errorNettogeschossflaeche);
    setBEAussenanlageflaeche(errorAussenanlageflaeche);
    setBEMietflaeche(errorMietflaeche);
    setBEStellplaetze(errorStellplaetze);
    setBEGeschosseUeberGrund(errorGeschosseUeberGrund);
    setBEGeschosseUnterGrund(errorGeschosseUnterGrund);
    setBEAnzahlTreppenhaeuser(errorAnzahlTreppenhaeuser);
    setBEAnzahlBauteile(errorAnzahlBauteile);
    setBEAnzahlAufzuege(errorAnzahlAufzuege);
    setBEAnzahlNutzerPersonen(errorAnzahlNutzerPersonen);
    setBEJahresmieteinnahmen(errorJahresmieteinnahmen);
    setBENettoFmKosten(errorNettoFmKosten);
    setBEDenkmalschutz(errorDenkmalschutz);
    setBETiefgarage(errorTiefgarage);
    setBEFassadenbefahranlage(errorFassadenbefahranlage);
    setBEBaualtersstufe(errorBaualtersstufe);
    setBEAusstattungsgrad(errorAusstattungsgrad);
    //send to server
    if (
      !errorName &&
      !errorNummer &&
      !errorBeschreibung &&
      !errorEigentuemer &&
      !errorVerantwortlicher &&
      !errorBemerkung &&
      !errorStreet &&
      !errorStreetNo &&
      !errorZip &&
      !errorCity &&
      !errorCountry &&
      !errorRegion &&
      !errorBaujahr &&
      !errorBruttogeschossflaeche &&
      !errorNettogeschossflaeche &&
      !errorAussenanlageflaeche &&
      !errorMietflaeche &&
      !errorStellplaetze &&
      !errorGeschosseUeberGrund &&
      !errorGeschosseUnterGrund &&
      !errorAnzahlTreppenhaeuser &&
      !errorAnzahlBauteile &&
      !errorAnzahlAufzuege &&
      !errorAnzahlNutzerPersonen &&
      !errorJahresmieteinnahmen &&
      !errorNettoFmKosten &&
      !errorDenkmalschutz &&
      !errorTiefgarage &&
      !errorFassadenbefahranlage &&
      !errorBaualtersstufe &&
      !errorAusstattungsgrad
    ) {
      saveBuildingOnServer();
    }
  }

  function saveBuildingOnServer() {
    const currLongitude = property ? property.longitude : null; //FIXME need geocoding
    const currLatitude = property ? property.latitude : null; //FIXME need geocoding
    const currRegion = bRegion ? bRegion.id : null;
    const currCountry = bCountry ? bCountry.id : null;
    const currHauptbetriebszeitVon = null;
    const currHauptbetriebszeitBis = null;
    const hauptBildId = bImgs && bImgs.length > 0 ? bImgs[0].id : null;
    const imageIds = [];
    if (bImgs && bImgs.length > 1) {
      //skip main image
      for (let i = 1; i < bImgs.length; i++) {
        imageIds.push(bImgs[i].id);
      }
    }
    const hauptPlanId = bDocs && bDocs.length > 0 ? bDocs[0].id : null;
    const docIds = [];
    if (bDocs && bDocs.length > 1) {
      //skip main image
      for (let j = 1; j < bDocs.length; j++) {
        docIds.push(bDocs[j].id);
      }
    }
    if (selectedBuilding && selectedBuilding.id) {
      //update building in liegenschaft
      updateGebaeudeInLiegenschaft(
        property.id,
        selectedBuilding.id,
        bNummer,
        bName,
        bEigentuemer,
        bVerantwortlicher,
        bBeschreibung,
        bBemerkung,
        bBaujahr,
        bBruttogeschossflaeche,
        bNettogeschossflaeche,
        bAussenanlageflaeche,
        bMietflaeche,
        bGeschosseUeberGrund + bGeschosseUnterGrund,
        bGeschosseUeberGrund,
        bGeschosseUnterGrund,
        bAnzahlTreppenhaeuser,
        bAnzahlAufzuege,
        bStellplaetze,
        bAnzahlNutzerPersonen,
        bAnzahlBauteile,
        bNettoFmKosten,
        bJahresmieteinnahmen,
        bAOffice,
        bARetail,
        bAWohnen,
        bALogistik,
        bASonstige,
        bTiefgarage,
        bFassadenbefahranlage,
        bDenkmalschutz,
        currHauptbetriebszeitVon,
        currHauptbetriebszeitBis,
        bBaualtersstufe,
        bAusstattungsgrad,
        hauptBildId,
        hauptPlanId,
        imageIds,
        docIds,
        bStreet,
        bStreetNumber,
        bZip,
        bCity,
        currRegion,
        currCountry,
        currLongitude,
        currLatitude
      )
        .then((data) => {
          setReadOnlyBuilding(true);
          //update this building in allBuildings
          const currentBuildings = _.cloneDeep(allBuildings);
          for (let i = 0; i < currentBuildings.length; i++) {
            if (currentBuildings[i].id === data.id) {
              currentBuildings[i] = data;
            }
          }
          setAllBuildings(currentBuildings);
          initBuildingAttributes(data);
          toast.current.show({
            severity: "success",
            summary: "Speicherung erfolgreich",
            detail: "Die Änderungen wurden gespeichert.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_SUCCESS,
          });
        })
        .catch(() => {
          toast.current.show({
            severity: "error",
            summary: "Speicherung fehlgeschlagen.",
            detail: "Das Gebäude konnte leider nicht gespeichert werden.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR,
          });
        });
    } else {
      //create new building
      createGebaeudeInLiegenschaft(
        property.id,
        bNummer,
        bName,
        bEigentuemer,
        bVerantwortlicher,
        bBeschreibung,
        bBemerkung,
        bBaujahr,
        bBruttogeschossflaeche,
        bNettogeschossflaeche,
        bAussenanlageflaeche,
        bMietflaeche,
        bGeschosseUeberGrund + bGeschosseUnterGrund,
        bGeschosseUeberGrund,
        bGeschosseUnterGrund,
        bAnzahlTreppenhaeuser,
        bAnzahlAufzuege,
        bStellplaetze,
        bAnzahlNutzerPersonen,
        bAnzahlBauteile,
        bNettoFmKosten,
        bJahresmieteinnahmen,
        bAOffice,
        bARetail,
        bAWohnen,
        bALogistik,
        bASonstige,
        bTiefgarage,
        bFassadenbefahranlage,
        bDenkmalschutz,
        currHauptbetriebszeitVon,
        currHauptbetriebszeitBis,
        bBaualtersstufe,
        bAusstattungsgrad,
        hauptBildId,
        hauptPlanId,
        imageIds,
        docIds,
        bStreet,
        bStreetNumber,
        bZip,
        bCity,
        currRegion,
        currCountry,
        currLongitude,
        currLatitude
      )
        .then((data) => {
          const currentAllBuildings = _.cloneDeep(allBuildings);
          currentAllBuildings.push(data);
          setAllBuildings(currentAllBuildings);
          setSelectedBuilding(data);
          setReadOnlyBuilding(true);
          setAddBuildingActive(false);
          //show success
          toast.current.show({
            severity: "success",
            summary: "Erstellen erfolgreich",
            detail: "Das Gebäude" + bName + " wurde erfolgreich erstellt.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_SUCCESS,
          });
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Erstellen fehlgeschlagen.",
            detail:
              "Das Gebäude" + bName + " konnte leider nicht erstellt werden.",
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR,
          });
        });
    }
  }

  ////////////
  // HELPER //
  ////////////

  function convertDocumentIdToTitle(documentId) {
    const defaultValue = "dieses Dokument";
    if (docLookup) {
      const val = docLookup.get(documentId);
      return val && val.title ? val.title : defaultValue;
    }
    return defaultValue;
  }

  function convertImageIdToTitle(imageId) {
    const defaultValue = "dieses Bild";
    if (imgLookup) {
      const val = imgLookup.get(imageId);
      return val && val.title ? val.title : defaultValue;
    }
    return defaultValue;
  }

  function calculateRecentPropModifications() {
    if (
      property &&
      property.recentModifications &&
      property.recentModifications.length > 0
    ) {
      return new Date(property.recentModifications[0].timestamp);
    }
    return null;
  }

  function calculateRecentBuildModifications() {
    if (
      selectedBuilding &&
      selectedBuilding.recentModifications &&
      selectedBuilding.recentModifications.length > 0
    ) {
      return new Date(selectedBuilding.recentModifications[0].timestamp);
    }
    return null;
  }

  ////////////////////////////
  // INJECTED VIEW ELEMENTS //
  ////////////////////////////

  const injectLiegenschaftData = () => {
    return (
      <BeeLiegenschaftData
        id={"bee-audit-liegenschaft-data"}
        showEditButton={true}
        readOnly={readOnlyProperty}
        activateHover={runsOnWeb()}
        showProgress={propertyPending}
        errorMsg={
          propertyError
            ? "Die Daten konnten leider nicht geladen werden."
            : null
        }
        allCountries={countries}
        name={propName}
        nummer={propNummer}
        beschreibung={propBeschreibung}
        eigentuemer={propEigentuemer}
        verantwortlicher={propVerantwortlicher}
        bemerkung={propBemerkung}
        street={propStreet}
        streetNumber={propStreetNumber}
        zip={propZip}
        city={propCity}
        country={propCountry}
        region={propRegion}
        images={getPropertyImgs()}
        documents={getPropertyDocs()}
        lastAdaptedDate={calculateRecentPropModifications()}
        isRequiredName={true}
        isRequiredNummer={true}
        isRequiredBeschreibung={false}
        isRequiredEigentuemer={false}
        isRequiredVerantwortlicher={false}
        isRequiredBemerkung={false}
        isRequiredStreet={true}
        isRequiredStreetNumber={true}
        isRequiredZip={true}
        isRequiredCity={true}
        isRequiredCountry={true}
        isRequiredRegion={false}
        isErrorName={propEName}
        isErrorNummer={propENummer}
        isErrorBeschreibung={propEBeschreibung}
        isErrorEigentuemer={propEEigentuemer}
        isErrorVerantwortlicher={propEVerantwortlicher}
        isErrorBemerkung={propEBemerkung}
        isErrorStreet={propEStreet}
        isErrorStreetNumber={propEStreetNumber}
        isErrorZip={propEZip}
        isErrorCity={propECity}
        isErrorCountry={propECountry}
        isErrorRegion={propERegion}
        isAdaptedName={propAName}
        isAdaptedNummer={propANummer}
        isAdaptedBeschreibung={propABeschreibung}
        isAdaptedEigentuemer={propAEigentuemer}
        isAdaptedVerantwortlicher={propAVerantwortlicher}
        isAdaptedBemerkung={propABemerkung}
        isAdaptedStreet={propAStreet}
        isAdaptedStreetNumber={propAStreetNumber}
        isAdaptedZip={propAZip}
        isAdaptedCity={propACity}
        isAdaptedCountry={propACountry}
        isAdaptedRegion={propARegion}
        onChangeName={(e) => {
          setPropAName(true);
          setPropName(e.target.value);
        }}
        onChangeNummer={(e) => {
          setPropANummer(true);
          setPropNummer(e.target.value);
        }}
        onChangeBeschreibung={(e) => {
          setPropABeschreibung(true);
          setPropBeschreibung(e.target.value);
        }}
        onChangeEigentuemer={(e) => {
          setPropAEigentuemer(true);
          setPropEigentuemer(e.target.value);
        }}
        onChangeVerantwortlicher={(e) => {
          setPropAVerantwortlicher(true);
          setPropVerantwortlicher(e.target.value);
        }}
        onChangeBemerkung={(e) => {
          setPropABemerkung(true);
          setPropBemerkung(e.target.value);
        }}
        onChangeStreet={(e) => {
          setPropAStreet(true);
          setPropStreet(e.target.value);
        }}
        onChangeStreetNumber={(e) => {
          setPropAStreetNumber(true);
          setPropStreetNumber(e.target.value);
        }}
        onChangeZip={(e) => {
          setPropAZip(true);
          setPropZip(e.target.value);
        }}
        onChangeCity={(e) => {
          setPropACity(true);
          setPropCity(e.target.value);
        }}
        onChangeCountry={(e) => {
          setPropACountry(true);
          setPropCountry(e);
        }}
        onChangeRegion={(e) => {
          setPropARegion(true);
          setPropRegion(e);
        }}
        onImageClick={(imageId) => {
          setPropertyMode(true);
          clickImageCallback(imageId);
        }}
        onImageRemove={(imageId) => {
          setPropertyMode(true);
          setCurrImgId(imageId);
          setV_ImgDelDialog(true);
        }}
        onImageAdd={() => {
          setPropertyMode(true);
          execIfOnWeb(() => {
            setV_ImgDialog(true);
            setImgUploadError(false);
          });
          execIfOnAndroid(() => {
            setAV_ImgDialog(true);
            setAImgUploadError(false);
          });
        }}
        onDocumentClick={(documentId) => {
          setPropertyMode(true);
          downloadDocument(documentId);
        }}
        onDocumentRemove={(documentId) => {
          setPropertyMode(true);
          setCurrDocId(documentId);
          setV_DocDelDialog(true);
        }}
        onDocumentAdd={() => {
          setPropertyMode(true);
          setDocUploadError(false);
          setV_DocDialog(true);
        }}
        onSave={() => saveProperty()}
        onCancel={() => {
          //reset to default
          setPropImgs(null);
          setPropDocs(null);
          initPropertyAttributes(property);
          setReadOnlyProperty(true);
        }}
        onClickEdit={() => setReadOnlyProperty(false)}
      />
    );
  };

  const injectNotificationToast = () => {
    return (
      <BeeToast
        id={"bee-audit-liegenschaft-toast"}
        ref={toast}
        position={"top-right"}
      />
    );
  };

  const injectHeader = () => {
    return (
      <BeeAuditHeader
        disabled={false}
        homeUrl={breadCrumbHome}
        items={createBreadCrumbLiegenschaftSetup(liegenschaftId === null)}
        type={"dark"}
        maxProgress={null}
        progress={null}
        warningMessage={null}
        showSave={false}
        showLogout={true}
        showProgress={false}
        showLeftRight={false}
        onBackPressed={null}
        onForwardPressed={null}
        onSave={null}
        onLogout={() => setV_LogoutDialog(true)}
      />
    );
  };

  const injectLogoutConfirmation = () => {
    return (
      <BeeConfirmDialog
        visible={v_LogoutDialog}
        message={
          "Möchten Sie sich wirklich ausloggen? Ungespeicherte Änderungen werden in diesem Fall verworfen!"
        }
        header={"Logout bestätigen"}
        type={"default"}
        onAccept={() => {
          props.logoutUser();
          setV_LogoutDialog(false);
        }}
        onReject={() => setV_LogoutDialog(false)}
        onHide={() => setV_LogoutDialog(false)}
        acceptLabel={"Ausloggen"}
        rejectLabel={"Abbrechen"}
      />
    );
  };

  const setupBuildingTabView = (buildings) => {
    const data = [];
    //add all buidings as tabs
    if (buildings && buildings.length > 0) {
      for (let i = 0; i < buildings.length; i++) {
        const title = buildings[i].name;
        const number = buildings[i].nummer;
        data.push({ title: "[" + number + "] - " + title, disabled: false });
      }
    }
    if (addBuildingActive) {
      data.push({ title: "Neues Gebäude", disabled: false });
    }
    //add (disabled) adding-button
    data.push({
      title: <i className="pi pi-plus" />,
      disabled: addBuildingActive,
    });
    return data;
  };

  const injectGebaeudeData = () => {
    const tabs = setupBuildingTabView(allBuildings);
    return (
      <>
        <div className={"bee-online-ls-gebaeude-headline"}>
          <BeeContentHeadline
            label={"Gebäude"}
            headline={"h1"}
            type={"primary"}
          />
        </div>
        <div className={"bee-online-ls-gebaeude-tabview"}>
          <BeeTabview
            type={"primary"}
            tabs={tabs}
            activeIndex={activeIndex}
            onChangeIndex={(index) => {
              if (readOnlyBuilding) {
                if (index === tabs.length - 1) {
                  //add view!!!
                  setActiveIndex(tabs.length - 1);
                  setSelectedBuilding(null);
                  initBuildingAttributes(null);
                  setAddBuildingActive(true);
                  setReadOnlyBuilding(false);
                } else {
                  //show view
                  initBuildingAttributes(allBuildings[index]);
                  setSelectedBuilding(allBuildings[index]);
                  setActiveIndex(index);
                  setReadOnlyBuilding(true);
                }
                //recreate image lookups
                setBImgs(null);
                setBDocs(null);
                setForceRerenderMix(!forceRerenderMix);
              } else {
                //prompt the user to store first
                //FIXME show error to user
                toast.current.show({
                  severity: "info",
                  summary: "Ungespeicherte Änderungen",
                  detail: "Bitte verlassen Sie zunächst den Bearbeitungsmodus.",
                  sticky: false,
                  closable: true,
                  life: DURATION_NOTIFICATION_INFO,
                });
              }
            }}
          />
        </div>
        <BeeGebaeudeData
          id={"bee-audit-gebaeude-data"}
          showEditButton={true}
          readOnly={readOnlyBuilding}
          forceRerenderMixToggle={forceRerenderMix}
          activateHover={runsOnWeb()}
          showProgress={allBuildingsPending}
          errorMsg={allBuildingsError}
          name={bName}
          nummer={bNummer}
          beschreibung={bBeschreibung}
          eigentuemer={bEigentuemer}
          verantwortlicher={bVerantwortlicher}
          bemerkung={bBemerkung}
          allCountries={countries}
          street={bStreet}
          streetNumber={bStreetNumber}
          zip={bZip}
          city={bCity}
          country={bCountry}
          region={bRegion}
          images={getBuildingImgs()}
          documents={getBuildingDocs()}
          lastAdaptedDate={calculateRecentBuildModifications()}
          currency={"EUR"}
          locale={"de-DE"}
          baujahr={bBaujahr}
          bruttogeschossflaeche={bBruttogeschossflaeche}
          nettogeschossflaeche={bNettogeschossflaeche}
          aussenanlageflaeche={bAussenanlageflaeche}
          mietflaeche={bMietflaeche}
          stellplaetze={bStellplaetze}
          geschosseUeberGrund={bGeschosseUeberGrund}
          geschosseUnterGrund={bGeschosseUnterGrund}
          anzahlTreppenhaeuser={bAnzahlTreppenhaeuser}
          anzahlBauteile={bAnzahlBauteile}
          anzahlAufzuege={bAnzahlAufzuege}
          anzahlNutzerPersonen={bAnzahlNutzerPersonen}
          jahresmieteinnahmen={bJahresmieteinnahmen}
          nettoFmKosten={bNettoFmKosten}
          denkmalschutz={bDenkmalschutz}
          tiefgarage={bTiefgarage}
          fassadenbefahranlage={bFassadenbefahranlage}
          baualtersstufe={bBaualtersstufe}
          ausstattungsgrad={bAusstattungsgrad}
          altersstufeOptions={allBaualtersstufen}
          ausstattungsOptions={allAusstattungsgrade}
          aOffice={bAOffice}
          aRetail={bARetail}
          aWohnen={bAWohnen}
          aLogistik={bALogistik}
          aSonstige={bASonstige}
          isRequiredName={true}
          isRequiredNummer={true}
          isRequiredBeschreibung={false}
          isRequiredEigentuemer={false}
          isRequiredVerantwortlicher={false}
          isRequiredBemerkung={false}
          isRequiredStreet={true}
          isRequiredStreetNumber={true}
          isRequiredZip={true}
          isRequiredCity={true}
          isRequiredCountry={true}
          isRequiredRegion={true}
          isRequiredBaujahr={false}
          isRequiredBruttogeschossflaeche={false}
          isRequiredNettogeschossflaeche={false}
          isRequiredAussenanlageflaeche={false}
          isRequiredMietflaeche={false}
          isRequiredStellplaetze={false}
          isRequiredGeschosseUeberGrund={false}
          isRequiredGeschosseUnterGrund={false}
          isRequiredAnzahlTreppenhaeuser={false}
          isRequiredAnzahlBauteile={false}
          isRequiredAnzahlAufzuege={false}
          isRequiredAnzahlNutzerPersonen={false}
          isRequiredJahresmieteinnahmen={false}
          isRequiredNettoFmKosten={false}
          isRequiredDenkmalschutz={false}
          isRequiredTiefgarage={false}
          isRequiredFassadenbefahranlage={false}
          isRequiredBaualtersstufe={false}
          isRequiredAusstattungsgrad={false}
          isRequiredNutzungsartmix={false}
          isAdaptedName={bAName}
          isAdaptedNummer={bANummer}
          isAdaptedBeschreibung={bABeschreibung}
          isAdaptedEigentuemer={bAEigentuemer}
          isAdaptedVerantwortlicher={bAVerantwortlicher}
          isAdaptedBemerkung={bABemerkung}
          isAdaptedStreet={bAStreet}
          isAdaptedStreetNumber={bAStreetNumber}
          isAdaptedZip={bAZip}
          isAdaptedCity={bACity}
          isAdaptedCountry={bACountry}
          isAdaptedRegion={bARegion}
          isAdaptedBaujahr={bABaujahr}
          isAdaptedBruttogeschossflaeche={bABruttogeschossflaeche}
          isAdaptedNettogeschossflaeche={bANettogeschossflaeche}
          isAdaptedAussenanlageflaeche={bAAussenanlageflaeche}
          isAdaptedMietflaeche={bAMietflaeche}
          isAdaptedStellplaetze={bAStellplaetze}
          isAdaptedGeschosseUeberGrund={bAGeschosseUeberGrund}
          isAdaptedGeschosseUnterGrund={bAGeschosseUnterGrund}
          isAdaptedAnzahlTreppenhaeuser={bAAnzahlTreppenhaeuser}
          isAdaptedAnzahlBauteile={bAAnzahlBauteile}
          isAdaptedAnzahlAufzuege={bAAnzahlAufzuege}
          isAdaptedAnzahlNutzerPersonen={bAAnzahlNutzerPersonen}
          isAdaptedJahresmieteinnahmen={bAJahresmieteinnahmen}
          isAdaptedNettoFmKosten={bANettoFmKosten}
          isAdaptedDenkmalschutz={bADenkmalschutz}
          isAdaptedTiefgarage={bATiefgarage}
          isAdaptedFassadenbefahranlage={bAFassadenbefahranlage}
          isAdaptedBaualtersstufe={bABaualtersstufe}
          isAdaptedAusstattungsgrad={bAAusstattungsgrad}
          isErrorName={bEName}
          isErrorNummer={bENummer}
          isErrorBeschreibung={bEBeschreibung}
          isErrorEigentuemer={bEEigentuemer}
          isErrorVerantwortlicher={bEVerantwortlicher}
          isErrorBemerkung={bEBemerkung}
          isErrorStreet={bEStreet}
          isErrorStreetNumber={bEStreetNumber}
          isErrorZip={bEZip}
          isErrorCity={bECity}
          isErrorCountry={bECountry}
          isErrorRegion={bERegion}
          isErrorBaujahr={bEBaujahr}
          isErrorBruttogeschossflaeche={bEBruttogeschossflaeche}
          isErrorNettogeschossflaeche={bENettogeschossflaeche}
          isErrorAussenanlageflaeche={bEAussenanlageflaeche}
          isErrorMietflaeche={bEMietflaeche}
          isErrorStellplaetze={bEStellplaetze}
          isErrorGeschosseUeberGrund={bEGeschosseUeberGrund}
          isErrorGeschosseUnterGrund={bEGeschosseUnterGrund}
          isErrorAnzahlTreppenhaeuser={bEAnzahlTreppenhaeuser}
          isErrorAnzahlBauteile={bEAnzahlBauteile}
          isErrorAnzahlAufzuege={bEAnzahlAufzuege}
          isErrorAnzahlNutzerPersonen={bEAnzahlNutzerPersonen}
          isErrorJahresmieteinnahmen={bEJahresmieteinnahmen}
          isErrorNettoFmKosten={bENettoFmKosten}
          isErrorDenkmalschutz={bEDenkmalschutz}
          isErrorTiefgarage={bETiefgarage}
          isErrorFassadenbefahranlage={bEFassadenbefahranlage}
          isErrorBaualtersstufe={bEBaualtersstufe}
          isErrorAusstattungsgrad={bEAusstattungsgrad}
          onChangeName={(e) => {
            setBAName(true);
            setBName(e.target.value);
          }}
          onChangeNummer={(e) => {
            setBANummer(true);
            setBNummer(e.target.value);
          }}
          onChangeBeschreibung={(e) => {
            setBABeschreibung(true);
            setBBeschreibung(e.target.value);
          }}
          onChangeEigentuemer={(e) => {
            setBAEigentuemer(true);
            setBEigentuemer(e.target.value);
          }}
          onChangeVerantwortlicher={(e) => {
            setBAVerantwortlicher(true);
            setBVerantwortlicher(e.target.value);
          }}
          onChangeBemerkung={(e) => {
            setBABemerkung(true);
            setBBemerkung(e.target.value);
          }}
          onChangeStreet={(e) => {
            setBAStreet(true);
            setBStreet(e.target.value);
          }}
          onChangeStreetNumber={(e) => {
            setBAStreetNumber(true);
            setBStreetNumber(e.target.value);
          }}
          onChangeZip={(e) => {
            setBAZip(true);
            setBZip(e.target.value);
          }}
          onChangeCity={(e) => {
            setBACity(true);
            setBCity(e.target.value);
          }}
          onChangeCountry={(e) => {
            setBACountry(true);
            setBCountry(e);
          }}
          onChangeRegion={(e) => {
            setBARegion(true);
            setBRegion(e);
          }}
          onChangeBaujahr={(e) => {
            setBABaujahr(true);
            setBBaujahr(e.target.value);
          }}
          onChangeBruttogeschossflaeche={(e) => {
            setBABruttogeschossflaeche(true);
            setBBruttogeschossflaeche(e.target.value);
          }}
          onChangeNettogeschossflaeche={(e) => {
            setBANettogeschossflaeche(true);
            setBNettogeschossflaeche(e.target.value);
          }}
          onChangeAussenanlageflaeche={(e) => {
            setBAAussenanlageflaeche(true);
            setBAussenanlageflaeche(e.target.value);
          }}
          onChangeMietflaeche={(e) => {
            setBAMietflaeche(true);
            setBMietflaeche(e.target.value);
          }}
          onChangeStellplaetze={(e) => {
            setBAStellplaetze(true);
            setBStellplaetze(e.target.value);
          }}
          onChangeGeschosseUeberGrund={(e) => {
            setBAGeschosseUeberGrund(true);
            setBGeschosseUeberGrund(e.target.value);
          }}
          onChangeGeschosseUnterGrund={(e) => {
            setBAGeschosseUnterGrund(true);
            setBGeschosseUnterGrund(e.target.value);
          }}
          onChangeAnzahlTreppenhaeuser={(e) => {
            setBAAnzahlTreppenhaeuser(true);
            setBAnzahlTreppenhaeuser(e.target.value);
          }}
          onChangeAnzahlBauteile={(e) => {
            setBAAnzahlBauteile(true);
            setBAnzahlBauteile(e.target.value);
          }}
          onChangeAnzahlAufzuege={(e) => {
            setBAAnzahlAufzuege(true);
            setBAnzahlAufzuege(e.target.value);
          }}
          onChangeAnzahlNutzerPersonen={(e) => {
            setBAAnzahlNutzerPersonen(true);
            setBAnzahlNutzerPersonen(e.target.value);
          }}
          onChangeJahresmieteinnahmen={(e) => {
            setBAJahresmieteinnahmen(true);
            setBJahresmieteinnahmen(e.target.value);
          }}
          onChangeNettoFmKosten={(e) => {
            setBANettoFmKosten(true);
            setBNettoFmKosten(e.target.value);
          }}
          onChangeDenkmalschutz={(e) => {
            setBADenkmalschutz(true);
            setBDenkmalschutz(e.target.value);
          }}
          onChangeTiefgarage={(e) => {
            setBATiefgarage(true);
            setBTiefgarage(e.target.value);
          }}
          onChangeFassadenbefahranlage={(e) => {
            setBAFassadenbefahranlage(true);
            setBFassadenbefahranlage(e.target.value);
          }}
          onChangeBaualtersstufe={(e) => {
            setBABaualtersstufe(true);
            setBBaualtersstufe(e);
          }}
          onChangeAusstattungsgrad={(e) => {
            setBAAusstattungsgrad(true);
            setBAusstattungsgrad(e);
          }}
          onChangeNutzungsartmix={(vals) => {
            setBAOffice(vals.anteilOffice);
            setBARetail(vals.anteilRetail);
            setBAWohnen(vals.anteilWohnen);
            setBALogistik(vals.anteilLogistik);
            setBASonstige(vals.anteilSonstige);
          }}
          onImageClick={(imageId) => {
            setPropertyMode(false);
            clickImageCallback(imageId);
          }}
          onImageRemove={(imageId) => {
            setPropertyMode(false);
            setCurrImgId(imageId);
            setV_ImgDelDialog(true);
          }}
          onImageAdd={() => {
            setPropertyMode(false);
            execIfOnWeb(() => {
              setV_ImgDialog(true);
              setImgUploadError(false);
            });
            execIfOnAndroid(() => {
              setAV_ImgDialog(true);
              setAImgUploadError(false);
            });
          }}
          onDocumentClick={(documentId) => {
            setPropertyMode(false);
            downloadDocument(documentId);
          }}
          onDocumentRemove={(documentId) => {
            setPropertyMode(false);
            setCurrDocId(documentId);
            setV_DocDelDialog(true);
          }}
          onDocumentAdd={() => {
            setPropertyMode(false);
            setDocUploadError(false);
            setV_DocDialog(true);
          }}
          onSave={() => {
            saveBuilding();
          }}
          onCancel={() => {
            if (addBuildingActive) {
              setActiveIndex(0);
              initBuildingAttributes(allBuildings ? allBuildings[0] : null);
              setSelectedBuilding(allBuildings ? allBuildings[0] : null);
              setAddBuildingActive(false);
              //if no buildings, then show button
              if (!(allBuildings && allBuildings.length > 0)) {
                setShowBuildings(false);
              }
            } else {
              initBuildingAttributes(selectedBuilding);
            }
            //reset to default
            setReadOnlyBuilding(true);
            setBImgs(null);
            setBDocs(null);
          }}
          onClickEdit={() => {
            setReadOnlyBuilding(false);
          }}
        />
      </>
    );
  };

  const injectPropertyImageGallery = () => {
    return (
      <BeeImageSlider
        className={"bee-img-slider"}
        type={"primary"}
        ref={propertyGallery}
        images={propImgs ? propImgs : []}
        activeIndex={0}
      />
    );
  };

  const injectBuildingImageGallery = () => {
    return (
      <BeeImageSlider
        className={"bee-img-slider"}
        type={"primary"}
        ref={buildingGallery}
        images={bImgs ? bImgs : []}
        activeIndex={0}
      />
    );
  };

  const injectImageUploadDialog = () => {
    return (
      <BeeFileUploadDialog
        id={"bee-audit-liegenschaft-detail-image"}
        header={"Bild hochladen"}
        visible={v_ImgDialog}
        showProgress={imgUploadProg}
        errorMsg={imgUploadError}
        type={"primary"}
        validFileFormats={ACCEPTED_IMAGE_FORMATS}
        onHide={() => setV_ImgDialog(false)}
        onUpload={(data) => createNewImage(data, false)}
      />
    );
  };

  const injectImageDeletionConfirmationDialog = () => {
    return (
      <BeeDeletionDialog
        id={"bee-audit-ui-image-deletion"}
        visible={v_ImgDelDialog}
        message={
          "Möchten Sie " +
          convertImageIdToTitle(currImgId) +
          " wirklich löschen?"
        }
        acceptLabel={"Löschen"}
        rejectLabel={"Abbrechen"}
        header={"Bild löschen"}
        type={"primary"}
        onAccept={() => {
          if (currImgId) {
            deleteImage(currImgId);
          }
          setV_ImgDelDialog(false);
        }}
        onReject={() => setV_ImgDelDialog(false)}
        onHide={() => setV_ImgDelDialog(false)}
      />
    );
  };

  const injectAndroidCameraDialog = () => {
    return (
      <BeeAndroidCameraDialog
        id={"bee-audit-ui-document-upload"}
        header={"Bild hochladen"}
        visible={v_AImgDialog}
        showProgress={aImgUploadProg}
        errorMsg={aImgUploadError}
        type={"primary"}
        img_quality={IMG_ANDROID_QUALITY}
        img_width={IMG_ANDROID_WIDTH}
        img_height={IMG_ANDROID_HEIGHT}
        img_preserveAspectRatio={IMG_ANDROID_PRESERVE_ASPECT_RATIO}
        onHide={() => setAV_ImgDialog(false)}
        onUpload={(data) => {
          createNewImage(data, true);
        }}
      />
    );
  };

  const injectFileUploadDialog = () => {
    return (
      <BeeFileUploadDialog
        id={"bee-audit-ui-document-upload"}
        header={"Dokument hochladen"}
        visible={v_DocDialog}
        showProgress={docUploadProg}
        errorMsg={docUploadError}
        type={"primary"}
        validFileFormats={ACCEPTED_FILE_FORMATS}
        onHide={() => setV_DocDialog(false)}
        onUpload={(data) => createNewDocument(data)}
      />
    );
  };

  const injectFileDeletionConfirmationDialog = () => {
    return (
      <BeeDeletionDialog
        id={"bee-audit-ui-audit-confirm-document-deletion"}
        visible={v_DocDelDialog}
        message={
          "Möchten Sie " +
          convertDocumentIdToTitle(currDocId) +
          " wirklich löschen?"
        }
        acceptLabel={"Löschen"}
        rejectLabel={"Abbrechen"}
        header={"Dokument löschen"}
        type={"primary"}
        onAccept={() => {
          if (currDocId) {
            deleteDocument(currDocId);
          }
          setV_DocDelDialog(false);
        }}
        onReject={() => setV_DocDelDialog(false)}
        onHide={() => setV_DocDelDialog(false)}
      />
    );
  };

  const injectShowBuildingButton = () => {
    if (property) {
      return (
        <div className="online-ls-detail-show-buildings-btn">
          <BeeOutlinedButton
            id={"bee-audit-liegenschaft-show-building-btn"}
            label={"Gebäude zu dieser Liegenschaft hinzufügen"}
            disabled={false}
            type={"primary"}
            onClick={() => {
              //change view
              setShowBuildings(true);
              //imitate click on "add Building"
              setActiveIndex(0);
              setSelectedBuilding(null);
              initBuildingAttributes(null);
              setReadOnlyBuilding(false);
              setAddBuildingActive(true);
            }}
          />
        </div>
      );
    }
  };

  const injectBuildingBlock = () => {
    if (
      property &&
      !propertyPending &&
      !propertyError &&
      !allBuildingsPending &&
      !allBuildingsError
    ) {
      return showBuildings ? injectGebaeudeData() : injectShowBuildingButton();
    }
    return null;
  };

  return (
    <div className="online-liegenschaft-detail">
      {injectHeader()}
      {injectLiegenschaftData()}
      {injectBuildingBlock()}
      {/* hidden dialogs & toasts */}
      {injectNotificationToast()}
      {injectLogoutConfirmation()}
      {v_ImgDialog ? injectImageUploadDialog() : null}
      {injectImageDeletionConfirmationDialog()}
      {v_AImgDialog ? injectAndroidCameraDialog() : null}
      {v_DocDialog ? injectFileUploadDialog() : null}
      {injectFileDeletionConfirmationDialog()}
      {/* gallery */}
      {injectPropertyImageGallery()}
      {injectBuildingImageGallery()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    // onlineAccessPossible: state.app.onlineAccessPossible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineLiegenschaftDetail);
