import React, { useState } from "react";
import { useId } from "react-id-generator";
import {
  BeeButton,
  BeeContentHeadline,
  BeeContentParagraph,
  BeeDeletionDialog,
} from "bee-atomic-components";
import _ from "lodash";
import "./BeeAuditLv.scss";
import BeeAuditHeader from "../Fragments/BeeAuditHeader";
import BeeAuditTabs from "../Fragments/BeeAuditTabs";
import BeeAuditLvPositionDialog from "../Dialogs/BeeAuditLvPositionDialog";

import { calculateGP } from "../../Helper/LvHelper";
import BeeAuditLvTreetable from "./BeeAuditLvTreetable";

export function BeeAuditLv({
  homeUrl,
  breadcrumbData,
  overallProgress,
  maxOverallProgress,
  warningMessage,
  viewOptions,
  selectedView,
  lvdata,
  meanReduction,
  sumGP,
  sumGPadapted,
  qualitySumGPadapted,
  quantitySumGPadapted,
  readOnly,
  username,
  currency,
  locale,
  showSave,
  showLogout,
  showReport,
  onSave,
  onLogout,
  onSelectView,
  onEntryChange,
  onEntryAdd,
  onEntryRemove,
  onEntryRestore,
  onClickAuffalligkeiten,
  onCreateExcelReport,
}) {
  const currentId = useId(1, "bee-audit-lv-")[0];

  //state
  const [lvCategoryTree, setLvCategoryTree] = useState();
  const [vDeletionDialog, setVDeletionDialog] = useState(false);
  const [vCreationDialog, setVCreationDialog] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [entryToDelete, setEntryToDelete] = useState();
  const [catIdToCreate, setCatIdToCreate] = useState();

  const [pNumber, setPNumber] = useState("");
  const [pTitle, setPTitle] = useState("");
  const [pAmount, setPAmount] = useState(null);
  const [pUnit, setPUnit] = useState("");
  const [pEp, setPEp] = useState(null);
  const [pIsOptionalPosition, setPIsOptionalPosition] = useState(false);
  const [pIsOptionalPositionActivated, setPIsOptionalPositionActivated] =
    useState(false);
  const [pANumber, setPANumber] = useState(false);
  const [pATitle, setPATitle] = useState(false);
  const [pAAmount, setPAAmount] = useState(false);
  const [pAUnit, setPAUnit] = useState(false);
  const [pAEp, setPAEp] = useState(false);
  const [pAIsOptionalPosition, setPAIsOptionalPosition] = useState(false);
  const [pAIsOptionalPositionActivated, setPAIsOptionalPositionActivated] =
    useState(false);
  const [pENumber, setPENumber] = useState(false);
  const [pETitle, setPETitle] = useState(false);
  const [pEAmount, setPEAmount] = useState(false);
  const [pEUnit, setPEUnit] = useState(false);
  const [pEEp, setPEEp] = useState(false);

  //classNames
  const classNames = "bee-audit-lv-overview";
  const contentClassNames = "m-4 p-2 ";

  function selectView(selection) {
    if (onSelectView && selection) {
      onSelectView(selection);
    }
  }

  function logoutCallback(e) {
    if (onLogout) {
      onLogout(e);
    }
  }

  function saveCallback(e) {
    if (onSave) {
      onSave(e);
    }
  }

  function clickAuffalligkeiten(id) {
    if (!readOnly && onClickAuffalligkeiten) {
      onClickAuffalligkeiten(id);
    }
  }

  function createExcelReport() {
    if (!readOnly && showReport && onCreateExcelReport) {
      onCreateExcelReport();
    }
  }

  function addPositionToCategory() {
    if (validateCreation()) {
      if (!readOnly && onEntryAdd) {
        //FIXME rework
        const entry = {
          key: pNumber,
          data: {
            type: "pos",
            number: pNumber,
            title: pTitle,
            unit: null,
            amount: 0,
            ep: 0,
            gp: 0,
            optionalPosition: pIsOptionalPosition,
            optionalPositionActivated: false,
            adaptedOptionalPositionActivated: pIsOptionalPositionActivated,
            supplementPosition: false,
            showRemove: true,
            reductionPercentage: 0,
            adaptedGp: calculateGP(pAmount, 100, pEp),
            fulfillment: "UGP",
            comment: null,
            adaptedUnit: pUnit,
            adaptedAmount: pAmount,
            adaptedEp: pEp,
            deactivated: false,
            newPosition: true,
          },
          className: "lv-depth-pos-" + countPNumberDots(pNumber),
        };
        setVCreationDialog(false);
        onEntryAdd(catIdToCreate, entry);
        setCatIdToCreate(null);
      }
      clearCreationFields();
    }
  }

  function countPNumberDots(number) {
    if (number) {
      return _.countBy(number)["."];
    }
  }

  function removePositionFromCategory(id) {
    if (!readOnly && onEntryRemove) {
      onEntryRemove(id);
    }
    setIdToDelete(null);
    setEntryToDelete(null);
  }

  function changeEntry(id, payload) {
    if (!readOnly && id && payload && onEntryChange) {
      onEntryChange(id, payload);
    }
  }

  function restoreEntry(id) {
    if (!readOnly && id && onEntryRestore) {
      onEntryRestore(id);
    }
  }

  const injectHeader = () => {
    return (
      <BeeAuditHeader
        homeUrl={homeUrl}
        items={breadcrumbData}
        type={"dark"}
        warningMessage={warningMessage}
        maxProgress={maxOverallProgress}
        progress={overallProgress}
        showSave={showSave}
        showLogout={showLogout}
        showProgress={true}
        showLeftRight={false}
        onSave={(e) => saveCallback(e)}
        onLogout={(e) => logoutCallback(e)}
      />
    );
  };

  const injectTabs = () => {
    return (
      <BeeAuditTabs
        options={viewOptions}
        selectedOption={selectedView}
        disabled={false}
        type={"primary"}
        onSelect={(option) => selectView(option)}
      />
    );
  };

  const injectReportButton = () => {
    return (
      <div className={"bee-audit-lv-overview-report-btn"}>
        <BeeButton
          label={"LV-Report erzeugen"}
          disabled={!showReport}
          type={"primary"}
          isLoading={false}
          onClick={() => createExcelReport()}
        />
      </div>
    );
  };

  function calcDeletionMessage() {
    const num = entryToDelete ? entryToDelete.number : "";
    const tit = entryToDelete ? entryToDelete.title : "";
    return (
      "Soll die Position '(" +
      num +
      ") - " +
      tit +
      "' wirklich gelöscht werden?"
    );
  }

  const injectDeletionDialog = () => {
    return (
      <BeeDeletionDialog
        visible={vDeletionDialog}
        message={calcDeletionMessage()}
        messageIcon={null}
        acceptLabel={"Löschen"}
        rejectLabel={"Abbrechen"}
        header={"LV-Position löschen"}
        type={"primary"}
        onAccept={() => {
          setVDeletionDialog(false);
          removePositionFromCategory(idToDelete);
        }}
        onReject={() => {
          setVDeletionDialog(false);
          setIdToDelete(null);
          setEntryToDelete(null);
        }}
        onHide={() => {
          setVDeletionDialog(false);
          setIdToDelete(null);
          setEntryToDelete(null);
        }}
      />
    );
  };

  function clearCreationFields() {
    setPNumber(null);
    setPTitle(null);
    setPAmount(null);
    setPUnit(null);
    setPEp(null);
    setPIsOptionalPosition(null);
    setPIsOptionalPositionActivated(null);
    setPANumber(false);
    setPATitle(false);
    setPAAmount(false);
    setPAUnit(false);
    setPAEp(false);
    setPAIsOptionalPosition(false);
    setPAIsOptionalPositionActivated(false);
    setPENumber(false);
    setPETitle(false);
    setPEAmount(false);
    setPEUnit(false);
    setPEEp(false);
  }

  function validateCreation() {
    //FIXME adapt, errorCodes?!
    let validNumber = false;
    if (pNumber) {
      validNumber = true;
    }
    let validTitle = false;
    if (pTitle) {
      validTitle = true;
    }
    let validAmount = false;
    if (pAmount && pAmount > 0) {
      validAmount = true;
    }
    let validUnit = false;
    if (pUnit) {
      validUnit = true;
    }
    let validEp = false;
    if (pEp && pEp > 0) {
      validEp = true;
    }
    setPENumber(!validNumber);
    setPETitle(!validTitle);
    setPEAmount(!validAmount);
    setPEUnit(!validUnit);
    setPEEp(!validEp);
    return validNumber && validTitle && validAmount && validUnit && validEp;
  }

  function getNextAvailablePositionNumber(catId) {
    let nextNumber = "000";
    if (lvdata) {
      //find current selected element
      let selection;
      const data = _.cloneDeep(lvdata);
      data.forEach(function f(element) {
        if (element.key === catId) {
          selection = element;
          return;
        } else if (element.children) {
          element.children.forEach(f);
        }
      });
      //parse next available number
      if (selection && selection.children) {
        let allNumbers = [];
        selection.children.forEach((element) => {
          if (element && element.data.type === "pos") {
            allNumbers.push(element.key);
          }
        });
        let lastNumber = _.sortBy(allNumbers, [
          (o) => {
            return o;
          },
        ]).pop();
        if (lastNumber) {
          const no = lastNumber.split(".").pop();
          let tmp = Math.floor(no);
          tmp += 1;
          let zeroStringLength = tmp <= 999 ? nextNumber.length : tmp.length;
          nextNumber = tmp.toString().padStart(zeroStringLength, "0");
        }
      }
    }
    setPNumber(catId + "." + nextNumber);
  }

  const injectCreationDialog = () => {
    return (
      <BeeAuditLvPositionDialog
        visible={vCreationDialog}
        readOnly={readOnly}
        currency={currency}
        locale={locale}
        categoryOptions={lvCategoryTree}
        selectedCategory={catIdToCreate}
        number={pNumber}
        title={pTitle}
        amount={pAmount}
        unit={pUnit}
        ep={pEp}
        optionalPosition={pIsOptionalPosition}
        optionalPositionActivated={pIsOptionalPositionActivated}
        isErrorNumber={pENumber}
        isErrorTitle={pETitle}
        isErrorAmount={pEAmount}
        isErrorUnit={pEUnit}
        isErrorEp={pEEp}
        isErrorOptionalPosition={false}
        isErrorOptionalPositionActivated={false}
        isAdaptedNumber={pANumber}
        isAdaptedTitle={pATitle}
        isAdaptedAmount={pAAmount}
        isAdaptedUnit={pAUnit}
        isAdaptedEp={pAEp}
        isAdaptedOptionalPosition={pAIsOptionalPosition}
        isAdaptedOptionalPositionActivated={pAIsOptionalPositionActivated}
        acceptLabel={"Erstellen"}
        rejectLabel={"Abbrechen"}
        header={"Neue Position anlegen"}
        type={"primary"}
        onAccept={() => addPositionToCategory()}
        onReject={() => {
          setVCreationDialog(false);
          setCatIdToCreate(null);
          clearCreationFields();
        }}
        onHide={() => {
          setVCreationDialog(false);
          setCatIdToCreate(null);
          clearCreationFields();
        }}
        onSelectCategory={(value) => {
          getNextAvailablePositionNumber(value);
          setCatIdToCreate(value);
        }}
        onChangeNumber={(value) => {
          setPANumber(true);
          setPNumber(value);
        }}
        onChangeTitle={(value) => {
          setPATitle(true);
          setPTitle(value);
        }}
        onChangeAmount={(value) => {
          setPAAmount(true);
          setPAmount(value);
        }}
        onChangeUnit={(value) => {
          setPAUnit(true);
          setPUnit(value);
        }}
        onChangeEp={(value) => {
          setPAEp(true);
          setPEp(value);
        }}
        onChangeIsOptionalPosition={(value) => {
          setPAIsOptionalPosition(true);
          setPIsOptionalPosition(value);
        }}
        onChangeIsOptionalPositionActivated={(value) => {
          setPAIsOptionalPositionActivated(true);
          setPIsOptionalPositionActivated(value);
        }}
      />
    );
  };

  function testIfCategoryTreeExists() {
    if (lvdata && !lvCategoryTree) {
      const data = _.cloneDeep(lvdata);
      let res = data.filter(function f(o) {
        if (o.children) {
          let childResults = o.children.filter(f);
          o.children = childResults;
        }
        if (o.data && o.data.type && o.data.type === "cat") {
          o.label = "(" + o.data.number + ") - " + o.data.title;
          return true;
        }
      });
      setLvCategoryTree(res);
    }
  }

  return (
    <div id={currentId} className={classNames}>
      {testIfCategoryTreeExists()}
      {injectHeader()}
      {injectTabs()}
      <div className={contentClassNames}>
        <BeeContentHeadline
          label={"Leistungsverzeichnis"}
          headline={"h2"}
          type={"primary"}
        />
        <BeeContentParagraph
          text={
            "Das Leistungsverzeichnis dient zur Beurteilung des Leistungserfüllungsgrads, möglicher Mengen- oder Preisanpassungen sowie der Deaktivierung oder dem hinzufügen einzelnen Leistungspositionen, damit der auditierte Leistungserfüllungsgrad exakt auf dem Leistungsumfang zum Auditzeitpunkt basiert. Etwaige Abweichungen zum Vertrags-LV werden somit dokumentiert und werden den Vertragsparteien berichtet („LV-Report“). Der Auditor hat die Möglichkeit nicht oder unzureichend erbrachte Leistungen nacherfüllen zu lassen. Bei der Nachprüfung kann die Beurteilung entsprechend korrigiert werden und ins Auditergebnis einfließen. "
          }
          size={"medium"}
          type={"primary"}
        />
        <BeeAuditLvTreetable
          lvdata={lvdata}
          readOnly={readOnly}
          username={username}
          sumMeanReduction={meanReduction}
          sumGP={sumGP}
          qualitySumGPadapted={qualitySumGPadapted}
          quantitySumGPadapted={quantitySumGPadapted}
          onClickAuffalligkeiten={(id) => clickAuffalligkeiten(id)}
          onAddPositionToCategory={(id) => {
            getNextAvailablePositionNumber(id);
            setCatIdToCreate(id);
            setVCreationDialog(true);
          }}
          onRemovePositionFromCategory={(id, entry) => {
            setIdToDelete(id);
            setEntryToDelete(entry);
            setVDeletionDialog(true);
          }}
          onEntryChange={(id, payload) => changeEntry(id, payload)}
          onRestorePosition={(id, payload) => restoreEntry(id)}
        />
        {showReport ? injectReportButton() : null}
        {vDeletionDialog ? injectDeletionDialog() : null}
        {vCreationDialog ? injectCreationDialog() : null}
        {/* implement remove dialogs, creation dialogs => just send callbacks to out */}
      </div>
    </div>
  );
}

export default BeeAuditLv;
