import React, { useState, useEffect, useRef } from "react";
import { useId } from "react-id-generator";
import { Dialog } from "primereact/dialog";
import {
  BeeValidatedTextInput,
  BeeValidatedTextArea,
  BeeValidatedDateInput,
  BeeInfoDialog,
  BeeDocumentTable,
  BeeFlatImageGallery,
  BeeButton,
  BeeLinkButton,
  BeeOutlinedButton,
  BeeContentHeadline,
  BeeValidatedDropDown,
  BeeValidatedTreeSelect,
  BeeContentParagraph,
  BeeChipArea,
  BeeToast,
  BeeDeletionDialog,
  BeeUploadDialog,
  BeeImageSlider,
  BeeAndroidCameraDialog,
} from "bee-atomic-components";
import {
  STATES_OF_AUFFALLIGKEIT,
  TYPE_MANGEL,
  TYPE_DEFIZIT,
  TYPE_HINWEIS,
  STATES_OF_AUFFALLIGKEIT_OPEN,
  CAUSES_DROPDOWN,
  TYPE_DROPDOWN,
  URL_TO_MEDIA_SERVER,
  ACCEPTED_FILE_FORMATS,
  ACCEPTED_IMAGE_FORMATS,
  DEFAULT_IMAGE_SIZE,
  IMG_ANDROID_HEIGHT,
  IMG_ANDROID_PRESERVE_ASPECT_RATIO,
  IMG_ANDROID_QUALITY,
  IMG_ANDROID_WIDTH,
} from "../../Helper/constants";
import {
  execIfOnAndroid,
  execIfOnWeb,
  openStoredMediaFileOnAndroid,
  runsOnWeb,
  storeOfflineMediaOnAndroidInProject,
  writeDocumentFromBlobOnAndroid,
} from "../../Helper/CapacitorHelper";
import {
  downloadDocumentFromServer,
  uploadDocumentToServer,
  uploadImageToServer,
} from "../../Helper/NetworkHelper";
import fileDownload from "js-file-download";
import { v4 as uuidv4 } from "uuid";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditDialogAuffalligkeiten.scss";
import _ from "lodash";
import { ConnectableObservable } from "rxjs";

export function BeeAuditDialogAuffalligkeiten({
  type,
  data,
  categoryTree,
  visible,
  readOnly,
  activateHover,
  //TODO: außen einbinden
  offlineMode,
  liegenschaftId,
  projectId,
  onAddDocumentToLookup,
  onAddImageToLookup,
  onSave,
  onCancel,
  onHide,
}) {
  const currentId = useId(1, "bee-audit-dialog-auffalligkeiten")[0];
  const [catTree, setCatTree] = useState();
  //state
  const [entryid, setId] = useState(data && data.id ? data.id : uuidv4());
  const [title, setTitle] = useState(data && data.title ? data.title : null);
  const [date, setDate] = useState(data && data.date ? data.date : new Date());
  const [category, setCategory] = useState(
    data && data.categoryId ? data.categoryId : null
  );
  const [state, setState] = useState(STATES_OF_AUFFALLIGKEIT_OPEN);
  const [auff_type, setAuffType] = useState(data ? data.typeValue : null);
  //cause bei start immer null weil causearray übergeben wird stattdessen bei start
  const [cause, setCause] = useState(null);
  const [causeArray, setCauseArray] = useState([]);
  const [causeDropDown, setCauseDropDown] = useState(CAUSES_DROPDOWN);
  const [description, setDescription] = useState(
    data && data.description ? data.description : null
  );
  const [images, setImages] = useState(data && data.images ? data.images : []);
  const [documents, setDocuments] = useState(
    data && data.documents ? data.documents : []
  );
  const [adaptedTitle, setAdaptedTitle] = useState(false);
  const [adaptedDate, setAdaptedDate] = useState(false);
  const [adaptedCategory, setAdaptedCategory] = useState(false);
  const [adaptedState, setAdaptedState] = useState(false);
  const [adaptedAuffType, setAdaptedAuffType] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [errorCategory, setErrorCategory] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorAuffType, setErrorAuffType] = useState(false);
  const [v_InfoDialog, setInfoDialog] = useState(false);
  const [infoId, setInfoId] = useState(null);
  //images
  const [currImgId, setCurrImgId] = useState(null);
  const [v_ImgDialog, setV_ImgDialog] = useState(false);
  const [v_ImgDelDialog, setV_ImgDelDialog] = useState(false);
  const [imgUploadProg, setImgUploadProg] = useState(false);
  const [imgUploadError, setImgUploadError] = useState();
  //documents
  const [currDocId, setCurrDocId] = useState(null);
  const [v_DocDialog, setV_DocDialog] = useState(false);
  const [v_DocDelDialog, setV_DocDelDialog] = useState(false);
  const [docUploadProg, setDocUploadProg] = useState(false);
  const [docUploadError, setDocUploadError] = useState();
  //android
  const [v_AImgDialog, setAV_ImgDialog] = useState(false);
  const [aImgUploadProg, setAImgUploadProg] = useState(false);
  const [aImgUploadError, setAImgUploadError] = useState(null);
  //refs
  const gallery = useRef(null);
  const toast = useRef(null);

  //classNames
  const typeClassNames =
    type === "primary"
      ? "bee-audit-dialog-auffalligk-primary"
      : type === "secondary"
      ? "bee-audit-dialog-auffalligk-secondary"
      : "bee-audit-dialog-auffalligk-primary";

  const classNames = "bee-audit-dialog-auffalligk " + typeClassNames;
  const imgColClassNames = "bee-audit-dialog-auffalligk-imgs";
  const docColClassNames = "bee-audit-dialog-auffalligk-docs";
  const typeColClassNames = "bee-audit-dialog-auffalligk-type";
  const btnRowClassNames = "bee-audit-dialog-auffalligk-btns";

  //label
  const headerLabel = "Auffälligkeiten";
  const title_label = "Titel";
  const title_error_msg = "Bitte Titel angeben.";
  const date_label = "Datum";
  const date_error_msg = "Bitte Datum auswählen.";
  const category_label = "Kategorie";
  const category_error_msg = "Bitte Kategorie auswählen.";
  const state_label = "Status";
  const state_error_msg = "Bitte Status auswählen.";
  const auff_type_label = "Typ";
  const auff_type_error_msg = "Bitte Typ auswählen.";
  const cause_label = "Ursachen auswählen";
  const description_label = "Beschreibung";
  const imgHeadline = "Fotos";
  const docHeadline = "Dokumente";
  const rejectLabel = "Abbrechen";
  const acceptLabel = "Hinzufügen";
  const readOnlyRejectLabel = "Schließen";
  const addfileBtnLabel = "Dokument hinzufügen";

  useEffect(() => {
    if (data) {
      //setup causes
      if (data.causeArray) {
        const causes = _.cloneDeep(CAUSES_DROPDOWN);
        const cleanedCauseArray = [];
        causes.forEach((cause) => {
          data.causeArray.forEach((causeId) => {
            if (cause.value === causeId) {
              cleanedCauseArray.push({
                label: cause.label,
                id: cause.value,
                removeable: true,
              });
            }
          });
        });
        setCauseArray(cleanedCauseArray);
        filterCauseDropdown(cleanedCauseArray);
      } else {
        filterCauseDropdown([]);
      }
      //setup state
      if (data.stateId) {
        const state = _.find(STATES_OF_AUFFALLIGKEIT, function (s) {
          return s.id === data.stateId;
        });
        setState(state ? state : STATES_OF_AUFFALLIGKEIT_OPEN);
      }
    }
  }, []);

  ///////////////////////
  // OUTSIDE CALLBACKS //
  ///////////////////////

  function addDocumentToLookup(document) {
    if (onAddDocumentToLookup) {
      onAddDocumentToLookup(document);
    }
  }

  function addImageToLookup(image) {
    if (onAddImageToLookup) {
      onAddImageToLookup(image);
    }
  }

  function clickImageCallback(imageId) {
    if (gallery && gallery.current) {
      for (let i = 0; i < images.length; i++) {
        if (images[i].id === imageId) {
          gallery.current.showImage(i);
          break;
        }
      }
      gallery.current.openGallery();
    }
  }

  function removeImageCallback(imageId) {
    setCurrImgId(imageId);
    setV_ImgDelDialog(true);
  }

  function addImageCallback(e) {
    execIfOnWeb(() => {
      setV_ImgDialog(true);
    });
    execIfOnAndroid(() => {
      setAV_ImgDialog(true);
    });
  }

  function deleteDocumentCallback(documentId) {
    setCurrDocId(documentId);
    setV_DocDelDialog(true);
  }

  function addDocumentCallback(e) {
    setV_DocDialog(true);
  }

  function deleteDocument(entryId) {
    //remove document from current documents

    let newDocuments = documents;
    newDocuments = documents.filter((item) => {
      return item.id !== entryId;
    });
    setDocuments(newDocuments);
  }

  function deleteImage(entryId) {
    //remove image from current auffalligkeit

    let newImages = images ? images : [];
    newImages = images.filter((item) => {
      return item.id !== entryId;
    });
    setImages(newImages);
  }

  function createNewDocument(data) {
    if (data) {
      setDocUploadProg(true);
      setDocUploadError(null);
      if (offlineMode) {
        //store for offline usage
        storeOfflineMediaOnAndroidInProject(
          liegenschaftId,
          projectId,
          data.file,
          data.file.name,
          function success(payload) {
            const newDocuments = documents ? documents : [];
            //add created entry to list
            const entry = {
              id: payload.id,
              title: data.name,
              filename: data.file.name,
              fileSrc: payload.url,
              timestamp: new Date(),
              size: data.file.size,
              type: data.file.type,
              fileKey: null,
              copyright: null,
              secured: false,
              mime: data.file.type,
            };
            newDocuments.push(entry);

            setDocuments(newDocuments);

            //add entry to documentlookup
            addDocumentToLookup(entry);
            setDocUploadProg(false);
            setV_DocDialog(false);
          },
          function error(error) {
            setDocUploadProg(false);
            setDocUploadError("Speicherung ist leider fehlgeschlagen.");
          }
        );
      } else {
        uploadDocumentToServer(data.file, data.name, data.originalFileName)
          .then((entry) => {
            if (entry) {
              const newDocuments = documents ? documents : [];
              newDocuments.push({
                id: entry.id,
                title: entry.title,
                filename: entry.filename,
                fileSrc: URL_TO_MEDIA_SERVER + entry.url,
                timestamp: new Date(entry.createdAt),
                size: entry.fileSize,
                type: entry.type,
                copyright: entry.copyright,
                secured: entry.access ? true : false,
                fileKey: entry.access,
              });
              setDocuments(newDocuments);

              //add entry to documentlookup
              addDocumentToLookup(entry);
            }
            setDocUploadProg(false);
            setV_DocDialog(false);
          })
          .catch((error) => {
            setDocUploadProg(false);
            setDocUploadError("Upload ist leider fehlgeschlagen.");
          });
      }
    }
  }

  function createNewImage(data, androidVersion) {
    if (data) {
      if (androidVersion) {
        setAImgUploadProg(true);
        setAImgUploadError(null);
      } else {
        setImgUploadProg(true);
        setImgUploadError(null);
      }
      if (offlineMode) {
        //store for offline usage
        storeOfflineMediaOnAndroidInProject(
          liegenschaftId,
          projectId,
          data.file,
          "IMG_" + uuidv4() + ".jpeg",
          function success(payload) {
            if (payload) {
              const newImages = images ? images : [];
              newImages.push({
                id: payload.id,
                url: payload.url,
                key: null,
                alt: null,
                title: data.name,
                copyright: null,
                secured: false,
                showPlaceholder: false,
              });

              //call adaption this stored entry
              setImages(newImages);
              //add entry to imageLookup
              const imageForStore = {
                id: payload.id,
                title: data.name,
                filename: data.file.name,
                fileSize: data.file.size,
                copyright: null,
                createdAt: new Date(),
                createdFromUser: null,
                orientation: "VERTICAL",
                dominantColor: null,
                keywords: null,
                versions: [
                  {
                    id: payload.id,
                    getvLabel: "ORIGIN",
                    height: 0,
                    width: 0,
                    url: payload.url,
                    type: data.file.type,
                    access: null,
                    access_expire: null,
                  },
                ],
              };
              addImageToLookup(imageForStore);
              //add entry to imageLookup
              setAImgUploadProg(false);
              setAV_ImgDialog(false);
            }
          },
          function error(error) {
            setAImgUploadProg(false);
            setAImgUploadError("Die Speicherung ist leider fehlgeschlagen.");
          }
        );
      } else {
        uploadImageToServer(data.file, data.name, data.originalFileName)
          .then((entry) => {
            if (entry) {
              const newImages = images ? images : [];
              let version = null;
              for (let i = 0; i < entry.versions.length; i++) {
                const v = entry.versions[i];
                //default to first version
                if (!version) {
                  version = v;
                }
                //search for medium pic
                if (v && v.vLabel) {
                  if (v.vLabel === DEFAULT_IMAGE_SIZE) {
                    version = v;
                  }
                }
              }
              if (entry && version) {
                newImages.push({
                  id: entry.id,
                  url: URL_TO_MEDIA_SERVER + version.url,
                  key: version.access,
                  alt: null,
                  title: entry.title,
                  copyright: entry.copyright,
                  secured: version.access ? true : false,
                  showPlaceholder: false,
                });

                //call adaption this stored entry
                setImages(newImages);
                //add entry to imageLookup
                addImageToLookup(entry);
              }
            }
            if (androidVersion) {
              setAImgUploadProg(false);
              setAV_ImgDialog(false);
            } else {
              setImgUploadProg(false);
              setV_ImgDialog(false);
            }
          })
          .catch((error) => {
            if (androidVersion) {
              setAImgUploadProg(false);
              setAImgUploadError("Upload ist leider fehlgeschlagen.");
            } else {
              setImgUploadProg(false);
              setImgUploadError("Upload ist leider fehlgeschlagen.");
            }
          });
      }
    }
  }

  function convertDocIdToTitle(documentId) {
    const defValue = "dieses Dokument";

    const newDocuments = documents ? documents : [];
    for (let i = 0; i < newDocuments.length; i++) {
      if (newDocuments[i].id === documentId) {
        return newDocuments[i].title ? newDocuments[i].title : defValue;
      }
    }
    return defValue;
  }

  function convertImgIdToTitle(imageId) {
    const defaultValue = "dieses Bild";
    const newImages = images ? images : [];
    for (let i = 0; i < newImages.length; i++) {
      if (newImages[i].id === imageId) {
        return newImages[i].title ? newImages[i].title : defaultValue;
      }
    }
    return defaultValue;
  }

  function save() {
    const causes = [];
    causeArray.forEach((cause) => causes.push(cause.id));
    if (validateInputs()) {
      if (onSave) {
        onSave({
          id: entryid,
          title: title,
          date: date,
          categoryId: category ? category : null,
          categoryLabel: convertCatIdToLabel(category, categoryTree, null),
          stateId:
            auff_type === TYPE_HINWEIS.value ? null : state ? state.id : null,
          typeValue: auff_type ? auff_type : null,
          causeArray:
            auff_type === TYPE_HINWEIS.value ? null : causes ? causes : null,
          description: description,
          documents: documents,
          images: images,
        });
      }
    }
  }

  function convertCatIdToLabel(catId, array, label) {
    if (array && catId) {
      for (const cat of array) {
        const currLabel = label ? label : cat.label;
        if (cat.key === catId) {
          return currLabel;
        }
        if (cat.children) {
          const result = convertCatIdToLabel(catId, cat.children, currLabel);
          if (result) {
            return result;
          }
        }
      }
    }
  }

  function cancel(e) {
    if (onCancel) {
      onCancel(e);
    }
  }

  function hide() {
    if (onHide) {
      onHide();
    }
  }

  //////////////
  // INTERNAL //
  //////////////

  function validateInputs() {
    let allValid = true;
    if (!title || title === "") {
      setErrorTitle(true);
      allValid = false;
    } else {
      setErrorTitle(false);
    }
    if (!date) {
      setErrorDate(true);
      allValid = false;
    } else {
      setErrorDate(false);
    }
    if (!category) {
      setErrorCategory(true);
      allValid = false;
    } else {
      setErrorCategory(false);
    }
    if (!state && auff_type !== TYPE_HINWEIS.value) {
      setErrorState(true);
      allValid = false;
    } else {
      setErrorState(false);
    }
    if (!auff_type) {
      setErrorAuffType(true);
      allValid = false;
    } else {
      setErrorAuffType(false);
    }
    return allValid;
  }

  function addCauseToArray(cause) {
    const causes = _.cloneDeep(causeArray);
    const causeToAdd = _.find(CAUSES_DROPDOWN, function (dValue) {
      return dValue.value === cause;
    });
    if (causeToAdd) {
      causes.push({
        label: causeToAdd.label,
        id: causeToAdd.value,
        removeable: true,
      });
    }
    setCauseArray(causes);
    filterCauseDropdown(causes);
  }

  function onClickChip(e, id) {
    setInfoDialog(true);
    setInfoId(id);
  }

  function onRemoveChip(e, id) {
    let causes = _.cloneDeep(causeArray);
    _.remove(causes, function (cause) {
      return cause.id === id;
    });

    setCauseArray(causes);
    filterCauseDropdown(causes);
  }

  function filterCauseDropdown(chipArray) {
    const filteredDropdown = _.cloneDeep(CAUSES_DROPDOWN);
    const causeIdsToRemove = [];
    if (chipArray) {
      chipArray.forEach((chip) => causeIdsToRemove.push({ value: chip.id }));
    }
    const newCauseDropdown = _.differenceBy(
      filteredDropdown,
      causeIdsToRemove,
      "value"
    );

    setCauseDropDown(newCauseDropdown);
    /*     setforceRerender(!forceRerender); */
  }

  function changeType(changedType) {
    setAdaptedAuffType(true);
    setAuffType(changedType);
    if (changedType === TYPE_HINWEIS.value) {
      setState(null);
      setCause(null);
    }
  }

  function downloadDocument(entryId) {
    const currDocuments = documents ? documents : [];
    for (let i = 0; i < documents.length; i++) {
      if (currDocuments[i].id === entryId) {
        if (offlineMode) {
          //just open the document
          openStoredMediaFileOnAndroid(
            liegenschaftId,
            projectId,
            entryId,
            currDocuments[i].filename,
            currDocuments[i].mime,
            function error() {
              toast.current.show({
                severity: "error",
                summary: "Fehler",
                detail: "Das Dokument kann leider nicht geöffnet werden",
                sticky: false,
                closable: true,
                life: 2000,
              });
            }
          );
        } else {
          const src = currDocuments[i].fileSrc;
          const secretKey = currDocuments[i].fileKey;
          downloadDocumentFromServer(src, secretKey)
            .then((entry) => {
              execIfOnWeb(() => {
                fileDownload(entry, currDocuments[i].filename);
              });
              execIfOnAndroid(() => {
                writeDocumentFromBlobOnAndroid(
                  entry,
                  currDocuments[i].filename,
                  entry.type,
                  true
                );
              });
            })
            .catch((error) => {
              toast.current.show({
                severity: "error",
                summary: "Fehler",
                detail: "Der Download ist leider fehlgeschlagen",
                sticky: false,
                closable: true,
                life: 2000,
              });
            });
        }
        break;
      }
    }
  }

  function getCleanCatTree() {
    if (!catTree && categoryTree) {
      const clonedTree = _.cloneDeep(categoryTree);
      const tree = filterIfCatNoGiven(clonedTree);
      setCatTree(tree);
      return tree;
    }
    return catTree;
  }

  function filterIfCatNoGiven(arr) {
    return arr
      .filter((item) => item.categoryNo)
      .map((item) => {
        item = Object.assign({}, item);
        if (item.children) {
          item.children = filterIfCatNoGiven(item.children);
        }
        return item;
      });
  }

  /* view elements */

  const injectAndroidCameraDialog = () => {
    return (
      <BeeAndroidCameraDialog
        id={"bee-audit-question-ui-document"}
        header={"Bild hochladen"}
        visible={v_AImgDialog}
        showProgress={aImgUploadProg}
        errorMsg={aImgUploadError ? aImgUploadError : null}
        type={"primary"}
        img_quality={IMG_ANDROID_QUALITY}
        img_width={IMG_ANDROID_WIDTH}
        img_height={IMG_ANDROID_HEIGHT}
        img_preserveAspectRatio={IMG_ANDROID_PRESERVE_ASPECT_RATIO}
        onHide={() => setAV_ImgDialog(false)}
        onUpload={(data) => createNewImage(data, true)}
      />
    );
  };

  const injectImageGallery = () => {
    return (
      <BeeImageSlider
        className={"bee-img-slider"}
        type={"primary"}
        ref={gallery}
        images={images ? images : []}
        activeIndex={0}
      />
    );
  };

  const injectImageUploadDialog = () => {
    return (
      <BeeUploadDialog
        type={"primary"}
        visible={v_ImgDialog}
        disabled={false}
        locale={"de-DE"}
        header={"Bild hochladen"}
        info={null}
        titleLabel={"Benennung"}
        titleVisible={true}
        copyrightLabel={null}
        copyrightVisible={false}
        dropzoneTitle={"Upload"}
        dropzoneDescription={
          "Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder Klicken Sie hinzufügen"
        }
        dropzoneAddLabel={"Klicken zum Hinzufügen"}
        dropzoneFormats={ACCEPTED_IMAGE_FORMATS}
        progressVisible={imgUploadProg}
        progressMessage={"Bild wird hochgeladen"}
        errorVisible={imgUploadError ? true : false}
        errorHeadline={imgUploadError}
        errorDescription={imgUploadError}
        onHide={() => {
          setV_ImgDialog(false);
          setImgUploadError(null);
        }}
        onUpload={(data) => createNewImage(data, false)}
      />
    );
  };

  const injectImageDeletionConfirmationDialog = () => {
    return (
      <BeeDeletionDialog
        id={"bee-audit-ui-audit-confirm-image-deletion"}
        visible={v_ImgDelDialog}
        message={
          "Möchten Sie " + convertImgIdToTitle(currImgId) + " wirklich löschen?"
        }
        acceptLabel={"Löschen"}
        rejectLabel={"Abbrechen"}
        header={"Bild löschen"}
        type={"primary"}
        onAccept={() => {
          if (currImgId) {
            deleteImage(currImgId);
          }
          setV_ImgDelDialog(false);
        }}
        onReject={() => setV_ImgDelDialog(false)}
        onHide={() => setV_ImgDelDialog(false)}
      />
    );
  };

  const injectFileUploadDialog = () => {
    return (
      <BeeUploadDialog
        type={"primary"}
        visible={v_DocDialog}
        disabled={false}
        locale={"de-DE"}
        header={"Dokument hochladen"}
        info={null}
        titleLabel={"Benennung"}
        titleVisible={true}
        copyrightLabel={null}
        copyrightVisible={false}
        dropzoneTitle={"Upload"}
        dropzoneDescription={
          "Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder Klicken Sie hinzufügen"
        }
        dropzoneAddLabel={"Klicken zum Hinzufügen"}
        dropzoneFormats={ACCEPTED_FILE_FORMATS}
        progressVisible={docUploadProg}
        progressMessage={"Dokument wird hochgeladen"}
        errorVisible={docUploadError ? true : false}
        errorHeadline={docUploadError}
        errorDescription={docUploadError}
        onHide={() => setV_DocDialog(false)}
        onUpload={(data) => createNewDocument(data)}
      />
    );
  };

  const injectFileDeletionConfirmationDialog = () => {
    return (
      <BeeDeletionDialog
        id={"bee-audit-ui-audit-confirm-document-deletion"}
        visible={v_DocDelDialog}
        message={
          "Möchten Sie " + convertDocIdToTitle(currDocId) + " wirklich löschen?"
        }
        acceptLabel={"Löschen"}
        rejectLabel={"Abbrechen"}
        header={"Dokument löschen"}
        type={"primary"}
        onAccept={() => {
          if (currDocId) {
            deleteDocument(currDocId);
          }
          setV_DocDelDialog(false);
        }}
        onReject={() => setV_DocDelDialog(false)}
        onHide={() => setV_DocDelDialog(false)}
      />
    );
  };

  const injectTitle = () => {
    return (
      <BeeValidatedTextInput
        readOnly={readOnly}
        label={title_label}
        errorLabel={errorTitle && !adaptedTitle ? title_error_msg : null}
        neutralLabel={errorTitle && adaptedTitle ? title_error_msg : null}
        value={title ? title : ""}
        disabled={false}
        formstate={
          errorTitle && adaptedTitle ? "neutral" : errorTitle ? "error" : "none"
        }
        required={true}
        onChange={(e) => {
          setAdaptedTitle(true);
          setTitle(e.target.value);
        }}
      />
    );
  };

  const injectDate = () => {
    return (
      <BeeValidatedDateInput
        label={date_label}
        errorLabel={errorDate && !adaptedDate ? date_error_msg : null}
        neutralLabel={errorDate && adaptedDate ? date_error_msg : null}
        value={date}
        disabled={false}
        formstate={
          errorDate && adaptedDate ? "neutral" : errorDate ? "error" : "none"
        }
        readOnly={readOnly}
        required={true}
        onChange={(e) => {
          setAdaptedDate(true);
          setDate(e.target.value);
        }}
      />
    );
  };

  const injectCategory = () => {
    return (
      <>
        <BeeValidatedTreeSelect
          label={category_label}
          errorLabel={
            errorCategory && !adaptedCategory ? category_error_msg : null
          }
          neutralLabel={
            errorCategory && adaptedCategory ? category_error_msg : null
          }
          value={category}
          options={getCleanCatTree()}
          disabled={false}
          formstate={
            errorCategory && adaptedCategory
              ? "neutral"
              : errorCategory
              ? "error"
              : "none"
          }
          readOnly={readOnly}
          required={true}
          onChange={(category) => {
            setAdaptedCategory(true);
            setCategory(category);
          }}
        />
      </>
    );
  };

  const injectStatus = () => {
    return (
      <BeeValidatedDropDown
        label={state_label}
        errorLabel={errorState && !adaptedState ? state_error_msg : null}
        neutralLabel={errorState && adaptedState ? state_error_msg : null}
        value={state}
        options={STATES_OF_AUFFALLIGKEIT}
        optionLabel={"title"}
        disabled={false}
        formstate={
          errorState && adaptedState ? "neutral" : errorState ? "error" : "none"
        }
        readOnly={auff_type === TYPE_HINWEIS.value || readOnly}
        required={true}
        onChange={(state) => {
          setAdaptedState(true);
          setState(state);
        }}
      />
    );
  };

  const injectType = () => {
    return (
      <div className={"grid"}>
        <div className={"col-12"}>
          <BeeValidatedDropDown
            label={auff_type_label}
            errorLabel={
              errorAuffType && !adaptedAuffType ? auff_type_error_msg : null
            }
            neutralLabel={
              errorAuffType && adaptedAuffType ? auff_type_error_msg : null
            }
            value={auff_type}
            options={TYPE_DROPDOWN}
            optionLabel={"label"}
            disabled={false}
            formstate={
              errorAuffType && adaptedAuffType
                ? "neutral"
                : errorAuffType
                ? "error"
                : "none"
            }
            readOnly={readOnly}
            required={true}
            onChange={(type) => {
              changeType(type);
            }}
          />
        </div>
        {auff_type ? (
          <div className={"col-12 pt-0 pb-0"}>
            <BeeContentParagraph
              text={
                auff_type === TYPE_MANGEL.value
                  ? TYPE_MANGEL.info
                  : auff_type === TYPE_DEFIZIT.value
                  ? TYPE_DEFIZIT.info
                  : auff_type === TYPE_HINWEIS.value
                  ? TYPE_HINWEIS.info
                  : null
              }
              size={"small"}
              type={"primary"}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const injectCause = () => {
    return (
      <>
        {readOnly ? null : (
          <BeeValidatedDropDown
            label={cause_label}
            value={cause}
            options={causeDropDown}
            optionLabel={"label"}
            disabled={false}
            formstate={"neutral"}
            readOnly={
              auff_type === TYPE_HINWEIS.value || readOnly || !auff_type
            }
            required={false}
            onChange={(cause) => {
              addCauseToArray(cause);
            }}
          />
        )}
      </>
    );
  };

  const injectCauseArea = () => {
    return (
      <BeeChipArea
        type={"primary"}
        label={readOnly ? "Ursachen" : ""}
        data={causeArray}
        readOnly={readOnly}
        onClick={(e, id) => onClickChip(e, id)}
        onRemove={(e, id) => onRemoveChip(e, id)}
      />
    );
  };

  const injectDescription = () => {
    return (
      <BeeValidatedTextArea
        label={description_label}
        value={description ? description : ""}
        rows={5}
        disabled={false}
        autoResize={false}
        formstate={"neutral"}
        readOnly={readOnly}
        required={false}
        onChange={(e) => setDescription(e.target.value)}
      />
    );
  };

  const injectImages = () => {
    return (
      <>
        {readOnly && data.images.length < 1 ? null : (
          <>
            <BeeContentHeadline label={imgHeadline} headline={"h3"} />
            <BeeFlatImageGallery
              activateHover={activateHover}
              readOnly={readOnly}
              showAddOption={!readOnly}
              images={images}
              onClick={(imageId) => clickImageCallback(imageId)}
              onRemove={(imageId) => removeImageCallback(imageId)}
              onAdd={(e) => addImageCallback(e)}
            />
          </>
        )}
      </>
    );
  };

  const injectDocuments = () => {
    return (
      <>
        {readOnly && data.documents.length < 1 ? null : (
          <>
            <BeeContentHeadline label={docHeadline} headline={"h3"} />
            <BeeDocumentTable
              data={documents}
              type={"dark"}
              readOnly={readOnly}
              showDownload={true}
              showDelete={!readOnly}
              onDownload={(documentId) => downloadDocument(documentId)}
              onDelete={(documentId) => deleteDocumentCallback(documentId)}
            />
            {!readOnly ? (
              <div className="d-inline-flex">
                <BeeOutlinedButton
                  label={addfileBtnLabel}
                  disabled={readOnly}
                  type={"primary"}
                  onClick={(e) => addDocumentCallback(e)}
                />
              </div>
            ) : null}
          </>
        )}
      </>
    );
  };

  const injectButtonRow = () => {
    return (
      <div className={"grid justify-content-end"}>
        {readOnly ? (
          <BeeButton
            label={readOnlyRejectLabel}
            disabled={false}
            type={type}
            onClick={() => hide()}
          />
        ) : (
          <>
            <BeeLinkButton
              label={rejectLabel}
              raised={false}
              disabled={false}
              type={"primary"}
              onClick={(e) => cancel(e)}
            />
            <BeeButton
              label={acceptLabel}
              disabled={false}
              type={type}
              onClick={() => save()}
            />
          </>
        )}
      </div>
    );
  };

  const injectInfoDialog = () => {
    let cause = _.find(CAUSES_DROPDOWN, function (c) {
      return c.value === infoId;
    });
    return (
      <BeeInfoDialog
        type={"default"}
        visible={v_InfoDialog}
        message={cause.info ? cause.info : ""}
        acceptLabel={"Ok"}
        header={cause.label ? cause.label : ""}
        onAccept={() => setInfoDialog(false)}
      />
    );
  };

  return (
    <>
      <Dialog
        id={currentId}
        header={headerLabel}
        visible={visible}
        className={classNames}
        modal={true}
        onHide={() => hide()}
      >
        <div className="grid">
          <div className="col-8">{injectTitle()}</div>
          <div className="col-4">{injectDate()}</div>
          <div className="col-8">{injectCategory()}</div>
          <div className={"col-4 "}>{injectStatus()}</div>
          <div className={"col-4 " + typeColClassNames}>{injectType()}</div>
          <div className="col-8">
            {<div>{injectCause()}</div>}
            {auff_type === TYPE_HINWEIS.value || auff_type === null ? null : (
              <div>{injectCauseArea()}</div>
            )}
          </div>
          <div className="col-12">{injectDescription()}</div>
          <div className={"col-12 " + imgColClassNames}>{injectImages()}</div>
          <div className={"col-12 " + docColClassNames}>
            {injectDocuments()}
          </div>
          <div className={"col-12 " + btnRowClassNames}>
            {injectButtonRow()}
          </div>
        </div>
      </Dialog>
      {/* dialog */}
      {v_InfoDialog ? injectInfoDialog() : null}
      {v_DocDialog ? injectFileUploadDialog() : null}
      {injectFileDeletionConfirmationDialog()}
      {v_ImgDialog ? injectImageUploadDialog() : null}
      {injectImageDeletionConfirmationDialog()}
      {v_AImgDialog ? injectAndroidCameraDialog() : null}
      {/* gallery */}
      {injectImageGallery()}
    </>
  );
}

export default BeeAuditDialogAuffalligkeiten;
