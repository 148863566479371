import React, { useState, useEffect } from "react";
import { BeeTextArea } from "bee-atomic-components";
import { useDebouncedValue } from "../../Helper/DebouncedValue";

export function BeeDebouncedTextArea({
  label,
  value,
  rows,
  autoResize,
  disabled,
  formstate,
  readOnly,
  required,
  delay,
  onChange,
}) {
  const [entry, setEntry] = useState(value);
  const currDelay = delay ? delay : 500;
  const debouncedValue = useDebouncedValue(entry, currDelay);

  useEffect(() => {
    setEntry(value);
  }, [value]);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <BeeTextArea
      label={label}
      value={entry}
      rows={rows}
      disabled={disabled}
      autoResize={autoResize}
      formstate={formstate}
      readOnly={readOnly}
      required={required}
      onChange={(e) => setEntry(e.target.value)}
    />
  );
}

export default BeeDebouncedTextArea;
