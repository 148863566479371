import React, { useState } from "react";
import { useId } from "react-id-generator";
import { Card } from "primereact/card";
import {
  BeeContentHeadline,
  BeeContentParagraph,
  BeeButton,
  BeeOutlinedButton,
  BeeValidatedPasswordInput,
  BeeValidatedTextInput,
  BeeLoadingSpinner,
} from "bee-atomic-components";
import "./BeeAuditLogin.scss";
import { ENTER } from "../../Helper/keyCodes";

export function BeeAuditLogin({
  headline,
  showError,
  showProgress,
  showOfflineMode,
  onLogin,
  onStartOffline,
}) {
  const currentId = useId(1, "bee-audit-login-")[0];

  const [username, setUsername] = useState(undefined);
  const [password, setPassword] = useState(undefined);

  //classNames
  const classNames =
    "grid formgrid justify-content-center align-content-center bee-login ";
  const contentClassNames = "col-12 sm:col-9 md:col-6 lg:col-5 fluid";
  const loginBtnClassNames = "field bee-login-login-btn";
  const loginHeaderClassNames = "field bee-login-header";

  //label
  const labelUsernameInput = "Nutzername";
  const labelPasswordInput = "Passwort";
  const labelLoginBtn = "Login";
  const labelUseOfflineBtn = "Offline verwenden";
  const labelOfflineDescription =
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.";
  const labelErrorMessageLoginFailed = "Login ist leider fehlgeschlagen!";

  const formstate = showError ? "error" : "none";

  function login() {
    if (onLogin) {
      onLogin(username, password);
    }
  }

  function startOffline(e) {
    if (onStartOffline) {
      onStartOffline(e);
    }
  }
  function handleKeyDown(e) {
    if (e.keyCode === ENTER && !e.altKey) {
      login();
    }
  }

  const injectLoginContent = () => {
    return (
      <div>
        <div className="field">
          <BeeValidatedTextInput
            label={labelUsernameInput}
            value={username}
            disabled={false}
            readOnly={false}
            required={false}
            formstate={formstate}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div
          className="field"
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
        >
          <BeeValidatedPasswordInput
            label={labelPasswordInput}
            errorLabel={labelErrorMessageLoginFailed}
            value={password}
            disabled={false}
            showPwStrength={false}
            showHint={false}
            formstate={formstate}
            readOnly={false}
            required={false}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className={loginBtnClassNames}>
          <BeeButton
            label={labelLoginBtn}
            disabled={!(username && password)}
            type={"primary"}
            onClick={() => login()}
          />
          {showProgress ? (
            <BeeLoadingSpinner type="secondary" strokeWidth="3" />
          ) : null}
        </div>

        {showOfflineMode ? (
          <>
            <div className="field">
              <BeeContentParagraph
                text={labelOfflineDescription}
                size={"xSmall"}
                type={"default"}
              />
            </div>
            <div className="field">
              <BeeOutlinedButton
                label={labelUseOfflineBtn}
                disabled={false}
                type={"primary"}
                onClick={(e) => startOffline(e)}
              />
            </div>
          </>
        ) : null}
      </div>
    );
  };

  return (
    <div id={currentId} className={classNames}>
      <div className={contentClassNames}>
        <Card>
          <div className={loginHeaderClassNames}>
            <BeeContentHeadline
              label={headline}
              headline={"h1"}
              type={"primary"}
            />
          </div>
          {injectLoginContent()}
        </Card>
      </div>
    </div>
  );
}

export default BeeAuditLogin;
