import React, { useRef } from "react";
import {
  BeeContentHeadline,
  BeeContentParagraph,
  BeeOutlinedButton,
  BeeToast,
} from "bee-atomic-components";

import _ from "lodash";

import BeeAuditHeader from "../Fragments/BeeAuditHeader";
import BeeAuditTabs from "../Fragments/BeeAuditTabs";
import BeeAuditAuffalligkeitenTable from "../Fragments/BeeAuditAuffalligkeitenTable";
import "./AuditUIAuffalligkeit.scss";

function AuditUIAuffalligkeit({
  homeUrl,
  breadcrumbData,
  liegenschaftId,
  projectId,
  auffalligkeiten,
  warningMessage,
  currentUser,
  viewOptions,
  selectedView,
  readOnly,
  offlineMode,
  showLogout,
  categoryTree,
  onSave,
  onLogout,
  onSelectView,
  onViewAuffalligkeit,
  onEditAuffalligkeit,
  onDeleteAuffalligkeit,
  onAddAuffalligkeit,
  onChangeAuffalligkeiten,
  onAddDocumentToLookup,
  onAddImageToLookup,
}) {
  //classNames
  const classNames = "bee-audit-overview";
  const contentClassNames = "m-4 p-2 ";
  //label
  const addAuffalligkeitLabel = "Auffälligkeit hinzufügen";

  //references

  const toast = useRef(null);

  ///////////////////////
  // OUTSIDE CALLBACKS //
  ///////////////////////
  function addToDocLookup(document) {
    if (!readOnly && onAddDocumentToLookup) {
      onAddDocumentToLookup(document);
    }
  }

  function addToImgLookup(image) {
    if (!readOnly && onAddImageToLookup) {
      onAddImageToLookup(image);
    }
  }

  function changeAuffalligkeiten(data) {
    if (onChangeAuffalligkeiten) {
      onChangeAuffalligkeiten(data);
    }
  }

  function viewAuffalligkeit(entryId) {
    if (onViewAuffalligkeit) {
      onViewAuffalligkeit(entryId);
    }
  }
  function editAuffalligkeit(entryId) {
    if (onEditAuffalligkeit) {
      onEditAuffalligkeit(entryId);
    }
  }
  function deleteAuffalligkeit(entryId) {
    if (onDeleteAuffalligkeit) {
      onDeleteAuffalligkeit(entryId);
    }
  }
  function addAuffalligkeit() {
    if (onAddAuffalligkeit) {
      onAddAuffalligkeit();
    }
  }

  function selectView(view) {
    if (onSelectView) {
      onSelectView(view);
    }
  }

  function logout() {
    if (onLogout) {
      onLogout();
    }
  }

  function save() {
    if (onSave) {
      onSave();
    }
  }

  const injectNotificationToast = () => {
    return (
      <BeeToast
        id={"bee-audit-ui-auffalligkeiten-toast"}
        ref={toast}
        position={"top-right"}
      />
    );
  };

  const injectHeader = () => {
    return (
      <BeeAuditHeader
        homeUrl={homeUrl}
        items={breadcrumbData}
        type={"dark"}
        maxProgress={
          categoryTree && categoryTree.length > 0 && categoryTree[0].data
            ? categoryTree[0].data.questions
            : 0
        }
        progress={
          categoryTree && categoryTree.length > 0 && categoryTree[0].data
            ? categoryTree[0].data.progress
            : 0
        }
        warningMessage={warningMessage}
        showSave={!readOnly}
        showLogout={showLogout}
        showProgress={true}
        showLeftRight={false}
        onSave={() => save()}
        onLogout={() => logout()}
      />
    );
  };

  const injectTabs = () => {
    return (
      <BeeAuditTabs
        options={viewOptions}
        selectedOption={selectedView}
        disabled={false}
        type={"primary"}
        onSelect={(option) => selectView(option)}
      />
    );
  };

  const injectAuffalligkeiten = () => {
    return (
      <>
        <div className={"flex align-items-center justify-content-between"}>
          <BeeContentHeadline
            label={"Auffälligkeiten"}
            headline={"h2"}
            type={"primary"}
          />
          {!readOnly ? (
            <BeeOutlinedButton
              label={addAuffalligkeitLabel}
              disabled={readOnly}
              type={"secondary"}
              onClick={() => addAuffalligkeit()}
            />
          ) : null}
        </div>
        <BeeContentParagraph
          text={
            <div className={"bee-audit-ui-auff-beschreibung"}>
              <div>
                {
                  "Im Zuge der Objektbegehungen und der Beurteilung der Qualität der durch den Dienstleister erbrachten Leistungen können Sie hier Auffälligkeiten dokumentiert. Hierbei wird grundsätzlich nach drei Kategorien unterschieden, Hinweisen, Defiziten und Mängeln."
                }
              </div>
              <div>
                <span className={"audit-ui-auff-bold"}>{"1.	"}</span>
                <span>
                  {" "}
                  {"Sie können auf Besonderheiten/ Merkmale durch einen  "}
                </span>
                <span className={"audit-ui-auff-bold"}>{"Hinweis"}</span>
                <span>
                  {
                    " aufmerksam machen, welche die Leistungsqualität treffend dokumentieren, also beispielsweise auch korrekt erfüllte Leistungen."
                  }
                </span>
              </div>
              <div>
                <span className={"audit-ui-auff-bold"}>{"2.	"}</span>
                <span> {"Sie weisen auf "}</span>
                <span className={"audit-ui-auff-bold"}>{"Defizite"}</span>
                <span>
                  {
                    " hin, welche im Rahmen der Betreiberverantwortung des AG behoben werden sollten, allerdings nicht im Leistungsumfang des Dienstleisters liegen. Weitere Defizite könnten Schlechtleistungen des Dienstleisters sein, welche auch als solche durch den Auditor bewertet wurden, aber nicht mehr im laufenden Vertragsjahr behoben werden müssen."
                  }
                </span>
              </div>
              <div>
                <span className={"audit-ui-auff-bold"}>{"3.	"}</span>
                <span className={"audit-ui-auff-bold"}>{"Mängel"}</span>
                <span>
                  {
                    " liegen im Leistungsumfang und Verantwortung des Dienstleisters und sollten behoben werden. Die Nachverfolgung kann durch Sie oder Dienstleistungssteuerer des AG nachverfolgt werden."
                  }
                </span>
              </div>
            </div>
          }
          size={"medium"}
          type={"primary"}
        />
        <BeeAuditAuffalligkeitenTable
          type={"dark"}
          data={auffalligkeiten}
          readOnly={readOnly}
          showEdit={true}
          showView={true}
          showDelete={!readOnly}
          onView={(entryId) => viewAuffalligkeit(entryId)}
          onDelete={(entryId) => deleteAuffalligkeit(entryId)}
          onEdit={(entryId) => editAuffalligkeit(entryId)}
        />
        {!readOnly ? (
          <BeeOutlinedButton
            label={addAuffalligkeitLabel}
            disabled={readOnly}
            type={"secondary"}
            onClick={() => addAuffalligkeit()}
          />
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className={classNames}>
        {injectHeader()}
        {injectTabs()}
        <div className={contentClassNames}>{injectAuffalligkeiten()}</div>
      </div>
      {/* toast */}
      {injectNotificationToast()}
    </>
  );
}

export default AuditUIAuffalligkeit;
