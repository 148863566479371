import React from "react";
import { useId } from "react-id-generator";
import { Button } from "primereact/button";
//theme
import "../../aa_style/generaltheme.css";
import "./BeeAuditQuestionMultipleChoice.scss";
import { BeeContentParagraph } from "bee-atomic-components";

export function BeeAuditQuestionMultipleChoice({
  answers,
  selectedId,
  optionNotAccurate,
  disabled,
  readOnly,
  onClickAnswer,
}) {
  const currentId = useId(1, "bee-audit-multi-choice-")[0];
  //classNames
  const classNames = "bee-audit-multi-choice";
  const questionBtnClassNames = "p-button-outlined bee-outlined-btn-primary";
  //label
  const noAnswerLabel = "Frage trifft nicht zu";

  function onClick(e, id) {
    if (onClickAnswer && !disabled) {
      onClickAnswer(e, id);
    }
  }

  const calcClassNames = (id) => {
    let style = "mb-2 bee-audit-multi-choice-btn";
    let readOnlyClassNames = readOnly ? " bee-audit-multi-choice-readOnly" : "";
    let classNames = style + readOnlyClassNames;
    return id === selectedId
      ? (classNames += " bee-audit-multi-choice-selected")
      : classNames;
  };

  function calcIconColor(weight) {
    return pickColor(weight, [144, 204, 102], [255, 24, 0]);
  }

  function pickColor(weight, color1, color2) {
    let w1 = weight / 100;
    let w2 = 1 - w1;
    let rgb = [
      Math.round(color1[0] * w1 + color2[0] * w2),
      Math.round(color1[1] * w1 + color2[1] * w2),
      Math.round(color1[2] * w1 + color2[2] * w2),
    ];
    return rgb.toString();
  }

  const createQuestionTemplate = (id, text, description, weight) => {
    return (
      <div
        className={calcClassNames(id)}
        key={id}
        style={
          weight === 0 || weight
            ? {
                "--weightcolor": calcIconColor(weight),
                "--weightbasecolor": "grey",
              }
            : {
                "--weightcolor": "160,160,160",
                "--weightbasecolor": "transparent",
              }
        }
      >
        <Button
          label={injectBtnLabel(description, id, text)}
          onClick={(e) => onClick(e, id)}
          disabled={disabled}
          className={questionBtnClassNames}
        />
      </div>
    );
  };
  function injectBtnLabel(description, id, text) {
    return description && id === selectedId ? (
      <>
        <span>{text + "\n"}</span>
        <span className="bee-audit-q-description">{description}</span>
      </>
    ) : (
      text
    );
  }

  const injectAnswers = () => {
    let result = [];
    if (answers !== null) {
      for (let i = 0; i < answers.length; i++) {
        const id = answers[i].id;
        const text = answers[i].text;
        const weight = answers[i].weight;
        const descripton = answers[i].description;
        result.push(createQuestionTemplate(id, text, descripton, weight));
      }
      if (optionNotAccurate) {
        result.push(createQuestionTemplate(-1, noAnswerLabel, null));
      }
    }
    return result;
  };

  return (
    <div id={currentId} className={classNames}>
      {injectAnswers()}
    </div>
  );
}

export default BeeAuditQuestionMultipleChoice;
