import React, { useState, useEffect } from "react";
import { useId } from "react-id-generator";
import {
  BeeContentHeadline,
  BeeContentParagraph,
  BeeIconButton,
  BeeLoadingBar,
  BeeStarBar,
} from "bee-atomic-components";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import "./BeeAuditOverview.scss";
import BeeAuditHeader from "../Fragments/BeeAuditHeader";
import BeeAuditTabs from "../Fragments/BeeAuditTabs";
import _ from "lodash";

export function BeeAuditOverview({
  categories,
  categoryRating,
  auffalligkeitenValue,
  homeUrl,
  breadcrumbData,
  overallProgress,
  maxOverallProgress,
  warningMessage,
  viewOptions,
  selectedView,
  showSave,
  showLogout,
  onCategorySelect,
  onSave,
  onLogout,
  onSelectView,
}) {
  const currentId = useId(1, "bee-audit-overview-")[0];
  const [expandedKeys, setExpandedKeys] = useState(null);

  //classNames
  const classNames = " bee-audit-overview";
  const tableClassNames = "m-4 p-2 bee-audit-overview-table";
  const categoryColTitleClassNames = "bee-audit-overview-category-template";
  const actionColClassNames = "bee-audit-overview-table-col-action";

  //label
  const categoryColLabel = "Kategorie";
  const progressColLabel = "Vollständigkeit";
  const ratingColLabel = "";
  const actionColLabel = "";

  //run once (expand keys by default)
  useEffect(() => {
    if (categories && !expandedKeys) {
      let keys = { ...expandedKeys };
      for (let i = 0; i < categories.length; i++) {
        keys[categories[i].key] = true;
        if (categories[i].children) {
          //maybe deeper if needed
          for (let j = 0; j < categories[i].children.length; j++) {
            keys[categories[i].children[j].key] = true;
          }
        }
      }
      setExpandedKeys(keys);
    }
  }, []);

  function selectView(selection) {
    if (onSelectView && selection) {
      onSelectView(selection);
    }
  }

  function selectCategory(category) {
    if (onCategorySelect) {
      onCategorySelect(category);
    }
  }

  function logoutCallback(e) {
    if (onLogout) {
      onLogout(e);
    }
  }

  function saveCallback(e) {
    if (onSave) {
      onSave(e);
    }
  }

  function openAuffaelligkeiten(rowDataId) {
    //FIXME implement
  }

  function categoryTemplate(node, column) {
    return <div className={categoryColTitleClassNames}>{node.data.title}</div>;
  }

  function ratingTemplate(node, column) {
    let val = _.find(categoryRating, ["id", node.key]);
    val = val.value !== null ? _.round(_.divide(val.value, 20), 1) : null;
    return (
      <div>
        <BeeStarBar
          value={val}
          stars={5}
          disabled={val === null}
          readOnly={true}
          type={"primary"}
          size={"1.5rem"}
          onChange={null}
        />
      </div>
    );
  }

  function progressTemplate(node, column) {
    const currProgress = node.data.progress + "/" + node.data.questions;
    const calculatedProgress = node.data.questions
      ? (node.data.progress / node.data.questions) * 100
      : 0;
    return (
      <div>
        <BeeLoadingBar
          value={calculatedProgress}
          indeterminate={false}
          type={"primary"}
        />
        <span>{currProgress}</span>
      </div>
    );
  }

  function actionTemplate(node, column) {
    if (node) {
      return (
        <div headername={""}>
          <BeeIconButton
            iconClass={"pi pi-play"}
            rounded={false}
            disabled={false}
            type={"secondary"}
            onClick={() => selectCategory(node)}
          />

          {/* disabled auffälligkeiten btn */}
          {/*       <BeeIconBadgeButton
            iconClass={"pi pi-exclamation-triangle"}
            value={auffalligkeitenValue}
            disabled={false}
            type={"primary"}
            onClick={() => openAuffalikeiten(node.id)}
          /> */}
        </div>
      );
    }
  }

  function injectHeader() {
    return (
      <BeeAuditHeader
        homeUrl={homeUrl}
        items={breadcrumbData}
        type={"dark"}
        maxProgress={maxOverallProgress}
        progress={overallProgress}
        warningMessage={warningMessage}
        showSave={showSave}
        showLogout={showLogout}
        showProgress={true}
        showLeftRight={false}
        onSave={(e) => saveCallback(e)}
        onLogout={(e) => logoutCallback(e)}
      />
    );
  }

  function injectTabs() {
    return (
      <BeeAuditTabs
        options={viewOptions}
        selectedOption={selectedView}
        disabled={false}
        type={"primary"}
        onSelect={(option) => selectView(option)}
      />
    );
  }

  function injectOverviewSection() {
    return (
      <>
        <BeeContentHeadline
          label={"Fragekategorien"}
          headline={"h2"}
          type={"primary"}
        />
        <BeeContentParagraph
          text={
            "Alle Fragen zur Beurteilung der Leistungsqualität sind direkt einer Kategorie gemäß Standard-Leistungsverzeichnis zugeordnet. Die Anzahl der Fragen pro Kategorie sowie der bereits beantworteten Fragen (Vollständigkeit) können dem Diagramm entnommen werden. Die jeweilige Kategorie ist direkt mit den Fragenkatalog der Kategorie verlinkt."
          }
          size={"medium"}
          type={"primary"}
        />
        <TreeTable
          value={categories}
          expandedKeys={expandedKeys}
          onToggle={(e) => setExpandedKeys(e.value)}
        >
          <Column
            expander
            field={("title", "subtitle")}
            body={categoryTemplate}
            header={categoryColLabel}
            columnKey="audit-overview-category"
            className={"bee-audit-overview-categoryCol"}
          />
          <Column
            body={ratingTemplate}
            header={ratingColLabel}
            columnKey="audit-overview-rating"
            className={"bee-audit-overview-ratingCol"}
          />
          <Column
            body={progressTemplate}
            header={progressColLabel}
            columnKey="audit-overview-progress"
            className={"bee-audit-overview-progressCol"}
          />
          <Column
            body={actionTemplate}
            header={actionColLabel}
            columnKey="audit-overview-action"
            className={"bee-audit-overview-actionCol"}
          />
        </TreeTable>
      </>
    );
  }

  return (
    <div id={currentId} className={classNames}>
      {injectHeader()}
      {injectTabs()}
      <div className={tableClassNames}>{injectOverviewSection()}</div>
    </div>
  );
}

export default BeeAuditOverview;
