import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import appReducer from "./app/appReducer";
import { encryptTransform } from "redux-persist-transform-encrypt";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["app"],
  transforms: [
    encryptTransform({
      secretKey: "TRgW6hRacTaSAwcF",
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
};

const rootReducer = combineReducers({
  app: appReducer,
});

export default persistReducer(persistConfig, rootReducer);
