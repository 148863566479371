import React from "react";
import { useId } from "react-id-generator";
import BeeAuditHeader from "../Fragments/BeeAuditHeader";
import BeeAuditOfflineSelectTable from "../Fragments/BeeAuditOfflineSelectTable";
import { BeeButton, BeeContentHeadline } from "bee-atomic-components";
import "./BeeAuditOfflineSelect.scss";

export function BeeAuditOfflineSelect({
  homeUrl,
  breadcrumbData,
  warningMessage,
  data,
  onClick,
  onWorkOnline,
}) {
  const currentId = useId(1, "bee-audit-offline-select-")[0];

  //classNames
  const classNames = "bee-audit-offline-select";
  const footerBtnClassNames =
    "grid justify-content-end bee-audit-offline-select-footer-btn";
  const contentClassNames = "m-4 p-2 bee-audit-offline-select-content";

  //label
  const workOnlineLabel = "Online Arbeiten";
  const headline = "Heruntergeladene Audits";

  function showOnline(e) {
    if (onWorkOnline) {
      onWorkOnline(e);
    }
  }

  function run(itemId) {
    if (onClick) {
      onClick(itemId);
    }
  }

  function injectHeader() {
    return (
      <BeeAuditHeader
        homeUrl={homeUrl}
        items={breadcrumbData}
        type={"dark"}
        warningMessage={warningMessage}
        showSave={false}
        showLogout={false}
        showProgress={false}
        onSave={null}
        onLogout={null}
      />
    );
  }
  function injectHeadline() {
    return (
      <BeeContentHeadline label={headline} headline={"h1"} type={"primary"} />
    );
  }

  function injectOfflineSelectSection() {
    return (
      <BeeAuditOfflineSelectTable
        type={"dark"}
        data={data}
        onClick={(itemId) => run(itemId)}
      />
    );
  }

  function injectFooterBtnRow() {
    return (
      <BeeButton
        label={workOnlineLabel}
        disabled={false}
        type={"secondary"}
        onClick={(e) => showOnline(e)}
      />
    );
  }

  return (
    <div id={currentId} className={classNames}>
      {injectHeader()}
      <div className={contentClassNames}>
        {injectHeadline()}
        {injectOfflineSelectSection()}
        <div className={footerBtnClassNames}>{injectFooterBtnRow()}</div>
      </div>
    </div>
  );
}

export default BeeAuditOfflineSelect;
